import { ICompanyEmailSettings } from "./ICompany"
import { EGender } from "./IEmployee"
import { IHolidaySettings } from "./IHolidays"
import { IPermissions, IPermissionGroup } from "./IPermissions"
import { IReference } from "./IReference"
import { ELanguage } from "./Language/languageHelper"
import { ICountriesTexts } from "./Language/languageTexts/countries"
import { IIndustriesTexts } from "./Language/languageTexts/industriesTexts"

export default interface ICompanySettingsDTO
{
    companyId: string
    name: string
    employees: ICompanyUser[]
    style: IStyleDTO
    OUs: IOU[]
    level: ILevel[]
    permissionGroups: IPermissionGroup[]
    defaultPermissionGroup: IReference | undefined
    allowedMailEndings: string[]
    clockodoSync: {
        sync: boolean,
        email: string,
    }
    calculations: ICompanyCalcualtions
    localisation: ICompanyLocalisation
    industry: keyof IIndustriesTexts
    holidaySettings: IHolidaySettings
    emailSettings: ICompanyEmailSettings
    clockodo: ICompanyClockodo
    apiKeys: ICompanyApiKey[]
    version: number;
}

export interface ICompanyApiKey
{
    id: number
    note: string
    createdAt: string
    createdBy: number
}


export enum EClockodoSyncType
{
    /**
    MetaData + TimeEntries 2 Months - fastest
    **/
    SMALL = 'SMALL',
    /**
    MetaData + TimeEntries this year - normal speed
    **/
    NORMAL = 'NORMAL',
    /**
    MetaData + TimeEntries this year & last year - slowest
    **/
    FULL = 'FULL',
}
export enum EClockodoStatus
{
    IDLE = "IDLE",
    SYNCING = "SYNCING",
    SYNCED = "SYNCED",
    ERROR = "ERROR",
}
export interface ICompanyClockodo
{
    status: EClockodoStatus
    lastUpdateProjects: string | null
    lastUpdateCustomer: string | null
    lastUpdateEmployeeAbsence: string | null
    lastUpdateEmployeeTimesLastYear: string | null
    lastUpdateEmployeeTimesThisYear: string | null
}
export interface ICompanyLocalisation
{
    country: keyof ICountriesTexts
    subCountry: string
    language: ELanguage
}
export interface ICompanyCalcualtions
{
    forecastMonthCount: number
    workedMonthCount: number
}
export interface IOU
{
    id: number
    parentOU?: number
    title: string
    leader: number
    employees: number[]
}
export interface ICompanyUser
{
    id: number
    // userName: string
    firstname: string
    lastname: string
    gender: EGender
    email: string
    permissions: IPermissions;
    permissionGroup: IReference;
}
export interface ISkillSmall
{
    title: string,
    id: number
}

export interface ILevel
{
    level: number,
    title: string,
    description: string,
    helperText: string,
}

export interface IStyleDTO
{
    primary: string
    secondary: string
    onSecondary: string
    onPrimary: string
}