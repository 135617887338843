import { IRestDocuTexts } from "@sharedInterfaces/Language/languageTexts/restDocu";
import { stageContext } from "@src/globals";

import { ApiEndpoint } from "../RestApiDocuPage";

export const endpointRequestCreate = (langStrings: IRestDocuTexts): ApiEndpoint =>
({
    title: langStrings.endpointCreateRequestTitle,
    endpoint: `${stageContext.apiGatewayRestDomain}/v1/request/create`,
    method: 'POST',
    description: langStrings.endpointCreateRequestDescription,
    parameters: [
        {
            name: 'object',
            required: true,
            type: 'object',
            object: 'AnalyseObject',
            description: langStrings.endpointCreateRequestAnalyseObject,
        },
        {
            name: 'options',
            required: false,
            type: 'object',
            object: 'Options',
            description: langStrings.endpointCreateRequestOptions,
        },
    ],
    response: {
        type: 'object',
        object: 'RequestResponse',
        description: langStrings.endpointCreateRequestResponse,
    }
});