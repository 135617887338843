
import { OperationVariables, gql } from "@apollo/client";
import IRole from "@sharedInterfaces/IRole";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setRoles } from "@store/reducer/rolesReducer";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import Role, { RoleGQL } from "@src/Objects/Role";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    roles(version:$version){
        version
        roles
        ${RoleGQL}
    }
},
`;

interface RoleResponse
{
    roles: { version: number; roles: IRole[] };
}


export default async function getRoles()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.role,
    };
    return GraphQL.query<RoleResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Roles.");
            if (obj.roles.roles)
            {
                const data = obj.roles.roles.map((p: IRole) => new Role(p));
                store.dispatch(setCompanyVersion(EEntityType.ROLE, obj.roles.version))
                store.dispatch(setRoles(data))
                return data;
            }
            return state.roles;
        });
}