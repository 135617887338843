import { Done } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import './SkillsInput.css'
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { ISmallSkill } from "@sharedInterfaces/IWhoIAm";
import { IProductCompetenceReference } from "@sharedInterfaces/IProduct";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { EEntityType, entityTypeToLink } from "@sharedInterfaces/globalEnums";
import { AppState } from "@store/store";
import SkillItem from "@src/App/NewLayout/Items/SkillItem/SkillItem";
import Item from "@src/App/NewLayout/Items/Item/Item";
import { INewSkill } from "@src/Components/EditSkillList/EditSkillList";
import { Typography } from "@mui/material";

import SkillSearchBox from "../../searchBoxes/SkillSearchBox/SkillSearchBox";
import RateBar from "../RateBar/RateBar";



import NewSkillRow from "./NewSkillRow";
interface SkillsInputProps
{
    allSkills: ISmallSkill[]
    skills: INewSkill[]
    showMode?: boolean
    size: 'medium' | 'small'
    allowNewSkills?: true
    disabled?: boolean
    helperText?: string
    minimalValues?: { title: string, id?: number, level: number }[]
    competences?: IProductCompetenceReference[]
    onChange(val: { title: string, id?: number, level: number }[]): void
}
/**
 * SkillsInput component.
 *
 *   @param {Array} props.allSkills - The list of all skills.
 *   @param {Array} props.skills - The list of selected skills.
 *   @param {boolean} props.showMode - Specifies if the component is in show mode.
 *   @param {string} props.size - The size of the component.
 *   @param {boolean} props.allowNewSkills - Specifies if new skills can be added.
 *   @param {number} props.minimalValues - The minimal values that are possible for the selected skills
 *   @param {Array} props.competences - The list of competences.
 *   @param {function} props.onChange - The callback function triggered when skills are changed.
 * 
 * @returns {JSX.Element} The rendered SkillsInput component.
 */
function SkillsInput({ allSkills, skills, showMode, size, allowNewSkills, minimalValues, competences, disabled, helperText, onChange, }: SkillsInputProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);

    const [newSkill, setNewSkill] = React.useState<INewSkill>({ title: '', level: 0 });
    const [editSkill, setEditSkill] = React.useState<{ originalSkill: INewSkill | null, editSkill: INewSkill }>(
        { originalSkill: null, editSkill: { title: '', level: 0 } }
    );
    const newSkillMinLevel = minimalValues?.find(m => m.title === newSkill.title)?.level
    const editSkillMinLevel = minimalValues?.find(m => m.title === editSkill.editSkill.title)?.level

    const maxLevel = levelDefinitions.length;
    const sortedSkills = [...skills].sort((a, b) =>
    {
        if (a.level !== b.level) return b.level - a.level;
        return a.title.localeCompare(b.title);
    });

    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const selectedCompetencesIds = !competences ? [] : competences.map(c => c.id);
    const competenceSkills = allCompetences
        .filter(c => selectedCompetencesIds?.includes(c.id))
        .flatMap(c =>
        {
            if (!competences) return []
            const index = selectedCompetencesIds?.indexOf(c.id);
            if (!c.levels[competences[index]?.level - 1]?.skills) return []
            return c.levels[competences[index].level - 1].skills.map(s => ({ ...s, competence: c.title }))
        })

    const skillsForBox: { title: string, id?: number, level: number, lockedBy?: string }[] = [
        ...competenceSkills.map(c => ({ ...c, lockedBy: c.competence })),
        ...sortedSkills,
    ]
    const filteredAllSkills = allSkills.filter(a =>
        skillsForBox.find(s => s.id === a.id || s.title === a.title) === undefined)

    const onClickSkill = useCallback((skillTitle: string) =>
    {
        const skill = skills.find(s => s.title === skillTitle);
        if (!skill) return;
        setEditSkill({ originalSkill: skill, editSkill: skill });
    }, [skills]);
    const handleAddClick = React.useCallback(() =>
    {
        if (newSkill.title !== '' && newSkill.level !== 0)
        {
            const newSkills = skills.slice();
            newSkills.push(newSkill);
            onChange(newSkills.map(s => ({
                id: s.id,
                title: s.title,
                level: s.level,
            })));
            setNewSkill({ title: '', level: 0 } as INewSkill);
            // const sug = document.querySelector('.searchSkillItemInput input') as HTMLElement;
            // if (sug) sug.focus();
        }
    }, [newSkill, skills, onChange]);
    const onDelete = (skillTitle: string) =>
    {
        let newSkills = skills.slice();
        //Change
        newSkills = newSkills.filter(nS => nS.title !== skillTitle);
        onChange(newSkills.map(s => ({
            id: s.id,
            title: s.title,
            level: s.level,
        })));
        // setEditSkill({ originalSkill: null, editSkill: { title: '', level: 0 } });
        // const sug = document.querySelector('.searchSkillItemInput input') as HTMLElement
        // if (sug) sug.focus();
    }

    const helperTextElement = helperText && (
        <Typography variant="caption" color="textSecondary" style={{ marginTop: 10 }}>
            {helperText}
        </Typography>
    );

    const skillsDOM = skillsForBox.map((skill, index) =>
    {
        const onClick = () =>
        {

            if ((!showMode && !disabled) && skill.title !== editSkill.originalSkill?.title)
            {
                return onClickSkill(skill.title)
            } else if (showMode && skill.id !== undefined)
            {
                navigate(entityTypeToLink(skill.id, EEntityType.SKILL));
            }
        }
        let skillDOM = <SkillItem
            id={showMode ? skill.id : undefined}
            key={index}
            newPrefix={!skill.id ? true : undefined}
            title={skill.title}
            level={skill.level}
            lockedBy={skill.lockedBy}
            onClick={(!skill.lockedBy && !showMode) ? onClick : undefined}
            onDelete={(skill.lockedBy || showMode) ? undefined : onDelete.bind(null, skill.title)}
        />
        if (editSkill.originalSkill?.title === skill.title && skill.lockedBy === undefined)
        {
            skillDOM =
                <div className="newSkillRow"
                    key={skill.title + skill.id}
                    style={{
                        "--percent": editSkill.editSkill.level / maxLevel * 100,
                        order: -1 * skill.level,
                        width: '100%',
                        flexBasis: 'fit-content',

                    } as React.CSSProperties}
                >
                    <Item
                        rightContent={<IconButton
                            size="small"
                            disabled={
                                editSkill.editSkill.title == '' ||
                                editSkill.editSkill.level == 0 ||
                                editSkill.editSkill.level == undefined
                            }
                            title={langStrings.save}
                            onClick={() =>
                            {
                                if (editSkill.editSkill.title !== '' &&
                                    editSkill.editSkill.level !== 0 &&
                                    editSkill.editSkill.level !== undefined)
                                {
                                    const newSkills = skills.slice();

                                    //Change
                                    const original = newSkills.find(nS => nS.title === editSkill.originalSkill?.title);
                                    if (!original) return;
                                    original.id = editSkill.editSkill.id;
                                    original.title = editSkill.editSkill.title;
                                    original.level = editSkill.editSkill.level;
                                    onChange(newSkills.map(s => ({
                                        id: s.id,
                                        title: s.title,
                                        level: s.level,
                                    })));
                                    setEditSkill({ originalSkill: null, editSkill: { title: '', level: 0 } });
                                }
                                else
                                {
                                    // setNewSkillError(true);
                                }
                            }}
                        >
                            <Done fontSize="small" />
                        </IconButton>}
                    >
                        <SkillSearchBox
                            filteredAllSkills={filteredAllSkills}
                            newSkill={editSkill.editSkill}
                            allowNew={allowNewSkills}
                            size="small"
                            setNewSkill={(newSkill) =>
                            {
                                setEditSkill({
                                    originalSkill: editSkill.originalSkill,
                                    editSkill: {
                                        level: editSkill.editSkill.level,
                                        title: newSkill.title,
                                        id: newSkill.id,
                                    }
                                })
                            }} />

                        {editSkill.editSkill.title !== "" &&
                            <RateBar minLevel={editSkillMinLevel} level={editSkill.editSkill.level} name={editSkill.editSkill.title} setValue={(newLevel) =>
                            {
                                setEditSkill({
                                    originalSkill: editSkill.originalSkill,
                                    editSkill: {
                                        ...editSkill.editSkill,
                                        level: newLevel
                                    }
                                });
                            }
                                // setChanged(true);
                            } />
                        }
                    </Item>
                </div>
        }
        return (
            skillDOM
        )
    }
    );

    return (
        <>
            <div
                className={'skillsInput ' + (size)}
                style={{
                    border: showMode ? 'none' : '',
                    opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto'

                }}
            >
                <div className="skills">
                    {skillsDOM}
                </div>
                {
                    (!showMode && !disabled) &&
                    <NewSkillRow
                        minLevel={newSkillMinLevel}
                        maxLevel={maxLevel}
                        filteredAllSkills={filteredAllSkills}
                        newSkill={newSkill}
                        allowNewSkills={allowNewSkills}
                        onNewSkillChange={setNewSkill}
                        onAddClick={handleAddClick}
                    />
                }
            </div>
            {helperTextElement}
        </>
    );
}
export default SkillsInput;