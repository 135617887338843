import { Action } from "redux";
import Opportunity from "@src/Objects/Opportunity";

const initialState: Opportunity[] = [];

export enum EOpportunityAction
{
    SET_OPPORTUNITYS = "SET_OPPORTUNITYS",
    DELETE_OPPORTUNITY = "DELETE_OPPORTUNITY",
    UPDATE_OPPORTUNITY = "UPDATE_OPPORTUNITY",
}

interface SetOpportunitiesAction extends Action
{
    type: EOpportunityAction.SET_OPPORTUNITYS;
    payload: Opportunity[];
}

interface DeleteOpportunityAction extends Action
{
    type: EOpportunityAction.DELETE_OPPORTUNITY;
    payload: number; // Assuming the unique identifier is a number
}

interface UpdateOpportunityAction extends Action
{
    type: EOpportunityAction.UPDATE_OPPORTUNITY;
    payload: Opportunity;
}

type OpportunityAction = SetOpportunitiesAction | DeleteOpportunityAction | UpdateOpportunityAction;

export const opportunityReducer = (state = initialState, action: OpportunityAction): Opportunity[] =>
{
    switch (action.type)
    {
        case EOpportunityAction.SET_OPPORTUNITYS:
            return action.payload;
        case EOpportunityAction.DELETE_OPPORTUNITY:
            return state.filter(opportunity => opportunity.id !== action.payload);
        case EOpportunityAction.UPDATE_OPPORTUNITY:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(opportunity => opportunity.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creators
export const setOpportunities = (opportunitys: Opportunity[]): SetOpportunitiesAction => ({
    type: EOpportunityAction.SET_OPPORTUNITYS,
    payload: opportunitys,
});

export const deleteOpportunity = (id: number): DeleteOpportunityAction => ({
    type: EOpportunityAction.DELETE_OPPORTUNITY,
    payload: id,
});

export const updateOpportunity = (opportunity: Opportunity): UpdateOpportunityAction => ({
    type: EOpportunityAction.UPDATE_OPPORTUNITY,
    payload: opportunity,
});
