import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation employeeSwitchOU($id:Int!, $employeeId: Int!) {
    employeeSwitchOU(id: $id,employeeId: $employeeId) 
    ${companySettingsGql}
},
`;

export default async function employeeSwitchOU(id: number, employeeId: number)
{
    const variables: OperationVariables = { id, employeeId };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}