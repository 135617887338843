import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, TableChart, TableRows } from '@mui/icons-material';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import './BusinessesPage.css';
import { setBusinessses } from '@store/reducer/businessReducer';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Business from '@src/Objects/Business';
import { textContainsLowerCase } from '@src/Objects/Helper';
import BusinessCard from '@src/Components/BusinessCard/BusinessCard';
import BusinessOverlay from '@src/Components/Overlays/BusinessOverlay/BusinessOverlay';
import { BusinessDialog } from '@src/Components/Dialogs/BusinessDialog/BusinessDialog';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getBusinesses from '@src/APIs/graphQl/Business/getBusinesses';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * Component representing the BusinessesPage.
 *
 * @returns {JSX.Element} The JSX element representing the BusinessesPage.
 */
function BusinessesPage()
{
    const dispatch = useDispatch();
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).businesses;
    const permissions = useSelector((state: AppState) => state.permissions);
    const entities = useSelector((state: AppState) => state.businesses);

    const [selected, setSelected] = useState<number[]>([]);
    const isDense = (width: number) => width <= 750
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);


    useEffect(() =>
    {
        getBusinesses().catch((ex) =>
        {
            setLoadingError(ex);
        })
    }, []);

    const setEntities = (entities: Business[]) =>
        dispatch((setBusinessses(entities)))



    const types: IViewType<Business>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 400,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.BUSINESS}
                        dense={isDense(width)}
                        noCheckbox={true}
                        selected={selected}
                        order='desc'
                        orderBy='projects'
                        setSelected={setSelected}
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.businessName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SALES_CUSTOMERS}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                        {
                            id: 'projects',
                            label: langStrings.projects,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 435,
                            type: 'string',
                        },

                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <BusinessOverlay
                                        businessId={entity.id}
                                    >
                                        {entity.title}
                                    </BusinessOverlay>,
                                    orderKey: entity.title
                                },
                                description: entity.description,
                                projects: entity.projects.length,
                            }
                            ))
                        } />

            ,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <BusinessCard key={entity.id} business={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'create',
            text: langStrings.newBusiness,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('Companies', 'Add', permissions))
                    return false;
                return true;

            },
            action()
            {
                return openDialog(
                    <BusinessDialog
                        id="BusinessDialog"
                        resolve={(entity) =>
                        {
                            if (!entities || !entity) return;
                            const newArray = entities.map(p => Object.assign({}, p));
                            newArray.push(entity);
                            setEntities(newArray);
                        }}
                    />);
            },
        }
    ];
    return (
        <EntitiesPage
            title={langStrings.businesses}
            entities={entities}
            selected={selected}
            loadingError={loadingError}
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },

            ]}
            types={types}
            actions={actions}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
        />
    );


}

export default BusinessesPage;
