import React from 'react';
import { useSelector } from 'react-redux';
import { Lock } from "@mui/icons-material";
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import AddRoleButton from '@src/Components/Buttons/AddRoleButton/AddRoleButton';
import RoleOverlay from '@src/Components/Overlays/RoleOverlay/RoleOverlay';

import Item from '../Item/Item';

import styles from './RoleItem.module.css';
interface RoleItemProps
{
    id: number;
    title: string;
    disableOverlay?: boolean
    lockedBy?: string;
    negative?: boolean
    onClick?: string | (() => void)
}

/**
 * Represents a role item.
 *
 * @param {RoleItemProps} param - The parameters for the role item.
 * @param {string} param.title - The title of the role item.
 * @param {string} param.id - The ID of the role item.
 * @param {boolean} param.disableOverlay - Indicates if the overlay is disabled for the role item.
 * @param {string} param.lockedBy - The user who has locked the role item.
 * @param {Function} param.onClick - The function to be called when the role item is clicked.
 * 
 * @returns {JSX.Element} The role item as a JSX element.
 */
function RoleItem({ title, id, disableOverlay, lockedBy, negative, onClick }: RoleItemProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;

    let percent = 75;

    if (negative)
    {
        percent = percent * -1;
    }
    return <Item
        percent={percent}
        onClick={onClick}
        // leftContent={
        //     <ItemBadge value={
        //         active ?
        //             langStrings.oftenUsed.active :
        //             langStrings.oftenUsed.inactive
        //     } title={langStrings.oftenUsed.active } />
        // }
        rightContent={<>
            {(lockedBy) &&
                <div className={styles.itemLocked} title={`${langStrings.oftenUsed.lockedBy}: "${lockedBy}"`}>
                    <Lock fontSize="small" />
                </div>
            }
            {
                (id) &&
                <AddRoleButton id={id} size={'verysmall'} />
            }
        </>}
    >
        <RoleOverlay key={id} disabled={disableOverlay} roleId={id}>
            {title}
        </RoleOverlay>
    </Item>
}
export default RoleItem;