import { Action } from "redux";

export const initialClientState: IClientState = {
    secret: null,
    offline: false,
    loading: false,
    dialogCount: 0,
};

export const isLoggedIn = (secret: null | string): boolean =>
{
    return (secret !== undefined && secret !== null && secret !== '')
}

export interface IClientState
{
    secret: null | string
    offline: boolean
    loading: boolean
    dialogCount: number
}
export enum EClientAction
{
    SET_SECRET = "SET_SECRET",
    SET_OFFLINE = "SET_OFFLINE",
    SET_LOADING = "SET_LOADING",
    SET_DIALOG_COUNT = "SET_DIALOG_COUNT"
}

interface SecretAction extends Action
{
    type: EClientAction.SET_SECRET;
    payload: string | null;
}
interface OfflineAction extends Action
{
    type: EClientAction.SET_OFFLINE;
    payload: boolean;
}
interface LoadingAction extends Action
{
    type: EClientAction.SET_LOADING;
    payload: boolean;
}
interface DialogCountAction extends Action
{
    type: EClientAction.SET_DIALOG_COUNT;
    payload: number;
}

type ClientActions = OfflineAction | LoadingAction | SecretAction | DialogCountAction;

export const clientReducer = (state = initialClientState, action: ClientActions): IClientState =>
{
    switch (action.type)
    {
        case EClientAction.SET_SECRET:
            return {
                ...state,
                secret: action.payload,
            };
        case EClientAction.SET_OFFLINE:
            return {
                ...state,
                offline: action.payload,
            };
        case EClientAction.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EClientAction.SET_DIALOG_COUNT:
            return {
                ...state,
                dialogCount: action.payload,
            };
        default:
            return state;
    }
};

// Action Creator
export const setOffline = (offline: boolean): OfflineAction => ({
    type: EClientAction.SET_OFFLINE,
    payload: offline,
});


// Action Creator
export const setLoading = (loading: boolean): LoadingAction => ({
    type: EClientAction.SET_LOADING,
    payload: loading,
});

// Action Creator
export const setSecret = (secret: string | null): SecretAction => ({
    type: EClientAction.SET_SECRET,
    payload: secret,
});

export const setDialogCount = (dialogCount: number): DialogCountAction =>
{
    return {
        type: EClientAction.SET_DIALOG_COUNT,
        payload: dialogCount,
    }
};