import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Employee from '@src/Objects/Employee';
import CardBox from '@src/Components/CardBox/CardBox';
import './EmployeeCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';


interface EmployeeCardProps
{
    employee: Employee
}

/**
 * EmployeeCard component.
 * 
 * @param {EmployeeCardProps} props - The props for the EmployeeCard.
 * @returns {JSX.Element} - The rendered EmployeeCard.
 */
function EmployeeCard(props: EmployeeCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).employees;
    const navigate = useNavigate();
    const emps = props.employee;
    return (
        <CardBox
            title={emps.title}
            className={'employeeCard'}
            onClick={() =>
            {
                navigate(`${emps.link}${emps.id}`)
            }}
        >
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={emps.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default EmployeeCard;
