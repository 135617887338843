import ICertificateDTO, { ICertificateEmployeeDTO, ICertificateOpportunity, ICertificateRole } from "@sharedInterfaces/ICertificate";
import { IProductReferenceDTO } from "@sharedInterfaces/IProduct";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export const CertificateGQL = `{
            id
            title
            description
            employees {
              id
              title
              startDate
              endDate
            }
            products {
                id
                title
            }
            roles{
                id
                title
            }
            otherNames
            productInfo {
                productName
                manufacturer
                link
            }
            prerequisites
            durationInDays
            cost
            ${entityFieldsGQL}

          }`
export default class Certificate extends Entity implements ICertificateDTO
{
    type: EEntityType = EEntityType.CERTIFICATE;
    description: string;
    employees: ICertificateEmployeeDTO[];
    otherNames: string[];
    products: IProductReferenceDTO[];
    productInfo?: { productName: string; manufacturer: string; link: string; } | undefined;
    prerequisites: string;
    durationInDays?: number | undefined;
    cost?: number | undefined;
    opportunities: ICertificateOpportunity[];
    roles: ICertificateRole[];

    constructor(obj: ICertificateDTO)
    {
        super(obj);
        this.description = obj.description;
        this.employees = obj.employees;
        this.otherNames = obj.otherNames;
        this.products = obj.products
        this.productInfo = obj.productInfo
        this.prerequisites = obj.prerequisites
        this.durationInDays = obj.durationInDays
        this.cost = obj.cost
        this.opportunities = obj.opportunities;
        this.roles = obj.roles;
    }
    async delete()
    {
        const { default: deleteCertificate } = await import("@src/APIs/graphQl/Certificate/deleteCertificate");
        return deleteCertificate(this.id);
    }
}