// import deleteSkillCategory from "@src/APIs/graphQl/SkillCategory/deleteSkillCategory";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import { ISkillCategoryDetailDTO, SkillCategoryEmployeeDTO } from "@sharedInterfaces/ISkillCategory";

import { Entity } from "../sharedReact/Objects/Entity";


export class SkillCategory extends Entity implements ISkillCategoryDetailDTO
{
    type: EEntityType = EEntityType.SKILL_CATEGORY;
    description: string;
    parentCategory: number | null;
    employees: SkillCategoryEmployeeDTO[];

    constructor(obj: ISkillCategoryDetailDTO)
    {
        super(obj);
        this.description = obj.description;
        this.parentCategory = obj.parentCategory;
        this.employees = obj.employees;
    }
    async delete()
    {
        // Import-Cycle durchbrechung
        const { default: deleteSkillCategory } = await import("@src/APIs/graphQl/SkillCategory/deleteSkillCategory");
        return deleteSkillCategory(this.id);
    }


}
