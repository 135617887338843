import { AutoFixHigh, Clear, Done } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ISkillDetailDTO, { TSkillRequestingState } from '@sharedInterfaces/ISkill';
import { ESkillType, EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { updateSkill } from '@store/reducer/skillReducer';
import { setEmployeeSkills } from '@store/reducer/employeeReducer';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { ISkillMetadataResponse } from '@sharedInterfaces/websocket/ISkillMetadata';
import { setCompanyAllSkills } from '@store/reducer/companyReducer';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import SkillDetail from '@src/Objects/SkillDetail';
import Button from '@sharedReact/General/Button/Button';
import editSkill from '@src/APIs/graphQl/Skill/editSkill';
import createSkill from '@src/APIs/graphQl/Skill/createSkill';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './SkillDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import MultiTextChipSelect from '../../formsControls/inputs/MultiTextChipSelect/MultiTextChipSelect';
import SkillCategorySelect from '../../formsControls/inputs/SkillCategorySelect/SkillCategorySelect';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import LinkInput from '../../formsControls/inputs/LinkInput/LinkInput';
import SkillTypeSelect from '../../formsControls/inputs/SkillTypeSelect/SkillTypeSelect';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';


interface SkillDialogProps
{
    id: string
    skill?: SkillDetail
    resolve?: (val: SkillDetail | null) => void;
}

/**
 * SkillDialog component.
 *
 * @param {SkillDialogProps} props - The props object.
 * @returns {JSX.Element} - The rendered SkillDialog component.
 */
export function SkillDialog({ id, skill, resolve, }: SkillDialogProps)
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const emp = useSelector((state: AppState) => state.employee);

    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    // const dispatch = useDispatch();
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [skillName, setSkillName] = React.useState<string>(skill ? skill.title : '');
    const [skillNameSuggestion, setSkillNameSuggestion] = React.useState<string>("");
    const [skillNameError, setSkillNameError] = React.useState<string | undefined>(undefined);
    const [description, setDescription] = React.useState<string>(skill ? skill.description : '');
    const [otherNames, setOtherNames] = React.useState<string[]>(skill ? skill.otherNames : []);
    // const [products, setProducts] = React.useState((skill ? skill.products : [] as { title: string, id?: number }[]));
    // const [employees, setEmployees] = React.useState((skill ? skill.employees : [] as IAllEmployee[]));
    const [categoryId, setCategoryId] = React.useState<number>((skill ? skill.categoryId : -1));
    const [skillType, setSkillType] = React.useState<ESkillType>(skill && skill.skillType ? skill.skillType : "" as ESkillType);

    const [productName, setProductName] = React.useState<string>(skill && skill.productInfo ? skill.productInfo.productName : "");
    const [manufacturer, setManufacturer] = React.useState<string>(skill && skill.productInfo ? skill.productInfo.manufacturer : "");
    const [link, setLink] = React.useState<string>(skill && skill.productInfo && skill.productInfo.link ? skill.productInfo.link : "");
    const [acquisition, setAcquisition] = React.useState<string>(skill ? skill.acquisition : "");
    const [acquisitionLink, setAcquisitionLink] = React.useState<string>(skill && skill.acquisitionLink ? skill.acquisitionLink : '');
    const [subKey, setSubKey] = React.useState<string>("");

    const [generatingMetadata, setGeneratingMetadata] = React.useState<boolean>(false);

    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newSkill: ISkillDetailDTO) =>
    {
        if (!emp) return;
        const newEntity = new SkillDetail(newSkill);
        const newAllSkills = [...allSkills];
        const newArray = [...emp.skills];

        if (!skill) // CREATE
        {
            newAllSkills.push({
                id: newSkill.id, title: newSkill.title, otherNames, categoryId,
                version: 0,
            });
            // newArray.push({ id: newSkill.id, title: newSkill .title,level:});
        }
        else //MODIFY
        {
            let index = newAllSkills.findIndex(e => newEntity.id === e.id);
            if (index > -1)
                newAllSkills[index] = { ...newAllSkills[index], title: newEntity.title, categoryId: newEntity.categoryId };
            index = newArray.findIndex(e => newEntity.id === e.id);
            if (index > -1)
                newArray[index] = { id: newEntity.id, title: newEntity.title, level: newArray[index].level };
        }
        dispatch(setEmployeeSkills(newArray));
        dispatch(setCompanyAllSkills(newAllSkills))
        dispatch(updateSkill(newEntity));
        resolve && resolve(newEntity);
        setSaving(false);
    }

    const onSave = () =>
    {
        setSaving(true);
        if (skillName === "")
        {
            setErrorText(langStrings.newSkillError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!skill)
        {
            createSkill(skillName, description, categoryId, otherNames, skillType,
                productName,
                manufacturer,
                link,
                acquisition,
                acquisitionLink,)
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            editSkill(skill.id, skillName, description, categoryId, otherNames, skillType,
                productName,
                manufacturer,
                link,
                acquisition,
                acquisitionLink,)
                .then(onFetch)
                .catch(onFetchError)
        }

    };

    const onClose = () =>
    {
        resolve && resolve(null);
    }
    const topic = EWebsocketTopics.generateSkillMetadata;
    const startAnalyse = () =>
    {
        const request: TSkillRequestingState = {
            all: true,
        };
        WebSocketClient.sendMessage(topic, {
            skillName,
            key: subKey,
            request
        });
        setGeneratingMetadata(true)
    }

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<ISkillMetadataResponse>(topic, (data) =>
        {
            //SkillMetadata
            if (subKey !== data.key) return;
            if (data.error)
            {
                showErrorDialog(new Error(data.error))
                setGeneratingMetadata(false);
                return;
            }
            const metaData = data.metadata;

            if (!metaData || data.error)
            {
                showErrorDialog(new Error(langStrings.error))
                setGeneratingMetadata(false);
                return;
            }
            setGeneratingMetadata(false)
            if (metaData.description && (!description || description === ""))
            {
                setDescription(metaData.description);
            }
            if (metaData.category && (!categoryId || categoryId === -1))
            {
                const category = skillCategories.find(c => c.title === metaData.category);
                if (category)
                {
                    setCategoryId(category.id)
                }
            }
            if (metaData.skillType && (!skillType || (skillType as string) === ""))
                setSkillType(metaData.skillType);
            if (metaData.synonyms && (!otherNames || otherNames.length === 0))
                setOtherNames(metaData.synonyms.map(n => n.toLowerCase() as string) as string[]);
            if (metaData.productInfo && (!productName || productName === ""))
                setProductName(metaData.productInfo.productName);
            if (metaData.productInfo && (!manufacturer || manufacturer === ""))
                setManufacturer(metaData.productInfo.manufacturer);
            if (metaData.productInfo && (!link || link === ""))
                setLink(metaData.productInfo.link);
            if (metaData.acquisition && (!acquisition || acquisition === ""))
                setAcquisition(metaData.acquisition);
            if (metaData.acquisitionLink && (!acquisitionLink || acquisitionLink === ""))
                setAcquisitionLink(metaData.acquisitionLink);
            if (metaData.skillName && (skillName !== metaData.skillName))
            {
                setSkillNameSuggestion(metaData.skillName);
            }
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])

    const existingSkills = allSkills.filter(a => !skill || skill.id !== a.id).map(s => s.title.toLowerCase());

    const validate = () =>
    {
        if (skillName === "")
        {
            setSkillNameError(
                langStrings.pleaseEnterTitle
            )
        } else if (existingSkills.includes(skillName.toLowerCase()))
        {
            setSkillNameError(
                langStrings.errorDuplicateTitle
                    .replace("[TITLE]", skillName)
                    .replace("[TITLE]", skillName)
            )
        }
        else
        {
            setSkillNameError(undefined);
        }
    }

    useEffect(validate, [skillName])


    return (
        <Dialog
            id={id}
            title={skill ? skill.title : langStrings.newSkill}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving || !!skillNameError}
                        text={!skill ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="skillDialog">
                <div className="newSkill">
                    <Row>
                        <RowElement title={langStrings.skillName} alignTitle="left">
                            <TextInput
                                value={skillName}
                                onChange={setSkillName}
                                helperText={skillNameError}
                                isAI
                            />
                        </RowElement>
                        <IconButton
                            title={langStrings.generateSkillData}
                            disabled={!!skillNameError || generatingMetadata}
                            onClick={startAnalyse}
                        >
                            {generatingMetadata ? <CircularProgress size={24} color='secondary' /> : <AutoFixHigh />}
                        </IconButton>
                    </Row>
                    {
                        skillNameSuggestion !== "" &&
                        <div>
                            {langStrings.suggestedSkillName}: {skillNameSuggestion}
                            <IconButton>
                                <Done onClick={() =>
                                {
                                    setSkillName(skillNameSuggestion);
                                    setSkillNameSuggestion("");
                                }} />
                            </IconButton>
                            <IconButton onClick={() =>
                            {
                                setSkillNameSuggestion("");
                            }}>
                                <Clear />
                            </IconButton>
                        </div>
                    }
                    <Row align='top'>
                        <RowElement title={langStrings.skillCategory} alignTitle="left">
                            <SkillCategorySelect
                                skillCategories={skillCategories}
                                selectedCategory={categoryId}
                                onChange={(id) => setCategoryId(id)}
                            />
                        </RowElement>
                        <RowElement title={langStrings.skillType} alignTitle="left">
                            <SkillTypeSelect
                                value={skillType}
                                onChange={(val: ESkillType) => setSkillType(val)}
                            />
                        </RowElement>
                    </Row>
                    <Row align='top'>
                        <RowElement title={langStrings.productName} alignTitle="left">
                            <TextInput size='small' value={productName} onChange={setProductName} />
                        </RowElement>
                        <RowElement title={langStrings.productInfo} alignTitle="left">
                            <LinkInput size='small' value={link} onChange={setLink} />
                        </RowElement>
                    </Row>
                    <RowElement title={langStrings.manufacturer} alignTitle="left">
                        <TextInput size='small' value={manufacturer} onChange={setManufacturer} />
                    </RowElement>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput value={description} onChange={setDescription} />
                    </RowElement>
                    <RowElement title={langStrings.otherNames} alignTitle="left">
                        <MultiTextChipSelect value={otherNames}
                            onChange={(names) =>
                            {
                                setErrorText("");
                                const newOtherNames = Array.from(new Set(names.map(n => n.toLowerCase())))
                                    .filter(newON =>
                                    {
                                        const existingSkill = allSkills.find(a => a.title.toLocaleLowerCase() === newON && (!skill || a.id !== skill.id)) ||
                                            allSkills.find(a => a.otherNames.find(o => o === newON) !== undefined && (!skill || a.id !== skill.id));
                                        if (existingSkill)
                                        {
                                            const errorTitle = existingSkill.title.toLocaleLowerCase() === newON;
                                            const errorString = (errorTitle ? langStrings.errorDuplicateTitle : langStrings.errorDuplicateOtherName)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[VALUE]", newON);
                                            setErrorText(errorString);
                                        }
                                        return !existingSkill;
                                    });
                                setOtherNames(newOtherNames)
                            }}
                        />
                    </RowElement>
                    <RowElement title={langStrings.acquisition} alignTitle="left">
                        <FormatedTextInput value={acquisition} onChange={setAcquisition} />
                    </RowElement>
                    <RowElement title={langStrings.howToLearn} alignTitle="left">
                        <LinkInput size='small' value={acquisitionLink} onChange={setAcquisitionLink} />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}