import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeCoverage } from "@sharedInterfaces/IOpportunity";

import GraphQL from "../graphQL";
import { employeeCoverageGql } from "../Opportunity/getOpportunity";

const q = gql`
query productGetSuggestedEmployees($productId: ID!) {
    productGetSuggestedEmployees(id:  $productId) 
    ${employeeCoverageGql}
},
`;

/**
 * Returns a promise that resolves to the suggested employees
 * for a given product.
 * @param {number} productId - The ID of the product.
 * @param {(product: IEmployeeCoverage[]) => void} callback - The callback function that handles the suggested employees.
 * @returns {Promise<{ productGetSuggestedEmployees: IEmployeeCoverage[]; }>} The promise that resolves to the suggested employees.
 */
export default async function productGetSuggestedEmployees(productId: number, callback: (product: IEmployeeCoverage[]) => void)
{
    const variables: OperationVariables = { productId };
    return GraphQL.query<{ productGetSuggestedEmployees: IEmployeeCoverage[] }>(q, variables, (obj) =>
    {
        if (!obj) throw new Error("Error loading productGetSuggestedEmployees");

        callback(obj.productGetSuggestedEmployees)
    }
    );
}
