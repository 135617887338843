import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings, { ILevel } from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editLevelDefinitions( $level: [EditLevel!]!) {
    editLevelDefinitions(level: $level) 
    ${companySettingsGql}
},
`;

export default async function editLevelDefinitions(level: ILevel[])
{
    const variables: OperationVariables = {
        level: level.map(l => ({
            level: l.level,
            title: l.title,
            description: l.description,
            helperText: l.helperText,
        }))
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}