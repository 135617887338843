import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage, MaybeSkill } from '@sharedInterfaces/IOpportunity';
import { IReference } from '@sharedInterfaces/IReference';
import { AppState } from '@store/store';
import getSuggestedEmployees from '@src/APIs/graphQl/Opportunity/getSuggestedEmployees';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import SkillsInput from '../../formsControls/inputs/SkillsInput/SkillsInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import { EmployeeSuggestions } from '../../Opportunities/EmployeeSuggestions/EmployeeSuggestions';


interface EmployeeTabProps
{
    headCount: number;
    neededSkills: MaybeSkill[];
    neededCertificates: IReference[]
    selectedEmployees: IEmployeeCoverage[];
    setSelectedEmployees: (val: IEmployeeCoverage[]) => void;
}
/**
 * EmployeeTab
 * 
 * @param {EmployeeTabProps} headCount - The number of employees in the team.
 * @param {EmployeeTabProps} neededSkills - The skills required for the employee.
 * @param {EmployeeTabProps} neededCertificates - The certificates required for the employee.
 * @param {EmployeeTabProps} selectedEmployees - The selected employees in the team.
 * @param {EmployeeTabProps} setSelectedEmployees - A function to set the selected employees.
 * @returns {JSX.Element} The EmployeeTab component.
 */
export function EmployeeTab({ headCount, neededSkills, neededCertificates, selectedEmployees, setSelectedEmployees, }: EmployeeTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).sales;

    React.useEffect(() =>
    {
        // const knownSkills = neededSkills.find(s => s.id !== undefined) !== undefined;
        if (neededSkills.length > 0 || neededCertificates.length > 0)// && knownSkills)
        {
            setLoading(true);
            getSuggestedEmployees(neededSkills, neededCertificates).then((suggestions) =>
            {
                setLoading(false);
                suggestions.sort((a, b) =>
                {
                    const diff = b.score - a.score;
                    if (diff !== 0) return diff;
                    return a.title.localeCompare(b.title);
                })
                const updatedSelectedEmps = suggestions.filter(sug => selectedEmployees.find(sel => sel.id === sug.id));
                selectedEmployees.filter(se => !suggestions.find(sel => se.id === sel.id) &&
                    !updatedSelectedEmps.find(sel => se.id === sel.id)
                ).forEach((notLongerSuggestedEmp =>
                {
                    updatedSelectedEmps.push({
                        ...notLongerSuggestedEmp,
                        score: 0,
                        skillCoverage: 0,
                        coveredSkills: [],
                        missingSkills: neededSkills.map(ns => ({ ...ns, wantedLevel: ns.level, level: 0 })),
                    });
                }));
                setSelectedEmployees(updatedSelectedEmps);

                setSuggestedEmployees(suggestions.filter(sug => !updatedSelectedEmps.find(sel => sel.id === sug.id)));
            });
        }
    }, []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [suggestedEmployees, setSuggestedEmployees] = React.useState<IEmployeeCoverage[]>([]);

    const selectedCount = selectedEmployees.length;
    const suggestedCount = suggestedEmployees.length;

    const [draggedItem, setDraggedItem] = useState<IEmployeeCoverage | null>(null);
    const [target, setTarget] = useState<'selected' | 'suggested' | null>(null);
    const handleDragStart = (_: unknown, item: IEmployeeCoverage | null) =>
    {
        setDraggedItem(item);
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) =>
    {
        event.preventDefault();
        if (event.currentTarget.id === 'selected') setTarget('selected');
        else if (event.currentTarget.id === 'suggested') setTarget('suggested');
    };

    const handleDragEnd = () =>
    {
        if (!draggedItem) return;
        if (target === 'selected')
        {
            if (selectedCount >= headCount) return;
            if (selectedEmployees.find(i => i.id === draggedItem.id) !== undefined)
                return;
            const newSelected = selectedEmployees.concat([draggedItem]);
            const newSuggested = suggestedEmployees.filter(esc => esc.id !== draggedItem.id);
            newSelected.sort((a, b) => b.score - a.score);
            newSuggested.sort((a, b) => b.score - a.score);
            setSelectedEmployees(newSelected);
            setSuggestedEmployees(newSuggested);
        } else if (target === 'suggested')
        {
            if (target === 'suggested')
            {
                if (suggestedEmployees.find(i => i.id === draggedItem.id) !== undefined)
                    return;
                const newSelected = selectedEmployees.filter(esc => esc.id !== draggedItem.id);
                const newSuggested = suggestedEmployees.concat([draggedItem]);
                newSelected.sort((a, b) => b.score - a.score);
                newSuggested.sort((a, b) => b.score - a.score);
                setSuggestedEmployees(newSuggested);
                setSelectedEmployees(newSelected);
            }
        }
        setDraggedItem(null);
    };

    return (
        <div
            className="tab"
        >
            {loading ? <span>{langStrings.loading}</span> : ''}
            {<React.Fragment>
                <RowElement alignTitle={'center'} title={`${langStrings.selectedEmployee} ${selectedCount} / ${headCount}`}>
                    {(selectedCount > headCount) &&
                        <ErrorBox warning>
                            <Typography variant='body2'>
                                {
                                    langStrings.toManyEmployeesSelected
                                        .replace('[LIMIT]', headCount.toString())
                                        .replace('[AMOUNT]', selectedCount.toString())
                                }
                            </Typography>
                        </ErrorBox>
                    }
                    <EmployeeSuggestions
                        id="selected"
                        inDialog={true}
                        rightTopButton={(id) => <IconButton
                            size={'small'}
                            onClick={() =>
                            {
                                const element = selectedEmployees.find(i => i.id === id);
                                if (suggestedEmployees.find(i => i.id === id) || !element)
                                    return;
                                const newSuggested = suggestedEmployees.concat([element]);
                                const newSelected = selectedEmployees.filter(esc => esc.id !== id);
                                newSelected.sort((a, b) => b.score - a.score);
                                newSuggested.sort((a, b) => b.score - a.score);
                                setSelectedEmployees(newSelected);
                                setSuggestedEmployees(newSuggested);
                            }}>
                            <Remove />
                        </IconButton>}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDragEnd={handleDragEnd}
                        suggestedEmployees={selectedEmployees} />
                </RowElement>
                <RowElement alignTitle={'center'} title={`${langStrings.suggestedEmployee} ${suggestedCount}`}>
                    {loading &&
                        <LoadingBox />}
                    <EmployeeSuggestions
                        id="suggested"
                        inDialog={true}
                        showFilter
                        rightTopButton={(id) => <div
                            title={selectedCount >= headCount ? langStrings.selectedEmployeeLimitHint : langStrings.select}
                        >
                            <IconButton
                                size={'small'}
                                title={selectedCount >= headCount ? langStrings.selectedEmployeeLimitHint : langStrings.select}
                                // disabled={selectedCount >= headCount}
                                onClick={() =>
                                {
                                    const element = suggestedEmployees.find(i => i.id === id);
                                    if (selectedEmployees.find(i => i.id === id) || !element)
                                        return;
                                    const newSelected = selectedEmployees.concat([element]);
                                    const newSuggested = suggestedEmployees.filter(esc => esc.id !== id);
                                    newSelected.sort((a, b) => b.score - a.score);
                                    newSuggested.sort((a, b) => b.score - a.score);
                                    setSelectedEmployees(newSelected);
                                    setSuggestedEmployees(newSuggested);
                                }}
                            >
                                <Add />
                            </IconButton>
                        </div>}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDragEnd={handleDragEnd}
                        suggestedEmployees={suggestedEmployees} />
                </RowElement>
            </React.Fragment>}
            <Row>
                <RowElement alignTitle={'center'} title={langStrings.neededSkills}>
                    <SkillsInput size="medium" showMode={true} skills={neededSkills} allSkills={[]}
                        onChange={() => { }} />
                </RowElement>
            </Row>
        </div>
    );
}