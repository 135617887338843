import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import { IAddEmployeeDTO } from "@sharedInterfaces/IEmployee";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation addEmployees($OUid:Int!, $employees: [EmployeeNew!]!) {
    addEmployees(OUid: $OUid ,employees: $employees) 
    ${companySettingsGql}
},
`;

export default async function addEmployees(OUid: number, employees: IAddEmployeeDTO[])
{
    const variables: OperationVariables = {
        OUid,
        employees: employees.map(e =>
        {
            return {
                firstname: e.firstname,
                lastname: e.lastname,
                gender: e.gender,
                email: e.email,
                permissionGroup: e.permissionGroup.id,
                admin: e.permissions.admin,
            }
        }),
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}