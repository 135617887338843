import { Add, Delete } from '@mui/icons-material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallSkill } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import changeMapSkill from '@src/APIs/graphQl/Skill/changeMapSkill';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import createAndMapSkill from '@src/APIs/graphQl/Skill/createAndMapSkill';
import { NewBadge } from '@src/App/NewLayout/Components/ItemBadge/ItemBadge';

import Button from '../../sharedReact/General/Button/Button';
import SkillSearchBox from '../formsControls/searchBoxes/SkillSearchBox/SkillSearchBox';
import ISkill from '../../interfaces/ISkill';
import CardBox from '../CardBox/CardBox';
import ErrorBox from '../ErrorBox/ErrorBox';
import './EditSkillList.css';
import RateBar from '../formsControls/inputs/RateBar/RateBar';


interface EditSkillListProps 
{
    title: string
    mode: 'edit' | 'add'
    skills: ISkill[]
    allSkills: ISmallSkill[]
    onChange?: (changed: true) => void
}

/**
 * EditSkillList function.
 * 
 * @param {EditSkillListProps} props - The props object.
 * @returns {JSX.Element} - The JSX element.
 */
function EditSkillList(props: EditSkillListProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);

    // const [addedSkills, setAddedSkills] = React.useState([] as string[]);
    const [addingSkills, setAddingSkills] = React.useState([] as string[]);
    React.useEffect(() =>
    {
        const existingSkillsTitles = employee?.skills.map(s => s.title) || [];
        if (props.skills && props.mode === 'add')
        {
            let tempSkills = props.skills.slice();
            tempSkills = tempSkills.filter(c => !existingSkillsTitles.includes(c.title))
            tempSkills.sort((a, b) =>
            {
                // const lvlDiff = b.level - a.level;
                // if (lvlDiff !== 0) return lvlDiff
                return a.title.localeCompare(b.title);
            })
            setSkills(tempSkills);
        } else
        {
            const tempSkills = employee ? cloneDeep(employee.skills) : [];
            tempSkills.sort((a, b) =>
            {
                const lvlDiff = b.level - a.level;
                if (lvlDiff !== 0) return lvlDiff
                return a.title.localeCompare(b.title);
            })
            setSkills(tempSkills);
        }


    }, [employee, props.skills, props.mode]);

    const [skills, setSkills]: [ISkill[], (value: ISkill[]) => void] = React.useState(props.skills);
    const [newSkill, setNewSkill]: [INewSkill, (value: INewSkill) => void] = React.useState({ title: '', level: 0, otherNames: [] } as INewSkill);
    const [newSkillError, setNewSkillError]: [boolean, (value: boolean) => void] = React.useState(false as boolean);

    const filteredAllSkills = props.allSkills.filter(a =>
        skills.find(s => s.id === a.id || s.title === a.title) === undefined)
    if (!employee) return null



    return (
        <CardBox title={props.title}>
            <div className="editSkillList">
                <div className="infoBoxSkill" >
                    {skills.map(skill =>

                        <div className="skillInfo" key={skill.id + skill.title}
                        // style={{
                        //     display: addedSkills.includes(skill.title) ? 'none' : undefined,
                        // }}
                        >
                            <div className="info">{skill.title}{!skill.id &&
                                <NewBadge />
                            }</div>
                            <div className="rating">

                                <RateBar level={skill.level} name={skill.title + skill.id} setValue={(val) =>
                                {
                                    changeRating(skill, val);
                                }} />
                            </div>
                            {props.mode === 'edit' &&
                                <DeleteButton skill={skill} onChange={props.onChange} />
                            }
                            {props.mode === 'add' &&
                                <div className="add">
                                    <Button
                                        size="verysmall"
                                        onClick={() =>
                                        {
                                            add(skill.title, skill.level, skill.id);
                                        }}
                                        icon={<Add
                                            fontSize="small"
                                        />}
                                        disabled={addingSkills.includes(skill.title) || offline || !skill.level}
                                        text="Add"
                                    />
                                </div>
                            }
                        </div>
                    )}
                    <div className='skillInfo'>
                        <div className="info" style={{ paddingTop: 1 }}>
                            <SkillSearchBox
                                filteredAllSkills={filteredAllSkills.map(s => ({ ...s, level: 0 }))}
                                newSkill={newSkill}
                                allowNew={true}
                                size='verysmall'
                                setNewSkill={(newSkill) => { setNewSkill({ ...newSkill, level: 0 }) }}
                            />
                        </div>
                        <div className="rating">
                            <RateBar level={newSkill.level} name={newSkill.title} setValue={(newLevel) =>
                            {
                                setNewSkill({
                                    ...newSkill,
                                    level: newLevel
                                });
                            }} />
                        </div>
                        <div className="add">
                            <Button
                                size="verysmall"
                                onClick={() =>
                                {
                                    if (newSkill.title !== '' &&
                                        newSkill.level !== 0)
                                    {
                                        add(newSkill.title, newSkill.level, newSkill.id);
                                    }
                                    else
                                    {
                                        setNewSkillError(true);
                                    }
                                }}
                                icon={<Add fontSize="small" />}
                                disabled={addingSkills.includes(newSkill.title) || offline}
                                text="Add"
                            />
                        </div>
                    </div>
                </div>
                {newSkillError && <ErrorBox close={() => { setNewSkillError(false) }}>{langStrings.pleaseEnterSkillData}</ErrorBox>}
            </div>
        </CardBox>
    );
    function changeRating(skill: ISkill, val: number)
    {
        const newSkills = skills.slice();
        const changedSkill = newSkills.find(s => s.title === skill.title);
        if (changedSkill)
        {
            changedSkill.level = val;
        }
        if (props.mode !== 'add')
            newSkills.sort((a, b) =>
            {
                const lvlDiff = b.level - a.level;
                if (lvlDiff !== 0) return lvlDiff;
                return a.title.localeCompare(b.title);
            });

        setSkills(newSkills);

        if (props.mode === 'edit')
        {
            changeMapSkill(skill.id, true, val)
                .then(() =>
                {
                    props.onChange && props.onChange(true);
                })
                .catch((ex) =>
                {
                    showErrorDialog(ex, "Failed to change level!");
                });
        }
    }

    function add(title: string, level: number, id?: number)
    {
        if (title !== '')
        {
            setNewSkill({ title: '' } as INewSkill);
            if (newSkillError) setNewSkillError(false);
            if (props.mode !== 'add')
            {
                const sug = document.querySelector('.searchSkillBoxInput input') as HTMLElement
                if (sug) sug.focus();
            }
            if (props.mode === 'add')
            {
                setAddingSkills(addingSkills.concat([title]))
            }
            if (id)
            {

                changeMapSkill(id, true, level)
                    .then(() =>
                    {
                        props.onChange && props.onChange(true);
                    })
                    .catch((ex) =>
                    {
                        console.log(ex);
                        showErrorDialog(ex, langStrings.errorAdd);
                        setAddingSkills(addingSkills.filter(a => a === title))
                    })
            }
            else
            {

                createAndMapSkill(title, level)
                    .then(() =>
                    {

                        props.onChange && props.onChange(true);

                        // setAddedSkills(addedSkills.concat([title]))
                    })
                    .catch((ex) =>
                    {
                        console.log(ex);
                        showErrorDialog(ex, langStrings.errorAdd);
                        setAddingSkills(addingSkills.filter(a => a === title))
                    })
            }
        }
        else
        {
            setNewSkillError(true);
        }
    }
}
export interface INewSkill
{
    id?: number
    title: string
    level: number
}

export default EditSkillList;

interface DeleteButtonProps
{
    skill: ISkill;
    onChange?: (changed: true) => void
}

/**
 * DeleteButton component.
 * 
 * @param {Object} skill - The skill object to be deleted.
 * 
 * @returns {JSX.Element} - A JSX Element representing the delete button.
 */
function DeleteButton({ skill, onChange }: DeleteButtonProps)
{
    const showErrorDialog = useErrorDialog();
    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const [deleting, setDeleting] = useState(false);
    if (!employee) return <></>;
    return (
        <div className="delete">
            <Button
                size="verysmall"
                onClick={() =>
                {
                    if (!skill.id) return;
                    setDeleting(true)
                    changeMapSkill(skill.id, false)
                        .then(() =>
                        {
                            setDeleting(false);
                            onChange && onChange(true);
                        })
                        .catch((ex) =>
                        {
                            setDeleting(false);
                            showErrorDialog(ex, langStrings.errorRemove);
                        })
                }}
                icon={<Delete
                    fontSize="small"
                />}
                disabled={deleting || offline}
                text="Delete"
            />
        </div>
    )
}