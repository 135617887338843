import { Autocomplete, Popper, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { EEntityType, entityTypeToLink, entityTypeToString } from "@sharedInterfaces/globalEnums";
import { ISearchSuggestions } from "@sharedInterfaces/ICompany";
import store, { AppState } from "@store/store";
import getSearchSuggestions from "@src/APIs/graphQl/Company/getSearchSuggestions";
import { LoadingBar } from "@sharedReact/Layouts/LayoutElements/ContentHeader/ContentHeader";

import { entityTypeToIcon } from "../../helper/tsxHelper";

import styles from "./SearchBox.module.css";
import { TYPING_TIMEOUT, MAX_WIDTH } from "./SearchBox";

export interface SeachInputProps
{
    open: boolean
    searchValue: string
    setSearchValue: (value: string) => void
    setOpen: (value: boolean) => void
}

/**
 * SearchInput component.
 *
 * @param {object} searchInputProps - The props for the SearchInput component.
 * @param {boolean} searchInputProps.open - The flag indicating if the search input is open.
 * @param {string} searchInputProps.searchValue - The current value of the search input.
 * @param {function} searchInputProps.setSearchValue - The function to update the value of the search input.
 * @param {function} searchInputProps.setOpen - The function to update the flag indicating if the search input is open.
 *
 * @returns {JSX.Element} - The rendered SearchInput component.
 */
export function SeachInput({ open, searchValue, setSearchValue, setOpen }: SeachInputProps)
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const size: 'medium' | 'small' | 'verysmall' = 'small' as 'medium' | 'small' | 'verysmall';
    const inputSize = size === 'medium' || size === 'small' ? size : 'small';
    const textFieldRef = useRef<HTMLInputElement>(null);

    const [typedValue, setTypedValue] = useState<string>("");
    const [suggestions, setSuggestions] = useState<ISearchSuggestions[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const fullState = store.getState();

    useEffect(() =>
    {
        if (searchValue)
        {
            if (loading) return;
            setLoading(true);
            getSearchSugs(searchValue);
        }

        function getSearchSugs(searchedValue: string)
        {
            const localSuggestions: ISearchSuggestions[] = getLocalSuggestions(searchValue, fullState);
            setSuggestions(localSuggestions);

            getSearchSuggestions(searchedValue, onlineSuggestions =>
            {
                const actualSuggestions = [...localSuggestions];
                onlineSuggestions.forEach(suggestion =>
                {
                    if (!actualSuggestions.some(s => s.id === suggestion.id && s.type === suggestion.type))
                    {
                        actualSuggestions.push(suggestion);
                    }
                });
                actualSuggestions.sort(sortSuggestions);

                setSuggestions(actualSuggestions);
                if (searchedValue !== searchValue)
                {
                    getSearchSugs(searchedValue);
                }
            })
                .catch(error =>
                {
                    console.error('Error fetching suggestions:', error);
                }).finally(() =>
                {
                    setLoading(false);
                });
        }
    }, [searchValue]);
    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setSearchValue(typedValue);
        }, TYPING_TIMEOUT);

        return () => clearTimeout(timer);
    }, [typedValue]);

    useEffect(() =>
    {
        if (textFieldRef.current && open)
        {
            textFieldRef.current.focus();
        }
    }, [open]);

    const onSelect = (selectedOption: ISearchSuggestions) =>
    {
        navigate(entityTypeToLink(selectedOption.id, selectedOption.type));
        setSearchValue("");
        setOpen(false);
    };

    return (
        <div style={{
            width: open ? MAX_WIDTH : 0,
            transition: 'width 0.5s ease-in-out',
            overflow: 'hidden',
            background: 'var(--var-secondary-color)',
            color: 'var(--var-on-secondary-color)',
            // background: 'lightgray',
        }}>
            <Autocomplete
                id="skillSelect"
                value={typedValue}
                size={inputSize}
                style={{
                    background: 'var(--var-secondary-color)',
                    color: 'var(--var-on-secondary-color)',
                }}
                // color="on-secondary"
                onBlur={() => { setOpen(false); }}
                onChange={(event, newValue) =>
                {
                    if (typeof newValue === 'string')
                    {
                        setTypedValue(newValue);
                    } else if (newValue)
                    {
                        onSelect(newValue);
                    }
                }}
                filterOptions={(options) =>
                {
                    // const { inputValue } = params;
                    // const isExisting = suggestions.some((option) => option.label.toLowerCase() === (inputValue.toLowerCase()));
                    return options;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={suggestions}
                getOptionLabel={(option) =>
                {
                    if (typeof option === 'string')
                        return option;
                    if (option.id)
                        return option.label;
                    return "unknown";
                }}

                renderOption={(props, option) =>
                {
                    const icon = entityTypeToIcon(option.type);
                    return (
                        <li
                            {...props}
                            key={`${option.type}-${option.id}-${option.label}`}
                            style={{ borderBottom: 'solid 1px #ddd' }}
                        >
                            <div className={styles.suggestion}>
                                {icon &&
                                    <div className={styles.icon}>{icon}</div>}
                                <div className={styles.suggestionTexts}>
                                    <span className={styles.label}>
                                        {option.label}
                                    </span>
                                    <span className={styles.type}>{entityTypeToString(lang, option.type)}</span>
                                </div>
                            </div>
                        </li>
                    );
                }}
                sx={{ width: '100%' }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        color="primary"
                        inputRef={textFieldRef}
                        onChange={(event) => setTypedValue(event.target.value)}
                        onKeyDown={(event) =>
                        {
                            if (event.key === 'Enter')
                            {
                                if (suggestions.length > 0)
                                    onSelect(suggestions[0]);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                margin: 0,
                                padding: size === 'verysmall' ? '0px 5px' : undefined,
                                background: 'var(--var-secondary-color)',
                                color: 'var(--var-on-secondary-color)',
                            },
                        }} />
                )}
                PopperComponent={props => <Popper {...props} container={document.getElementById('themeContainer')} />}
                componentsProps={{
                    popper: {
                        style: {
                            zIndex: '2000000000 !IMPORTANT',
                            minWidth: MAX_WIDTH,
                            border: 'solid 1px #666',
                        },
                    }
                }} />
            {loading && <LoadingBar />}
        </div>
    );
}


function getLocalSuggestions(searchValue: string, fullState: AppState)
{
    const localSuggestions: ISearchSuggestions[] = [];

    const searchValueLower = searchValue.toLowerCase();

    const addSuggestions = (
        items: { id: number; title: string }[],
        entityType: EEntityType
    ) =>
    {
        items.forEach(item =>
        {
            if (item.title.toLowerCase().includes(searchValueLower))
            {
                localSuggestions.push({ id: item.id, label: item.title, type: entityType });
            }
        });
    };

    addSuggestions(fullState.company.allEmployees, EEntityType.EMPLOYEE);
    addSuggestions(fullState.OUs, EEntityType.ORGANISATIONAL_UNIT);
    addSuggestions(fullState.company.smallRoles, EEntityType.ROLE);
    addSuggestions(fullState.company.allCompetences, EEntityType.COMPETENCE);
    addSuggestions(fullState.products, EEntityType.PRODUCT);
    addSuggestions(fullState.company.allSkills, EEntityType.SKILL);
    addSuggestions(fullState.company.allCertificates, EEntityType.CERTIFICATE);
    addSuggestions(fullState.businesses, EEntityType.BUSINESS);
    addSuggestions(fullState.projects, EEntityType.PROJECT);
    addSuggestions(fullState.opportunity, EEntityType.OPPORTUNITY);

    return localSuggestions;
}
const typeOrder: Record<string, number> = {
    [EEntityType.EMPLOYEE]: 1,
    [EEntityType.ORGANISATIONAL_UNIT]: 2,
    [EEntityType.ROLE]: 3,
    [EEntityType.COMPETENCE]: 4,
    [EEntityType.PRODUCT]: 5,
    [EEntityType.SKILL]: 6,
    [EEntityType.CERTIFICATE]: 7,
    [EEntityType.BUSINESS]: 8,
    [EEntityType.PROJECT]: 9,
    [EEntityType.OPPORTUNITY]: 10,
};

function sortSuggestions(a: ISearchSuggestions, b: ISearchSuggestions): number
{
    // Wenn Typ gleich ist, nach Score sortieren
    if (a.score === undefined && b.score !== undefined)
    {
        return 1;
    }
    if (a.score !== undefined && b.score === undefined)
    {
        return 0;
    }
    if (a.score !== undefined && b.score !== undefined)
    {
        if (a.score !== b.score)
        {
            return a.score - b.score;
        }
    }
    // nach Typ sortieren
    if (typeOrder[a.type] !== typeOrder[b.type])
    {
        return typeOrder[a.type] - typeOrder[b.type];
    }
    // Wenn Score  und Typ gleich sind, nach Titel sortieren
    return a.label.localeCompare(b.label);
}