export interface IPermissionActions
{
    Add: boolean
    Delete: boolean
    Edit: boolean
    Retrieve: boolean
    RetrieveOwn: boolean
    RetrieveSalesData: boolean
}

export interface IEntitiesPermissions
{
    Certificates: IPermissionActions
    Companies: IPermissionActions
    Competencies: IPermissionActions
    Products: IPermissionActions
    Projects: IPermissionActions
    Skills: IPermissionActions
    SkillCategories: IPermissionActions
    Opportunities: IPermissionActions
    OrgUnits: IPermissionActions
    Roles: IPermissionActions
}
export interface IPermissionGroup
{
    id: number;
    title: string;
    entities: IEntitiesPermissions;
}
export interface IPermissions
{
    admin: boolean;
    entities: IEntitiesPermissions;
}

export const ForbiddenActions: IPermissionActions = {
    Add: false,
    Delete: false,
    Edit: false,
    Retrieve: false,
    RetrieveOwn: false,
    RetrieveSalesData: false,
}
export const allowedActions: IPermissionActions = {
    Add: true,
    Delete: true,
    Edit: true,
    Retrieve: true,
    RetrieveOwn: true,
    RetrieveSalesData: true,
}
export const defaultEntitiesPermissions: IEntitiesPermissions =
{
    Certificates: { ...ForbiddenActions, Retrieve: true, },
    Companies: { ...ForbiddenActions },
    Competencies: { ...ForbiddenActions },
    Products: { ...ForbiddenActions },
    Projects: { ...ForbiddenActions },
    Skills: { ...ForbiddenActions, Retrieve: true, },
    SkillCategories: { ...ForbiddenActions, Retrieve: true, },
    Opportunities: { ...ForbiddenActions },
    OrgUnits: { ...ForbiddenActions, Retrieve: true, },
    Roles: { ...ForbiddenActions, Retrieve: true },
}

export const disabledEntitiesPermissions: IEntitiesPermissions =
{
    Certificates: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: true },
    Companies: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
    Competencies: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
    Products: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
    Projects: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
    Skills: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: true },
    SkillCategories: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: true },
    Opportunities: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
    OrgUnits: { ...allowedActions, Retrieve: false, RetrieveSalesData: false },
    Roles: { ...allowedActions, Delete: false, Edit: false, Add: false, Retrieve: false },
}


export const hiddenEntitiesPermissions: IEntitiesPermissions = {
    Certificates: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Companies: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Competencies: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Products: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Projects: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Skills: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    SkillCategories: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    Opportunities: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
    OrgUnits: { ...ForbiddenActions, Add: true, Edit: true, Delete: true, RetrieveOwn: true },
    Roles: { ...ForbiddenActions, RetrieveOwn: true, RetrieveSalesData: true },
}

/**
* Function to check permissions for a specific entity and action.
*
* @param {keyof IEntitiesPermissions} entity - The entity for which permissions are being checked.
* @param {keyof IPermissionActions} action - The action being performed.
* @param {IPermissions} permissions - The permissions object.
* @returns {boolean} - True if the user has the required permissions, false otherwise.
*/
export function checkPermissions(entity: keyof IEntitiesPermissions, action: keyof IPermissionActions, permissions: IPermissions)
{
    if (permissions.admin) return true;
    if (!permissions.entities[entity]) return false;
    if (permissions.entities[entity][action] === undefined) return false;

    return permissions.entities[entity][action]
}


/**
 * Sets the default permissions for an entity.
 * 
 * @param permissionGroup - The permission group object for the entity.
 * 
 * @returns The updated permission group object.
 */
export function setDefaultEntityPermissions(permissionGroup: IPermissionGroup): IPermissionGroup
{
    const defaultEntities = Object.keys(defaultEntitiesPermissions) as (keyof IEntitiesPermissions)[];
    const updatedEntities: IEntitiesPermissions = { ...permissionGroup.entities };
    for (const entity of defaultEntities)
    {
        updatedEntities[entity] = {
            ...defaultEntitiesPermissions[entity],
            ...permissionGroup.entities[entity],
        };
    }
    return {
        ...permissionGroup,
        entities: updatedEntities,
    };
}