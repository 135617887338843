import { CallSplit } from '@mui/icons-material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeSkills } from '@store/reducer/employeeReducer';
import { setCompanyAllSkills } from '@store/reducer/companyReducer';
import { setSkills } from '@store/reducer/skillReducer';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import SkillDetail from '@src/Objects/SkillDetail';
import splitSkill from '@src/APIs/graphQl/Skill/splitSkill';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';

import './SplitSkillsDialog.css';
interface SplitSkillsDialogProps
{
    id: string
    skill: SkillDetail
    resolve: () => void
    skillsWithoutFilter: SkillDetail[]
}

/**
 * SplitSkillsDialog
 * 
 * @param {SplitSkillsDialogProps} props - The props object
 * 
 * @returns {JSX.Element}
 */
export default function SplitSkillsDialog(props: SplitSkillsDialogProps)
{

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const dispatch = useDispatch();
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const employee = useSelector((state: AppState) => state.employee);

    const skill = props.skill;

    const [newSkillName1, setNewSkillName1] = React.useState(skill ? skill.title : '' as string);
    const [newSkillName2, setNewSkillName2] = React.useState('' as string);
    const [newDescription1, setNewDescription1] = React.useState(skill ? skill.description : '' as string);
    const [newDescription2, setNewDescription2] = React.useState('' as string);
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    if (!employee || !skill)
    {
        return null
    }

    return (<Dialog
        id={props.id}
        title={langStrings.splitSkills + ': ' + skill.title}
        onClose={function (): void
        {
            props.resolve();
        }}
        footer={
            <div style={{ float: 'right' }}>
                <Button
                    icon={<CallSplit />}
                    disabled={saving}
                    text={langStrings.split}
                    size={'normal'}
                    onClick={async function (): Promise<void>
                    {
                        setSaving(true);
                        await splitSkill(
                            skill.id,
                            newSkillName1,
                            newSkillName2,
                            newDescription1,
                            newDescription2,

                        ).catch(ex =>
                        {

                            setErrorText(langStrings.error + ex);
                            setTimeout(() =>
                            {
                                setSaving(false);
                            }, 2500);
                        }).then((result) =>
                        {

                            if (!result)
                            {
                                setErrorText(langStrings.error);
                                return;
                            }
                            setErrorText("");

                            setSaving(false);

                            const newAllSkills = allSkills.filter(f => f.id !== skill.id);
                            newAllSkills.push({
                                title: result.skill1.title, id: result.skill1.id, otherNames: [], categoryId: -1,
                                version: 0,
                            });
                            newAllSkills.push({
                                title: result.skill2.title, id: result.skill2.id, otherNames: [], categoryId: -1,
                                version: 0,
                            });
                            dispatch(setCompanyAllSkills(newAllSkills))

                            const empSkill = employee.skills.find(s => s.id === skill.id)
                            if (empSkill)
                            {
                                const newEmployeeSkills = employee.skills.filter(f => f.id !== skill.id);
                                newEmployeeSkills.push({ title: result.skill1.title, id: result.skill1.id, level: empSkill.level });
                                newEmployeeSkills.push({ title: result.skill2.title, id: result.skill2.id, level: empSkill.level });
                                dispatch(setEmployeeSkills(newEmployeeSkills));
                            }
                            const newSkills = props.skillsWithoutFilter.filter(f => f.id !== skill.id);
                            newSkills.push(new SkillDetail(result.skill1));
                            newSkills.push(new SkillDetail(result.skill2));

                            dispatch(setSkills(newSkills));
                            props.resolve();
                        })
                    }}
                />
            </div>
        }
    >
        <div className="splitBox">
            <p>{langStrings.skillSelectedForSplit}: {skill.title}</p>
            <span>{langStrings.effectEmployeesWarning} {skill.employees.length} {langStrings.employees}:</span>
            <ul>
                {skill.employees.map(emp =>
                {
                    return <li key={emp.id}>{emp.title}
                        <ul>
                            <li key={skill.title}>{skill.title + " - " + emp.level}</li>
                        </ul>
                    </li>
                })}
            </ul>
            <h2>{langStrings.newSkill} 1: {newSkillName1}</h2>
            <div className="newSkill">
                <RowElement title={langStrings.skill} alignTitle="left">
                    <TextInput value={newSkillName1} onChange={s => setNewSkillName1(s)} />
                </RowElement>
                <RowElement title={langStrings.description} alignTitle="left">
                    <TextInput value={newDescription1} onChange={s => setNewDescription1(s)} />
                </RowElement>
                <p>{langStrings.descriptionGeneratesAutomaticly}</p>
            </div>
            <h2>{langStrings.newSkill} 2: {newSkillName2}</h2>
            <div className="newSkill">
                <RowElement title={langStrings.skill} alignTitle="left">
                    <TextInput value={newSkillName2} onChange={s => setNewSkillName2(s)} />
                </RowElement>
                <RowElement title={langStrings.description} alignTitle="left">
                    <TextInput value={newDescription2} onChange={s => setNewDescription2(s)} />
                </RowElement>
                <p>{langStrings.descriptionGeneratesAutomaticly}</p>
            </div>

            {errorText}
        </div>
    </Dialog>
    );
}


