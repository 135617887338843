import { OperationVariables, gql } from "@apollo/client";
import { generateFullName } from "@sharedInterfaces/globalHelper";
import { ISmallSkill } from "@sharedInterfaces/IWhoIAm";
import store from "@store/store";
import { setEmployeeSkills } from "@store/reducer/employeeReducer";
import { setSkills } from "@store/reducer/skillReducer";
import SkillDetail from "@src/Objects/SkillDetail";
import { setLastSkillUpdate } from "@store/reducer/employeeSettingsReducer";

import GraphQL from "../graphQL";


const query = gql`
mutation changeMapSkill($skillId: Int!, $add: Boolean!, $level: Int) {
    changeMapSkill(id: $skillId, add: $add, level: $level) 
},
`;

export default async function changeMapSkill(skillId: number, add: boolean, level?: number)
{
    const variables: OperationVariables = { skillId, add, level };
    const result = await GraphQL.mutate<boolean>(query, variables);

    store.dispatch(setLastSkillUpdate(new Date()));
    const state = store.getState();
    if (!add)
    {
        store.dispatch(setEmployeeSkills(state.employee.skills.filter(c => c.id !== skillId)));

        const newSkills = [...state.skills];
        const skill = newSkills.find(c => c.id === skillId);
        if (skill)
        {
            const newSkill = new SkillDetail({ ...skill });
            newSkill.employees = newSkill.employees.filter(e => e.id !== state.employee.id)
            newSkills[newSkills.indexOf(skill)] = newSkill;
            store.dispatch(setSkills(newSkills))
        }
    } else if (add && level)
    {
        //Update Emp Skill
        const smallSkill = state.company.allSkills.find(s => s.id === skillId) as ISmallSkill;
        const newSkills = state.employee.skills.filter(c => c.id !== skillId);
        newSkills.push({ id: skillId, title: smallSkill.title, level: level });
        store.dispatch(setEmployeeSkills(newSkills));

        //Update big SKill
        const newSkills2 = [...state.skills];
        const skill = newSkills2.find(c => c.id === skillId);
        if (skill)
        {
            const newSkill = new SkillDetail({ ...skill, employees: skill.employees.filter(e => e.id !== state.employee.id) });
            newSkill.employees.push({
                id: state.employee.id,
                title: generateFullName(state.employee.firstName, state.employee.lastName),
                level: level,
            })
            newSkills2[newSkills2.indexOf(skill)] = newSkill;
            store.dispatch(setSkills(newSkills2))
        }

    }
    return result;
}

