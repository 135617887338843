import { OperationVariables, gql } from "@apollo/client";
import ISkillDetailDTO from "@sharedInterfaces/ISkill";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteSkill, updateSkill } from "@store/reducer/skillReducer";
import { setCompanyAllSkills } from "@store/reducer/companyReducer";
import { setEmployeeSkills } from "@store/reducer/employeeReducer";
import SkillDetail, { skillDetailGQL } from "@src/Objects/SkillDetail";

import GraphQL from "../graphQL";
import { NotFoundError } from '../NotFoundError';

const q = gql`
query GetSkill($skillId: ID!) {
    skill(id:  $skillId) 
    ${skillDetailGQL}
},
`;

/**
 * Retrieves a skill by skillId.
 *
 * @param {number} skillId - The ID of the skill to retrieve.
 * @param {number} version - The actual version of the skill to retrieve.
 * @returns {Promise<{ skill: ISkill }>} A promise that resolves with the retrieved skill.
 */
export default function getSkill(skillId: number)
{
    const variables: OperationVariables = { skillId };
    return GraphQL.query<{ skill: ISkillDetailDTO }>(q, variables).then((obj) =>
    {
        if (!obj?.skill)
        {
            const state = store.getState();
            store.dispatch(deleteSkill(skillId));
            store.dispatch(setEmployeeSkills(state.employee.skills.filter(empEneity => empEneity.id !== skillId)));
            store.dispatch(setCompanyAllSkills(state.company.allSkills.filter(allEntity => allEntity.id !== skillId)))
            throw new NotFoundError(skillId, EEntityType.SKILL)
        }
        const newSkill = new SkillDetail(obj.skill);
        store.dispatch(updateSkill(newSkill));
        return newSkill;
    })
}