import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import store from "@store/store";
import { deleteOrgUnit as deleteOrgUnitReducerAction } from "@store/reducer/orgUnitReducer";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation deleteOU($id: Int!) 
    {
        deleteOU(id: $id) 
            ${companySettingsGql}
},
`;

/**
 * Deletes the OU with the specified ID.
 *
 * @param {number} id - The ID of the OU to delete.
 * @return {Promise<Company>} A promise that resolves to the updated Company object.
 */
export default async function deleteOU(id: number)
{
    const variables: OperationVariables = { id };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then((o) =>
        {
            store.dispatch(deleteOrgUnitReducerAction(id))
            return new Company(o)
        });
}