import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Close, Info } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ISmallSkill } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import changeMapSkill from '@src/APIs/graphQl/Skill/changeMapSkill';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import RateBar from '../../formsControls/inputs/RateBar/RateBar';
import { useLevelDefinitionsDialog } from '../LevelDefinitionsDialog/LevelDefinitionsDialog';
interface mapSkillDialogModalProps
{
    id: string
    skillId: number
    resolve: (val: boolean) => void;
}
const MapSkillDialogModal: React.FC<mapSkillDialogModalProps> =
    ({ id, skillId, resolve, }) =>
    {
        const showLevelDefinitionsDialog = useLevelDefinitionsDialog();
        const showErrorDialog = useErrorDialog();
        const lang = useSelector((state: AppState) => state.employee.language);
        const langStrings = getLanguageTexts(lang).skills;

        const offline = useSelector((state: AppState) => state.client.offline);
        const employee = useSelector((state: AppState) => state.employee);
        const smallSkill = useSelector((state: AppState) => state.company.allSkills.find(s => s.id === skillId)) as ISmallSkill;
        const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);

        const empSkill = employee.skills.find(s => s.id === skillId)

        const [saving, setSaving] = React.useState<boolean>(false);
        const [isOpen, setIsOpen] = useState(true);


        if (!employee || skillId === -1) return null
        const onCancel = () =>
        {
            resolve(false);
            setIsOpen(false);
        };
        const add = (newLevel: number) =>
        {
            if (newLevel === 0) return;
            onCancel();
            if (saving || !employee) return;
            setSaving(true);
            changeMapSkill(skillId, true, newLevel)
                .then(() =>
                {
                    setSaving(false);
                })
                .catch(async (ex) =>
                {
                    console.log(ex)
                    await showErrorDialog(ex, langStrings.errorAdd)
                    setSaving(false);
                })
        }




        useEffect(() =>
        {
            if (!isOpen)
            {
                onCancel();
            }
        }, [isOpen, onCancel]);

        if (!isOpen) return null;

        return (
            <Dialog
                id={id}
                footer={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        disabled={offline}
                        onClick={showLevelDefinitionsDialog}
                    >
                        <Info color='primary' />
                    </IconButton>
                    <Button
                        disabled={offline}
                        onClick={onCancel}
                        text={langStrings.oftenUsed.cancel}
                        icon={<Close />}
                        size='normal' />
                </div>}
                title={empSkill?.level ? langStrings.editSkillProfile : langStrings.addSkill}
                onClose={onCancel}>
                <div className='selectLevel'>
                    <RateBar
                        level={empSkill ? empSkill.level : 0} name={smallSkill.title} setValue={(number) =>
                        {
                            add(number);
                        }} />
                </div>
                <div style={{ marginTop: 10 }}>
                    {levelDefinitions.map((levelDefinition) => <div key={levelDefinition.level}>
                        {levelDefinition.level} - {levelDefinition.title}
                    </div>)}
                </div>
            </Dialog>
        );
    };



export function useMapSkillDialog()
{
    const { openDialog } = useContext(DialogManagerContext);

    const mapSkillDialog = (skillId: number) =>
    {
        return new Promise<boolean>((resolve) =>
        {
            const dialog = (
                <MapSkillDialogModal
                    id="MapSkillDialogModal"
                    skillId={skillId}
                    resolve={(result) => resolve(result)}
                />
            );
            openDialog(dialog);
        });
    };

    return mapSkillDialog;
}