import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeDTO } from "@sharedInterfaces/IEmployee";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setEmployees } from "@store/reducer/employeesReducer";
import Employee, { employeeGQL } from "@src/Objects/Employee";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    employees(version:$version){
        version
        employees
        ${employeeGQL}
    }
},
`;

interface EmployeeResponse
{
    employees: { version: number; employees: IEmployeeDTO[] };
}


export default async function getEmployees()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.employee,
    };
    return GraphQL.query<EmployeeResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Employees.");
            if (obj.employees.employees)
            {
                const data = obj.employees.employees.map((p: IEmployeeDTO) => new Employee(p));
                store.dispatch(setCompanyVersion(EEntityType.EMPLOYEE, obj.employees.version))
                store.dispatch(setEmployees(data))
                return data;
            }
            return state.employees;
        });
}