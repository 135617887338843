import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autorenew, Cancel } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import { AppState } from '@store/store';
import { unsubscribe } from '@src/APIs/rest/unsubscribe';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import Button from '../../sharedReact/General/Button/Button';
import Row from '../../sharedReact/General/Forms/Row/Row';

function EmailUnsubscribePage()
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).emailTexts;

    const [subscribed, setSubscribed] = useState(true);
    const [updating, setUpdating] = useState(true);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');


    useEffect(() =>
    {
        // Extrahieren der E-Mail-Adresse aus dem Query-Parameter 'email'
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        const tokenParam = queryParams.get('token');
        if (emailParam) setEmail(emailParam);
        if (tokenParam) setToken(tokenParam)
    }, [location]);

    useEffect(() =>
    {
        if (email === "" || token === "") return
        handleUnsubscribe();
    }, [email, token])

    function handleUnsubscribe()
    {
        setUpdating(true);
        unsubscribe(email, token, true)
            .then((result) =>
            {
                setUpdating(false);
                if (result)
                {
                    setSubscribed(false);
                    setError(false);
                }
                else 
                {
                    setError(true);
                }
            })
            .catch((error) => showErrorDialog(error))
    }

    function handleResubscribe()
    {
        setUpdating(true);
        unsubscribe(email, token, false)
            .then((result) =>
            {
                setUpdating(false);
                if (result)
                {
                    setSubscribed(true);
                    setError(false);
                }
                else 
                {
                    setError(true);
                }
            })
            .catch((error) => showErrorDialog(error))
    }

    let text = "";
    let buttonText = "";

    if (error)
    {
        text = langStrings.error;
        buttonText = langStrings.retry;
    }
    else if (subscribed && updating)
    {
        text = langStrings.youAreBeingUnsubscribed;
        buttonText = langStrings.beingCanceled;
    } else if (!subscribed && !updating)
    {
        text = langStrings.youHaveBeenUnsubscribed;
        buttonText = langStrings.resubscribeButton;
    } else if (subscribed && !updating)
    {
        text = langStrings.youHaveBeenResubscribed;
        buttonText = langStrings.unsubscribeButton;
    } else if (!subscribed && updating)
    {
        text = langStrings.youWillBeResubscribed;
        buttonText = langStrings.beingResubscribed;
    }

    return (
        <InnerLayout title={langStrings.title}>
            {
                (email !== "" && token !== "") &&
                <div className="unsubscribe-container">
                    <Typography variant='body1'>
                        {text}
                    </Typography>
                    <Row justifyContent='start'>
                        {
                            email &&
                            <div>
                                <Typography variant='body1'>
                                    {langStrings.emailDisplay}
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {email}
                                </Typography>
                            </div>
                        }
                        <Button
                            style={{ width: 300 }}
                            color="primary"
                            disabled={updating}
                            icon={updating ? (
                                <CircularProgress size={24} /> // Anzeigen eines Lade-Icons, wenn updating
                            ) : subscribed ? (
                                <Cancel /> // Anzeigen des Cancel-Icons, wenn subscribed
                            ) : (
                                <Autorenew /> // Anzeigen des Autorenew-Icons, wenn nicht subscribed
                            )}
                            text={buttonText}
                            onClick={subscribed ? handleUnsubscribe : handleResubscribe}
                        />
                    </Row>
                    {/* <LoadingBox /> */}
                </div>
            }
            {
                (email === "" || token === "") &&
                <div className="unsubscribe-container">
                    <Typography variant='body1'>
                        {langStrings.noEmail}
                    </Typography>
                </div>
            }
        </InnerLayout>
    );
}


export default EmailUnsubscribePage;
