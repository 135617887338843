import React, { useContext } from 'react';
import { ICompanyApiKey } from '@sharedInterfaces/ICompanySettings';
import Company from '@src/Objects/Company';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Button from '@sharedReact/General/Button/Button';
import { Add, Close, CopyAll, Delete, OpenInNew } from '@mui/icons-material';
import { AskForTextDialog } from '@src/Components/Dialogs/AskForTextDialog/AskForTextDialog';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import createNewApiKey from '@src/APIs/graphQl/Company/createNewApiKey';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import deleteApiKey from '@src/APIs/graphQl/Company/deleteApiKey';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
import Row from '@sharedReact/General/Forms/Row/Row';
import { useNavigate } from 'react-router-dom';
import { ELinks } from '@sharedInterfaces/globalEnums';



interface RestApiTabProps
{
    apiKeys: ICompanyApiKey[],
    setCompany: (company: Company) => void;
}
export default function RestApiTab({ apiKeys, setCompany }: RestApiTabProps)
{
    // apiKeys = [{ id: 1, note: 'test 123', createdAt: new Date(), createdBy: 1 }]
    const naviate = useNavigate();
    const showErrorDialog = useErrorDialog();
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    return <>
        <Row justifyContent='space-between'>
            <Typography variant='h4'>
                {langStrings.restApiKeys}
            </Typography>
            {/* Link zur Doku */}
            <Button
                text={langStrings.oftenUsed.documentation}
                icon={<OpenInNew />}
                onClick={function (): void
                {
                    naviate(`/${ELinks.DOCUMENTATION_REST}`)
                }}
            />
        </Row>
        <ApiKeyTable
            apiKeys={apiKeys}
            onAddApiKey={onAddApiKey}
            onDeleteApiKey={onDeleteApiKey}
        />
    </>

    function onAddApiKey()
    {
        openDialog(<AskForTextDialog
            id={'askForApiKeyDialog'}
            title={langStrings.addApiKey}
            message={langStrings.pleaseEnterApiKeyNote}
            defaultValue={""}
            resolve={async (note) =>
            {
                if (note === null) return;

                createNewApiKey(note)
                    .then((result) =>
                    {
                        setCompany(result.company);

                        openDialog(<ApiKeyDialog
                            id='apiKey'
                            title={langStrings.apiKey}
                            apiKey={result.apiKey}
                            resolve={() => { }}
                        />)
                    })
                    .catch(err =>
                    {
                        showErrorDialog(err)
                    })
            }}
        />)
    }

    function onDeleteApiKey(id: number)
    {
        deleteApiKey(id)
            .then(setCompany)
            .catch(err =>
            {
                showErrorDialog(err)
            })
    }
}

interface RestApiTableProps
{
    apiKeys: ICompanyApiKey[]
    onAddApiKey: () => void
    onDeleteApiKey: (id: number) => void
}
function ApiKeyTable({ apiKeys, onAddApiKey, onDeleteApiKey }: RestApiTableProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);

    function getEmployeeName(userId: number)
    {
        const emp = allEmployees.find(e => e.id === userId);
        return emp ? emp.title : 'Unknown';
    }

    const addButton = <AddApiKeyButton onAdd={onAddApiKey} />;
    return (
        <>
            {(apiKeys?.length >= 7) &&
                addButton
            }
            <TableContainer component={Paper}>
                <Table aria-label="apiKeyTable">
                    <TableHead>
                        <TableRow>
                            <TableCell>{langStrings.id}</TableCell>
                            <TableCell align="left">{langStrings.note}</TableCell>
                            <TableCell align="left">{langStrings.oftenUsed.createdBy}</TableCell>
                            <TableCell align="left">{langStrings.oftenUsed.createdAt}</TableCell>
                            <TableCell align="right">{langStrings.oftenUsed.delete}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiKeys && apiKeys.length > 0 ? (
                            apiKeys.map((key, index) => (
                                <TableRow
                                    key={key.id}
                                    sx={{
                                        backgroundColor: index % 2 === 1 ? 'rgba(0, 0, 0, 0.04)' : 'white'
                                    }}
                                >
                                    <TableCell>{key.id}</TableCell>
                                    <TableCell>{key.note}</TableCell>
                                    <TableCell>{getEmployeeName(key.createdBy)}</TableCell>
                                    <TableCell>{new Date(key.createdAt).toLocaleString()}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={onDeleteApiKey.bind(null, key.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <Typography variant="body1" color="textSecondary">
                                        {langStrings.noApiKeysYet}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {addButton}
        </>
    );
}

function AddApiKeyButton({ onAdd }: { onAdd: () => void })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    return <Button
        icon={<Add />}
        text={langStrings.addApiKey}
        style={{ maxWidth: 300, margin: '5px 0px' }}
        onClick={onAdd}
    />
}


interface ApiKeyDialogProps
{
    id: string;
    title: string;
    apiKey: string;
    resolve: () => void;
}

function ApiKeyDialog({ id, title, apiKey, resolve }: ApiKeyDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    return <Dialog id={id}
        title={title}
        footer={<div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
            <Button
                text={langStrings.oftenUsed.close}
                icon={<Close />}
                onClick={resolve} />
        </div>}
        onClose={resolve}>
        {/* {apiKey} */}


        <RowElement title={langStrings.apiKey}>
            <TextField
                fullWidth
                variant="outlined"
                value={apiKey}
                disabled
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton title={langStrings.oftenUsed.copy} onClick={copyToClipboard.bind(null, apiKey)}>
                            <CopyAll />
                        </IconButton>
                    ),
                }}
            />
        </RowElement>
    </Dialog>
}


function copyToClipboard(link: string) 
{
    navigator.clipboard.writeText(link);
}