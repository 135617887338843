import { AutoFixHigh, Clear, Done } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, CircularProgress } from '@mui/material';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ICertificateDTO, { TCertificateRequestingState } from '@sharedInterfaces/ICertificate';
import { AppState } from '@store/store';
import { setEmployeeCertificates } from '@store/reducer/employeeReducer';
import { setCompanyAllCertificates } from '@store/reducer/companyReducer';
import { updateCertificate } from '@store/reducer/certificatesReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import Certificate from '@src/Objects/Certificate';
import editCertificate from '@src/APIs/graphQl/Certificate/editCertificate';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { ICertificateMetadataResponse } from '@sharedInterfaces/websocket/ICertificateMetadata';
import createCertificate from '@src/APIs/graphQl/Certificate/createCertificate';

import ErrorBox from '../../ErrorBox/ErrorBox';
import './CertificateDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import MultiTextChipSelect from '../../formsControls/inputs/MultiTextChipSelect/MultiTextChipSelect';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import LinkInput from '../../formsControls/inputs/LinkInput/LinkInput';
import NumberInput from '../../formsControls/inputs/NumberInput/NumberInput';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';


interface CertificateDialogProps
{
    id: string
    certificate?: Certificate
    resolve?: (val: Certificate | null) => void;
}

/**
 * React component representing a certificate dialog.
 * 
 * @param props - The props for the certificate dialog.
 * @returns JSX element representing the certificate dialog.
 */
export function CertificateDialog({ id, certificate, resolve }: CertificateDialogProps)
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;

    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);
    const emp = useSelector((state: AppState) => state.employee);

    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [certificateName, setCertificateName] = React.useState(certificate ? certificate.title : '' as string);
    const [certificateNameSuggestion, setCertificateNameSuggestion] = React.useState<string>("");
    const [certificateNameError, setCertificateNameError] = React.useState<string | undefined>(undefined);

    const [description, setDescription] = React.useState(certificate ? certificate.description : '' as string);
    const [otherNames, setOtherNames] = React.useState<string[]>(certificate ? certificate.otherNames : []);

    const [productName, setProductName] = React.useState<string>(certificate && certificate.productInfo ? certificate.productInfo.productName : "");
    const [manufacturer, setManufacturer] = React.useState<string>(certificate && certificate.productInfo ? certificate.productInfo.manufacturer : "");
    const [link, setLink] = React.useState<string>(certificate && certificate.productInfo && certificate.productInfo.link ? certificate.productInfo.link : "");
    const [prerequisites, setPrerequisites] = React.useState<string>(certificate ? certificate.prerequisites : "");

    const [durationInDays, setDurationInDays] = React.useState<number>(certificate?.durationInDays ? certificate.durationInDays : 0);
    const [cost, setCost] = React.useState<number>(certificate?.cost ? certificate.cost : 0);

    const [generatingMetadata, setGeneratingMetadata] = React.useState<boolean>(false);

    const [subKey, setSubKey] = React.useState<string>("");
    const topic = EWebsocketTopics.generateCertificateMetadata;
    const startAnalyse = () =>
    {
        const request: TCertificateRequestingState = {
            all: true,
        };
        WebSocketClient.sendMessage(topic, {
            certificateName,
            key: subKey,
            request
        });
        setGeneratingMetadata(true)
    }
    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<ICertificateMetadataResponse>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if (data.error)
            {
                showErrorDialog(new Error(data.error))
                setGeneratingMetadata(false);
                return;
            }
            const metaData = data.metadata;

            if (!metaData)
            {
                showErrorDialog(new Error(langStrings.error))
                setGeneratingMetadata(false);
                return;
            }
            setGeneratingMetadata(false)
            if (metaData.description && (!description || description === ""))
                setDescription(metaData.description);
            if (metaData.synonyms && (!otherNames || otherNames.length === 0))
                setOtherNames(metaData.synonyms.map(n => n.toLowerCase() as string) as string[]);
            if (metaData.productInfo && (!productName || productName === ""))
                setProductName(metaData.productInfo.productName);
            if (metaData.productInfo && (!manufacturer || manufacturer === ""))
                setManufacturer(metaData.productInfo.manufacturer);
            if (metaData.productInfo && (!link || link === ""))
                setLink(metaData.productInfo.link);
            if (metaData.prerequisites && (!prerequisites || prerequisites === ""))
                setPrerequisites(metaData.prerequisites);
            if (metaData.durationInDays && (!durationInDays || durationInDays === 0))
                setDurationInDays(metaData.durationInDays);
            if (metaData.cost && (!cost || cost === 0))
                setCost(metaData.cost);

            if (metaData.certificateName && (certificateName !== metaData.certificateName))
            {
                setCertificateNameSuggestion(metaData.certificateName);
            }
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])


    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newCertificate: ICertificateDTO) =>
    {
        if (!emp) return;
        const newEntity = new Certificate(newCertificate);
        const newAllCerts = [...allCertificates];
        const newArray = [...emp.certificates];

        if (!certificate) // CREATE
        {
            newAllCerts.push({
                id: newCertificate.id, title: newCertificate.title, otherNames,
                version: 0,
            });
            // newArray.push(newCertificate);
        }
        else //MODIFY
        {
            let index = newAllCerts.findIndex(e => newEntity.id === e.id);
            newAllCerts[index] = { ...newAllCerts[index], title: newEntity.title };
            index = newArray.findIndex(e => newEntity.id === e.id);
            if (index > -1)
                newArray[index] = newEntity;
        }
        dispatch(setEmployeeCertificates(newArray))
        dispatch(setCompanyAllCertificates(newAllCerts))
        dispatch(updateCertificate(newEntity))
        resolve && resolve(newEntity);
        setSaving(false);
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (certificateName === "")
        {
            setErrorText(langStrings.newCertificateError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!certificate)
        {
            await createCertificate(
                certificateName,
                description,
                otherNames,
                productName,
                manufacturer,
                link,
                prerequisites,
                cost,
                durationInDays
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editCertificate(
                certificate.id,
                certificateName,
                description,
                otherNames,
                productName,
                manufacturer,
                link,
                prerequisites,
                cost,
                durationInDays
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };
    const onClose = () =>
    {
        resolve && resolve(null);
    }

    const existingCertificates = allCertificates.filter(a => !certificate || certificate.id !== a.id).map(s => s.title.toLowerCase());


    const validate = () =>
    {
        if (certificateName === "")
        {
            setCertificateNameError(
                langStrings.pleaseEnterTitle
            )
        } else if (existingCertificates.includes(certificateName.toLowerCase()))
        {
            setCertificateNameError(
                langStrings.errorDuplicateTitle
                    .replace("[TITLE]", certificateName)
                    .replace("[TITLE]", certificateName)
            )
        }
        else
        {
            setCertificateNameError(undefined);
        }
    }

    useEffect(validate, [certificateName])

    return (
        <Dialog
            id={id}
            title={certificate ? certificate.title : langStrings.newCertificate}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!certificate ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="certificateDialog">
                <div className="newCertificate">
                    <Row>
                        <RowElement title={langStrings.certificateName} alignTitle="left">
                            <TextInput
                                value={certificateName}
                                isAI
                                onChange={setCertificateName}
                                helperText={certificateNameError}
                            />
                        </RowElement>
                        <IconButton
                            title={langStrings.generateCertificateData}
                            disabled={!!certificateNameError || generatingMetadata}
                            onClick={startAnalyse}
                        >
                            {generatingMetadata ? <CircularProgress size={24} color='secondary' /> : <AutoFixHigh />}
                        </IconButton>
                    </Row>
                    {
                        certificateNameSuggestion !== "" &&
                        <div>
                            {langStrings.suggestedCertificateName}: {certificateNameSuggestion}
                            <IconButton
                                onClick={() =>
                                {
                                    setCertificateName(certificateNameSuggestion);
                                    setCertificateNameSuggestion("");
                                }}
                            >
                                <Done />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                {
                                    setCertificateNameSuggestion("");
                                }}
                            >
                                <Clear />
                            </IconButton>
                        </div>
                    }
                    <Row align='top'>
                        <RowElement title={langStrings.productName} alignTitle="left">
                            <TextInput size='small' value={productName} onChange={setProductName} />
                        </RowElement>
                        <RowElement title={langStrings.productInfo} alignTitle="left">
                            <LinkInput size='small' value={link} onChange={setLink} />
                        </RowElement>
                    </Row>
                    <RowElement title={langStrings.manufacturer} alignTitle="left">
                        <TextInput size='small' value={manufacturer} onChange={setManufacturer} />
                    </RowElement>
                    <Row>
                        <RowElement title={langStrings.durationInDays} alignTitle="left">
                            <NumberInput value={durationInDays} onChange={setDurationInDays} />
                        </RowElement>
                        <RowElement title={langStrings.oftenUsed.cost} alignTitle="left">
                            <NumberInput value={cost} onChange={setCost} />
                        </RowElement>
                    </Row>
                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput value={description} onChange={setDescription} />
                    </RowElement>
                    <RowElement title={langStrings.otherNames} alignTitle="left">
                        <MultiTextChipSelect value={otherNames}
                            onChange={(names) =>
                            {
                                setErrorText("");
                                const newOtherNames = Array.from(new Set(names.map(n => n.toLowerCase())))
                                    .filter(newON =>
                                    {
                                        const existingSkill = allCertificates.find(a => a.title.toLocaleLowerCase() === newON && (!certificate || a.id !== certificate.id)) ||
                                            allCertificates.find(a => a.otherNames.find(o => o === newON) !== undefined && (!certificate || a.id !== certificate.id));
                                        if (existingSkill)
                                        {
                                            const errorTitle = existingSkill.title.toLocaleLowerCase() === newON;
                                            const errorString = (errorTitle ? langStrings.errorDuplicateTitle : langStrings.errorDuplicateOtherName)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[VALUE]", newON);
                                            setErrorText(errorString);
                                        }
                                        return !existingSkill;
                                    });
                                setOtherNames(newOtherNames)
                            }}
                        />
                    </RowElement>
                    <RowElement title={langStrings.prerequisites} alignTitle="left">
                        <FormatedTextInput value={prerequisites} onChange={setPrerequisites} />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}