import { Delete } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { OU } from '@src/Objects/Company';


interface RemoveOUDialogProps
{
    id: string
    employees: ICompanyUser[];
    ou: OU;
    resolve?: () => void;
    onSave: (val: boolean) => Promise<unknown>;
}
/**
* RemoveOUDialog component.
* @param {RemoveOUDialogProps} props - The props that are passed to the RemoveOUDialog component.
* @returns {JSX.Element} - The JSX element representing the RemoveOUDialog component.
*/
export function RemoveOUDialog(props: RemoveOUDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const [confirmed, setConfirmed] = React.useState(props.ou.employees.length === 0);
    const [deleting, setDeleting] = React.useState(false as boolean);
    const [error, setError] = React.useState("");
    const emps = props.ou.employees;

    return <Dialog
        id={props.id}
        title={langStrings.removeTitle.replace('[TITLE]', props.ou.title)}
        footer={<div style={{ float: 'right' }}>
            <Button
                icon={<Delete />}
                disabled={deleting || !confirmed}
                text={langStrings.oftenUsed.delete}
                onClick={async function (): Promise<void>
                {
                    if (!confirmed)
                    {
                        return props.resolve && props.resolve();
                    }
                    setDeleting(true);
                    await props.onSave(confirmed)
                        .catch(ex =>
                        {
                            setError(ex.toString());
                            setTimeout(() =>
                            {
                                setDeleting(false);
                            }, 3000);

                        });
                }} />
        </div>}
        onClose={function (): void
        {
            props.resolve && props.resolve();
        }}>
        <div className="RemoveOUDialog">
            <Typography>{langStrings.sureToDelete} {props.ou.title}</Typography>
            {emps.length > 0 &&
                <React.Fragment>
                    <Typography>{langStrings.deleteContainsWarning.replace('[COUNT]', emps.length.toString())}</Typography>
                    {emps.map(e => <li key={e.id}>{generateFullName(e.firstname, e.lastname)}</li>)}

                    <input type="checkbox" onChange={(e) => setConfirmed(e.currentTarget.checked)} /> {langStrings.deleteConfirm}
                </React.Fragment>}
            {error}
        </div>
    </Dialog>;
}
