import React from "react";
import { useSelector } from "react-redux";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import './PeopleOrgPage.css'
import { ELinks } from "@sharedInterfaces/globalEnums";

import { BoxButton, BoxButtons } from "../../Components/BoxButton/BoxButton";

/**
 * Represents the PeopleOrgPage component.
 * 
 * @returns {JSX.Element} The JSX element for the PeopleOrgPage.
 */
export default function PeopleOrgPage()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).navigation;

    return (
        <InnerLayout title={langStrings.employeeAndOEsLong} >
            <div className="page">
                <BoxButtons>
                    <BoxButton
                        title={langStrings.employees}
                        description={langStrings.employeesDescription}
                        onClick={`/${ELinks.PEOPLE_EMPLOYEES}`}
                    />
                    <BoxButton
                        title={langStrings.orgUnits}
                        description={langStrings.orgUnitsDescription}
                        onClick={`/${ELinks.PEOPLE_ORGUNITS}`}
                    />
                </BoxButtons>

            </div>
        </InnerLayout>
    );
}
