import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { setEmployeeCertificates } from '@store/reducer/employeeReducer';
import { setCompanyAllCertificates } from '@store/reducer/companyReducer';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { ErrorDialog, } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import changeMapCertificate from '@src/APIs/graphQl/Certificate/changeMapCertificate';
import createAndMapCertificate from '@src/APIs/graphQl/Certificate/createAndMapCertificate';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import Row from '../../../sharedReact/General/Forms/Row/Row';
import DateInput from '../../formsControls/inputs/DateInput/DateInput';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
interface mapCertificateDialogModalProps
{
    id: string
    certId?: number
    title?: string
    resolve: (val: boolean) => void;
}
export const MapCertificateDialog: React.FC<mapCertificateDialogModalProps> =
    ({ id, certId, title, resolve, }) =>
    {
        const { openDialog } = useContext(DialogManagerContext);
        const dispatch = useDispatch();
        const lang = useSelector((state: AppState) => state.employee.language);
        const langStrings = getLanguageTexts(lang).certificates;
        const employee = useSelector((state: AppState) => state.employee);
        const own = employee?.certificates.find(oc => oc.id === certId);
        const offline = useSelector((state: AppState) => state.client.offline);
        const allCertificates = useSelector((state: AppState) => state.company.allCertificates);

        const [saving, setSaving] = React.useState<boolean>(false);
        const [stateStartDate, setStateStartDate] = React.useState<undefined | Date>(own?.startDate || new Date());
        const [stateEndDate, setStateEndDate] = React.useState<undefined | Date>(own?.endDate);
        const allCert = allCertificates.find(c => c.id === certId);
        const [isOpen, setIsOpen] = useState(true);

        useEffect(() =>
        {
            setStateStartDate(own?.startDate || new Date())
            setStateEndDate(own?.endDate)
        }, [own])

        useEffect(() =>
        {
            if (stateStartDate === own?.startDate) return;
            if (allCert?.durationInDays && stateStartDate)
            {
                const newDate = new Date(stateStartDate);
                newDate.setDate(newDate.getDate() + allCert.durationInDays);
                setStateEndDate(newDate);
            }
        }, [stateStartDate]);

        if (!employee || certId === -1) return null

        const add = () =>
        {
            if (!title) return;
            if (!stateStartDate) return;
            if (saving || !employee) return;

            createAndMapCertificate(title, stateStartDate, stateEndDate)
                .then((result) =>
                {
                    const newCerts = [...employee.certificates];
                    newCerts.push({ id: result, title: title, startDate: stateStartDate, endDate: stateEndDate });
                    dispatch(setEmployeeCertificates(newCerts))
                    const newAllCerts = [...allCertificates];
                    newAllCerts.push({ title: title, id: result, otherNames: [], version: 0 });
                    dispatch(setCompanyAllCertificates(newAllCerts))

                    resolve(true);
                    setIsOpen(false);
                })
                .catch((ex) =>
                {
                    console.log(ex);
                    openDialog(<ErrorDialog id={'ErrorDialog'}
                        error={ex}
                        errorMessage={langStrings.errorAdd}
                        resolve={function () { }}
                    />);
                })
        }

        const map = () =>
        {
            if (!certId || !allCert) return;
            if (!stateStartDate) return;
            if (saving || !employee) return;
            setSaving(true);
            changeMapCertificate(certId, true, stateStartDate, stateEndDate)
                .then(() =>
                {
                    const newCerts = employee.certificates.filter(c => c.id !== certId);
                    newCerts.push({
                        id: certId,
                        title: allCert.title,
                        startDate: stateStartDate,
                        endDate: stateEndDate,
                    });
                    setSaving(false);
                    dispatch(setEmployeeCertificates(newCerts))
                    resolve(true);
                    setIsOpen(false);
                })
                .catch((ex) =>
                {
                    openDialog(<ErrorDialog id={'ErrorDialog'}
                        error={ex}
                        errorMessage={langStrings.errorAdd}
                        resolve={function () { }}
                    />);
                    setSaving(false);
                })
        }


        const onConfirm = () =>
        {
            if (certId)
            {
                map();
            } else
            {
                add();
            }
        };

        const onCancel = () =>
        {
            resolve(false);
            setIsOpen(false);
        };

        useEffect(() =>
        {
            if (!isOpen)
            {
                onCancel()
            }
        }, [isOpen, onCancel]);

        if (!isOpen) return null;

        return (
            <Dialog
                id={id}
                footer={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        onClick={onCancel}
                        text={langStrings.oftenUsed.cancel}
                        icon={<Close />}
                        size='normal' />
                    <Button
                        color='primary'
                        disabled={saving || !stateStartDate || offline}
                        onClick={onConfirm}
                        text={langStrings.oftenUsed.save}
                        icon={<Check />}
                        size='normal' />
                </div>}
                title={own ? langStrings.editCertificateTitle : langStrings.addCertificateTitle}
                onClose={onCancel}>
                <Typography variant='body1'>
                    {langStrings.addCertDialogHint}
                </Typography>
                <Row>
                    <RowElement title={langStrings.startDate}>
                        <DateInput value={stateStartDate} onChange={(val) =>
                        {
                            setStateStartDate(val)
                        }} />
                        {/* <Typography variant='caption'>
                                {langStrings.oftenUsed.today}
                            </Typography> */}
                    </RowElement>
                    <RowElement title={langStrings.endDate}>
                        <DateInput value={stateEndDate} onChange={(val) =>
                        {

                            setStateEndDate(val);
                        }} />
                    </RowElement>
                </Row>
                <Typography variant='caption'>
                    {langStrings.startDateHint}
                </Typography>
            </Dialog>
        );
    };

export function useMapCertificateDialog()
{
    const { openDialog } = useContext(DialogManagerContext);

    const mapCertificateDialog = useCallback((certId?: number, title?: string): Promise<boolean> =>
    {
        return new Promise<boolean>((resolve) =>
        {
            const dialog = (
                <MapCertificateDialog
                    id="mapCertificateDialog"
                    certId={certId}
                    title={title}
                    resolve={resolve}
                />
            );
            openDialog(dialog);
        });
    }, [openDialog]);

    return mapCertificateDialog;
}