import { OperationVariables, gql } from "@apollo/client";
import { EHolidaySource, IHolidaySettings } from "@sharedInterfaces/IHolidays";

import GraphQL from "../graphQL";

const query = gql`
mutation editHolidaySource($source: String!) {
    editHolidaySource(source: $source)
    {
        source
        holidays {
            year
            holidays {
                date
                name
                isFullDay
            }
        }
    }
},
`;

/**
 * Edits the holiday source.
 *
 * @param source - The holiday source.
 * @returns A promise that resolves to a boolean value indicating
 *          if the holiday source was successfully edited.
 */
export default async function editHolidaySource(source: EHolidaySource)
{
    const variables: OperationVariables = {
        source,
    };
    return GraphQL.mutate<IHolidaySettings>(query, variables)
}