import React from "react"
import { Typography } from "@mui/material"

import styles from './EntityPageInformation.module.css'
interface EntityPageInformationProps
{
    title: string
    size?: 'normal' | 'full'
    helpText?: string
    children: JSX.Element | string
}

/**
 * Function to render Entity Page Information.
 *
 * @param {EntityPageInformationProps} title - The title of the entity page.
 * @param {EntityPageInformationProps} size - The size of the entity page.
 * @param {EntityPageInformationProps} helpText - The help text of the entity page.
 * @param {EntityPageInformationProps} children - The children of the entity page.
 *
 * @returns {JSX.Element} The rendered entity page information.
 */
export default function EntityPageInformation({ title, size, helpText, children }: EntityPageInformationProps)
{
    const newSize = size ? size : 'normal';
    return (
        <div className={styles.entityPageInformation} style={{
            ...(newSize === 'normal' ? {} :
                {
                    gridColumnStart: 1,
                    gridColumnEnd: 'end',
                }
            )
        }}
            title={helpText}
        >
            <Typography variant="caption">{title}:</Typography>
            <div>
                {typeof children === 'string' ?
                    <Typography variant='body2' className="rowElementContent">
                        {children}
                    </Typography> :
                    children
                }
            </div>
        </div>
    )
}