import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface IEntityTypesTexts
{
    absence: string
    aiRequest: string
    availmonth: string
    business: string
    certificate: string
    competence: string
    employee: string
    empmonthproject: string
    empsettings: string
    opportunity: string
    orgunit: string
    product: string
    project: string
    role: string
    skill: string
    skillcategory: string
    smallCertificate: string
    smallCompetence: string
    smallEmployee: string
    smallRole: string
    smallSkill: string

}
type ILangData = {
    [lang in ELanguage]: IEntityTypesTexts
}
export const entityTypesTexts: ILangData = {
    'th': {
        aiRequest: 'คำขอ AI',
        availmonth: 'เดือนที่พร้อมใช้งาน',
        absence: 'การขาดงาน',
        empsettings: 'การตั้งค่าพนักงาน',
        empmonthproject: 'การส่งออกข้อมูลจากนาฬิกาของพนักงาน',
        role: oftenUsedTranslations['th'].role,
        smallRole: oftenUsedTranslations['th'].role,
        skillcategory: oftenUsedTranslations['th'].skillCategory,
        product: oftenUsedTranslations['th'].product,
        employee: oftenUsedTranslations['th'].employee,
        skill: oftenUsedTranslations['th'].skill,
        certificate: oftenUsedTranslations['th'].certificate,
        project: oftenUsedTranslations['th'].project,
        business: oftenUsedTranslations['th'].business,
        opportunity: oftenUsedTranslations['th'].opportunity,
        competence: oftenUsedTranslations['th'].competence,
        smallSkill: oftenUsedTranslations['th'].skill,
        smallCertificate: oftenUsedTranslations['th'].certificate,
        smallCompetence: oftenUsedTranslations['th'].competence,
        smallEmployee: oftenUsedTranslations['th'].employee,
        orgunit: oftenUsedTranslations['th'].orgUnit,
    },
    'en': {
        aiRequest: 'AI Request',
        availmonth: 'Availability Month',
        absence: 'Absence',
        empsettings: 'Employee Settings',
        empmonthproject: 'Employee Clockodo Export',
        role: oftenUsedTranslations['en'].role,
        smallRole: oftenUsedTranslations['en'].role,
        skillcategory: oftenUsedTranslations['en'].skillCategory,
        product: oftenUsedTranslations['en'].product,
        employee: oftenUsedTranslations['en'].employee,
        skill: oftenUsedTranslations['en'].skill,
        certificate: oftenUsedTranslations['en'].certificate,
        project: oftenUsedTranslations['en'].project,
        business: oftenUsedTranslations['en'].business,
        opportunity: oftenUsedTranslations['en'].opportunity,
        competence: oftenUsedTranslations['en'].competence,
        smallSkill: oftenUsedTranslations['en'].skill,
        smallCertificate: oftenUsedTranslations['en'].certificate,
        smallCompetence: oftenUsedTranslations['en'].competence,
        smallEmployee: oftenUsedTranslations['en'].employee,
        orgunit: oftenUsedTranslations['en'].orgUnit,
    },
    'de': {
        aiRequest: 'AI-Request',
        availmonth: 'Verfügbarkeitsmonat',
        absence: 'Abwesenheit',
        empsettings: 'Mitarbeiter Einstellungen',
        empmonthproject: 'Mitarbeiter Clockodo-Export',
        skillcategory: oftenUsedTranslations['de'].skillCategory,
        product: oftenUsedTranslations['de'].product,
        employee: oftenUsedTranslations['de'].employee,
        skill: oftenUsedTranslations['de'].skill,
        certificate: oftenUsedTranslations['de'].certificate,
        project: oftenUsedTranslations['de'].project,
        business: oftenUsedTranslations['de'].business,
        opportunity: oftenUsedTranslations['de'].opportunity,
        competence: oftenUsedTranslations['de'].competence,
        role: oftenUsedTranslations['de'].role,
        smallSkill: oftenUsedTranslations['de'].skill,
        smallCertificate: oftenUsedTranslations['de'].certificate,
        smallCompetence: oftenUsedTranslations['de'].competence,
        smallEmployee: oftenUsedTranslations['de'].employee,
        smallRole: oftenUsedTranslations['de'].role,
        orgunit: oftenUsedTranslations['de'].orgUnit,
    },
}