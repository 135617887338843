import React, { useContext } from 'react';
import './HelpDialog.css';
import { Close, Help } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EHelpId, } from '@sharedInterfaces/Language/languageTexts/helpDialog';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import Dialog from '../Dialog/Dialog';

interface HelpDialogButtonProps
{
    helpId: EHelpId;
}
/**
 * HelpDialog component.
 *
 * @param {HelpDialogButtonProps} props - The properties for the HelpDialog.
 * @returns {JSX.Element} The rendered HelpDialog component.
 */
export default function HelpDialogButton(props: HelpDialogButtonProps)
{
    const { openDialog } = useContext(DialogManagerContext);



    return (
        <React.Fragment>
            <div className='helpButton' onClick={() =>
            {
                openDialog(
                    <HelpDialog
                        id={'HelpDialog'}
                        helpId={props.helpId}
                    />
                );
            }}>
                <Help />
            </div>
        </React.Fragment>

    );
}

interface HelpDialogProps
{
    id: string
    helpId: EHelpId;
    resolve?: () => void
}
function HelpDialog({ id, helpId, resolve }: HelpDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).helpDialog;

    return <Dialog
        id={id}
        title={langStrings.dialogs[helpId].title}
        onClose={function (): void
        {
        }}
        footer={<div style={{ float: 'right' }}>
            <Button
                icon={<Close />}
                text={langStrings.close}
                size={'normal'}
                onClick={async function (): Promise<void>
                {
                    resolve && resolve();
                }} />
        </div>}
    >
        {langStrings.dialogs[helpId].texts.map(text => <p key={text}>{text}</p>)}
    </Dialog>;
}

