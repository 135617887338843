import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeDTO } from "@sharedInterfaces/IEmployee";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteEmployee, updateEmployee, } from "@store/reducer/employeesReducer";
import { setEmployeeStore } from "@store/reducer/employeeReducer";
import Employee, { employeeGQL } from "@src/Objects/Employee";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

const q = gql`
query GetEmployee($id: ID!) {
    employee(id: $id) 
    ${employeeGQL}
},
`;

/**
 * Retrieves an employee by their ID and updates the Redux store.
 * 
 * @param {number} id - The ID of the employee.
 * @returns {Promise<Employee>} A promise that resolves with the retrieved employee object.
 */
export default async function getEmployee(id: number)
{
    const variables: OperationVariables = { id };
    return GraphQL.query<{ employee: IEmployeeDTO }>(q, variables).then((obj) =>
    {
        if (!obj?.employee)
        {
            store.dispatch(deleteEmployee(id));
            throw new NotFoundError(id, EEntityType.EMPLOYEE);
        }
        const state = store.getState();
        const employee = new Employee(obj.employee);
        store.dispatch(updateEmployee(employee));
        if (state.employee.id === id)
        {
            store.dispatch(setEmployeeStore({
                ...state.employee,
                ...employee,
            }));
        }
        return employee;
    });
}
