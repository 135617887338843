import React from "react"
import { Category, Chat, GroupWork, Person, Psychology, SafetyDivider, School, ShoppingCart, Store, VerifiedUser } from "@mui/icons-material";
import { EEntityType } from "@sharedInterfaces/globalEnums";
/**
 * Converts the given entity type to an icon element.
 *
 * @param {import("./globalEnums").EEntityType} type - The entity type to convert.
 * 
 * @returns {JSX.Element} - The icon element representing the entity type.
 */
export function entityTypeToIcon(type: EEntityType): JSX.Element | undefined
{
    switch (type)
    {
        case EEntityType.SKILL_CATEGORY:
            return <Category />
        case EEntityType.PRODUCT:
            return <ShoppingCart />
        case EEntityType.EMPLOYEE:
            return <Person />
        case EEntityType.SKILL:
            return <Psychology />
        case EEntityType.CERTIFICATE:
            return <School />
        case EEntityType.PROJECT:
            return <GroupWork />
        case EEntityType.BUSINESS:
            return <Store />
        case EEntityType.OPPORTUNITY:
            return <Chat />
        case EEntityType.COMPETENCE:
            return <VerifiedUser />
        case EEntityType.ROLE:
            return <SafetyDivider />
        default:
            return undefined;
    }
}
/**
 * Überprüft, ob ein Element oder ein Elternelement eine spezifische Klasse besitzt.
 * @param {Event} event - Das Event-Objekt.
 * @param {string} searchClass - Die Klasse, nach der gesucht werden soll.
 * @param {string} stopClass - Die Klasse, bei der die Suche abgebrochen werden soll.
 * @return {boolean} - Gibt true zurück, wenn die Klasse gefunden wurde, sonst false.
 */
export function hasClassInPath(event: React.MouseEvent<HTMLDivElement, MouseEvent>, searchClass: string, stopClass: string)
{
    let element: HTMLElement = event.target as HTMLElement;
    while (element)
    {
        if (element.classList && element.classList.contains(stopClass))
        {
            return false;
        }
        if (element.classList && element.classList.contains(searchClass))
        {
            return true;
        }
        element = element.parentNode as HTMLElement;
    }
    return false;
}


export function RenderJson({ data }: { data: unknown })
{
    return (
        <pre>
            {JSON.stringify(data, null, 5)}
        </pre>
    );
}