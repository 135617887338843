import { OperationVariables, gql } from "@apollo/client";
import ISkillDetailDTO from "@sharedInterfaces/ISkill";
import { ESkillType } from "@sharedInterfaces/globalEnums";
import SkillDetail, { skillDetailGQL } from "@src/Objects/SkillDetail";

import GraphQL from "../graphQL";


const query = gql`
mutation editSkill(
    $id:Int!,
    $title: String!,
    $description: String!,
    $categoryId: Int!,
    $otherNames:[String!]!,
    $skillType: String!,
    $productName: String!,
    $manufacturer: String!,
    $link: String!,
    $acquisition: String!,
    $acquisitionLink: String!
) 
{
    editSkill(
        id: $id,
        title: $title,
        description: $description,
        categoryId: $categoryId,
        otherNames: $otherNames,
        skillType: $skillType,
        productName: $productName,
        manufacturer: $manufacturer,
        link: $link,
        acquisition: $acquisition,
        acquisitionLink: $acquisitionLink
    ) 
    ${skillDetailGQL}
},
`;

/**
 * Edit a skill.
 * 
 * @param {number} id - The ID of the skill.
 * @param {string} title - The title of the skill.
 * @param {string} description - The description of the skill.
 * @param {number} categoryId - The category ID of the skill.
 * @param {string[]} otherNames - The other names of the skill.
 * @param {ESkillType} skillType - The type of the skill.
 * @param {string} productName - The product name of the skill.
 * @param {string} manufacturer - The manufacturer of the skill.
 * @param {string} link - The link of the skill.
 * @param {string} acquisition - The acquisition of the skill.
 * @param {string} acquisitionLink - The acquisition link of the skill.
 * @returns {Promise<SkillDetail>} A promise that resolves to the updated skill detail.
 */
export default async function editSkill(id: number, title: string,
    description: string, categoryId: number, otherNames: string[], skillType: ESkillType, productName: string,
    manufacturer: string,
    link: string,
    acquisition: string,
    acquisitionLink: string,)
{
    const variables: OperationVariables = {
        id,
        title,
        description,
        categoryId,
        otherNames,
        skillType,
        productName,
        manufacturer,
        link,
        acquisition,
        acquisitionLink,
    };
    return GraphQL.mutate<ISkillDetailDTO>(query, variables)
        .then(s => new SkillDetail(s));
}