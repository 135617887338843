import React from 'react';
import './Button.css';
import { Typography } from '@mui/material';

interface ButtonProps
{
    size?: "verysmall" | "small" | "normal",
    text: string
    helpText?: string
    icon?: React.ReactNode
    style?: React.CSSProperties
    color?: 'primary' | 'secondary' | 'normal' | 'red' | 'green'
    onClick: () => void
    disabled?: boolean
}

/**
 * Button component.
 *
 * @param {ButtonProps} props - The props for the Button component.
 * @returns {JSX.Element} - The rendered Button component.
 */
function Button(props: ButtonProps)
{
    const innerSize = props.size ? props.size : 'normal'
    const innerColor = props.color ? props.color : 'primary'
    const buttonClasses = [
        'Button',
        innerSize === 'small' ? 'small' : null,
        innerSize === 'verysmall' ? 'verysmall' : null,
        props.disabled ? 'disabled' : null,
        innerColor === 'primary' ? 'primary' : null,
        innerColor === 'secondary' ? 'secondary' : null,
        innerColor === 'normal' ? 'normal' : null,
        innerColor === 'red' ? 'red' : null,
        innerColor === 'green' ? 'green' : null,
    ].filter(className => className !== null).join(' ');


    return (
        <div
            className={buttonClasses}
            style={props.style}
            title={props.helpText}
            tabIndex={0}
            onKeyDown={onEnterClick}
            onClick={
                () =>
                {
                    if (props.disabled) return;
                    props.onClick()
                }
            }
        >
            {props.icon &&
                <div className="icon">
                    {props.icon}
                </div>
            }
            {innerSize !== "verysmall" &&
                <Typography variant='body1'>{props.text}</Typography>
            }
        </div >
    );
}

export default Button;


function onEnterClick(evt: React.KeyboardEvent<HTMLDivElement>)
{
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter')
    {
        evt.currentTarget.click();
    }
}