import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown, Settings } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AppState } from '@store/store';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import { hashToJson, jsonToHash } from '@src/helper/helper';
import { useTableSettingsDialog } from '@sharedReact/Dialog/Dialogs/TableSettingsDialog/TableSettingsDialog';

import styles from './DataTable.module.css'

/**
 * Function to compare two elements of type T in descending order based on a specified property.
 * 
 * @param {T} a - The first element to compare.
 * @param {T} b - The second element to compare.
 * @param {string} orderBy - The property to order by.
 * @returns {number} - The result of the comparison. Positive if a should come before b, negative if b should come before a, and zero if they are equal.
 */
function descendingComparator<T, K extends keyof T>(a: T, b: T, orderBy: K)
{
    // Zugriff auf die Werte
    const valueA = a[orderBy] as FieldValue ?? '';
    const valueB = b[orderBy] as FieldValue ?? '';

    // Bestimmung des Vergleichswerts
    let orderKeyA: string | number;
    let orderKeyB: string | number;

    if (typeof valueA === 'string' || typeof valueA === 'number')
    {
        orderKeyA = valueA;
    } else if (typeof valueA === 'object' && valueA !== null && 'orderKey' in valueA)
    {
        orderKeyA = valueA.orderKey;
    } else
    {
        orderKeyA = ''; // Oder einen Standardwert setzen
    }

    if (typeof valueB === 'string' || typeof valueB === 'number')
    {
        orderKeyB = valueB;
    } else if (typeof valueB === 'object' && valueB !== null && 'orderKey' in valueB)
    {
        orderKeyB = valueB.orderKey;
    } else
    {
        orderKeyB = ''; // Oder einen Standardwert setzen
    }

    // Durchführen des Vergleichs
    if (orderKeyA < orderKeyB)
    {
        return 1;
    }
    if (orderKeyA > orderKeyB)
    {
        return -1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

/**
 * Returns a comparator function that can be used for sorting an array of objects.
 * The comparator function compares two objects based on the given "orderBy" property and "order" direction.
 * 
 * @param {Order} order - The direction of sorting ("asc" or "desc").
 * @param {string} orderBy - The property of the objects to be used for sorting.
 * @returns {(a: T, b: T) => number} - The comparator function.
 */
function getComparator<T extends IDataTableRow<IDataTableField<FieldValueType>[]>>(
    order: Order,
    orderBy: keyof T
): (
    a: T,
    b: T,
) => number
{
    return (a, b) =>
    {
        // Annahme: Wenn valueA oder valueB undefined ist, verwenden Sie als Fallback einen leeren String
        // Diese Annahme könnte angepasst werden, basierend auf dem, was logisch für Ihre Daten ist
        const valueA = a[orderBy] ?? '';
        const valueB = b[orderBy] ?? '';

        let orderKeyA: string | number | undefined;
        let orderKeyB: string | number | undefined;

        if (typeof valueA === 'string' || typeof valueA === 'number')
        {
            orderKeyA = valueA;
        } else if (typeof valueA === 'object' && valueA !== null && 'orderKey' in valueA)
        {
            orderKeyA = valueA.orderKey;
        }

        if (typeof valueB === 'string' || typeof valueB === 'number')
        {
            orderKeyB = valueB;
        } else if (typeof valueB === 'object' && valueB !== null && 'orderKey' in valueB)
        {
            orderKeyB = valueB.orderKey;
        }

        // Zusätzliche Überprüfung, um sicherzustellen, dass orderKeyA und orderKeyB definiert sind
        // Hier könnte eine Logik implementiert werden, die definiert, was passieren soll, falls orderKeyA oder orderKeyB undefined ist
        if (orderKeyA === undefined || orderKeyB === undefined)
        {
            // Logik für den Umgang mit undefined Werten
            return 0; // Oder eine andere Art von Handhabung
        }

        // Handle 'TOP' cases first
        if (orderKeyA === 'TOP' && orderKeyB !== 'TOP') return 1;
        if (orderKeyB === 'TOP' && orderKeyA !== 'TOP') return 1;

        // Fallback to original comparison logic
        // Sicherstellen, dass orderKeyA und orderKeyB für den Vergleich geeignete Werte haben
        const result = descendingComparator(a, b, orderBy); // Sie müssen hier möglicherweise die Typisierung anpassen, um TS-Fehler zu vermeiden
        return order === 'desc' ? result : -result;
    };
}



// type FieldValueType = string | number | JSX.Element | { [key: string]: any, orderKey: string | number };
type FieldValueType = keyof IFieldMapping;
interface EnhancedTableProps<T extends IDataTableRow<IDataTableField<FieldValueType>[]>>
{
    numSelected: number;
    order: Order;
    orderBy: string;
    tableId: string;
    rowCount: number;
    fields: IDataTableField<FieldValueType>[]
    originalFields: IDataTableField<FieldValueType>[]
    tableSettings: IDataTableSettings
    noCheckbox?: boolean
    expandable?: boolean
    onRequestSort: (event: React.MouseEvent<HTMLElement>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setTableSettings: (newSettings: IDataTableSettings) => void
}

/**
 * A component representing the head of an enhanced table.
 *
 * @param {EnhancedTableProps<T>} props - The props for the enhanced table.
 * @returns {React.JSX.Element} The rendered component.
 */
function EnhancedTableHead<T extends IDataTableRow<IDataTableField<FieldValueType>[]>>(props: EnhancedTableProps<T>)
{
    const openTableSettingsDialog = useTableSettingsDialog();

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, fields, originalFields, noCheckbox, expandable, tableId, tableSettings, onRequestSort, setTableSettings } =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<HTMLElement>) =>
        {
            onRequestSort(event, property);
        };

    const onSettingsClick = React.useCallback(async () =>
    {
        const newTableSettings = await openTableSettingsDialog(tableId, originalFields, tableSettings);
        localStorage.setItem(`tableSettings-${tableId}`, JSON.stringify(newTableSettings))
        setTableSettings(newTableSettings);
    }, [tableId, originalFields, tableSettings]);
    const settingsButton = <IconButton
        size='small'
        aria-label="settings"
        style={{
            marginLeft: 'auto', // Setzt den Abstand links automatisch, um den Button nach rechts zu verschieben
            alignSelf: 'center', // Zentriert den Button vertikal im Container
            float: 'right',
        }}
        onClick={onSettingsClick}
    >
        <Settings />
    </IconButton>;
    return (
        <TableHead>
            <TableRow>
                {!noCheckbox && <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select row',
                        }}
                    />
                </TableCell>}
                {expandable && <TableCell padding="checkbox" >

                </TableCell>}
                {fields.map((headCell, index) => (
                    <TableCell
                        key={headCell.id.toString()}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <div
                        // style={{ display: 'flex' }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                            {fields.length - 1 === index &&
                                settingsButton
                            }
                        </div>
                    </TableCell>
                ))}
                {
                    fields.length === 0 &&
                    <TableCell>
                        {settingsButton}
                    </TableCell>
                }
            </TableRow>

        </TableHead>
    );
}
type IFieldMapping = {
    string: string,
    description: string,
    percent: number,
    hours: number,
    currency: number,
    level: { value: number, orderKey: number, percent: number }
    'JSX.Element': { value: JSX.Element, orderKey: string | number }
    date: Date | undefined,
};

export type FieldValue = string | number | JSX.Element | Date | undefined | { value: number, orderKey: number, percent: number } | { value: JSX.Element, orderKey: string | number };


export type TDataTableAlignType = 'left' | 'center' | 'right' | 'justify';

export interface IDataTableField<T extends FieldValueType>
{
    id: string;
    align: TDataTableAlignType;
    disablePadding: boolean;
    label: string;
    type: T;
    minWidth?: number;
    width?: number;
    link?: string;
}

export interface IDataTableRow<F extends IDataTableField<keyof IFieldMapping>[]>
{
    [key: string]: IFieldMapping[F[number]['type']] | number | string;
    id: number;
}
interface IEnhancedTableProps<T extends IDataTableRow<IDataTableField<keyof IFieldMapping>[]>>
{
    style?: React.CSSProperties
    id: string
    dense?: boolean
    noCheckbox?: boolean
    expandable?: boolean
    hideFooter?: boolean
    rows: T[]
    fields: IDataTableField<keyof IFieldMapping>[]
    selected: number[]
    order?: 'asc' | 'desc'
    orderBy?: string
    setSelected: (selected: number[]) => void

}
interface UrlParameters
{
    [key: string]: string;
}
const prepareInitialTableSettings = (fields: IDataTableField<keyof IFieldMapping>[]) =>
{
    // Initialisieren Sie das order-Array mit den IDs der Felder,
    // möglicherweise basierend auf einer bestimmten Logik oder einfach in der Reihenfolge, wie sie kommen.
    const order = fields.map(field => field.id);

    // Initialisieren Sie hiddenFields als ein Objekt, 
    // wobei jedes Feld standardmäßig auf false gesetzt ist (alle Felder sichtbar).
    // Ändern Sie diesen Teil, wenn einige Felder standardmäßig versteckt sein sollen.
    const hiddenFields = fields.reduce((acc, field) =>
    {
        acc[field.id] = false;
        return acc;
    }, {} as { [fieldId: string]: boolean });

    return { hiddenFields, order };
};

export interface IDataTableSettings
{
    hiddenFields: {
        [fieldId: string]: boolean
    }
    order: string[]
}
/**
 * Component for an enhanced table.
 *
 * @param {IEnhancedTableProps<T>} props - The props for the enhanced table.
 * @returns {React.JSX.Element} - The rendered enhanced table.
 */
export default function EnhancedTable<T extends IDataTableRow<IDataTableField<keyof IFieldMapping>[]>>(props: IEnhancedTableProps<T>)
{
    const width = useSelector((state: AppState) => state.windowSize.width);
    const rows = props.rows;
    const dense = props.dense;
    const [tableSettings, setTableSettings] = React.useState(prepareInitialTableSettings(props.fields));
    const fields = props.fields.filter(field => field.minWidth === undefined || field.minWidth <= width);
    const [order, setOrder] = React.useState<Order>(props.order ? props.order : 'desc');
    const [orderBy, setOrderBy] = React.useState<string>(props.orderBy ? props.orderBy : 'id');
    const selected = props.selected;
    const setSelected = props.setSelected;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.hideFooter ? 9999999 : 50);
    const [openRows, setOpenRows] = React.useState<number[]>([]);

    const sortedFields = React.useMemo(() =>
    {
        const hiddenFieldsSet = new Set(Object.keys(tableSettings.hiddenFields).filter(key => tableSettings.hiddenFields[key]));
        const orderMap = new Map(tableSettings.order.map((id, index) => [id, index]));

        return fields
            .filter(f => !hiddenFieldsSet.has(f.id))
            .sort((a, b) => (orderMap.get(a.id) || 0) - (orderMap.get(b.id) || 0));
    }, [fields, tableSettings]);

    React.useEffect(() =>
    {
        //Load TableSettings and update
        const storedSettings = localStorage.getItem(`tableSettings-${props.id}`);
        if (storedSettings)
        {
            const parsedSettings = JSON.parse(storedSettings);
            setTableSettings(parsedSettings);
        }
    }, [props.id]);
    React.useEffect(() =>
    {
        const initialOrder = tableSettings.order;
        const missingFieldsInOrder = props.fields
            .filter(({ id }) => !initialOrder.includes(id))
            .map(({ id }) => id);

        if (missingFieldsInOrder.length)
        {
            setTableSettings(prevSettings => ({
                ...prevSettings,
                order: [...prevSettings.order, ...missingFieldsInOrder],
            }));
        }
    }, [props.fields,]);

    const handleRequestSort = React.useCallback((
        event: React.MouseEvent<HTMLElement>,
        property: string,
    ) =>
    {

        const parameter = hashToJson<UrlParameters>(window.location.hash);
        const isAsc = orderBy === property && order === 'asc';
        parameter[`${props.id}order`] = isAsc ? 'desc' : 'asc';
        parameter[`${props.id}orderBy`] = property;
        window.location.hash = jsonToHash(parameter);
        setOrder(parameter[`${props.id}order`] as Order);
        setOrderBy(parameter[`${props.id}orderBy`]);
    }, [orderBy, order]);

    React.useEffect(
        () =>
        {
            const parameter = hashToJson<UrlParameters>(window.location.hash);
            if (sortedFields.some(f => f.id == parameter[`${props.id}orderBy`]))
            {
                if (parameter[`${props.id}order`])
                {
                    setOrder(parameter[`${props.id}order`] as 'asc' | 'desc');
                }
                if (parameter[`${props.id}orderBy`])
                {
                    setOrderBy(parameter[`${props.id}orderBy`]);
                }
            }
        },
        [sortedFields])

    const handleSelectAllClick = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) =>
    {
        if (event.target.checked)
        {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    }, [rows, setSelected]);

    const handleCheckboxClick = React.useCallback((id: number) =>
        () =>
        {
            const selectedIndex = selected.indexOf(id);
            let newSelected: number[] = [];

            if (selectedIndex === -1)
            {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0)
            {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1)
            {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0)
            {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            setSelected(newSelected);
        }, [selected, setSelected]);

    const handleChangePage = (event: unknown, newPage: number) =>
    {
        setPage(newPage);
        document.querySelector('.contentOuter')?.scroll(0, 0)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        // setRowsPerPage(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (id: number) => selected.indexOf(id) !== -1;


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            rows.sort(getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [page, rowsPerPage, sortedFields],
    );


    return (
        <div style={props.style}>
            <Box sx={{ width: '100%' }} key="DataTableBox">
                <TableContainer>
                    <Table
                        // sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            tableId={props.id}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            tableSettings={tableSettings}
                            setTableSettings={setTableSettings}
                            fields={sortedFields}
                            originalFields={fields}
                            noCheckbox={props.noCheckbox}
                            expandable={props.expandable}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) =>
                            {


                                return (<EnhancedTableRow
                                    key={index}
                                    row={row}
                                    index={index}
                                    expandable={props.expandable || false}
                                    openRows={openRows}
                                    noCheckbox={props.noCheckbox || false}
                                    sortedFields={sortedFields}
                                    handleCheckboxClick={handleCheckboxClick}
                                    setOpenRows={setOpenRows}
                                    isSelected={isSelected}
                                />
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!props.hideFooter &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Box>
        </div>
    );
}

interface EnhancedTableRowProps<T extends IDataTableRow<IDataTableField<keyof IFieldMapping>[]>>
{
    row: T
    index: number
    expandable: boolean
    openRows: number[]
    noCheckbox: boolean
    sortedFields: IDataTableField<keyof IFieldMapping>[]
    handleCheckboxClick: (id: number) => () => void
    setOpenRows: (openRow: number[]) => void
    isSelected: (id: number) => boolean
}
// Erstellen Sie eine separate Komponente
const EnhancedTableRowComponent = <T extends IDataTableRow<IDataTableField<keyof IFieldMapping>[]>>({
    row,
    index,
    expandable,
    openRows,
    noCheckbox,
    sortedFields,
    setOpenRows,
    isSelected,
    handleCheckboxClick
}: EnhancedTableRowProps<T>) =>
{
    const navigate = useNavigate();
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    return <React.Fragment
        key={`${row.id}${index}`}
    >
        <TableRow
            hover
            // onClick={(event) => handleClick(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={`${row.id}${index}`}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
        >
            {expandable &&
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                        {
                            const open = openRows.includes(row.id);
                            if (open)
                            {
                                setOpenRows(openRows.filter(o => o !== row.id));
                            } else
                            {
                                const newArray = openRows.slice();
                                newArray.push(row.id);
                                setOpenRows(newArray);
                            }
                        }}
                    >
                        {openRows.includes(row.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            }
            {!noCheckbox &&
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                        onClick={handleCheckboxClick(row.id)}
                    />
                </TableCell>
            }
            {
                sortedFields.map((field) =>
                {
                    type FieldType = typeof field.type;
                    let value: string | number | JSX.Element = '';
                    let className: string = '';
                    interface OwnCSSProperties extends CSSProperties
                    {
                        '--percent'?: number,
                    }
                    const style: OwnCSSProperties = {};
                    const originalValue = row[field.id] as IFieldMapping[FieldType];
                    if (field.type === 'JSX.Element')
                    {
                        const element = (originalValue as { value: JSX.Element; orderKey: string; });
                        value = element.value;
                    } else if (field.type === 'level')
                    {
                        const level = originalValue as {
                            value: number;
                            orderKey: number;
                            percent: number;
                        };
                        value = Math.round(
                            level.value * 10
                        ) / 10;
                        className = styles.level;
                        style['--percent'] = level.percent;
                    }
                    else
                    {
                        const notNullValue = originalValue ? originalValue : '';
                        switch (field.type)
                        {
                            case 'string':
                                value = notNullValue.toString();
                                break;
                            case 'description':
                                value =
                                    <ExpandableText><FormatedTextInput value={notNullValue as string} /></ExpandableText>;
                                break;
                            case 'currency':
                                value = notNullValue !== "" ? `${notNullValue.toLocaleString('de-DE')}€` : '';
                                break;
                            case 'date':
                                value = notNullValue instanceof Date ? `${(notNullValue as Date).toLocaleDateString()}` : '';
                                break;
                            case 'hours':
                                value = typeof originalValue === 'number' ? Math.round(originalValue).toString() : (originalValue === null ? '-' : `[WRONG DATA TYPE ${typeof originalValue}]`);
                                break;
                            case 'percent':
                                value = typeof originalValue === 'number' ? `${Math.round(originalValue).toString()}%` : (originalValue === null ? '-' : `[WRONG DATA TYPE ${typeof originalValue}]`);
                                break;
                            default:
                                value = notNullValue.toString();
                                break;
                        }
                    }
                    const onHrefClick = (e: unknown, link: string, id: number) =>
                    {
                        if (document.querySelector('.MuiBackdrop-root')) return;

                        navigate(link + id);
                    };
                    return <TableCell
                        className={className}
                        style={style}
                        key={field.id}
                        padding={field.disablePadding ? 'none' : 'normal'}
                        align={field.align}
                        width={field.width}
                        onClick={field.link ? (e) => onHrefClick(e, (field.link ? field.link : ''), row['id']) : undefined}
                    >
                        {value}
                    </TableCell>;
                })
            }
        </TableRow>

        {(
            expandable && openRows.includes(row.id) &&
            row.expandable && typeof row.expandable === 'object' && !(row.expandable instanceof Date)
        ) && <TableRow>
                <TableCell colSpan={20} padding={'none'}>
                    {row.expandable.value ? row.expandable.value : <></>}
                </TableCell>
            </TableRow>
        }
    </ React.Fragment>
}

export const EnhancedTableRow = React.memo(EnhancedTableRowComponent) as typeof EnhancedTableRowComponent;