import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Edit, Delete } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { SkillCategory } from '@src/Objects/SkillCategory';
import CardBox from '@src/Components/CardBox/CardBox';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import SkillCategoryDialog from '@src/Components/Dialogs/SkillCategoryDialog/SkillCategoryDialog';
import SkillsInputWithoutLevel from '@src/Components/formsControls/inputs/SkillsInputWithoutLevel/SkillsInputWithoutLevel';

interface SkillCategoryCardProps
{
    cat: SkillCategory;
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

export const SkillCategoryCard: React.FC<SkillCategoryCardProps> = ({ cat, onSelectChange, selected }) =>
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const skills = useSelector((state: AppState) => state.company.allSkills);
    const filteredSortedSkills = skills.filter(skill => cat.id === skill.categoryId).sort((a, b) => a.title.localeCompare(b.title));
    return (
        <div key={cat.title} className='category'>
            <CardBox title={<NavLink to={entityTypeToLink(cat.id, EEntityType.SKILL_CATEGORY)}>{cat.title}</NavLink>}
                menu={
                    <div>
                        <MenuItem
                            onClick={() =>
                            {
                                return openDialog(
                                    <SkillCategoryDialog
                                        id="SkillCategoryDialog"
                                        skillCategory={cat}
                                    />);
                            }}
                        >
                            <ListItemIcon>
                                <Edit />
                            </ListItemIcon>
                            <ListItemText>{langStrings.edit}</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                            {
                                cat.delete();
                            }}
                        >
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            <ListItemText>{langStrings.delete}</ListItemText>
                        </MenuItem>
                    </div>
                }
                onSelectChange={onSelectChange}
                selected={selected}
            >
                <ExpandableText>
                    <>
                        {langStrings.description + ": "}
                        <FormatedTextInput value={cat.description} readonly={true} />
                    </>
                </ExpandableText>
                <div className='skillContainer'>
                    <SkillsInputWithoutLevel allSkills={filteredSortedSkills} skills={filteredSortedSkills} showMode={true} size={'small'}
                    />
                </div>
            </CardBox>
        </div>
    );
};
