import { OperationVariables, gql } from "@apollo/client";
import IProjectDTO from "@sharedInterfaces/IProject";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setCompanyVersion } from "@store/reducer/companyReducer";
import { setProjects } from "@store/reducer/projectReducer";
import Project, { projectGQL } from "@src/Objects/Project";

import GraphQL from "../graphQL";

const q = gql`
query($version:Int!) {
    projects(version:$version){
        version
        projects
        ${projectGQL}
    }
},
`;

interface ProjectResponse
{
    projects: { version: number; projects: IProjectDTO[] };
}


export default async function getProjects()
{
    const state = store.getState();
    const versions = state.company.versions;
    const variables: OperationVariables = {
        version: versions.project,
    };
    return GraphQL.query<ProjectResponse>(q, variables, undefined)
        .then((obj) =>
        {
            if (!obj) throw new Error("Fehler beim laden der Projects.");
            if (obj.projects.projects)
            {
                const data = obj.projects.projects.map((p: IProjectDTO) => new Project(p));
                store.dispatch(setCompanyVersion(EEntityType.PROJECT, obj.projects.version))
                store.dispatch(setProjects(data))
                return data;
            }
            return state.projects;
        });
}