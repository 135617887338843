import { OperationVariables, gql } from "@apollo/client";
import IBusinessDTO, { IEditBusinessDTO } from "@sharedInterfaces/IBusiness";
import { businessGQL } from "@src/Objects/Business";

import GraphQL from "../graphQL";

const query = gql`
mutation editBusiness($id:Int!, $obj: EditBusiness!) {
    editBusiness(id: $id ,obj: $obj) 
    ${businessGQL}
},
`;

export default async function editBusiness(business: IEditBusinessDTO)
{
    const variables: OperationVariables = {
        id: business.id,
        obj: {
            ...business,
            projects: business.projects.map(s => ({
                id: s.id,
                title: s.title,
            })),
        },
    };

    return GraphQL.mutate<IBusinessDTO>(query, variables)
}

//, (obj: any) => callback(new Business(companyId, obj.editBusiness as IBusiness))