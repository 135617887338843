import { IPlanningDTO } from "@sharedInterfaces/IAvailabilityMonth";
import { IEmployeeProjectReferenceDTO } from "@sharedInterfaces/IEmployee";

/**
 * Function to clear planning.
 *
 * @param planings - Array of IPlanningDTO objects representing the planning.
 * @param hiddenProjectIds - Array of numbers representing the IDs of hidden projects.
 * @param empProjects - Array of IEmployeeProjectReferenceDTO objects representing the employee projects.
 * @returns - Array of IPlanningDTO objects representing the cleared planning.
 */
export function clearPlaning(planings: IPlanningDTO[], hiddenProjectIds: number[], empProjects: IEmployeeProjectReferenceDTO[]): IPlanningDTO[]
{
    if (planings === undefined) return planings;
    // console.log("🚀 ~ file: clearPlaning.tsx:6 ~ planings:", planings);
    let copy = planings.map(p => ({ ...p }));
    copy = copy.filter(obj =>
    {
        const pId = parseInt(obj.activityId);
        const empProject = empProjects.find(ep => ep.id === pId && ep.active)
        return !hiddenProjectIds.includes(pId) && empProject !== undefined;
    });
    // console.log("🚀 ~ file: clearPlaning.tsx:10 ~ copy:", copy);
    return copy;

}
