import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IconButton, Slider, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';

import { EmployeeCoverage } from './EmployeeCoverage';

import './EmployeeSuggestions.css'

interface EmployeeSuggestionsProps
{
    id: string;
    suggestedEmployees: IEmployeeCoverage[];
    flexDirection?: string;
    inDialog?: true;
    showFilter?: true;
    rightTopButton?: (id: number) => JSX.Element
    handleDragStart?: (event: React.DragEvent<HTMLDivElement>, item: IEmployeeCoverage | null) => void;
    handleDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDragEnd?: (event: React.DragEvent<HTMLDivElement>) => void;
}
/**
 * Renders suggestions for employees.
 *
 * @param {EmployeeSuggestionsProps} id - The employee's id.
 * @param {EmployeeSuggestionsProps} suggestedEmployees - The suggested employees.
 * @param {EmployeeSuggestionsProps} flexDirection - The direction of the flexbox.
 * @param {EmployeeSuggestionsProps} handleDragStart - The function to handle the drag start event.
 * @param {EmployeeSuggestionsProps} handleDragOver - The function to handle the drag over event.
 * @param {EmployeeSuggestionsProps} handleDragEnd - The function to handle the drag end event.
 * 
 * @returns {JSX.Element} The employee suggestions as a JSX element.
 */
export function EmployeeSuggestions({ rightTopButton, id, suggestedEmployees, flexDirection, inDialog, showFilter, handleDragStart, handleDragOver, handleDragEnd }: EmployeeSuggestionsProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).sales;

    const allEmployees = useSelector((state: AppState) => state.company.allEmployees);

    const [filterSkillScore, setFilterSkillScore] = React.useState(0);
    const [filterSkillCoverage, setFilterSkillCoverage] = React.useState(0);
    const [filterCertScore, setFilterCertScore] = React.useState(0);
    const [filterAvailability, setFilterAvailability] = React.useState(0);

    const [filterOpen, setFilterOpen] = React.useState(false);

    const showCert = suggestedEmployees.some(e => e.coveredCertificates.length || e.missingCertificates.length)
    const handleChangefilterSkillScore = (event: Event, newValue: number | number[]) =>
    {
        setFilterSkillScore(newValue as number);
    };
    const handleChangefilterSkillCoverage = (event: Event, newValue: number | number[]) =>
    {
        setFilterSkillCoverage(newValue as number);
    };
    const handleChangefilterCertScore = (event: Event, newValue: number | number[]) =>
    {
        setFilterCertScore(newValue as number);
    };
    const handleChangefilterAvailability = (event: Event, newValue: number | number[]) =>
    {
        setFilterAvailability(newValue as number);
    };



    const emps = suggestedEmployees.filter((emp) =>
    {
        const aEmp = allEmployees.find(e => e.id === emp.id);
        const availability = (!aEmp?.availability ||
            (
                aEmp.availability.availability === 0 &&
                aEmp.availability.workDayCount === 0
            )
        ) ? 0 : Math.round(aEmp.availability.availability);
        const certScore = emp.coveredCertificates.length ? Math.round(
            emp.coveredCertificates.length /
            (emp.coveredCertificates.length + emp.missingCertificates.length) * 100
        ) : 0;
        return emp.score * 100 >= filterSkillScore && emp.skillCoverage * 100 >= filterSkillCoverage && (certScore >= filterCertScore) && availability >= filterAvailability;
    });

    return (
        <div style={{ width: '100%' }}>
            {showFilter &&
                <>
                    <IconButton
                        size='small'
                        style={{ float: 'right' }}
                        onClick={() => setFilterOpen(!filterOpen)}
                    >
                        <FilterList fontSize='small' />
                    </IconButton>
                    <div style={{
                        display: 'flex',
                        columnGap: 10,
                        justifyContent: 'stretch',
                        alignItems: 'center',
                        padding: '8px 7px 0px 7px',
                        maxHeight: filterOpen ? 55 : 0,
                        overflow: 'hidden',

                        transition: 'max-height 0.3s',
                    }}>
                        <div
                            title={langStrings.skillScoreHint}
                            style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}
                        >
                            <Typography variant='caption' align='center'>
                                {langStrings.skillScore}
                            </Typography>
                            <Slider
                                size='small'
                                aria-label={langStrings.skillScore}
                                value={filterSkillScore}
                                onChange={handleChangefilterSkillScore}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                step={5}
                                shiftStep={5}
                                marks
                            />
                        </div>
                        <div
                            title={langStrings.skillCoverageHint}
                            style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}
                        >
                            <Typography variant='caption' align='center'>
                                {langStrings.skillCoverage}
                            </Typography>
                            <Slider
                                size='small'
                                aria-label={langStrings.skillCoverage}
                                value={filterSkillCoverage}
                                onChange={handleChangefilterSkillCoverage}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                step={5}
                                shiftStep={5}
                                marks
                            />
                        </div>
                        {showCert &&
                            <div
                                title={langStrings.certificatesHint}
                                style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}
                            >
                                <Typography variant='caption' align='center'>
                                    {langStrings.certificateScore}
                                </Typography>
                                <Slider
                                    size='small'
                                    aria-label={langStrings.certificateScore}
                                    value={filterCertScore}
                                    onChange={handleChangefilterCertScore}

                                    min={0}
                                    max={100}
                                    valueLabelDisplay="auto"
                                    step={5}
                                    shiftStep={5}
                                    marks
                                />

                            </div>

                        }
                        <div
                            title={langStrings.availabilityHint}
                            style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center' }}
                        >
                            <Typography variant='caption' align='center'>
                                {langStrings.availability}
                            </Typography>
                            <Slider
                                size='small'
                                aria-label={langStrings.availability}
                                value={filterAvailability}
                                onChange={handleChangefilterAvailability}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                                step={5}
                                shiftStep={5}
                                marks
                            />
                        </div>
                    </div>
                </>
            }
            <div
                className="employeeSuggestions"
                id={id}
                style={{ flexDirection: flexDirection as 'column' | undefined }}
                onDragOver={handleDragOver}
            >
                {emps.map(emp =>
                {
                    // const missingSkills = emp.missingSkills.map(s => { return { id: s.id, title: s.title, level: s.wantedLevel } })
                    return <EmployeeCoverage
                        rightTopButton={rightTopButton}
                        handleDragStart={handleDragStart}
                        // handleDragOver={handleDragOver}
                        handleDragEnd={handleDragEnd}
                        key={emp.id}
                        suggestedEmployee={emp}
                        inDialog={inDialog}
                    />;
                }
                )}
            </div>
        </div>
    );
}
