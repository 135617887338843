import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IAvailabilitiesTexts extends ITextBase
{
    noSkillUpdateOneMonth: string
    noProjects: string
    onlyOnWorkday: string
    holidays: string
    utilization: string
    absenceDays: string
    plannedDays: string
    showOrHide: string
    hideShowDialogTitle: string
    delete: string
    calendarWeek: string
    sum: string
    activity: string
    days: string
    hours: string
    inputAsHelper: string
    week: string
    day: string
    month: string
    workdays: string
    typeHelper: string
    dialogName: string
    create: string
    save: string
    forecastPlanning: string
    edit: string
    availabilityInPercent: string
    availabilityInDays: string
    absence: string
}
type ILangData = {
    [lang in ELanguage]: IAvailabilitiesTexts
}
export const availabilitiesTexts: ILangData = {
    'th': {
        noSkillUpdateOneMonth: 'หลังจากที่คุณได้อัปเดตการคาดการณ์ของคุณแล้ว ลองมาสะท้อนดูสิว่าความเจริญเติบโตของคุณมีอะไรบ้าง? ไม่ได้เป็นเพียงการเรียนรู้ทักษะใหม่ๆ เท่านั้น แต่ยังรวมถึงการปรับปรุงและพัฒนาทักษะที่คุณมีอยู่แล้วด้วย พวกเราตั้งตารอที่จะได้ยินเรื่องราวที่คุณต้องการแบ่งปัน!',
        noProjects: 'คุณยังไม่ได้รับการกำหนดโปรเจ็ค',
        oftenUsed: oftenUsedTranslations['th'],
        onlyOnWorkday: 'เฉพาะวันหยุดนักขัตฤกษ์ที่ตรงกับวันทำการปกติ',
        holidays: 'วันหยุด',
        utilization: 'การใช้งาน',
        absenceDays: 'วันที่ขาดงาน',
        plannedDays: 'วันที่วางแผน',
        dialogName: 'วางแผนสำหรับ: ',
        availabilityInPercent: 'ความพร้อมเป็นเปอร์เซ็นต์',
        availabilityInDays: 'ความพร้อมในวัน',
        create: oftenUsedTranslations['th'].create,
        save: oftenUsedTranslations['th'].save,
        edit: oftenUsedTranslations['th'].edit,
        month: oftenUsedTranslations['th'].month,
        week: oftenUsedTranslations['th'].week,
        day: oftenUsedTranslations['th'].day,
        typeHelper: 'ความละเอียด',
        workdays: 'วันทำงาน',
        forecastPlanning: 'การวางแผนการพยากรณ์',
        days: oftenUsedTranslations['th'].days,
        hours: oftenUsedTranslations['th'].hours,
        inputAsHelper: 'ป้อนข้อมูลเป็น',
        activity: 'กิจกรรม',
        sum: 'ผลรวม',
        calendarWeek: 'สัปดาห์',
        absence: 'ขาด',
        delete: oftenUsedTranslations['th'].delete,
        hideShowDialogTitle: 'ซ่อน/แสดงกิจกรรม',
        showOrHide: 'แสดง/ซ่อน',
    },
    'en': {
        noSkillUpdateOneMonth: 'Now that you\'ve updated your forecast, how about a brief reflection on your developments? It\'s not just about acquiring new skills, but also about honing what you already possess.We\'re eager to hear what you\'d like to share!',
        noProjects: 'You have not been assigned any projects yet.',
        oftenUsed: oftenUsedTranslations['en'],
        onlyOnWorkday: 'Only holidays that fall on a working day.',
        holidays: 'Holidays',
        utilization: 'Utilization',
        absenceDays: 'Absence days',
        plannedDays: 'Planned days',
        dialogName: 'Planning for: ',
        availabilityInPercent: 'Availability in percent',
        availabilityInDays: 'Availability in days',
        create: oftenUsedTranslations['en'].create,
        save: oftenUsedTranslations['en'].save,
        edit: oftenUsedTranslations['en'].edit,
        typeHelper: 'Granularity',
        month: oftenUsedTranslations['en'].month,
        week: oftenUsedTranslations['en'].week,
        day: oftenUsedTranslations['en'].day,
        workdays: 'Workdays',
        forecastPlanning: 'Forecast planning',
        days: oftenUsedTranslations['en'].days,
        hours: oftenUsedTranslations['en'].hours,
        inputAsHelper: 'Input as',
        activity: 'Activity',
        sum: 'Sum',
        calendarWeek: 'CW',
        absence: 'Absent',
        delete: oftenUsedTranslations['en'].delete,
        hideShowDialogTitle: 'Hide/Show Activities',
        showOrHide: 'Show/Hide',
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        dialogName: 'Planung für: ',
        availabilityInPercent: 'Verfügbarkeit in Prozent',
        availabilityInDays: 'Verfügbarkeit in Tagen',
        create: oftenUsedTranslations['de'].create,
        save: oftenUsedTranslations['de'].save,
        edit: oftenUsedTranslations['de'].edit,
        typeHelper: 'Granularität',
        month: oftenUsedTranslations['de'].month,
        week: oftenUsedTranslations['de'].week,
        day: oftenUsedTranslations['de'].day,
        workdays: 'Werktage',
        forecastPlanning: 'Forecast-Planung',
        days: oftenUsedTranslations['de'].days,
        hours: oftenUsedTranslations['de'].hours,
        inputAsHelper: 'Erfassung in',
        activity: 'Aktivität',
        sum: 'Summe',
        calendarWeek: 'KW',
        absence: 'Abwesend',
        delete: oftenUsedTranslations['de'].delete,
        hideShowDialogTitle: 'Aktivitäten ein/ausblenden',
        showOrHide: 'Ein/Ausblenden',
        plannedDays: 'Geplante Tage',
        absenceDays: 'Abwesenheitstage',
        utilization: 'Auslastung',
        holidays: 'Feiertage',
        onlyOnWorkday: 'Nur Feiertage die in auf einen Werktag fallen.',
        noProjects: 'Dir wurden noch keine Projekte zugeordnet.',
        noSkillUpdateOneMonth: 'Nachdem du jetzt deinen Forecast aktualisiert hast, wie sieht es mit einer kurzen Reflexion deiner Entwicklungen aus? Es geht nicht nur um neue Fertigkeiten, sondern auch um die Verfeinerung dessen, was du schon kannst. Wir sind gespannt, was du mit uns teilen möchtest!',
    },
}