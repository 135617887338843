import { Action } from "redux";
import Competence from "@src/Objects/Competence";

const initialState: Competence[] = [];

export enum ECompetenceAction
{
    SET_COMPETENCES = "SET_COMPETENCES",
    DELETE_COMPETENCE = "DELETE_COMPETENCE",
    UPDATE_COMPETENCE = "UPDATE_COMPETENCE",
}

interface SetCompetencesAction extends Action
{
    type: ECompetenceAction.SET_COMPETENCES;
    payload: Competence[];
}

interface DeleteCompetenceAction extends Action
{
    type: ECompetenceAction.DELETE_COMPETENCE;
    payload: number; // Annahme, dass ID eine Zahl ist
}

interface UpdateCompetenceAction extends Action
{
    type: ECompetenceAction.UPDATE_COMPETENCE;
    payload: Competence;
}

type CompetenceAction = SetCompetencesAction | DeleteCompetenceAction | UpdateCompetenceAction;

export const competenceReducer = (state = initialState, action: CompetenceAction): Competence[] =>
{
    switch (action.type)
    {
        case ECompetenceAction.SET_COMPETENCES:
            return action.payload;
        case ECompetenceAction.DELETE_COMPETENCE:
            return state.filter(competence => competence.id !== action.payload);
        case ECompetenceAction.UPDATE_COMPETENCE:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(competence => competence.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setCompetences = (competences: Competence[]): SetCompetencesAction => ({
    type: ECompetenceAction.SET_COMPETENCES,
    payload: competences,
});

export const deleteCompetence = (id: number): DeleteCompetenceAction => ({
    type: ECompetenceAction.DELETE_COMPETENCE,
    payload: id,
});

export const updateCompetence = (competence: Competence): UpdateCompetenceAction => ({
    type: ECompetenceAction.UPDATE_COMPETENCE,
    payload: competence,
});
