import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Add, Delete, Lock, Loop, PublishedWithChanges, Remove, Share, Tune } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import SkillOverlay from '@src/Components/Overlays/SkillOverlay/SkillOverlay';
import { useMapSkillDialog } from '@src/Components/Dialogs/MapSkillDialog/MapSkillDialog';
import { useQuestionEmployeeSkillDialog } from '@src/Components/Dialogs/QuestionEmployeeSkill/QuestionEmployeeSkill';
import { ShareButton, } from '@src/Components/Buttons/ShareButton/ShareButton';
import changeMapSkill from '@src/APIs/graphQl/Skill/changeMapSkill';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import Item from '../Item/Item';
import { IItemAction } from '../Item/ItemAction';
import ItemBadge, { NewBadge } from '../../Components/ItemBadge/ItemBadge';

import styles from './SkillItem.module.css';
interface SkillItemProps
{
    title: string;
    id?: number;
    level?: number;
    wantedLevel?: number;
    count?: number;
    disableOverlay?: boolean
    locked?: boolean;
    lockedBy?: string;
    employeeId?: number;
    newPrefix?: true;
    onClick?: string | (() => void)
    onDelete?: () => void
}

/**
 * Represents a skill item component.
 * 
 * @param {SkillItemProps} props - The properties for the skill item component.
 * @returns {JSX.Element} - The rendered skill item component.
 */
function SkillItem({ title, id, level, wantedLevel, count, disableOverlay, locked, lockedBy, newPrefix, onClick, employeeId, onDelete, }: SkillItemProps)
{
    const questionEmployeeSkillDialog = useQuestionEmployeeSkillDialog();
    const mapSkillDialog = useMapSkillDialog();
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const levelDefinitions = useSelector((state: AppState) => state.levelDefinition);

    const skillDetail = useSelector((state: AppState) => state.skills.find(as => as.id === id));

    const employee = useSelector((state: AppState) => state.employee);
    const [updating, setUpdating] = React.useState("");
    const own = employee.skills.find(oc => oc.id === id);

    const inOverlay = disableOverlay ? disableOverlay : false;
    const titleLevel = levelDefinitions[level ? level - 1 : 0]?.title;
    const titleWantedLevel = levelDefinitions[wantedLevel ? wantedLevel - 1 : 0]?.title;
    const maxLevel = levelDefinitions.length;
    let percent = 75;
    if (wantedLevel !== undefined && level !== undefined)
    {
        const levelDiff = level - wantedLevel;
        percent = levelDiff / maxLevel * 100;

    } else if (level !== undefined)
    {
        percent = level / maxLevel * 100
    }

    const laodingIcon = <Loop fontSize='small' />;
    const notEditAndKnownSkill = onDelete === undefined && id;
    const actions: IItemAction[] = []
    if (notEditAndKnownSkill) //Nicht Edit Mode und ist im System bekannt
    {
        if (own) //Nicht Edit Mode und ist im System bekannt Und Verknüpft mit Emp
        {
            actions.push({
                id: 'remove',
                icon: updating === 'remove' ? laodingIcon : <Remove fontSize='small' />,
                helper: langStrings.removeButtonHelper,
                text: langStrings.removeButtonTitle,
                onClick: () =>
                {
                    setUpdating('remove');
                    changeMapSkill(id, false)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
            actions.push({
                id: 'edit',
                icon: updating === 'edit' ? laodingIcon : <Tune fontSize='small' />,
                text: langStrings.editButtonTitle,
                helper: langStrings.editButtonHelper,
                onClick: () =>
                {
                    setUpdating('edit');
                    mapSkillDialog(id)
                        .finally(() =>
                        {
                            setUpdating('');
                        })
                }

            });
        } else
        {
            actions.push({
                id: 'add',
                icon: updating === 'add' ? laodingIcon : <Add fontSize='small' />,
                text: langStrings.addButtonTitle,
                helper: langStrings.addButtonHelper,
                onClick: () =>
                {
                    setUpdating('add');
                    mapSkillDialog(id)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
        }
        if (employeeId && employeeId !== employee.id)
        {
            actions.push({
                id: 'toQuestion',
                icon: updating === 'toQuestion' ? laodingIcon : <PublishedWithChanges fontSize='small' />,
                text: langStrings.questionSkillTitle,
                helper: langStrings.questionSkillHelper,
                onClick: () =>
                {
                    setUpdating('toQuestion');
                    questionEmployeeSkillDialog(employeeId, id, level)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
        }
        if (skillDetail)
        {
            actions.push({
                id: 'share',
                icon: <Share fontSize='small' />,
                text: langStrings.oftenUsed.share,
                helper: langStrings.oftenUsed.share,
                onClick: () =>
                {
                    openDialog(<ShareButton entity={skillDetail} />)
                    // showShareDialog(skillDetail)
                }
            })
        }
    }

    const order = wantedLevel ?
        (wantedLevel * -1) :
        (level ? level * -1 :
            undefined);
    return <Item
        onClick={onClick}
        percent={percent}
        order={order}
        actions={actions}
        leftContent={
            <>
                {
                    level !== undefined && <ItemBadge value={level} title={`${langStrings.oftenUsed.level}: ${titleLevel}`} />
                }
                {
                    count !== undefined && <ItemBadge value={count} title={langStrings.count} />
                }
                {
                    wantedLevel !== undefined && <ItemBadge value={wantedLevel} title={`${langStrings.wantedLevel}: ${titleWantedLevel}`} />
                }
                {/* { //TODO: ENTSCHEIDEN WAS HIERMIT PASSIERT
                    Math.random() > 0.25 && <ItemBadge value={Math.random() > 0.5 ? <SentimentVeryDissatisfiedTwoTone fontSize='small'  /> : <SentimentVerySatisfiedTwoTone fontSize='small' htmlColor='darkgreen' />} title='Mood' />
                } */}
            </>
        }
        rightContent={
            <>
                {
                    onDelete !== undefined &&
                    <div className="skillItemDelete" title={langStrings.delete}>
                        <IconButton
                            onClick={onDelete}
                            size='small'
                        ><Delete fontSize='small' /></IconButton>
                    </div>
                }
                {
                    (locked !== undefined || lockedBy !== undefined) &&
                    <div className={styles.itemLocked} title={lockedBy ? `${langStrings.oftenUsed.lockedBy}: "${lockedBy}"` : langStrings.oftenUsed.locked}>
                        <Lock fontSize='small' />
                    </div>
                }
                {/* {
                    (onDelete === undefined && id) &&
                    <AddSkillButton id={id} title={title} size={'verysmall'} />
                } */}

            </>
        }
    >
        {!id ? title :
            <SkillOverlay key={id} disabled={inOverlay} skillId={id}>
                {title}

            </SkillOverlay>
        }
        {newPrefix && <NewBadge />}
    </Item>
}
export default SkillItem;