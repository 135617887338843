import { IBusinessReferenceDTO } from "./IBusiness";
import { ICertificateEmployeeDTO } from "./ICertificate";
import IEntity from "./IEntity";
import { IEmployeeCoverage, ISkillLevelCompare } from "./IOpportunity";
import { IPermissions } from "./IPermissions";
import { IProductReferenceDTO } from "./IProduct";
import { IReference } from "./IReference";
import { ISkillReferenceDTO } from "./ISkill";

export interface IEmployeeDTO extends IEntity
{
    id: number,
    firstName: string,
    lastName: string,
    gender: EGender,
    description: string,
    email: string,
    pictureId: string | null,
    certificates: ICertificateEmployeeDTO[],
    skills: ISkillReferenceDTO[],
    similarUsers: ISimilarUserDTO[];
    availability: IAvailabilityDTO;
    products: IEmployeeProductReferenceDTO[];
    projects: IEmployeeProjectReferenceDTO[];
    opportunities: IEmployeeOpportunityReferenceDTO[];
    competences: IEmployeeCompetenceReference[];
    roles: IEmployeeRoleReference[];
    salesIdea: string;
}
export interface IAvailabilityDTO
{
    availability: number
    workDayCount: number
}
export interface IEmployeeProductReferenceDTO extends IProductReferenceDTO
{
    skillCoverage: number
}
export interface IEmployeeRoleReference extends IReference
{

}
export interface IEmployeeOpportunityReferenceDTO extends IReference
{
    skillCoverage: number;
    score: number;
    missingSkills: ISkillLevelCompare[];
    coveredSkills: ISkillLevelCompare[];
    missingCertificates: IReference[];
    coveredCertificates: IReference[];
}
export interface IEmployeeProjectReferenceDTO extends IReference
{
    business: IBusinessReferenceDTO
    active: boolean
}
export interface IEmployeeCompetenceReference extends IReference
{
    level: number
}
export enum EGender
{
    FEMALE = "female",
    MALE = "male",
    OTHER = "other"
}
export interface IEmployeeForListPageDTO
{
    id: number,
    firstName: string,
    lastName: string,
    description: string,
}
export interface IAddEmployeeDTO
{
    firstname: string;
    lastname: string;
    email: string;
    gender: EGender;
    permissionGroup: IReference;
    permissions: IPermissions;
}
export interface IEmployeeSuggestedCompetence
{
    id: number
    title: string
    level: number
    description: string
    employee: IEmployeeCoverage
}

// export interface IEmployeeGoals
// {
//     wantedCompetences: IEmployeeWantedCompetenceStatus
// }


// export interface IEmployeeWantedCompetenceStatus
// {
//     id: number
//     title: string
//     level: number
//     levelTitle
// }

export interface IEmployeeSuggestedSkill
{
    id: number
    title: string
    description: string
}
export interface ISimilarUserDTO extends IReference
{
    id: number
    match: number
}
