import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation changeOUTitle($id:Int!, $name: String!) {
    changeOUTitle(id: $id,name: $name) 
    ${companySettingsGql}
},
`;

export default async function changeOUTitle(id: number, name: string)
{
    const variables: OperationVariables = { id, name };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(obj => new Company(obj));
}