import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export enum EHelpId
{
    categorizeDialog = 'categorizeDialog'
}

type IDialogTexts = {
    [key in EHelpId]: {
        title: string;
        texts: string[];
    };
};

export interface IHelpDialogTexts
{
    restore: string;
    maximize: string;
    minimize: string;
    close: string
    dialogs: IDialogTexts
}
type ILangData = {
    [lang in ELanguage]: IHelpDialogTexts
}
export const helpdialogTexts: ILangData = {
            'th': {
                restore: 'กู้คืน',
        maximize: 'ขยายใหญ่สุด',
                minimize: 'ย่อขนาด',
                close: oftenUsedTranslations['th'].close,
                dialogs: {
                    categorizeDialog: {
                        title: 'คำสั่ง-ดร็อปทักษะเพื่อหมวดหมู่',
                        texts: [
                            `คำสั่ง-ดร็อปทักษะเพื่อหมวดหมู่เป็นเครื่องมือที่ให้คุณจัดเรียงและจัดหมวดหมู่ทักษะต่าง ๆ ได้อย่างง่ายดาย ด้วยคำสั่งนี้คุณสามารถสร้างหมวดหมู่ใหม่ แก้ไขหมวดหมู่ที่มีอยู่หรือลบหมวดหมู่ได้ และยังสามารถลากและวางทักษะลงในหมวดหมู่ที่เหมาะสมด้วยการดร็อปแอนด์ดร็อป`,
                            `เป้าหมายหลักของคำสั่ง-ดร็อปทักษะเพื่อหมวดหมู่คือการให้คุณมีอินเตอร์เฟซที่ใช้งานง่ายในการจัดหมวดหมู่ทักษะของคุณอย่างมีประสิทธิภาพ ด้วยความสะดวกในการดร็อปแอนด์ดร็อปคุณสามารถเลือกทักษะจากรายการและลากไปในหมวดหมู่ที่ต้องการได้อย่างง่ายดาย ซึ่งช่วยให้คุณมีการจัดระเบียบและองค์กรในทักษะของคุณอย่างชัดเจน`,
                            `นอกจากนี้คำสั่ง-ดร็อปทักษะเพื่อหมวดหมู่ยังมีคุณสมบัติเด่นที่ให้คุณขอความช่วยเหลือจากปัญญาประดิษฐ์ (AI) ในการจัดหมวดหมู่ เมื่อคุณให้คำสั่งที่เฉพาะเจาะจงคุณจะสามารถให้คำแนะนำแก่ AI เพื่อให้มีคำแนะนำชัดเจนในการจัดหมวดหมู่ทักษะ เช่น คุณสามารถพูดว่า: "แนะนำหมวดหมู่ให้ฉัน" หรือ "สร้างหมวดหมู่ XY และ ABC กรุณา" แล้ว AI จะสร้างหมวดหมู่ที่แนะนำตามคำสั่งของคุณหรือสร้างหมวดหมู่ที่คุณต้องการ`,
                            `คำสั่ง-ดร็อปทักษะเพื่อหมวดหมู่เป็นเครื่องมือที่มีประสิทธิภาพในการจัดองค์ความรู้ของคุณอย่างมีประสิทธิภาพ ด้วยความง่ายในการใช้งานและความสามารถในการรับความช่วยเหลือจาก AI ในการจัดหมวดหมู่คุณสามารถประหยัดเวลาและความพยายามในการกำหนดโครงสร้างของรายการทักษะของคุณ ด้วยเครื่องมือนี้คุณจะสามารถเก็บสถานการณ์ไว้ในระเบียบเรียบร้อยและใช้ประโยชน์จากทักษะของคุณอย่างมีประสิทธิภาพ`,
                        ]
                    }
                }
            },
            'en': {
                restore: 'Restore',
        maximize: 'Maximize',
                minimize: 'Minimize',
                close: oftenUsedTranslations['en'].close,
                dialogs: {
                    categorizeDialog: {
                        "title": "Skills Categorisation Dialog",
                        "texts": [
                            "The Skills Categorisation Dialog is an interactive tool that allows you to sort and organize skills into different categories. With this dialog, you can create new categories, rename or delete existing categories, and move skills into the respective categories using drag and drop.",
                            "The main goal of the Skills Categorisation Dialog is to provide you with a user-friendly interface to efficiently categorize your skills. With easy drag and drop functionality, you can effortlessly move skills from a list into the desired categories. This enables you to have a clear and structured organization of your skills.",
                            "Furthermore, the Skills Categorisation Dialog offers a unique feature where you can request AI assistance in categorizing your skills. By providing specific instructions, you can give the AI clear guidelines on how to categorize the skills. For example, you can say, 'Suggest a categorisation for me' or 'Please create the categories XY and ABC.' Based on your instructions, the AI will generate a proposed categorisation or create the desired categories.",
                            "The Skills Categorisation Dialog is a powerful tool to effectively organize your skills. With its user-friendly interface and the ability to leverage AI support for categorisation, you save time and effort in structuring your skills list. This tool allows you to maintain an overview and make the most of your skills."
                        ]
                    }
                }
            },
            'de': {
                close: oftenUsedTranslations['de'].close,
                minimize: 'Minimieren',
                maximize: 'Maximieren',
                restore: 'Wiederherstellen',
                dialogs: {
                    categorizeDialog: {
                        title: 'Fähigkeiten-Kategorisierungdialog',
                        texts: [
                            `Der Fähigkeiten-Kategorisierungdialog ist ein interaktives Werkzeug, das Ihnen ermöglicht, Fähigkeiten in verschiedene Kategorien zu sortieren und zu organisieren. Mit diesem Dialog können Sie neue Kategorien erstellen, bestehende Kategorien umbenennen oder löschen und die Fähigkeiten mithilfe von Drag & Drop in die entsprechenden Kategorien verschieben.`,
                            `Das Hauptziel des Fähigkeiten-Kategorisierungdialogs besteht darin, Ihnen eine benutzerfreundliche Schnittstelle zur Verfügung zu stellen, mit der Sie Ihre Fähigkeiten effizient kategorisieren können. Durch die einfache Drag & Drop-Funktionalität können Sie die Fähigkeiten problemlos von einer Liste in die gewünschten Kategorien ziehen. Dies ermöglicht Ihnen eine übersichtliche und strukturierte Organisation Ihrer Fähigkeiten.`,
                            `Darüber hinaus bietet der Fähigkeiten-Kategorisierungdialog eine einzigartige Funktion, bei der Sie die KI um Unterstützung bei der Kategorisierung bitten können. Durch die Eingabe spezifischer Anweisungen können Sie der KI klare Vorgaben geben, wie sie die Fähigkeiten kategorisieren soll. Zum Beispiel können Sie sagen: "Schlage mir eine Kategorisierung vor" oder "Erstelle bitte die Kategorien XY und ABC". Die KI wird dann basierend auf Ihren Anweisungen eine vorgeschlagene Kategorisierung erstellen oder die gewünschten Kategorien generieren.`,
                            `Der Fähigkeiten-Kategorisierungdialog ist ein leistungsstarkes Werkzeug, um Ihre Fähigkeiten effektiv zu organisieren. Durch die einfache Handhabung und die Möglichkeit, die Kategorisierung durch die KI unterstützen zu lassen, sparen Sie Zeit und Mühe bei der Strukturierung Ihrer Fähigkeitenliste. Mit diesem Tool behalten Sie stets den Überblick und können Ihre Fähigkeiten optimal nutzen.`,
                        ]
                    }
                },
            },
        }