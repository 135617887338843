import React, { useContext } from "react";
import { HistoryToggleOff } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Entity } from "@sharedReact/Objects/Entity";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { AppState } from "@store/store";
import { useSelector } from "react-redux";
import { DialogManagerContext } from "@sharedReact/Dialog/DialogManager";

import { VersionInfoDialog } from "./VersionInfoDialog";

export default function VersionInfoButton({ entity, close }: {
    entity: Entity | undefined
    close?: () => void
})
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).entityPage;

    if (!entity) return <></>;
    return <MenuItem
        key="version-button"
        onClick={async () =>
        {
            close && close();
            return openDialog(
                <VersionInfoDialog
                    id="VersionInfoDialog"
                    entity={entity}
                    resolve={() => { }}
                />);
        }}
    >
        <ListItemIcon>
            <HistoryToggleOff />
        </ListItemIcon>
        <ListItemText>{langStrings.versionInfo}</ListItemText>
    </MenuItem>
}