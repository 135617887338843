import { Done } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import IBusinessDTO from '@sharedInterfaces/IBusiness';
import { IReference } from '@sharedInterfaces/IReference';
import { AppState } from '@store/store';
import Business from '@src/Objects/Business';
import { updateBusiness } from '@store/reducer/businessReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import createBusiness from '@src/APIs/graphQl/Business/createBusiness';
import editBusiness from '@src/APIs/graphQl/Business/editBusiness';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './BusinessDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import Row from '../../../sharedReact/General/Forms/Row/Row'
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';

interface BusinessDialogProps
{
    id: string
    business?: Business
    resolve?: (val: Business | null) => void;
}


/**
 * 
 * @param {BusinessDialogProps} business - The business object.
 * @param {Function} resolve - The resolve function.
 * @param {Function} removeElement - The removeElement function.
 * @returns {JSX.Element} - The JSX element representing the business dialog.
 */
export function BusinessDialog({ id, business, resolve }: BusinessDialogProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).businesses;

    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [businessName, setBusinessName] = React.useState(business ? business.title : '' as string);
    const [description, setDescription] = React.useState(business ? business.description : '' as string);
    const [tenantId, setTenantId] = React.useState(business ? business.tenantId : '' as string);
    const [projects, setProjects] = React.useState((business ? business.projects : [] as IReference[]));

    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newBusiness: IBusinessDTO) =>
    {
        const entity = new Business(newBusiness);
        resolve && resolve(entity);
        setSaving(false);
        dispatch(updateBusiness(entity));
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (businessName === "")
        {
            setErrorText(langStrings.newBusinessError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!business)
        {
            await createBusiness(
                {
                    title: businessName,
                    tenantId,
                    description,
                    projects: projects,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editBusiness(
                {
                    id: business.id,
                    title: businessName,
                    tenantId,
                    description,
                    projects: projects,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };
    const onClose = () =>
    {
        resolve && resolve(null);
    }
    return (
        <Dialog
            id={id}
            title={business ? langStrings.editBusiness + business.title : langStrings.newBusiness}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!business ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="businessDialog">
                <div className="newBusiness">
                    <Row>
                        <RowElement title={langStrings.businessName} alignTitle="left">
                            <TextInput disabled={business ? business.sync : false} value={businessName} onChange={s => setBusinessName(s)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.tenantId} alignTitle="left">
                            <TextInput value={tenantId} disabled={business ? business.sync : false} onChange={s => setTenantId(s)} />
                        </RowElement>
                    </Row>
                    <Row>
                        <RowElement title={langStrings.description} alignTitle="left">
                            <FormatedTextInput value={description} onChange={setDescription} />
                        </RowElement>
                    </Row>
                    {/* <Row>
                        <RowElement title={langStrings.employees} alignTitle="left">
                            <EmployeesInput selectedEmployees={employees} allEmployees={allEmployees} onChange={setEmployees} showMode={business ? business.sync : false} size={'medium'} />
                        </RowElement>
                    </Row> */}
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}