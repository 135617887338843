import React from 'react';
import { Line } from 'react-chartjs-2';
import
{
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { IOUAvailabilityDataEmployeesDTO, } from '@sharedInterfaces/IOrgUnit';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface AvailabilityGraphProps
{
    monthIds: string[];
    monthOptions: Intl.DateTimeFormatOptions;
    availabilityData: IOUAvailabilityDataEmployeesDTO;
}
function calculateMonthlyAverageAvailability(availabilityData: IOUAvailabilityDataEmployeesDTO, monthIds: string[])
{
    const monthlyAverages = monthIds.map(monthId =>
    {
        let totalAvailability = 0;
        let count = 0;

        availabilityData.employees.forEach(emp =>
        {
            const month = emp.months.find((m) => m.monthId === monthId);
            if (month && month.weeks?.length)
            {
                const monthWorkDayCount = month.weeks.reduce((acc: number, week) => acc + week.workDayCount, 0);
                const monthHolidayHours = month.weeks.reduce((acc: number, week) => acc + week.holidayHours, 0);
                const monthPlannedDays = month.weeks.reduce((acc: number, week) => acc + week.plannedDays, 0);
                const monthPlannedDaysInternal = month.weeks.reduce((acc: number, week) => acc + week.plannedDaysInternal, 0);
                const monthBusyDays = (monthHolidayHours / 8) + monthPlannedDays + monthPlannedDaysInternal;
                const monthFreeDays = monthWorkDayCount - monthBusyDays;
                const monthPercentAvailability = monthWorkDayCount ? Math.round((monthFreeDays / monthWorkDayCount) * 100) : 0;

                totalAvailability += monthPercentAvailability;
                count++;
            }
        });

        const averageAvailability = count > 0 ? Math.round(totalAvailability / count) : 0;

        return { id: monthId, availability: averageAvailability };
    });

    return monthlyAverages;
}


export function AvailabilityGraph({
    monthIds,
    monthOptions,
    availabilityData,
}: AvailabilityGraphProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const style = useSelector((state: AppState) => state.company.style);

    const averages = calculateMonthlyAverageAvailability(availabilityData, monthIds);

    const dataObj = averages.map(average =>
    {
        return {
            month: average,
            title: new Date(average.id).toLocaleString(undefined, monthOptions),
            availability: average.availability,
        }
    });

    const options = {
        responsive: true,
        animation: {
            duration: 0,
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    // Diese Callback-Funktion fügt das €-Zeichen vor jeden Tick-Wert ein
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    callback: (value: unknown, _index: unknown, _values: unknown) => `${value}%`,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    label: function (context: any)
                    {
                        let label = context.dataset.label || '';
                        if (label)
                        {
                            label += ': ';
                        }
                        if (context.parsed.y !== null)
                        {
                            label += context.parsed.y + '%';
                        }
                        return label;
                    }
                }
            },
        },
    };

    const labels = dataObj.map(o => o.title);

    const data = {
        labels,
        datasets: [
            {
                label: langStrings.availability,
                data: dataObj.map(o => o.availability),
                borderColor: style.secondary,
                backgroundColor: style.primary,
                tension: 0.4,
                yAxisID: 'y',
            },
        ],
    };
    return <Line options={options} data={data} height={40} />;
}
