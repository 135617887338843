import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../../graphQL";

const query = gql`
mutation unhideSuggestedCompetence($id:Int!) {
    unhideSuggestedCompetence(id: $id)
},
`;

/**
 * Hides the suggested competence with the given id.
 *
 * @param {number} id - The id of the suggested competence.
 * @returns {Promise<boolean>} A promise that resolves to true if the suggested competence was successfully hidden, otherwise false.
 */
export default async function unhideSuggestedCompetence(id: number)
{
    const variables: OperationVariables = {
        id,
    };
    return GraphQL.mutate<boolean>(query, variables)
}