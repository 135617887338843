import { AutoFixHigh, Clear, Done } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import ICompetence, { ICompetenceLevelMaybe, TCompetenceRequestingState } from '@sharedInterfaces/ICompetence';
import { AppState } from '@store/store';
import { setCompanyAllCompetences } from '@store/reducer/companyReducer';
import { updateCompetence } from '@store/reducer/competenceReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import Competence from '@src/Objects/Competence';
import createCompetence from '@src/APIs/graphQl/Competence/createCompetence';
import editCompetence from '@src/APIs/graphQl/Competence/editCompetence';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { ICompetenceMetadataResponse } from '@sharedInterfaces/websocket/ICompetenceMetadata';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../ErrorBox/ErrorBox';
import './CompetenceDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import { CompetenceLevelsInput } from '../../formsControls/inputs/CompetenceLevelsInput/CompetenceLevelsInput';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';




interface CompetenceDialogProps
{
    id: string
    competence?: Competence
    resolve?: (val: Competence | null) => void;
}

/**
 * CompetenceDialog component.
 * @param props - CompetenceDialogProps object.
 * @return JSX.Element.
 */
export function CompetenceDialog({ id, competence, resolve }: CompetenceDialogProps)
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);

    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [competenceName, setCompetenceName] = React.useState<string>(competence ? competence.title : '');
    const [competenceNameSuggestion, setCompetenceNameSuggestion] = React.useState<string>("");
    const [competenceNameError, setCompetenceNameError] = React.useState<string | undefined>(undefined);

    const [description, setDescription] = React.useState<string>(competence ? competence.description : '');
    const [levels, setLevels] = React.useState<ICompetenceLevelMaybe[]>((competence ? competence.levels.map(l => Object.assign({}, l)) : []));

    const [generatingMetadata, setGeneratingMetadata] = React.useState<boolean>(false);
    const existingCompetences = allCompetences.filter(a => !competence || competence.id !== a.id).map(s => s.title.toLowerCase());
    const [subKey, setSubKey] = React.useState<string>("");
    const topic = EWebsocketTopics.generateCompetenceMetadata;
    const startAnalyse = () =>
    {
        const request: TCompetenceRequestingState = {
            all: true,
        };
        WebSocketClient.sendMessage(topic, {
            competenceName,
            key: subKey,
            request
        });
        setGeneratingMetadata(true)
    }
    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<ICompetenceMetadataResponse>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if (data.error)
            {
                showErrorDialog(new Error(data.error))
                setGeneratingMetadata(false);
                return;
            }
            const metaData = data.metadata;

            if (!metaData)
            {
                showErrorDialog(new Error(langStrings.error))
                setGeneratingMetadata(false);
                return;
            }
            setGeneratingMetadata(false)
            if (metaData.description && (!description || description === ""))
                setDescription(metaData.description);

            if (metaData.competenceName && (competenceName !== metaData.competenceName))
            {
                setCompetenceNameSuggestion(metaData.competenceName);
            }
            if (metaData.level.length && !levels.length)
            {
                setLevels(metaData.level)
            }
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])


    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }

    const onFetch = (newCompetence: ICompetence) =>
    {
        setErrorText("");
        const newEntity = new Competence(newCompetence);
        const newAllCompetences = [...allCompetences];
        if (competence)
        {
            const index = newAllCompetences.findIndex(e => newEntity.id === e.id);
            if (index > -1)
                newAllCompetences[index] = { ...newAllCompetences[index], title: newEntity.title, levels: newCompetence.levels };
        }
        else
        {
            newAllCompetences.push({ id: newCompetence.id, title: newCompetence.title, levels: newCompetence.levels, version: 0 });
        }
        dispatch(setCompanyAllCompetences(newAllCompetences))
        dispatch(updateCompetence(newEntity));
        resolve && resolve(newEntity)
        setSaving(false);
    }

    const validate = () =>
    {
        if (competenceName === "")
        {
            setCompetenceNameError(
                langStrings.pleaseEnterTitle
            )
        } else if (existingCompetences.includes(competenceName.toLowerCase()))
        {
            setCompetenceNameError(
                langStrings.errorDuplicateTitle
                    .replace("[TITLE]", competenceName)
                    .replace("[TITLE]", competenceName)
            )
        }
        else
        {
            setCompetenceNameError(undefined);
        }
    }

    useEffect(validate, [competenceName])
    const onSave = async () =>
    {
        setSaving(true);
        if (competenceName === "")
        {
            setErrorText(langStrings.noVaildCompetenceName);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (levels.some(l => l.title === ""))
        {
            setErrorText(langStrings.noVailedLevelName);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!competence)
        {
            await createCompetence(
                {
                    title: competenceName,
                    description,
                    levels,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editCompetence(
                {
                    id: competence.id,
                    title: competenceName,
                    description,
                    levels,
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };
    const onClose = () =>
    {
        resolve && resolve(null);
    }

    return (
        <Dialog
            id={id}
            title={competence ? competence.title : langStrings.newCompetence}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!competence ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="competenceDialog">
                <div className="newCompetence">
                    <Row>
                        <RowElement title={langStrings.competenceName} alignTitle="left">
                            <TextInput
                                value={competenceName}
                                isAI
                                onChange={setCompetenceName}
                                helperText={competenceNameError}
                            />
                        </RowElement>
                        <IconButton
                            title={langStrings.generateCompetenceData}
                            disabled={!!competenceNameError || generatingMetadata}
                            onClick={startAnalyse}
                        >
                            {generatingMetadata ? <CircularProgress size={24} color='secondary' /> : <AutoFixHigh />}
                        </IconButton>
                    </Row>
                    {
                        competenceNameSuggestion !== "" &&
                        <div>
                            {langStrings.suggestedCompetenceName}: {competenceNameSuggestion}
                            <IconButton
                                onClick={() =>
                                {
                                    setCompetenceName(competenceNameSuggestion);
                                    setCompetenceNameSuggestion("");
                                }}
                            >
                                <Done />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                {
                                    setCompetenceNameSuggestion("");
                                }}
                            >
                                <Clear />
                            </IconButton>
                        </div>
                    }


                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput value={description} onChange={setDescription}
                            placeholder={langStrings.descriptionPlaceholder} />
                    </RowElement>
                    <RowElement title={langStrings.levels} alignTitle="left">
                        <CompetenceLevelsInput levels={levels} onUpdate={setLevels} />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}>{errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}