import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

import styles from './Item.module.css';
const MemoizedIconButton = React.memo(IconButton);



export interface IItemAction
{
    id: string;
    icon: React.ReactNode;
    text: string;
    helper?: string;
    onClick?: () => void;
}

export const ItemActions: React.FC<{ actions: IItemAction[]; }> = ({ actions }) =>
{
    // actions.map((action, index) => <div key={index}>{action.icon}</div>)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const closeMenu = React.useCallback(() =>
    {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const menuItems = React.useMemo(() =>
    {
        return actions.slice(1).map((action) => (
            <MenuItem
                key={action.id}
                onClick={() =>
                {
                    action.onClick?.();
                    closeMenu();
                }}
                className={styles.menuItem} // moved inline styles to css
            >
                {action.icon} {action.text}
            </MenuItem>
        ));
    }, [actions, closeMenu]);

    return (
        <div className={styles.actions}>
            {actions.length > 0 &&
                <ActionButton action={actions[0]} />
            }
            {actions.length === 2 &&
                <ActionButton action={actions[1]} />
            }
            {/* {actions.map((action, index) => <div key={index}>{action.icon}</div>)} */}
            {actions.length > 2 &&
                <MemoizedIconButton
                    size='small'
                    className={styles.iconButton}
                    onClick={openMenu}
                >
                    <MoreVert fontSize='small' />
                </MemoizedIconButton>}
            {anchorEl &&
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    onClose={closeMenu}
                    open={Boolean(anchorEl)}
                >
                    {menuItems}
                </Menu>
            }
        </div>
    );
};


export const ActionButton: React.FC<{ action: IItemAction; }> = ({ action }) =>
{
    return (
        <div className={styles.actionButton}>
            <Tooltip title={action.text} enterDelay={500} enterNextDelay={500}>
                <IconButton
                    aria-label={action.text}
                    size='small'
                    className={styles.iconButton}
                    onClick={action.onClick}
                >
                    {action.icon}
                </IconButton>
            </Tooltip>
        </div>
    );
}