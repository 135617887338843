import { Cancel, Check } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';

import TextInput from '../../formsControls/inputs/TextInput/TextInput';

interface AskForTextDialogProps
{
    id: string
    title: string;
    message: string;
    defaultValue?: string;
    resolve: (value: string | null) => void;
}
export const AskForTextDialog: React.FC<AskForTextDialogProps> = ({ id, title, message, defaultValue, resolve, }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    const [isOpen, setIsOpen] = useState(true);
    const [value, setValue] = useState<string>(defaultValue ? defaultValue : '');

    const onConfirm = () =>
    {
        resolve(value);
        setIsOpen(false);
    };

    const onCancel = () =>
    {
        resolve(null);
        setIsOpen(false);
    };

    useEffect(() =>
    {
        if (!isOpen)
        {
            onCancel()
        }
    }, [isOpen, onCancel]);

    if (!isOpen) return null;

    return (
        <Dialog
            id={id}
            footer={<div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                <Button
                    text={langStrings.oftenUsed.cancel}
                    icon={<Cancel />}
                    onClick={onCancel} />
                <Button
                    icon={<Check />}
                    text={langStrings.oftenUsed.confirm}
                    onClick={onConfirm} />
            </div>}
            title={title}
            onClose={function (): void
            {
                onCancel();
            }}>
            <Typography variant='body1'>
                {message}
            </Typography>
            <TextInput value={value} onChange={setValue} />
        </Dialog>
    );
};