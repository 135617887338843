import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import { setSecret } from "@store/reducer/clientReducer";
import { setCompanyId, setCompanyStyle } from "@store/reducer/companyReducer";
import store from "@store/store";
import { setEmployeeNames } from "@store/reducer/employeeReducer";

import { restPost } from "./restApi";

interface IRegisterClientCompanyResponse
{
    clientSecret: string;
    companyId: string;
    style: IStyleDTO;
    firstname: string;
    lastname: string;
}
interface IRegisterCompanyCompany
{
    companyId: string;
    title: string;
}

interface IRegisterClientCompanies
{
    companies: IRegisterCompanyCompany[]
}
type TRegisterClientResponse = IRegisterClientCompanyResponse | IRegisterClientCompanies;

async function registerClient(idToken: string, companyId?: string): Promise<true | IRegisterCompanyCompany[]>
{
    return new Promise<true | IRegisterCompanyCompany[]>((resolve, reject) =>
    {
        restPost<TRegisterClientResponse>('/registerClient',
            {
                companyId
            }, idToken).then((data) =>
            {
                if ('companies' in data)
                {
                    return resolve(data.companies);
                }
                else
                {
                    const client = data as IRegisterClientCompanyResponse;
                    if (!client.companyId)
                    {
                        console.log("Client:", client)
                        console.error('No companyId');
                        return reject('No companyId');
                    }
                    store.dispatch(setSecret(client.clientSecret))
                    store.dispatch(setCompanyStyle(client.style))
                    store.dispatch(setCompanyId(client.companyId))
                    store.dispatch(setEmployeeNames(client))
                    return resolve(true);
                }
            }).catch(async (ex) =>
            {
                return reject(ex);
            })
    });
}
export default registerClient;