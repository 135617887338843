import { Close, Warning } from '@mui/icons-material';
import React from 'react';
import './ErrorBox.css';
interface ErrorBoxProps
{
    warning?: true
    children: React.ReactNode
    close?: () => void
}

function ErrorBox(props: ErrorBoxProps)
{
    return (
        <div className={'errorBox' + (props.warning ? ' warn' : '')}
        >
            <div className='errorContent'>
                <Warning />
                <div>
                    {props.children}
                </div>
            </div>
            {props.close && <div className='close' onClick={() =>
            {
                props.close && props.close();
            }}><Close /></div>}
        </div>
    );
}

export default ErrorBox;