import React from 'react';
import { NavLink } from 'react-router-dom';
import './SkillTypeSelect.css';
import { ELinks, ESkillType } from '@sharedInterfaces/globalEnums';

interface SkillTypeSelectProps
{
    value?: ESkillType
    readonly?: boolean
    onChange?: (val: ESkillType) => void
}

/**
 * Renders a skill type select component.
 * @param {boolean} readonly - Whether the skill type select is read-only or not.
 * @param {function} onChamge - The function to be called when the skill type select value changes.
 * @returns {JSX.Element} - The rendered skill type select component.
 */
function SkillTypeSelect({ value, readonly, onChange: onChamge }: SkillTypeSelectProps)
{
    if (readonly)
    {
        if (!value) return null
        return <NavLink to={`/${ELinks.SKILLCATEGORIES}/` + value}>
            {value}</NavLink>
    }
    return (
        <div className="skillTypeSelect">
            <select
                value={value}
                onChange={(e) =>
                {
                    const val = e.target.value as ESkillType;
                    onChamge && onChamge(val)
                }}
            >
                <option value={-1}></option>
                {Object.values(ESkillType).map(skillType => <option
                    key={skillType}
                    value={skillType}
                >
                    {skillType}
                </option>)}
            </select>
        </div>
    );
}

export default SkillTypeSelect;


