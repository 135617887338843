import { Merge, TableChart, TableRows } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './CertificatesPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import Certificate from '@src/Objects/Certificate';
import { textContainsLowerCase } from '@src/Objects/Helper';
import CertificateOverlay from '@src/Components/Overlays/CertificateOverlay/CertificateOverlay';
import MergeCertificatesDialog from '@src/Components/Dialogs/MergeCertificatesDialog/MergeCertificatesDialog';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import getCertificates from '@src/APIs/graphQl/Certificate/getCertificates';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import CertificateCard from '@src/App/NewLayout/Cards/Certificates/CertificateCard/CertificateCard';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';


/**
 * Renders the CertificatesPage component
 * 
 * @returns {JSX.Element}
 */
function CertificatesPage()
{
    const { openDialog } = useContext(DialogManagerContext);

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const permissions = useSelector((state: AppState) => state.permissions);
    const emp = useSelector((state: AppState) => state.employee);
    const entities = useSelector((state: AppState) => state.certificates)

    const isDense = (width: number) => width <= 1000

    const [selected, setSelected] = useState<number[]>([]);
    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);

    useEffect(() =>
    {
        getCertificates().catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, []);

    const types: IViewType<Certificate>[] = [
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 375,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.CERTIFICATE}
                        dense={isDense(width)}
                        noCheckbox={!checkPermissions('Certificates', 'Delete', permissions) || !checkPermissions('Certificates', 'Edit', permissions)}
                        selected={selected}
                        setSelected={setSelected}
                        order='desc'
                        orderBy='employees'
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.certificateName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.CERTIFICATES}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                        {
                            id: 'employees',
                            label: langStrings.employees,
                            disablePadding: isDense(width),
                            align: 'center',
                            type: 'string',
                        },
                        {
                            id: 'products',
                            label: langStrings.products,
                            disablePadding: isDense(width),
                            align: 'center',
                            minWidth: 475,
                            type: 'string',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <CertificateOverlay
                                        certificateId={entity.id}
                                    >
                                        {entity.title}
                                    </CertificateOverlay>,
                                    orderKey: entity.title
                                },
                                description: entity.description,
                                employees: entity.employees.length,
                                products: entity.products.length,
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <CertificateCard key={entity.id} certificate={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        {
            id: 'merge',
            text: langStrings.merge,
            icon: <Merge />,
            filter(selectedCount)
            {
                if (!checkPermissions('Certificates', 'Delete', permissions) || !checkPermissions('Certificates', 'Edit', permissions))
                    return false;
                return selectedCount > 1;

            },
            action(selectedIDs)
            {
                if (!this.filter(selectedIDs.length)) return;
                openDialog(
                    <MergeCertificatesDialog
                        id="MergeCertificatesDialog"
                        resolve={() => { setSelected([]) }}
                        certificatesWithoutFilter={entities}
                        certificates={entities.filter(s => selected.indexOf(s.id) > -1)} />
                )
            },
        }
    ];


    return (
        <EntitiesPage
            title={langStrings.certificates}
            entities={entities}
            loadingError={loadingError}

            selected={selected}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
                {
                    id: 'my', title: langStrings.oftenUsed.myEntries,
                    filter(entity)
                    {
                        return entity.employees.find(e => e.id === emp?.id) !== undefined
                    },
                },
            ]}
            types={types}
            actions={actions}
        />
    );
}
export default CertificatesPage;