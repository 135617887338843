import { OperationVariables, gql } from "@apollo/client";
import { IOrgUnitSalesView } from "@sharedInterfaces/IOrgUnit";

import GraphQL from "../graphQL";

import { GQL_OU_SALES_DATA_VIEW } from "./createOUSalesView";

const query = gql`
mutation editOUSalesViews($views: [NewOUSalesViewInput!]!) {
    editOUSalesViews(views: $views) 
    ${GQL_OU_SALES_DATA_VIEW}
},
`;

export default async function editOUSalesViews(views: IOrgUnitSalesView[])
{
    const variables: OperationVariables = {
        views
    };
    return GraphQL.mutate<IOrgUnitSalesView[]>(query, variables)
}