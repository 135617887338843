import { OperationVariables, gql } from "@apollo/client";
import { EClockodoSyncType } from "@sharedInterfaces/ICompanySettings";

import GraphQL from "../graphQL";


const query = gql`
mutation startClockodoSync($syncType: String) {
    startClockodoSync(syncType: $syncType) 
},
`;

export default async function startClockodoSync(syncType?: EClockodoSyncType)
{
    const variables: OperationVariables = {
        syncType
    };
    return GraphQL.mutate<boolean>(query, variables)
}