/**
 * Function to create a firework.
 *
 * @param {number} xCenter - The x-coordinate of the firework center.
 * @param {number} yCenter - The y-coordinate of the firework center.
 *
 * @returns {void}
 */
function createFirework(xCenter = 0.5, yCenter = 0.5)
{
    const numParticles = 25;
    const explodeTime = 500;
    const bigSize = 20;
    const timeInterval = explodeTime / numParticles;

    const container = document.querySelector('body');
    if (!container) return;

    const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#FFFFFF'];
    const particles: HTMLDivElement[] = [];

    const startX = window.innerWidth * xCenter;
    const startY = window.innerHeight * yCenter;


    // Großer "Pop"
    const bigParticle = document.createElement('div');
    bigParticle.className = 'firework';
    bigParticle.style.position = 'absolute';
    bigParticle.style.width = `${bigSize}px`;
    bigParticle.style.height = `${bigSize}px`;
    bigParticle.style.borderRadius = '50%';
    bigParticle.style.background = colors[Math.floor(Math.random() * colors.length)];
    bigParticle.style.left = `${startX}px`;
    bigParticle.style.top = `${startY}px`;
    container.appendChild(bigParticle);

    // Kleine Pixel initial erzeugen, aber noch nicht bewegen
    for (let i = 0; i < numParticles; i++)
    {
        setTimeout(() =>
        {
            const particle = document.createElement('div');
            particle.className = 'firework';
            particle.style.position = 'absolute';
            particle.style.width = '4px';
            particle.style.height = '4px';
            particle.style.borderRadius = '50%';
            particle.style.background = colors[Math.floor(Math.random() * colors.length)];


            const innerRangeSize = bigSize * 0.75;
            const innerRangeSizeHalf = innerRangeSize / 2;
            const randomOffsetX = Math.random() * innerRangeSize - innerRangeSizeHalf;
            const randomOffsetY = Math.random() * innerRangeSize - innerRangeSizeHalf;

            particle.style.left = `${Math.round(startX + innerRangeSizeHalf + randomOffsetX)}px`;
            particle.style.top = `${Math.round(startY + innerRangeSizeHalf + randomOffsetY)}px`;
            particle.style.opacity = '1';
            container.appendChild(particle);
            particles.push(particle);
        }, i * timeInterval);
    }

    setTimeout(() =>
    {
        bigParticle.remove();

        particles.forEach(particle =>
        {
            const angle = Math.random() * 2 * Math.PI;
            const distance = Math.random() * 200;
            const x = Math.cos(angle) * distance;
            const y = Math.sin(angle) * distance;
            particle.style.transition = 'all 1s ease-out';

            requestAnimationFrame(() =>
            {
                particle.style.opacity = '1';
                particle.style.transform = `translate(${x}px, ${y}px)`;
            });

            particle.addEventListener('transitionend', (event) =>
            {
                if (event.propertyName === 'transform')
                {
                    const target = event.target as HTMLElement;
                    target.remove();
                }
            });
        });
    }, explodeTime);
}
/**
 * Function to generate a firework salvo.
 *
 * @returns {void}
 */
export function fireworkSalvo()
{
    let intensity = 1;
    const maxIntensity = 5;
    const peakTime = 2000;
    const endTime = 5000;
    let currentTime = 0;
    const timeStep = 100;

    const startTime = new Date();

    const interval = setInterval(() =>
    {
        const now = new Date();
        currentTime = now.getTime() - startTime.getTime();

        if (currentTime <= peakTime)
        {
            intensity = Math.min(maxIntensity, intensity + 0.2);
        } else if (currentTime > peakTime && currentTime <= endTime)
        {
            intensity = Math.max(1, intensity - 0.1);
        }
        else
        {
            clearInterval(interval);
            setTimeout(() =>
            {
                const particles = document.querySelectorAll('.firework');
                particles.forEach(particle => particle.remove());
            }, 2500);
            return;
        }

        const numSalvos = Math.round(intensity);
        const range = Math.random();
        for (let i = 0; i < numSalvos; i++)
        {
            let xOffset, yOffset;

            if (range < 0.2)
            {
                xOffset = Math.random(); // zwischen 0 und 1
                yOffset = Math.random(); // zwischen 0 und 1
            }
            else
            {
                xOffset = Math.random() * 0.4 + 0.3; // zwischen 0.3 und 0.7
                yOffset = Math.random() * 0.4 + 0.3; // zwischen 0.3 und 0.7
            }
            createFirework(xOffset, yOffset);
        }
    }, timeStep);

}
