import { setOffline as setOfflineReducer } from "./reducer/clientReducer";
import { setLoading as setLoadingReducer } from "./reducer/clientReducer";
import store from "./store";

export function setOffline(offline: boolean)
{
    const actualState = store.getState().client.offline;
    if (actualState !== offline)
        store.dispatch(setOfflineReducer(offline))
}

export function setLoading(loading: boolean)
{
    const actualState = store.getState().client.loading;
    if (actualState !== loading)
        store.dispatch(setLoadingReducer(loading))
}