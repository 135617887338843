import { AccountTree, Add, Delete, Merge, TableChart, TableRows } from '@mui/icons-material';
import React, { lazy, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import './SkillCategoriesPage.css';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { EEntityType, ELinks } from '@sharedInterfaces/globalEnums';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { textContainsLowerCase } from '@src/Objects/Helper';
import { SkillCategory } from '@src/Objects/SkillCategory';
import SkillCategoryOverlay from '@src/Components/Overlays/SkillCategoryOverlay/SkillCategoryOverlay';
import CategorizeDialogMain from '@src/Components/Dialogs/CategorizeDialog/CategorizeDialogMain';
import SkillCategoryDialog from '@src/Components/Dialogs/SkillCategoryDialog/SkillCategoryDialog';
import EnhancedTable from '@src/App/NewLayout/Components/DataTable/DataTable';
import { SkillCategoryCard } from '@src/App/NewLayout/Cards/SkillCategories/SkillCategoryCard/SkillCategoryCard';

import EntitiesPage, { EViewType, IEntitiesPageAction } from '../../../../../sharedReact/Pages/EntitiesPage/EntitiesPage';
import { IViewType } from '../../../../../sharedReact/Pages/EntitiesPage/ViewTypeMenu';

// import { SkillCategoryTree } from './SkillCategoryTree';

const SkillCategoryTree = lazy(() => import('./SkillCategoryTree'));

/**
 * Represents the SkillCategoriesPage component.
 * 
 * @returns {JSX.Element} The JSX element representing the SkillCategoriesPage.
 */
function SkillCategoriesPage()
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skillCategories;

    const entities = useSelector((state: AppState) => state.company.skillCategories);
    const permissions = useSelector((state: AppState) => state.permissions);

    const isDense = (width: number) => width <= 1000
    const [selected, setSelected] = useState<number[]>([]);

    const types: IViewType<SkillCategory>[] = [
        {
            id: EViewType.TREE,
            title: langStrings.oftenUsed.treeStructure,
            icon: <AccountTree fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <SkillCategoryTree elements={innerEntries} />,
        },
        {
            id: EViewType.TABLE,
            title: langStrings.oftenUsed.table,
            icon: <TableRows fontSize='small' />,
            minWidth: 375,
            renderContainer:
                (allEntities, innerEntries, width, selected) =>
                    <EnhancedTable
                        id={EEntityType.SKILL_CATEGORY}
                        dense={isDense(width)}
                        noCheckbox={!((checkPermissions('SkillCategories', 'Delete', permissions) || checkPermissions('SkillCategories', 'Add', permissions)) && checkPermissions('SkillCategories', 'Edit', permissions))}
                        selected={selected}
                        setSelected={setSelected}
                        order='asc'
                        orderBy='title'
                        fields={[{
                            id: 'id',
                            label: 'ID',
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'string',
                        },
                        {
                            id: 'title',
                            label: langStrings.skillcategoryName,
                            disablePadding: isDense(width),
                            align: 'left',
                            type: 'JSX.Element',
                            link: `/${ELinks.SKILLCATEGORIES}/`,
                        },
                        {
                            id: 'description',
                            label: langStrings.description,
                            disablePadding: isDense(width),
                            align: 'left',
                            minWidth: 1000,
                            type: 'description',
                        },
                        ]}
                        rows={
                            innerEntries.map(entity => ({
                                id: entity.id,
                                title: {
                                    value: <SkillCategoryOverlay skillCategoryId={entity.id}
                                    >{entity.title} </SkillCategoryOverlay>,
                                    orderKey: entity.title
                                },
                                description: entity.description,
                            }
                            ))
                        } />,
        },
        {
            id: EViewType.CARD,
            title: langStrings.oftenUsed.cards,
            icon: <TableChart fontSize='small' />,
            renderContainer: (allEntities, innerEntries) => <Container>
                {
                    innerEntries.map(entity =>
                        <SkillCategoryCard key={entity.id}
                            cat={entity}
                        />)
                }
            </Container>,
        }
    ];

    const actions: IEntitiesPageAction[] = [
        ...(checkPermissions("SkillCategories", 'Add', permissions) ? [{
            id: 'create',
            text: langStrings.create,
            icon: <Add />,
            filter()
            {
                if (!checkPermissions('SkillCategories', 'Add', permissions))
                    return false;
                return true;
                // return selectedCount === 1;

            },
            action()
            {
                return openDialog(
                    <SkillCategoryDialog
                        id="SkillCategoryDialog"
                        resolve={() =>
                        {
                        }}
                    />);
            },
        }] :
            []),
        ...(checkPermissions("SkillCategories", 'Delete', permissions) ? [{
            id: 'delete',
            text: langStrings.delete,
            icon: <Delete />,
            filter(selectedCount: number)
            {
                return selectedCount === 1;
            },
            action()
            {
                if (entities)
                {
                    const entity = entities.find(s => selected.indexOf(s.id) > -1)
                    if (entity)
                    {
                        entity.delete();
                        setSelected([]);
                    }
                }
            },
        }] :
            []),
        ...((checkPermissions('SkillCategories', 'Delete', permissions) &&
            checkPermissions('SkillCategories', 'Add', permissions) &&
            checkPermissions('SkillCategories', 'Edit', permissions)) ? [{
                id: 'categorize',
                text: langStrings.categorize,
                icon: <Merge />,
                filter()
                {
                    if (
                        !checkPermissions('SkillCategories', 'Delete', permissions)
                        || !checkPermissions('SkillCategories', 'Add', permissions)
                        || !checkPermissions('SkillCategories', 'Edit', permissions)
                    )
                        return false;
                    return true; //TODO: Activate again
                },
                action()
                {
                    openDialog(<CategorizeDialogMain
                        id="CategorizeDialogMain"
                        resolve={() => { }}
                    />)
                },
            }] :
            []),
    ];

    return (
        <EntitiesPage
            title={langStrings.skillCategories}
            entities={entities}
            selected={selected}
            loadingError={!checkPermissions('SkillCategories', 'Retrieve', permissions) ? { statusCode: 401, message: 'Forbidden' } : null}
            filter={(entity, selectedView, searchText) =>
                selectedView.filter(entity) && textContainsLowerCase(searchText, entity.title)
            }
            setSelected={setSelected}
            views={[
                {
                    id: 'all', title: langStrings.oftenUsed.allEntries,
                    filter()
                    {
                        return true;
                    },
                },
            ]}
            types={types}
            actions={actions}
        />
    );
}
export default SkillCategoriesPage;
