import { ISmallCompetence } from "@sharedInterfaces/IWhoIAm";
import Opportunity from "@src/Objects/Opportunity";
import Product from "@src/Objects/Product";
import Role from "@src/Objects/Role";

/**
 * Timeout function
 * 
 * @param {number} ms - The number of milliseconds to wait before resolving the Promise.
 * 
 * @returns {Promise<unknown>} - A Promise that will resolve after the specified time.
 */
export function timeout(ms: number)
{
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Retrieves the localized name of the month for a given date.
 * 
 * @param {Date} date - The date for which to retrieve the localized month name.
 * @returns {string} The localized name of the month.
 */
export function getMonthLocalName(date: Date)
{
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const localizedMonthName = date.toLocaleString(undefined, monthOptions);
    return localizedMonthName;
}


interface HasId
{
    id: number | string;
}

/**
 * Removes duplicate elements from an array of objects based on their id property.
 *
 * @param {T[]} arr - The array of objects.
 * @returns {T[]} - The array of objects without duplicate elements.
 */
export function removeDuplicatesById<T extends HasId>(arr: T[]): T[]
{
    const seen = new Set();
    return arr.filter(el =>
    {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
    });
}
/**
 * Combines skills and certificates.
 *
 * @param {Object} stateEntity - The state entity.
 * @param {Array} allCompetences - The array of all competences.
 * @returns {Array} - The combined skills and certificates.
 * {
 *    combinedSkills: {
 *        title: string;
 *        id: number;
 *        level: number;
 *        lockedBy?: string;
 *    }[];
 *    combinedCertificates: {
 *        title: string;
 *        id: number;
 *        lockedBy?: string;
 *    }[];
 * }
 */
export function combineSkillsAndCertificates(stateEntity: Product | Opportunity | Role | undefined, allCompetences: ISmallCompetence[])
{
    if (stateEntity === undefined || !stateEntity.competences) return { combinedSkills: [], combinedCertificates: [] };

    const competences = stateEntity.competences;

    const selectedCompetencesIds = competences.map(c => c.id);
    const skillMap = new Map<number, { title: string; id: number; level: number; lockedBy?: string; }>();
    const certMap = new Map<number, { title: string; id: number; lockedBy?: string; }>();

    // Populate skill and certificate Maps with data from competences
    allCompetences
        .filter(c => selectedCompetencesIds.includes(c.id))
        .forEach(c =>
        {
            const index = selectedCompetencesIds.indexOf(c.id);
            const level = competences[index].level - 1;

            c.levels[level]?.skills?.forEach(s =>
            {
                const existingSkill = skillMap.get(s.id);
                if (!existingSkill || s.level > existingSkill.level)
                {
                    skillMap.set(s.id, {
                        ...s,
                        lockedBy: undefined // c.title
                    });
                }
            });

            c.levels[level]?.certificates?.forEach(cert =>
            {
                if (!certMap.has(cert.id))
                {
                    certMap.set(cert.id, {
                        ...cert,
                        lockedBy: undefined // c.title
                    });
                }
            });
        });

    // Populate skill Map with data from stateEntity
    if (stateEntity.skills)
    {
        stateEntity.skills.forEach(s =>
        {
            const existingSkill = skillMap.get(s.id);
            if (!existingSkill || s.level > existingSkill.level)
            {
                skillMap.set(s.id, s);
            }
        });
    }

    // Populate certificate Map with data from stateEntity if it is a Product
    if (stateEntity.certificates)
    {
        stateEntity.certificates.forEach(cert =>
        {
            if (!certMap.has(cert.id))
            {
                certMap.set(cert.id, cert);
            }
        });
    }

    const combinedSkills = Array.from(skillMap.values());
    const combinedCertificates = Array.from(certMap.values());

    return { combinedSkills, combinedCertificates };
}


export function hashToJson<T extends { [key: string]: string }>(hashString: string): T
{
    // Entfernt das erste Zeichen (#), wenn es vorhanden ist
    const hash = hashString.startsWith('#') ? hashString.slice(1) : hashString;

    // Teilt den Hash in Schlüssel-Wert-Paare
    const pairs = hash.split('&');

    // Konvertiert die Paare in ein JSON-Objekt
    const result = pairs.reduce((obj, pair) =>
    {
        const [key, value] = pair.split('=');
        if (key === "") return obj;
        (obj as { [key: string]: string })[key] = value;
        return obj;
    }, {} as T);
    return result;
}

export function jsonToHash<T extends { [key: string]: string }>(json: T): string
{
    const hash = Object.entries(json).map(([key, value]) => `${key}=${value}`).join('&');
    return hash;
}