import React, { useState } from 'react';
import { NavigateNext, Done, NavigateBefore, Refresh } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeDTO } from '@sharedInterfaces/IEmployee';
import { ISuggestedCertificate, ISuggestedRole, ISuggestedSkill } from '@sharedInterfaces/ISkill';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { setEmployeeDescription } from '@store/reducer/employeeReducer';
import Button from '@sharedReact/General/Button/Button';
import './AnalyseCvDialog.css';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import getUploadURL from '@src/APIs/graphQl/Company/getUploadURL';
import getUploadedFileContent from '@src/APIs/graphQl/Company/getUploadedFileContent';
import updateMyDescription from '@src/APIs/graphQl/Employee/updateMyDescription';
import ISkill from '@src/interfaces/ISkill';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { ICvAnalyse } from '@sharedInterfaces/websocket/ICvAnalyse';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import EditSkillList from '../../EditSkillList/EditSkillList';
import EditCertificateList from '../../EditCertificateList/EditCertificateList';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import AiConfirmation from '../../formsControls/inputs/AiConfirmation/AiConfirmation';
import EditRoleList from '../../EditRoleList/EditRoleList';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
// const FormatedTextInput = lazy(() => import(
//     /* webpackChunkName: "formated-text-input" */
//     /* webpackPrefetch: true */
//     '../../formsControls/inputs/FormatedTextInput/FormatedTextInput'));

export const MAX_CONTENT_LENGTH = 15000 * 4

enum ECvDialogPage
{
    AI_CONFIRMATION = 0,
    DESCRIPTION = 1,
    ROLE = 2,
    CERTIFICATE = 3,
    SKILL = 4,
}
interface AnalyseCvDialogProps
{
    id: string
    initialLoadingText: string
    resolve: () => void
    file: File | undefined
}
/**
 * Represents a dialog for analysing skills.
 *
 * @param {AnalyseCvDialogProps} props - The properties for the dialog.
 * @return {JSX.Element} The rendered dialog component.
 */
function AnalyseCvDialog(props: AnalyseCvDialogProps)
{
    const showErrorDialog = useErrorDialog();

    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).analyseDialog;
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const employee = useSelector((state: AppState) => state.employee);

    const allRoles = useSelector((state: AppState) => state.company.smallRoles);
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);

    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);

    const [text, setText] = useState<string>("");
    const [loadingText, setLoadingText] = useState<string>(props.initialLoadingText);
    const [loadingError, setLoadingError] = useState<string>("");
    const [aiConfirmation, setAiConfirmation] = useState<boolean>(false as boolean);
    const [dialogStep, setDialogStep] = useState<ECvDialogPage>(ECvDialogPage.AI_CONFIRMATION);
    const [roles, setRoles] = useState<ISuggestedRole[] | null>(null);
    const [skills, setSkills] = useState<ISuggestedSkill[] | null>(null);
    const [certificates, setCertificates] = useState<ISuggestedCertificate[] | null>(null);
    const [description, setDescription] = useState<string | null>(null);

    const [rolesChanged, setRolesChanged] = useState(false);
    const [skillsChanged, setSkillsChanged] = useState(false);
    const [certificatesChanged, setCertificatesChanged] = useState(false);


    const topic = EWebsocketTopics.analyseCvText;
    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<ICvAnalyse>(topic, (data) =>
        {
            if (data.error)
            {
                setLoadingError(data.error);
                return;
            }
            if (data.description)
            {
                setLoadingText("");
                setDescription(data.description);
                if (description === data.description) setRolesChanged(true);
            }
            if (data.roles)
            {
                setRoles(data.roles);
                if (!data.roles.length) setRolesChanged(true);
            }
            if (data.skills)
            {
                setSkills(data.skills);
                if (!data.skills.length) setSkillsChanged(true);
            }
            if (data.certificates)
            {
                setCertificates(data.certificates);
                if (!data.certificates.length) setCertificatesChanged(true);
            }
            // console.log(data);
        });
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])


    React.useEffect(() =>
    {
        const dialogFlow = async () =>
        {
            let initialText = langStrings.initialText;
            if (props.file)
            {
                await getUploadURL(async (post) =>
                {
                    if (props.file)
                    {
                        setLoadingText(langStrings.uploading);
                        await uploadFile(post, props.file)
                            .catch(ex => showErrorDialog(ex));
                        setLoadingText(langStrings.extractingText);
                        const path = `${companyId}/${post.id}.docx`;
                        await getUploadedFileContent(path, (initialText) =>
                        {
                            setLoadingText("");
                            initialText = initialText
                                .trim()
                                .replace(/[\n]{2,}/gm, '\n')
                                .replace(/[\s]{2,}/gm, ' ');

                            if (initialText.length > MAX_CONTENT_LENGTH)
                                initialText = initialText.substring(0, length);
                            setText(initialText);
                        });
                    }
                });
            } else
            {
                setLoadingText("");
                if (initialText.length > MAX_CONTENT_LENGTH)
                    initialText = initialText.substring(0, length);
                setText(initialText);
            }

        }
        dialogFlow();

    }, [companyId, langStrings, props.file]);


    const clearData = () =>
    {
        setLoadingError("");
        setDescription(null);
        setRoles(null);
        setCertificates(null);
        setSkills(null);
    }

    const closeDialog = () =>
    {
        // setHtmlText("");
        setText("");
        setDialogStep(ECvDialogPage.AI_CONFIRMATION);
        setAiConfirmation(false);
        setSkills([]);
        setCertificates([]);
        setLoadingText("");
        setLoadingError("");
        props.resolve();

    }
    let dialogTitle;
    let btnNextText = langStrings.next;
    switch (dialogStep)
    {
        case ECvDialogPage.AI_CONFIRMATION:
            if (loadingText === "")
                dialogTitle = langStrings.titleAnoymize;
            else dialogTitle = (props.file ? langStrings.fileAnalyze : langStrings.textAnalyze);
            break;
        case ECvDialogPage.DESCRIPTION:
            dialogTitle = langStrings.titleSummary;
            btnNextText = langStrings.skip
            break;
        case ECvDialogPage.ROLE:
            dialogTitle = langStrings.titleRoles;
            if (!rolesChanged) btnNextText = langStrings.skip
            break;
        case ECvDialogPage.CERTIFICATE:
            dialogTitle = langStrings.titleCertificates;
            if (!certificatesChanged) btnNextText = langStrings.skip
            break;
        case ECvDialogPage.SKILL:
            dialogTitle = langStrings.titleSkills;
            if (!skillsChanged) btnNextText = `${langStrings.skip} & ${langStrings.close}`;
            else btnNextText = langStrings.close;
            break;
        default:
            dialogTitle = (props.file ? langStrings.fileAnalyze : langStrings.textAnalyze);
    }
    if (!employee) return null
    function requestAnalysation()
    {
        clearData()
        WebSocketClient.sendMessage(topic, {
            text, request: {
                description: true,
                roles: readRoles,
                skills: true,
                certificates: true,
            }
        });
        setDialogStep(ECvDialogPage.DESCRIPTION);
    }

    function requestNewSuggestions(): void
    {
        WebSocketClient.sendMessage(topic, {
            text, request: {
                description: dialogStep === ECvDialogPage.DESCRIPTION,
                roles: dialogStep === ECvDialogPage.ROLE,
                skills: dialogStep === ECvDialogPage.SKILL,
                certificates: dialogStep === ECvDialogPage.CERTIFICATE,
            }
        });
        if (dialogStep === ECvDialogPage.DESCRIPTION)
        {
            setDescription(null);
        } else if (dialogStep === ECvDialogPage.ROLE)
        {
            setRoles(null);
        }
        else if (dialogStep === ECvDialogPage.SKILL)
        {
            setSkills(null);
        }
        else if (dialogStep === ECvDialogPage.CERTIFICATE)
        {
            setCertificates(null);
        }
    }

    //Is there data that can be refreshed
    let canRefresh = false;
    if (dialogStep === ECvDialogPage.DESCRIPTION && description)
    {
        canRefresh = true;
    } else if (dialogStep === ECvDialogPage.ROLE && roles)
    {
        canRefresh = true;
    }
    else if (dialogStep === ECvDialogPage.SKILL && skills)
    {
        canRefresh = true;
    }
    else if (dialogStep === ECvDialogPage.CERTIFICATE && certificates)
    {
        canRefresh = true;
    }

    return (<Dialog
        id={props.id}
        className='analyseCvDialog'
        title={dialogTitle}
        onClose={closeDialog}
        footer={
            <React.Fragment>
                {dialogStep === ECvDialogPage.AI_CONFIRMATION && <div style={{ float: 'right' }}>
                    {loadingText === "" &&
                        <Button
                            icon={<Done />}
                            disabled={!aiConfirmation}
                            text={langStrings.approve}
                            size={'normal'}
                            onClick={requestAnalysation}
                        />
                    }
                </div>}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 5,
                        flexWrap: 'wrap',
                    }}
                >
                    {(dialogStep > ECvDialogPage.AI_CONFIRMATION && loadingText === "") &&
                        <Button
                            icon={<NavigateBefore />}
                            disabled={loadingText !== ""}
                            text={langStrings.back}
                            size='normal'
                            onClick={() =>
                            {
                                let lastStep = dialogStep - 1;
                                if (lastStep === ECvDialogPage.ROLE && !readRoles)
                                {
                                    lastStep = lastStep - 1
                                }
                                setDialogStep(lastStep)
                            }}
                        />
                    }
                    {dialogStep > ECvDialogPage.AI_CONFIRMATION &&
                        <Button
                            icon={<Refresh />}
                            text={langStrings.requestSuggestions}
                            size='normal'
                            disabled={!canRefresh}
                            onClick={requestNewSuggestions}
                        />
                    }
                    {(dialogStep > ECvDialogPage.AI_CONFIRMATION && loadingText === "") &&
                        <Button
                            icon={<NavigateNext />}
                            disabled={loadingText !== ""}
                            text={btnNextText}
                            size={'normal'}
                            onClick={async function (): Promise<void>
                            {
                                let nextStep = dialogStep + 1;
                                if (nextStep === ECvDialogPage.ROLE && !readRoles)
                                {
                                    nextStep = nextStep + 1
                                }
                                if (dialogStep < ECvDialogPage.SKILL)
                                {
                                    setDialogStep(nextStep);
                                }
                                else 
                                {
                                    closeDialog();
                                }
                            }}
                        />
                    }
                </div>
            </React.Fragment>
        }
    >
        <div style={{ minHeight: 'min(500px, 50vh)', minWidth: 'min(800px, 75vw)' }}>
            <Stepper activeStep={dialogStep} alternativeLabel >
                <Step
                    key={ECvDialogPage.AI_CONFIRMATION}
                    onClick={() => { setDialogStep(ECvDialogPage.AI_CONFIRMATION) }}
                >
                    <StepLabel>
                        {langStrings.approve}
                    </StepLabel>
                </Step>
                <Step
                    key={ECvDialogPage.DESCRIPTION}
                    onClick={() => { aiConfirmation && setDialogStep(ECvDialogPage.DESCRIPTION) }}
                >
                    <StepLabel
                        icon={(dialogStep !== ECvDialogPage.AI_CONFIRMATION && !description) ? <CircularProgress size={24} /> : undefined}
                    >
                        {langStrings.profileDescription}
                    </StepLabel>
                </Step>
                {readRoles &&
                    <Step
                        key={ECvDialogPage.ROLE}
                        onClick={() => { aiConfirmation && setDialogStep(ECvDialogPage.ROLE) }}
                    >
                        <StepLabel
                            icon={(dialogStep !== ECvDialogPage.AI_CONFIRMATION && !roles) ? <CircularProgress size={24} /> : undefined}
                        >
                            {langStrings.roles}
                        </StepLabel>
                    </Step>
                }
                <Step
                    key={ECvDialogPage.CERTIFICATE}
                    onClick={() => { aiConfirmation && setDialogStep(ECvDialogPage.CERTIFICATE) }}
                >
                    <StepLabel
                        icon={(dialogStep !== ECvDialogPage.AI_CONFIRMATION && !certificates) ? <CircularProgress size={24} /> : undefined}
                    >
                        {langStrings.certificates}
                    </StepLabel>
                </Step>
                <Step
                    key={ECvDialogPage.SKILL}
                    onClick={() => { aiConfirmation && setDialogStep(ECvDialogPage.SKILL) }}
                >
                    <StepLabel
                        icon={(dialogStep !== ECvDialogPage.AI_CONFIRMATION && !skills) ? <CircularProgress size={24} /> : undefined}
                    >
                        {langStrings.skills}
                    </StepLabel>
                </Step>
            </Stepper>
            <div style={{ padding: 15 }}>
                {loadingError !== "" &&
                    <ErrorBox >
                        <Typography variant='body1'>
                            {/* {loadingError} */}
                            {langStrings.errorAi}
                            <Button
                                text={langStrings.retry}
                                style={{
                                    maxWidth: 200,
                                }}
                                onClick={() =>
                                {
                                    setDialogStep(ECvDialogPage.AI_CONFIRMATION);
                                    clearData();
                                }}
                            />
                        </Typography>
                    </ErrorBox>
                }
                {loadingText !== "" &&
                    <LoadingBox text={loadingText} />
                }

                {(loadingError === "" && loadingText === "") &&
                    <>
                        {dialogStep === ECvDialogPage.AI_CONFIRMATION &&
                            <EditAndConfirm
                                file={props.file !== undefined}
                                aiConfirmation={aiConfirmation}
                                setAiConfirmation={setAiConfirmation}
                                text={text}
                                setText={(text) =>
                                {
                                    setText(text);
                                }}
                            />
                        }
                        {(dialogStep === ECvDialogPage.DESCRIPTION) && (
                            description ?
                                <UpdateSummary
                                    employee={employee}
                                    description={description}
                                    setDescription={setDescription}
                                    setLoadingError={setLoadingError}
                                    setDialogStep={setDialogStep}
                                    dialogStep={dialogStep}
                                />
                                : <LoadingBox text={langStrings.waitForAi} />
                        )
                        }
                        {(dialogStep === ECvDialogPage.ROLE) && (
                            roles ?
                                <>
                                    <Typography variant='body1' paragraph>
                                        {langStrings.roleHelpText}
                                    </Typography>
                                    <EditRoleList
                                        mode='add'
                                        title={langStrings.suggestedRoles}
                                        // onlyNewSkills={true}
                                        roles={roles}
                                        allRoles={allRoles}
                                        onChange={setRolesChanged}
                                    />
                                </>
                                : <LoadingBox text={langStrings.waitForAi} />
                        )
                        }
                        {(dialogStep === ECvDialogPage.CERTIFICATE) && (
                            certificates ?
                                <>
                                    <Typography variant='body1' paragraph>
                                        {langStrings.certificateHelpText}
                                    </Typography>
                                    <EditCertificateList
                                        mode='add'
                                        title={langStrings.suggestedCertificates}
                                        // onlyNewSkills={true}
                                        certificates={certificates}
                                        allCertificates={allCertificates}
                                        onChange={setCertificatesChanged}
                                    />
                                </>
                                : <LoadingBox text={langStrings.waitForAi} />
                        )
                        }
                        {dialogStep === ECvDialogPage.SKILL && (
                            skills ?
                                <>
                                    <Typography variant='body1' paragraph>
                                        {langStrings.skillHelpText}
                                    </Typography>
                                    <EditSkillList
                                        title={langStrings.suggestedSkills}
                                        mode='add'
                                        skills={skills as ISkill[]}
                                        allSkills={allSkills}
                                        onChange={setSkillsChanged}
                                    />
                                </>
                                : <LoadingBox text={langStrings.waitForAi} />
                        )
                        }
                    </>
                }
            </div>
        </div>
    </Dialog>
    );
}
export default AnalyseCvDialog;

/**
 * Updates the summary.
 *
 * @param employee - The employee state.
 * @param description - The description.
 * @param setDescription - The state setter for the description.
 * @param setLoadingError - The state setter for the loading text.
 * @param setDialogStep - The state setter for the dialog step.
 * @param dialogStep - The dialog step.
 */
function UpdateSummary({ employee, description, setDescription, setLoadingError, setDialogStep, dialogStep }
    :
    {
        employee: IEmployeeDTO;
        description: string;
        setDescription: (description: string) => void;
        setLoadingError: React.Dispatch<React.SetStateAction<string>>;
        setDialogStep: React.Dispatch<React.SetStateAction<number>>;
        dialogStep: number;
    }
)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).analyseDialog;

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
        <RowElement title={langStrings.actualProfileDescription} alignTitle='left'>
            <FormatedTextInput value={employee.description} />
        </RowElement>
        <RowElement title={langStrings.newProfileDescription} alignTitle='left'>

            <FormatedTextInput value={description} onChange={function (val: string): void
            {
                setDescription(val);
            }} />
        </RowElement>
        <div style={{ marginTop: 10 }}>
            <Button size={'normal'}
                text={langStrings.updateEmployeeDescription}
                icon={<Done />}
                onClick={async function (): Promise<void>
                {
                    await updateMyDescription(description)
                        .then((result) =>
                        {
                            if (!result)
                            {
                                return;
                            }
                            setLoadingError("");
                            setDialogStep(dialogStep + 1);
                            dispatch(setEmployeeDescription(description));
                            // if (result.certificates.length === 0) setSaveStep(2);
                        })
                        .catch(ex =>
                        {
                            setLoadingError(langStrings.error + ' ' + ex);
                        });
                }}
                disabled={description === employee.description} />
        </div>
    </div>;
}


/**
 * EditAndConfirm function.
 * 
 * @param {boolean} file - The file parameter.
 * @param {boolean} aiConfirmation - The aiConfirmation parameter.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setAiConfirmation - The setAiConfirmation parameter.
 * @param {string} text - The text parameter.
 * @param {React.Dispatch<React.SetStateAction<string>>} setText - The setText parameter.
 */
function EditAndConfirm({ file, aiConfirmation, setAiConfirmation, text, setText }:
    {
        file: boolean;
        aiConfirmation: boolean;
        setAiConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
        text: string;
        setText: React.Dispatch<React.SetStateAction<string>>;
    })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).analyseDialog;

    return <div>
        {file ?
            <Typography variant='body1' paragraph>
                {langStrings.explainationFile}
            </Typography>
            :
            <Typography variant='body1' paragraph >
                {langStrings.explainationText}
            </Typography>
        }
        <AiConfirmation
            confirmed={aiConfirmation}
            setConfirmed={setAiConfirmation}
            originalText={text}
            // originalText={htmlText}
            setText={setText} />
    </div>;
}

/**
 * Function to upload a file.
 * 
 * @param {Object} post - The post object with id, url, and fields.
 * @param {File} file - The file to be uploaded.
 * 
 * @returns {Promise<void>} - A promise that resolves when the file is uploaded successfully.
 */
export function uploadFile(post: {
    id: string;
    url: string;
    fields: {
        [key: string]: string;
    }
}, file: File)
{
    return new Promise<void>((resolve, reject) =>
    {
        const formData = new FormData();
        Object.entries(post.fields).forEach(([key, value]) =>
        {
            formData.append(key, value);
        });
        formData.append("file", file);

        fetch(post.url, {
            method: 'POST',
            body: formData,
        }).then(response =>
        {
            if (response.ok)
            {
                resolve();
            } else
            {
                reject(new Error(`Upload failed with status ${response.status}`));
            }
        }).catch(error =>
        {
            reject(new Error(`Upload failed: ${error.message}`));
        });
    })
}