import { OperationVariables, gql } from "@apollo/client";
import { IEmployeeCoverage, MaybeSkill } from "@sharedInterfaces/IOpportunity";
import { IReference } from "@sharedInterfaces/IReference";

import GraphQL from "../graphQL";

import { employeeCoverageGql } from "./getOpportunity";


const query = gql`
query($skills:[OpportunitySkillInput!]!, $certificates:[OpportunityCertificateInput!]!) {
    getSuggestedEmployees(skills: $skills, certificates: $certificates) 
    ${employeeCoverageGql}
},
`;

/**
 * Retrieves a list of suggested employees based on the provided skills.
 * 
 * @param {Array<object>} skills - Array of skills used for suggesting employees.
 * @returns {Promise<Array<object>>} - A promise that resolves to an array of suggested employees with their corresponding skill coverage.
 */
export default async function getSuggestedEmployees(skills: MaybeSkill[], certificates: IReference[]): Promise<IEmployeeCoverage[]>
{
    const variables: OperationVariables = {
        skills,
        certificates
    };
    return GraphQL.query<{ getSuggestedEmployees: IEmployeeCoverage[] }>(query, variables)
        .then(result => result.getSuggestedEmployees)
}