import React, { useState } from 'react';
import './SkillsInputWithoutLevel.css'
import { ISmallSkill } from "@sharedInterfaces/IWhoIAm";
import SkillItem from "@src/App/NewLayout/Items/SkillItem/SkillItem";

import SkillSearchBox from "../../searchBoxes/SkillSearchBox/SkillSearchBox";
interface SkillsInputWithoutLevelProps
{
    allSkills: ISmallSkill[]
    skills: { title: string, id: number }[]
    showMode: boolean
    size: 'medium' | 'small'
    allowNewSkills?: true
    onChange?(val: { title: string, id: number }[]): void
}
/**
 * Renders a skills input component without levels.
 * 
 * @param {SkillsInputWithoutLevelProps} props - The props for the component.
 * @returns {JSX.Element}
 */
function SkillsInputWithoutLevel(props: SkillsInputWithoutLevelProps)
{

    const [newSkill, setNewSkill] = useState<{ title: string, level: number, }>({ title: '', level: 1 });
    const sortedSkills = [...props.skills].sort((a, b) =>
    {
        return a.title.localeCompare(b.title);
    });
    const filteredAllSkills = props.allSkills.filter(a =>
        props.skills.find(s => s.id === a.id || s.title === a.title) === undefined)

    const skills = sortedSkills.map((skill, index) =>
    {
        const withoutLink = !props.showMode || skill.id === undefined;
        const skillDOM = <SkillItem
            id={props.showMode ? skill.id : undefined}
            key={index}
            newPrefix={!skill.id ? true : undefined}
            title={skill.title}
            onDelete={
                props.showMode ? undefined :
                    () =>
                    {
                        const newSkills = props.skills.slice()
                            .filter(s => s.id !== skill.id);
                        props.onChange && props.onChange(newSkills);
                    }
            }
        />
        if (withoutLink) return skillDOM;
        return skillDOM;
        // return <NavLink key={skill.id} to={entityTypeToLink(skill.id, EEntityType.SKILL)}
        // >
        //     {skillDOM}
        // </NavLink>
    }
    );

    return (
        <div className={'skillsInputWithoutLevel ' + (props.size)} style={{ border: props.showMode ? 'none' : '' }} >
            {skills}
            {
                !props.showMode &&
                <SkillSearchBox
                    filteredAllSkills={filteredAllSkills}
                    newSkill={{ ...newSkill }}
                    size="small"
                    setNewSkill={(skill) =>
                    {
                        if (!skill.title || !skill.id) return;
                        const newSkills = props.skills.slice();
                        newSkills.push({ id: skill.id as number, title: skill.title });
                        props.onChange && props.onChange(newSkills);
                        setNewSkill({ title: ' ', level: Math.random() });
                        setTimeout(() =>
                        {
                            setNewSkill({ title: '', level: Math.random() });
                            const sug = document.querySelector('.searchSkillBoxInput input') as HTMLElement;
                            if (sug) sug.focus();
                        }, 100)
                    }} />
            }
        </div>
    );
}
export default SkillsInputWithoutLevel;