import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Menu, ListItemButton } from '@mui/material';
import { Entity } from '@sharedReact/Objects/Entity';

import { EViewType } from './EntitiesPage';

export interface IViewType<T extends Entity>
{
    id: EViewType,
    title: string,
    icon: JSX.Element
    minWidth?: number
    renderContainer: (allEntities: T[], visibleEntities: T[], width: number, selected: number[]) => JSX.Element;
    // renderEntity: <T extends Entity>(entity: T) => JSX.Element;
}
interface ViewTypeMenuProps<T extends Entity>
{
    types: IViewType<T>[];
    selected: IViewType<T>;
    onChange: (type: IViewType<T>) => void
}
export function ViewTypeMenu<T extends Entity>(props: ViewTypeMenuProps<T>)
{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const types = props.types;


    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (
        _: React.MouseEvent<HTMLElement>,
        index: number
    ) =>
    {
        props.onChange(types[index])
        setAnchorEl(null);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };
    if (!types.length) return <></>;
    const selectedType = props.selected;

    return (
        <div>
            <ListItemButton
                dense
                id="type-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label={selectedType.title}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
            >

                <ListItemText
                    primary={selectedType.title} />
                <ListItemIcon>
                    <ExpandMore fontSize='small' />
                </ListItemIcon>
            </ListItemButton>
            <Menu
                id="type-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'type-button',
                    role: 'listbox',
                }}
            >
                {types.map((type, index) => (
                    <MenuItem
                        key={type.id}
                        dense
                        selected={type.id === selectedType.id}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        <ListItemIcon>
                            {type.icon}
                        </ListItemIcon>
                        {type.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
