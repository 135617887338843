import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Done } from '@mui/icons-material';
import { ELanguage, getLanguageOfCountry, getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyLocalisation } from '@sharedInterfaces/ICompanySettings';
import { ICountriesTexts } from '@sharedInterfaces/Language/languageTexts/countries';
import { subNationLabels } from '@sharedInterfaces/Language/languageTexts/subNationLabel';
import { IIndustriesTexts } from '@sharedInterfaces/Language/languageTexts/industriesTexts';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import updateGeneralCompanySettings from '@src/APIs/graphQl/Company/updateGeneralCompanySettings';

import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import LanguageSelect from '../../Components/formsControls/inputs/LanguageSelect/LanguageSelect';
import SubCountrySelect from '../../Components/formsControls/inputs/SubCountrySelect/SubCountrySelect';
import CountrySelect from '../../Components/formsControls/inputs/CountrySelect/CountrySelect';
import IndustrySelect from '../../Components/formsControls/inputs/IndustrySelect/IndustrySelect';
import Button from '../../sharedReact/General/Button/Button';
import ErrorBox from '../../Components/ErrorBox/ErrorBox';

interface GeneralTabProps
{
    company: Company;
    setCompany: (company: Company) => void;
}
/**
 * GeneralTab component.
 *
 * @param {Object} company - The company object.
 * @param {Function} setCompany - The function to set the company object.
 * @returns {JSX.Element} The rendered GeneralTab component.
 */
export function GeneralTab({ company, setCompany }: GeneralTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [localisation, setLocalisation] = useState<ICompanyLocalisation>(
        { language: '' as ELanguage, country: '' as keyof ICountriesTexts, subCountry: '' });
    const [industry, setIndustry] = useState<keyof IIndustriesTexts>('' as keyof IIndustriesTexts);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() =>
    {
        if (company.localisation)
        {
            setLocalisation({ ...company.localisation });
        }
        if (company.industry)
        {
            setIndustry(company.industry);

        }
    }, [company])
    useEffect(() =>
    {
        if (localisation && localisation.country && !localisation.language)
        {
            setLocalisation({ ...localisation, language: getLanguageOfCountry(localisation.country) });
        }
    }, [localisation.country]);

    return (
        <div className='generalTab'>
            <RowElement title={langStrings.country}>
                <CountrySelect country={localisation.country} setCountry={(value) =>
                {
                    setLocalisation({ ...localisation, country: value });
                    setChanged(true);
                }} />
            </RowElement>
            {localisation.country &&
                <>
                    <RowElement title={langStrings.language}>
                        <LanguageSelect language={localisation.language} setLanguage={(value) =>
                        {
                            setLocalisation({ ...localisation, language: value });
                            setChanged(true);
                        }} />
                    </RowElement>

                    <RowElement title={subNationLabels[lang][localisation.country]}>
                        <SubCountrySelect country={localisation.country} subCountry={localisation.subCountry} setInnerCountry={(value) =>
                        {
                            setLocalisation({ ...localisation, subCountry: value });
                            setChanged(true);
                        }} />
                    </RowElement>

                    <RowElement title={langStrings.industry}>
                        <IndustrySelect industry={industry} setIndustry={(value) =>
                        {
                            setIndustry(value);
                            setChanged(true);
                        }} />
                    </RowElement>
                </>
            }
            {saveError && <ErrorBox close={setSaveError.bind(null, null)}>
                {saveError}
            </ErrorBox>}
            {changed &&
                <Button
                    disabled={saving || !localisation.country || !localisation.language || !localisation.subCountry || !industry}
                    size={'normal'} text={langStrings.save} icon={<Done />} onClick={function (): void
                    {
                        setSaving(true);
                        updateGeneralCompanySettings(localisation, industry)
                            .then(setCompany).then(setChanged.bind(null, false))
                            .catch(err => setSaveError(err.toString()))
                            .finally(() => setSaving(false));
                    }} />
            }
        </div>
    );
}
