import React, { useEffect, useState } from 'react';
import { DirectionsRun, Done, PanTool, PriorityHigh, Sync } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Checkbox, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import setClockodoSync from '@src/APIs/graphQl/Company/setClockodoSync';
import { EClockodoStatus, EClockodoSyncType, ICompanyClockodo } from '@sharedInterfaces/ICompanySettings';
import startClockodoSync from '@src/APIs/graphQl/Company/startClockodoSync';

import TextInput from '../../Components/formsControls/inputs/TextInput/TextInput';
import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../Components/ErrorBox/ErrorBox';
import Button from '../../sharedReact/General/Button/Button';

interface ClockodoSyncTabProps
{
    clockodoSync: { sync: boolean; email: string; };
    clockodo: ICompanyClockodo
    setCompany: (company: Company) => void;
    reload: () => void;
}
/**
 * Component for the Synchronization Tab.
 *
 * @param {ClockodoSyncTabProps} clockodoSync - The clockodoSync parameter.
 * @param {function} setCompany - The setCompany parameter.
 * @returns {JSX.Element} The JSX element.
 */
export function ClockodoSyncTab({ clockodoSync, clockodo, setCompany, reload }: ClockodoSyncTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [saving, setSaving] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [clockodoSyncState, setClockodoSyncState] = useState<{ sync: boolean; email: string; apiKey: string; }>({
        sync: clockodoSync.sync,
        email: clockodoSync.email,
        apiKey: ''
    });
    const [changed, setChanged] = useState<boolean>(false);
    const [status, setStatus] = useState<EClockodoStatus>(clockodo.status);

    useEffect(() =>
    {
        setStatus(clockodo.status);
    }
        , [clockodo.status]
    )

    useEffect(
        () =>
        {
            let timer1: NodeJS.Timeout | null = null;
            // if (clockodo.status === EClockodoStatus.SYNCING)
            // {
            timer1 = setInterval(reload, 30 * 1000);
            // } else
            return () =>
            {
                timer1 && clearInterval(timer1);
            };
        },
        [clockodo.status]
    );
    useEffect(() =>
    {
        setClockodoSyncState({
            sync: clockodoSync.sync,
            email: clockodoSync.email,
            apiKey: ''
        });
    }, [clockodoSync.sync]);

    return (
        <div>
            <RowElement alignTitle='left' title={langStrings.clockodoSync}>
                <Checkbox checked={clockodoSyncState.sync} onChange={((event) =>
                {
                    setChanged(true);
                    const checked = event.currentTarget.checked;
                    if (checked)
                    {
                        setClockodoSyncState({ ...clockodoSyncState, sync: true });
                    }
                    else
                    {
                        setClockodoSyncState({ email: clockodoSyncState.email, apiKey: '', sync: checked });
                    }
                })} />
            </RowElement>
            {clockodoSyncState.sync &&
                <>
                    <RowElement alignTitle='left' title={langStrings.clockodoUserEmail}>
                        <TextInput value={clockodoSyncState.email} size='small' onChange={(text) =>
                        {
                            setChanged(true);
                            setClockodoSyncState({
                                ...clockodoSyncState,
                                email: text,
                            });
                        }} />
                    </RowElement>
                    <RowElement alignTitle='left' title={langStrings.clockodoUserApiKey}>
                        <TextInput
                            value={clockodoSyncState.apiKey}
                            password={true}
                            size='small'
                            onChange={(text) =>
                            {
                                setChanged(true);
                                setClockodoSyncState({
                                    ...clockodoSyncState,
                                    apiKey: text,
                                });
                            }} />
                    </RowElement>
                </>}
            {saveError && <ErrorBox close={setSaveError.bind(null, null)}>
                {saveError}
            </ErrorBox>}
            {changed &&
                <Button
                    disabled={saving ||
                        (
                            clockodoSyncState.sync &&
                            (
                                clockodoSyncState.email.indexOf('@') === -1 ||
                                clockodoSyncState.email.lastIndexOf('.') < clockodoSyncState.email.lastIndexOf('@') ||
                                clockodoSyncState.apiKey === ''
                            )
                        )}
                    size={'normal'} text={langStrings.save} icon={<Done />} onClick={function (): void
                    {
                        setSaving(true);
                        setClockodoSync(clockodoSyncState)
                            .then(setCompany).then(() => setChanged(false))
                            .catch(err => setSaveError(err.toString()))
                            .finally(() =>
                            {
                                setSaving(false);
                                setTimeout(reload, 2000);
                            });
                    }} />
            }

            <div>
                <Typography variant='h5'>
                    {langStrings.status}
                </Typography>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {langStrings.status}:
                                </Typography>
                            </td>
                            <td>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {status === EClockodoStatus.SYNCING &&
                                        <DirectionsRun />
                                    }
                                    {status === EClockodoStatus.SYNCED &&
                                        <Done />
                                    }
                                    {status === EClockodoStatus.ERROR &&
                                        <PriorityHigh />
                                    }
                                    {(status === EClockodoStatus.IDLE || !clockodoSync.sync) &&
                                        <PanTool />
                                    }

                                    <Typography variant='body2' fontWeight={'bold'}>
                                        {clockodoSync.sync ? status : 'Stopped'}
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant='body2'>
                                    {langStrings.lastUpdateCustomer}:
                                </Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>
                                    {clockodo.lastUpdateCustomer ?
                                        new Date(clockodo.lastUpdateCustomer).toLocaleString()
                                        : langStrings.oftenUsed.never}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant='body2'>
                                    {langStrings.lastUpdateProjects}:
                                </Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>
                                    {clockodo.lastUpdateProjects ?
                                        new Date(clockodo.lastUpdateProjects).toLocaleString()
                                        : langStrings.oftenUsed.never}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant='body2'>
                                    {langStrings.lastUpdateEmployeeAbsence}:
                                </Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>
                                    {clockodo.lastUpdateEmployeeAbsence ?
                                        new Date(clockodo.lastUpdateEmployeeAbsence).toLocaleString()
                                        : langStrings.oftenUsed.never}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant='body2'>
                                    {langStrings.lastUpdateEmployeeTimesThisYear}:
                                </Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>
                                    {clockodo.lastUpdateEmployeeTimesThisYear ?
                                        new Date(clockodo.lastUpdateEmployeeTimesThisYear).toLocaleString()
                                        : langStrings.oftenUsed.never}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant='body2'>
                                    {langStrings.lastUpdateEmployeeTimesLastYear}:
                                </Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>
                                    {clockodo.lastUpdateEmployeeTimesLastYear ?
                                        new Date(clockodo.lastUpdateEmployeeTimesLastYear).toLocaleString()
                                        : langStrings.oftenUsed.never}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button
                    text={langStrings.syncNow}
                    style={{ maxWidth: 250, marginTop: 10 }}
                    icon={<Sync />}
                    disabled={status === EClockodoStatus.SYNCING || !clockodoSync.sync}
                    onClick={function (): void
                    {
                        startClockodoSync(EClockodoSyncType.FULL)
                            .then(() => setStatus(EClockodoStatus.SYNCING))
                            .catch(() => setStatus(EClockodoStatus.ERROR))
                            .finally(() =>
                            {
                                setTimeout(reload, 2000);
                                setTimeout(reload, 5000);
                                setTimeout(reload, 10000);
                                setTimeout(reload, 15000);
                            });
                    }} />
            </div>
        </div>
    );
}
