import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../../graphQL";

const query = gql`
mutation deleteWantedCompetence($id:Int!) {
    deleteWantedCompetence(id: $id)
},
`;

/**
 * Marks the first experience as read.
 * 
 * @returns {Promise<boolean>}
 */
export default async function deleteWantedCompetence(id: number)
{
    const variables: OperationVariables = {
        id,
    };
    return GraphQL.mutate<boolean>(query, variables);
}