import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import { EGender } from '@sharedInterfaces/IEmployee';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

interface GenderSelectProps
{
    value: EGender;
    onChange: (val: EGender) => void;
}

const GenderSelect: React.FC<GenderSelectProps> = ({ value, onChange }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).userDialog;

    return (
        <FormControl component="fieldset">
            <RadioGroup
                row
                aria-label="gender"
                name="gender"
                value={value}
                onChange={(e) => onChange(e.target.value as EGender)}
            >
                <FormControlLabel
                    value={EGender.FEMALE}
                    control={<Radio />}
                    label={langStrings.female}
                />
                <FormControlLabel
                    value={EGender.MALE}
                    control={<Radio />}
                    label={langStrings.male}
                />
                <FormControlLabel
                    value={EGender.OTHER}
                    control={<Radio />}
                    label={langStrings.other}
                />
            </RadioGroup>
        </FormControl>
    );
}

export default GenderSelect;