import { OperationVariables, gql } from "@apollo/client";
import IProjectDTO, { ICreateProjectDTO } from "@sharedInterfaces/IProject";
import { projectGQL } from "@src/Objects/Project";

import GraphQL from "../graphQL";

const query = gql`
mutation createProject($obj: CreateProject!) {
    createProject(obj: $obj) 
    ${projectGQL}
},
`;

export default async function createProject(project: ICreateProjectDTO)
{
    project.employees = project.employees.map((c) => ({
        id: c.id,
        title: c.title
    }))
    project.business = {
        id: project.business.id,
        title: project.business.title
    }
    project.startDate = project.startDate ? project.startDate : null;
    project.endDate = project.endDate ? project.endDate : null;
    const variables: OperationVariables = {
        obj: project
    };

    return GraphQL.mutate<IProjectDTO>(query, variables)
}

//, (obj: any) => callback(new Project(companyId, obj.createProject as IProject))