import { useSelector } from 'react-redux';
import React from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';
import RoleItem from '@src/App/NewLayout/Items/RoleItem/RoleItem';
interface RolesCardProps
{
    title?: string;
    roles: {
        title: string
        id: number
        lockedBy?: string
    }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * RolesCard component.
 * 
 * @param {RolesCardProps} title - The title of the box.
 * @param {RolesCardProps} roles - The roles to display.
 * @param {RolesCardProps} inOverlay - Flag indicating if the box is in overlay mode.
 * @returns {JSX.Element} The RolesCard as a JSX element.
 */
function RolesCard({ limit, title, roles, inOverlay, }: RolesCardProps)
{
    if (!roles) roles = [];
    roles = [...roles].sort((a, b) =>
    {
        return a.title.localeCompare(b.title);
    })
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const newTitle = generateTopXText(title ? title : langStrings.roles, limit)
        ;
    const limitedElements = limit ? roles
        .slice(0, limit > roles.length ? roles.length : limit) : roles;
    const DOM = limitedElements.map((role) =>
    {
        return <RoleItem
            key={role.id}
            id={role.id}
            title={role.title}
            lockedBy={role.lockedBy}
            disableOverlay={inOverlay}
            onClick={entityTypeToLink(role.id, EEntityType.ROLE)}
        />
    });
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>

    );
}

export default RolesCard;
