import React, { useState } from 'react';
import './MultiTextChipSelect.css';
import { Chip } from '@mui/material';
import { Add, Done } from '@mui/icons-material';
import RoundIconButton from '@src/Components/Buttons/RoundIconButton/RoundIconButton';

import TextInput from '../TextInput/TextInput';

interface MultiTextChipSelectProps
{
    value: string[]
    placeholder?: string
    onChange: (val: string[]) => void
}

/**
 * Component that represents a MultiTextChipSelect.
 * 
 * @param {MultiTextChipSelectProps} props - The props for the MultiTextChipSelect.
 * @returns {JSX.Element} The rendered MultiTextChipSelect component.
 */
function MultiTextChipSelect(props: MultiTextChipSelectProps)
{
    // const lang = useSelector((state: AppState)=> state.employee.language);
    // const langStrings = getLanguageTexts(lang);

    const [adding, setAdding] = useState<string | null>(null);
    const value = props.value
    return (
        <div className='MultiTextChipSelect'
        >
            {
                value.map((data) =>
                {
                    return (
                        <Chip
                            className='chip'
                            key={data}
                            color={'primary'}
                            label={data}
                            onDelete={() =>
                            {
                                props.onChange(value.filter(d => d !== data))
                            }}
                        />
                    );
                })
            }
            {adding !== null && <Chip
                style={{ width: 'calc(100% - 90px)' }}
                className='chip'
                label={<TextInput placeholder={props.placeholder} size='verysmall' value={adding} onChange={(value) => { setAdding(value) }} />}
                variant="outlined"
                onDelete={() =>
                {
                    setAdding(null);
                }}
            />
            }
            <RoundIconButton icon={adding !== null ? <Done /> : <Add />} onClick={() =>
            {
                if (adding === null)
                {
                    setAdding("");
                } else
                {
                    const newArray = [...value, adding]
                    props.onChange(newArray);
                    setAdding(null);
                }
            }} helperText={"Add"} size={'small'} />
        </div>
    );
}

export default MultiTextChipSelect;


