import { TEntityVersions } from "@sharedInterfaces/ICompany";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import { ISmallCertificate, ISmallCompetence, ISmallSkill, ISmallEmployee, ISmallRole } from "@sharedInterfaces/IWhoIAm";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import { SkillCategory } from "@src/Objects/SkillCategory";

export interface CompanyState
{
    companyId: string
    name: string
    style: IStyleDTO
    allCompetences: ISmallCompetence[];
    allSkills: ISmallSkill[];
    allCertificates: ISmallCertificate[];
    skillCategories: SkillCategory[]
    allEmployees: ISmallEmployee[]
    smallRoles: ISmallRole[]
    versions: TEntityVersions
}


export const initialCompanyState: CompanyState = {
    companyId: '',
    name: '',
    allCompetences: [],
    allSkills: [],
    allCertificates: [],
    smallRoles: [],
    skillCategories: [],
    allEmployees: [],
    style: {
        primary: '#0190ce',
        secondary: '#004766',
        onPrimary: '#ffffff',
        onSecondary: '#ffffff',
    },
    versions: {
        smallSkill: 0,
        smallCompetence: 0,
        smallCertificate: 0,
        smallEmployee: 0,
        smallRole: 0,
        skillcategory: 0,
        aiRequest: 0,
        product: 0,
        availmonth: 0,
        employee: 0,
        skill: 0,
        certificate: 0,
        project: 0,
        absence: 0,
        business: 0,
        empsettings: 0,
        empmonthproject: 0,
        opportunity: 0,
        competence: 0,
        role: 0,
        orgunit: 0,
    }
};


export enum ESetCompanyAction
{
    SET_COMPANY_ID = 'SET_COMPANY_ID',
    SET_COMPANY_NAME = 'SET_COMPANY_NAME',
    SET_COMPANY_ALL_SKILLS = 'SET_COMPANY_ALL_SKILLS',
    SET_COMPANY_ALL_COMPETENCES = 'SET_COMPANY_ALL_COMPETENCES',
    SET_COMPANY_ALL_CERTIFICATES = 'SET_COMPANY_ALL_CERTIFICATES',
    SET_COMPANY_SMALL_ROLES = 'SET_COMPANY_SMALL_ROLES',
    SET_COMPANY_SKILL_CATEGORIES = 'SET_COMPANY_SKILL_CATEGORIES',
    SET_COMPANY_ALL_EMPLOYEES = 'SET_COMPANY_ALL_EMPLOYEES',
    SET_COMPANY_STYLE = "SET_COMPANY_STYLE",
    SET_COMPANY_VERSIONS = 'SET_COMPANY_VERSIONS',
    SET_COMPANY_VERSION = 'SET_COMPANY_VERSION',
}


// Action interface for setting the company ID
interface SetCompanyIdAction
{
    type: ESetCompanyAction.SET_COMPANY_ID;
    payload: string;
}

// Action interface for setting the company name
interface SetCompanyNameAction
{
    type: ESetCompanyAction.SET_COMPANY_NAME;
    payload: string;
}

// Action interface for setting all skills
interface SetCompanyAllSkillsAction
{
    type: ESetCompanyAction.SET_COMPANY_ALL_SKILLS;
    payload: ISmallSkill[];
}

// Action interface for setting all competences
interface SetCompanyAllCompetencesAction
{
    type: ESetCompanyAction.SET_COMPANY_ALL_COMPETENCES;
    payload: ISmallCompetence[];
}

// Action interface for setting all certificates
interface SetCompanyAllCertificatesAction
{
    type: ESetCompanyAction.SET_COMPANY_ALL_CERTIFICATES;
    payload: ISmallCertificate[];
}

// Action interface for setting all roles
interface SetCompanySmallRolesAction
{
    type: ESetCompanyAction.SET_COMPANY_SMALL_ROLES;
    payload: ISmallRole[];
}

// Action interface for setting skill categories
interface SetCompanySkillCategoriesAction
{
    type: ESetCompanyAction.SET_COMPANY_SKILL_CATEGORIES;
    payload: SkillCategory[];
}

// Action interface for setting all employees
interface SetCompanyAllEmployeesAction
{
    type: ESetCompanyAction.SET_COMPANY_ALL_EMPLOYEES;
    payload: ISmallEmployee[];
}
// Action interface for setting style
interface SetCompanyStyleAction
{
    type: ESetCompanyAction.SET_COMPANY_STYLE;
    payload: IStyleDTO;
}
// Action interface for setting versions
interface SetCompanyVersionsAction
{
    type: ESetCompanyAction.SET_COMPANY_VERSIONS;
    payload: TEntityVersions;
}
interface SetCompanyVersionAction
{
    type: ESetCompanyAction.SET_COMPANY_VERSION;
    payload: {
        version: number,
        type: EEntityType
    };
}
// Union of all action interfaces
type CompanyAction =
    SetCompanyIdAction
    | SetCompanyNameAction
    | SetCompanyAllSkillsAction
    | SetCompanyAllCompetencesAction
    | SetCompanyAllCertificatesAction
    | SetCompanySmallRolesAction
    | SetCompanySkillCategoriesAction
    | SetCompanyAllEmployeesAction
    | SetCompanyStyleAction
    | SetCompanyVersionsAction
    | SetCompanyVersionAction

export const companyReducer = (state = initialCompanyState, action: CompanyAction): CompanyState =>
{
    switch (action.type)
    {
        case ESetCompanyAction.SET_COMPANY_ID:
            return {
                ...state,
                companyId: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_NAME:
            return {
                ...state,
                name: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_ALL_SKILLS:
            return {
                ...state,
                allSkills: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_ALL_COMPETENCES:
            return {
                ...state,
                allCompetences: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_ALL_CERTIFICATES:
            return {
                ...state,
                allCertificates: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_SMALL_ROLES:
            return {
                ...state,
                smallRoles: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_SKILL_CATEGORIES:
            return {
                ...state,
                skillCategories: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_ALL_EMPLOYEES:
            action.payload.sort((a, b) => a.title.localeCompare(b.title));
            return {
                ...state,
                allEmployees: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_STYLE:
            return {
                ...state,
                style: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_VERSIONS:
            return {
                ...state,
                versions: {
                    ...initialCompanyState.versions,
                    ...state.versions,
                    ...action.payload,
                }
            }
        case ESetCompanyAction.SET_COMPANY_VERSION:
            return {
                ...state,
                versions: {
                    ...initialCompanyState.versions,
                    ...state.versions,
                    [action.payload.type]: action.payload.version,
                }
            }
        default:
            return state;
    }
};

// Action Creator for setting the company ID
export const setCompanyId = (companyId: string): SetCompanyIdAction => ({
    type: ESetCompanyAction.SET_COMPANY_ID,
    payload: companyId,
});

// Action Creator for setting the company name
export const setCompanyName = (name: string): SetCompanyNameAction => ({
    type: ESetCompanyAction.SET_COMPANY_NAME,
    payload: name,
});

// Action Creator for setting all skills
export const setCompanyAllSkills = (skills: ISmallSkill[]): SetCompanyAllSkillsAction => ({
    type: ESetCompanyAction.SET_COMPANY_ALL_SKILLS,
    payload: skills.map(c => ({ ...c, id: parseInt(c.id.toString()) })),
});

// Action Creator for setting all competences
export const setCompanyAllCompetences = (competences: ISmallCompetence[]): SetCompanyAllCompetencesAction => ({
    type: ESetCompanyAction.SET_COMPANY_ALL_COMPETENCES,
    payload: competences.map(c => ({ ...c, id: parseInt(c.id.toString()) })),
});

// Action Creator for setting all certificates
export const setCompanyAllCertificates = (certificates: ISmallCertificate[]): SetCompanyAllCertificatesAction => ({
    type: ESetCompanyAction.SET_COMPANY_ALL_CERTIFICATES,
    payload: certificates.map(c => ({ ...c, id: parseInt(c.id.toString()) })),
});

// Action Creator for setting all roles
export const setCompanySmallRoles = (roles: ISmallRole[]): SetCompanySmallRolesAction => ({
    type: ESetCompanyAction.SET_COMPANY_SMALL_ROLES,
    payload: roles.map(c => ({ ...c, id: parseInt(c.id.toString()) })),
});

// Action Creator for setting skill categories
export const setCompanySkillCategories = (categories: SkillCategory[]): SetCompanySkillCategoriesAction => ({
    type: ESetCompanyAction.SET_COMPANY_SKILL_CATEGORIES,
    payload: categories.sort((a, b) => a.title.localeCompare(b.title)),
});

// Action Creator for setting all employees
export const setCompanyAllEmployees = (employees: ISmallEmployee[]): SetCompanyAllEmployeesAction => ({
    type: ESetCompanyAction.SET_COMPANY_ALL_EMPLOYEES,
    payload: employees.map(c => ({ ...c, id: parseInt(c.id.toString()) })),
});

// Action Creator for setting company style
export const setCompanyStyle = (style: IStyleDTO): SetCompanyStyleAction => ({
    type: ESetCompanyAction.SET_COMPANY_STYLE,
    payload: style,
});

// Action Creator for setting company versions
export const setCompanyVersions = (versions: TEntityVersions): SetCompanyVersionsAction => ({
    type: ESetCompanyAction.SET_COMPANY_VERSIONS,
    payload: versions,
})

// Action Creator for setting company versions
export const setCompanyVersion = (type: EEntityType, version: number): SetCompanyVersionAction => ({
    type: ESetCompanyAction.SET_COMPANY_VERSION,
    payload: {
        version,
        type,
    },
})

