import React, { MouseEventHandler } from 'react';
import { Edit, Delete, MoreVert } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, IconButton, Menu, } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import MenuButton from '@sharedReact/General/MenuButton/MenuButton';

interface EntityPageMenuProps
{
    menuItems?: (JSX.Element | undefined)[];
    onDelete?: () => void;
    openEditDialog?: () => void;
}
/**
 * Represents the EntityPageMenu component.
 *
 * @param {EntityPageMenuProps} onDelete - The function called when delete action is triggered.
 * @param {EntityPageMenuProps} openEditDialog - The function called when open-edit action is triggered.
 *
 * @returns {JSX.Element} The JSX element representing the EntityPageMenu component.
 */
export function EntityPageMenu({ menuItems, onDelete, openEditDialog }: EntityPageMenuProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).entityPage;
    const offline = useSelector((state: AppState) => state.client.offline);
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const menuContainerRef = React.useRef(null); // Stelle sicher, dass dieses ref an den Container gebunden ist, den du verwenden möchtest.

    const handleClick = (event: any) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    if (!onDelete && !openEditDialog && !menuItems?.length) return <></>;
    if (!openEditDialog && !onDelete && (!menuItems || menuItems.length === 0))
    {
        return <></>
    }
    return (
        <div ref={menuContainerRef}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                container={menuContainerRef.current}
                slotProps={{
                    paper: { style: { zIndex: 1300 } }
                }}
            >
                {openEditDialog && <MenuItem
                    key={'edit'}
                    onClick={() => { openEditDialog(); handleClose() }}
                    disabled={offline}
                >
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>{langStrings.edit}</ListItemText>
                </MenuItem>}
                {onDelete && <MenuItem
                    key={'delete'}
                    onClick={() => { onDelete(); handleClose() }}
                    disabled={offline}
                >
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>{langStrings.delete}</ListItemText>
                </MenuItem>}
                {menuItems?.map((menuItem, index) => menuItem && React.cloneElement(menuItem, { key: index, close: handleClose }))}
            </Menu>
        </div>
    );
}
