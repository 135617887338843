import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation addHoliday($year: Int!, $date: String!,$name: String!,$isFullDay: Boolean!) {
    addHoliday(year: $year, date: $date,name: $name,isFullDay: $isFullDay) 
},
`;


/**
 * Adds a holiday to the list.
 * 
 * @param {number} year - The year of the holiday.
 * @param {string} date - The date of the holiday.
 * @param {string} name - The name of the holiday.
 * @param {boolean} isFullDay - Indicates if the holiday is a full day.
 * 
 * @returns {Promise<boolean>} A promise that resolves with a boolean indicating if the holiday was added successfully.
 */
export default async function addHoliday(year: number, date: string, name: string, isFullDay: boolean)
{
    const variables: OperationVariables = {
        year,
        date,
        name,
        isFullDay,
    };
    return GraphQL.mutate<boolean>(query, variables)
}