import { OperationVariables, gql } from "@apollo/client";
import IProjectDTO from "@sharedInterfaces/IProject";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteProject, updateProject } from "@store/reducer/projectReducer";
import Project, { projectGQL } from "@src/Objects/Project";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

const q = gql`
query GetProject($projectId: ID!) {
    project(id: $projectId) 
    ${projectGQL}
},
`;

/**
 * Retrieves a project by its ID and updates the Redux store.
 *
 * @param {number} projectId - The ID of the project to retrieve.
 * @returns {Promise<Project>} A promise that resolves with the retrieved project.
 */
export default async function getProject(projectId: number)
{
    const variables: OperationVariables = { projectId };
    return GraphQL.query<{ project: IProjectDTO }>(q, variables).then((obj) =>
    {
        if (!obj?.project)
        {
            store.dispatch(deleteProject(projectId));
            throw new NotFoundError(projectId, EEntityType.PROJECT);
        }
        const project = new Project(obj.project);
        store.dispatch(updateProject(project));
        return project;
    });
}
