import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation createAndMapCertificate($title: String!, $startDate: DateTime, $endDate: DateTime) {
    createAndMapCertificate(title: $title, startDate: $startDate, endDate: $endDate) 
},
`;

/**
 * Creates and maps a certificate.
 *
 * @param {string} title - The title of the certificate.
 * @returns {Promise<number>} - A promise that resolves with the created certificate's ID.
 */
export default async function createAndMapCertificate(title: string, startDate: Date | undefined, endDate: Date | undefined)
{
    const variables: OperationVariables = {
        title,
        startDate,
        endDate,
    };
    return GraphQL.mutate<number>(query, variables);
}
