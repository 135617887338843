import { OperationVariables, gql } from "@apollo/client";
import { ELanguage } from "@sharedInterfaces/Language/languageHelper";

import GraphQL from "../graphQL";

const query = gql`
mutation updateUserLanguage($lang:String) {
    updateUserLanguage(lang: $lang)
},
`;

/**
 * Marks the first experience as read.
 * 
 * @returns {Promise<boolean>}
 */
export default async function updateUserLanguage(lang: ELanguage | null)
{
    const variables: OperationVariables = {
        lang,
    };
    return GraphQL.mutate<boolean>(query, variables);
}