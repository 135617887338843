import { OperationVariables, gql } from "@apollo/client";
import { ISkillReferenceDTO } from "@sharedInterfaces/ISkill";

import GraphQL from "../../graphQL";


const query = gql`
mutation configureGoal($id:Int,$title:String!,$description:String!, $skills:[SkillInput!]!, $untilDate:DateTime!) {
    configureGoal(id: $id, title:$title, description: $description, skills: $skills, untilDate: $untilDate) 
},
`;


/**
 * Configures a goal.
 * 
 * @param skills - Array of skill references.
 * @param untilDate - The date until the goal is valid.
 * @param id - The ID of the goal.
 * 
 * @returns A promise that resolves to void.
 */
export default async function configureGoal(skills: ISkillReferenceDTO[], untilDate: Date, title: string, description: string, id: number | undefined)
{
    const variables: OperationVariables = {
        id,
        title,
        description,
        untilDate,
        skills: skills.map(s => ({ id: s.id, level: s.level })),
    }
    return GraphQL.mutate<number>(query, variables)
}