import { Done, Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeProjectReferenceDTO } from '@sharedInterfaces/IEmployee';
import { AppState } from '@store/store';
import { setHiddenProjects } from '@store/reducer/employeeSettingsReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import updateHiddenProjects from '@src/APIs/graphQl/Employee/updateHiddenProjects';

interface HideShowProjectsDialogProps
{
    id: string
    resolve?: () => void;
}
/**
 * Function to render the HideShowProjectsDialog component.
 *
 * @param {HideShowProjectsDialogProps} props - The props object containing the necessary data for rendering the component.
 * @returns {JSX.Element} - The rendered HideShowProjectsDialog component.
 */
export function HideShowProjectsDialog(props: HideShowProjectsDialogProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const projects = useSelector((state: AppState) => state.employee.projects);
    const hiddenProjectsInitial = useSelector((state: AppState) => state.employeeSettings.hiddenProjects);
    const [hiddenProjects, setHiddenProjectsState] = React.useState<number[]>(hiddenProjectsInitial);

    const onclose = () =>
    {
        props.resolve && props.resolve()
    }

    return (
        <Dialog
            id={props.id}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        text={langStrings.save}
                        size="normal"
                        onClick={() => updateHiddenProjects(hiddenProjects).then(() =>
                        {
                            dispatch(setHiddenProjects(hiddenProjects));
                            onclose()
                        })} />
                </div>
            }
            title={langStrings.hideShowDialogTitle}
            onClose={onclose}
        >
            <div>
                <table className="activityTable">
                    <thead>
                        <tr>
                            <td>{langStrings.activity}</td>
                            <td>{langStrings.showOrHide}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.filter(p => p.active).map(p => (
                            <tr key={p.id}>
                                {renderProjectTD(p as IEmployeeProjectReferenceDTO)}
                                <td>
                                    <div className="hideProjects">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                            {
                                                const newHiddenProjects = hiddenProjects.concat();
                                                if (hiddenProjects.includes(p.id))
                                                {
                                                    const index = hiddenProjects.lastIndexOf(p.id);
                                                    newHiddenProjects.splice(index, 1);
                                                }
                                                else
                                                {
                                                    newHiddenProjects.push(p.id);
                                                }
                                                setHiddenProjectsState(newHiddenProjects);
                                            }}
                                        >
                                            {hiddenProjects.includes(p.id) ? (
                                                <VisibilityOff fontSize="small" style={{ color: 'var(--var-secondary-color)' }} />
                                            ) : (
                                                <Visibility fontSize="small" style={{ color: 'var(--var-primary-color)' }} />
                                            )}
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
}


/**
 * Renders a TD element for a project.
 *
 * @param {IEmployeeProjectReferenceDTO} project - The project to be rendered.
 * @returns {JSX.Element} - The rendered TD element.
 */
function renderProjectTD(project: IEmployeeProjectReferenceDTO)
{
    return (
        <td>
            <div className='projectColumn'>
                {project.business.title !== "" &&
                    < div className='business'>
                        {project.business.title}
                    </div>
                }
                <div> {project.title}</div>
            </div>
        </td>
    )
}