import './AddCertificateButton.css';
import React from 'react';
import { Add, AirplanemodeActive, Loop, Remove, Tune } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import changeMapCertificate from '@src/APIs/graphQl/Certificate/changeMapCertificate';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import RoundIconButton from '../RoundIconButton/RoundIconButton';
import { useMapCertificateDialog } from '../../Dialogs/MapCertificateDialog/MapCertificateDialog';
interface AddCertificateButtonProps
{
    id: number
    size: 'small' | 'medium' | 'verysmall'
    onChange?: (val: boolean) => void
}

/**
 * AddCertificateButton component.
 *
 * @param {AddCertificateButtonProps} id - The id of the button.
 * @param {AddCertificateButtonProps} size - The size of the button.
 * @param {AddCertificateButtonProps} onChange - The callback function for when the button is clicked.
 *
 * @returns {JSX.Element} The JSX element for the Add Certificate button.
 */
function AddCertificateButton({ id, size, onChange, }: AddCertificateButtonProps)
{
    const mapCertificateDialog = useMapCertificateDialog();
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);

    const [saving, setSaving] = React.useState<boolean>(false);

    const remove = () =>
    {
        if (saving || !employee) return;
        setSaving(true);
        changeMapCertificate(id, false, undefined, undefined)
            .then(() =>
            {
                setSaving(false);
                if (onChange) onChange(false);
            })
            .catch(async (ex: Error) =>
            {
                await showErrorDialog(ex, langStrings.errorRemove)
                setSaving(false);
            })
    }

    if (!employee || id === -1) return null
    const own = employee.certificates.find(oc => oc.id === id);
    if (saving)
    {
        return <Loop fontSize={size === 'verysmall' ? 'small' : 'medium'} />;
    }

    return (
        <>
            {
                offline ?
                    <AirplanemodeActive />
                    :
                    (
                        own ?
                            <div className={`addCertificateButton removeButton ${size}`}>
                                <RoundIconButton
                                    helperText={langStrings.removeButtonTitle}
                                    onClick={() =>
                                    {
                                        remove();
                                    }}
                                    icon={<Remove fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                    size={size}
                                />
                                <RoundIconButton
                                    size={size}
                                    helperText={langStrings.editButtonTitle}
                                    onClick={async () =>
                                    {
                                        await mapCertificateDialog(id);
                                        if (onChange) onChange(true);
                                    }}
                                    icon={<Tune fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                />
                            </div> :
                            <RoundIconButton
                                helperText={langStrings.addButtonTitle}
                                onClick={async () =>
                                {
                                    await mapCertificateDialog(id);
                                    if (onChange) onChange(true);
                                    // add();
                                }}
                                icon={<Add fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                size={size}
                            />
                    )
            }
        </>
    )
}

export default AddCertificateButton;
