import { OperationVariables, gql } from "@apollo/client";
import { IGenerateSkillCategory, ISetGenerateCategoriesRequest, ISkillCategory } from "@sharedInterfaces/ISkillCategory";
import store from "@store/store";
import { setCompanySkillCategories } from "@store/reducer/companyReducer";
import { SkillCategory } from "@src/Objects/SkillCategory";

import GraphQL from "../graphQL";

const querySet = gql`
mutation setGenerateCategories($categories:[GenerateCategoryInput!]!) {
    setGenerateCategories(categories: $categories) 
    {
        id,
        title,
        description,
        parentCategory,
    }
},
`;

/**
 * Sets the generated skill categories.
 * 
 * @param {IGenerateSkillCategory[]} categories - The array of skill categories to set.
 * 
 * @returns {Promise<SkillCategory[]>} A Promise that resolves to an array of skill categories.
 */
export async function setGenerateCategories(categories: IGenerateSkillCategory[]):
    Promise<SkillCategory[]>
{
    const variables: OperationVariables = {
        categories: categories.map((c) => ({ title: c.title, id: c.id, parentCategory: c.parentCategory }))
    } as ISetGenerateCategoriesRequest;


    const requestResult = await GraphQL.mutate<ISkillCategory[]>(querySet, variables)

    const newSkillCategories = requestResult.map(e => new SkillCategory({ ...e, employees: [] }));

    store.dispatch(setCompanySkillCategories(newSkillCategories));
    // store.dispatch(setSkillCategories(newSkillCategories));

    return newSkillCategories
}