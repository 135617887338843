import React from 'react';
import { Menu } from '@mui/material';
import './MenuBox.css';

interface MenuBoxProps
{
    anchor: HTMLElement | null
    setAnchor: (newAnchor: HTMLElement | null) => void
    children: React.ReactNode
}

function MenuBox(props: MenuBoxProps)
{
    return (
        <Menu
            open={true}
            anchorEl={props.anchor}
            onClose={() => { props.setAnchor(null) }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,

                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
        >
            {props.children}
        </Menu>
    );
}

export default MenuBox;
