import React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

/**
 * Generates a linear progress with label.
 *
 * @param props - The properties for the linear progress.
 * @returns The JSX element for the linear progress with label.
 */
export function LinearProgressWithLabel(props: LinearProgressProps)
{
    const value = props.value ? props.value : 0;
    return (
        <>
            <LinearProgress
                variant="determinate"
                value={value}
                {...props} />
            <span
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="caption" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </span>
        </>
    );
}
