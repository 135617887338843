import React from 'react';
import './CertificateCompare.css'
import { IReference } from '@sharedInterfaces/IReference';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';

import CertificateItem from '../../App/NewLayout/Items/CertificateItem/CertificateItem';
interface CertificateCompareProps
{
    certificates: IReference[]
    inDialog?: true
    size: 'small' | 'medium'
    negative?: true
}
/**
 * Function to compare certificates.
 *
 * @param {CertificateCompareProps} props - The props for comparison.
 * @returns {JSX.Element} - The JSX element representing the comparison.
 */
function CertificateCompare(props: CertificateCompareProps)
{
    const certificates = props.certificates.map(certificate =>
    {
        const withoutLink = props.inDialog || certificate.id === undefined;

        return <CertificateItem
            key={certificate.title + certificate.id}
            certId={certificate.id}
            title={certificate.title}
            disableOverlay={withoutLink}
            negative={props.negative}
            onClick={(!props.inDialog && certificate.id) ?
                entityTypeToLink(certificate.id, EEntityType.CERTIFICATE)
                : undefined
            }
        />;
    }
    );

    return (
        <div className={'CertificateCompare ' + (props.size)} style={{ border: props.inDialog ? 'none' : '' }} >
            {
                certificates
            }
        </div>
    );
}

export default CertificateCompare;