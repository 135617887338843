import { OperationVariables, gql } from "@apollo/client";
import IProduct, { ICreateProduct } from "@sharedInterfaces/IProduct";
import { productGQL } from "@src/Objects/Product";

import GraphQL from "../graphQL";

const query = gql`
mutation createProduct($obj: CreateProduct!) {
    createProduct(obj: $obj) 
    ${productGQL}
},
`;

/**
 * Creates a new product.
 * 
 * @param {ICreateProduct} product - The product object to create.
 * @returns {Promise<IProduct>} A promise that resolves to the created product.
 */
export default async function createProduct(product: ICreateProduct)
{
    product.certificates = product.certificates.map((c) => ({
        id: c.id,
        title: c.title,
    }))
    product.skills = product.skills.map((c) => ({
        id: c.id,
        title: c.title,
        level: c.level,
    }))
    product.employees = product.employees.map((c) => ({
        id: c.id,
        title: c.title,
    }))
    product.competences = product.competences.map((c) => ({
        id: c.id,
        title: c.title,
        level: c.level,
    }))
    product.roles = product.roles.map((c) => ({
        id: c.id,
        title: c.title,
    }))
    const variables: OperationVariables = {
        obj: product,
    };

    return GraphQL.mutate<IProduct>(query, variables)
}

//, (obj: any) => callback(new Product(companyId, obj.createProduct as IProduct))