import { Add, Check, Delete, Edit, GroupAdd } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { IPermissionGroup } from '@sharedInterfaces/IPermissions';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Company, { OU } from '@src/Objects/Company';
import addEmployees from '@src/APIs/graphQl/Employee/addEmployees';
import changeOULeader from '@src/APIs/graphQl/OrgUnit/changeOULeader';
import changeOUTitle from '@src/APIs/graphQl/OrgUnit/changeOUTitle';
import deleteEmployee from '@src/APIs/graphQl/Employee/deleteEmployee';
import deleteOU from '@src/APIs/graphQl/OrgUnit/deleteOU';
import employeeSwitchOU from '@src/APIs/graphQl/OrgUnit/employeeSwitchOU';
import './EditOuList.css';
import editEmployee from '@src/APIs/graphQl/Employee/editEmployee';
import createOUs from '@src/APIs/graphQl/OrgUnit/createOUs';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import CardBox from '../../CardBox/CardBox';
import EmployeeSelect from '../../formsControls/inputs/EmployeeSelect/EmployeeSelect';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';

import { AddEmployeesDialog } from './AddEmployeesDialog';
import { RemoveOUDialog } from './RemoveOUDialog';
import { AddOUDialog } from './AddOUDialog';
import { EditEmployeeDialog } from './EditEmployeeDialog';

interface EditOuListProps
{
    OUs: OU[]
    permissionGroups: IPermissionGroup[]
    employees: ICompanyUser[]
    setCompany: (val: Company) => void
}

/**
 * EditOuList
 * 
 * @param {EditOuListProps} props
 * 
 * @returns {JSX.Element}
 */
function EditOuList(props: EditOuListProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [target] = useState<{ id: number }>({ id: -1 });
    let draggedItem: ICompanyUser | null = null;

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: ICompanyUser) =>
    {
        draggedItem = item;
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) =>
    {
        event.preventDefault();
        if (!event.currentTarget.id.startsWith('OU'))
        {
            target.id = -1;
            return;
        }
        if (event.currentTarget.id !== 'OU' + target)
            target.id = parseInt(event.currentTarget.id.replace('OU', ''))
    };

    const handleDragEnd = () =>
    {
        if (draggedItem === null || target.id === -1) return;
        const OUs = props.OUs.slice();
        const targetOU = OUs.find(ou => ou.id === target.id)
        const sourceOU = OUs.find(ou => ou.employees.find(e => e.id === (draggedItem as ICompanyUser).id))
        if (targetOU === undefined) return;
        if (sourceOU && targetOU.id === sourceOU.id) return;
        employeeSwitchOU(targetOU.id, draggedItem.id).then((result) =>
        {
            props.setCompany(result);
        });
        draggedItem = null;
        target.id = -1;
    };

    const employeesWithoutOU = props.employees.filter(employee =>
    {
        return !props.OUs.some(ou => ou.employees.find(e => e.id === employee.id));
    });
    const topOUs = props.OUs.filter(ou => ou.parentOU === null || ou.parentOU === undefined);
    return (
        <CardBox title={langStrings.orgUnits}>
            <div className="EditOuList">
                {topOUs.map(ou => <OU
                    handleDragOver={handleDragOver}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    setCompany={props.setCompany}
                    key={ou.id}

                    ou={ou}
                    layer={0}
                    OUs={props.OUs}
                    permissionGroups={props.permissionGroups}
                    employees={props.employees}
                />)}
                {
                    employeesWithoutOU.length > 0 &&
                    <div>
                        <p>
                            {langStrings.employeeWithoutOU}
                        </p>
                        <OUEmployeesList
                            id={-1}
                            OUs={props.OUs}
                            permissionGroups={props.permissionGroups}
                            setCompany={props.setCompany}
                            handleDragStart={handleDragStart}
                            handleDragEnd={handleDragEnd}
                            handleDragOver={handleDragOver}
                            employees={{ employees: employeesWithoutOU }}
                        >
                        </OUEmployeesList>
                    </div>
                }
            </div>
        </CardBox>
    );
    interface OUProps
    {
        OUs: OU[],
        permissionGroups: IPermissionGroup[],
        ou: OU,
        employees: ICompanyUser[]
        layer: number,
        setCompany: (val: Company) => void
        handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void
        handleDragStart: (event: React.DragEvent<HTMLDivElement>, item: ICompanyUser) => void
        handleDragEnd: (event: React.DragEvent<HTMLDivElement>) => void
    }

    function generateBorderColor(layer: number)
    {
        switch (layer)
        {
            case 0:
                return '#000000'
            case 1:
                return '#0000ff'
            case 2:
                return '#00FF00'
            case 3:
                return '#00ffff'
            case 4:
                return '#800080'
            default:
                return '#000000'
        }
    }
    function OU(props: OUProps)
    {
        const { openDialog } = useContext(DialogManagerContext)
        const [dialogOpen, setDialogOpen] = React.useState(false as boolean);
        const [employees] = useState<ICompanyUser[]>(props.ou.employees);
        const [editName, setEditName] = React.useState("" as string);
        const [editLeader, setEditLeader] = React.useState(-1 as number);
        const [saving, setSaving] = React.useState(false as boolean);

        return <React.Fragment>
            <div className="ouBox" style={{
                marginLeft: props.layer > 0 ? 20 : 0,
                border: `solid 1px ${generateBorderColor(props.layer)}`,
                boxShadow: `0 5px 7px 0 ${generateBorderColor(props.layer)}50`,
            }
            }>
                <div className="ouHead" key={props.ou.id}>
                    <div className="pad">
                        <div className="ouTitle">
                            {
                                editName === "" ? props.ou.title : <TextInput
                                    value={editName}
                                    onChange={function (val: string): void
                                    {
                                        setEditName(val);
                                    }}
                                />
                            }
                            <div className="editTitle">
                                <div className="editTitleIcon"
                                    style={{ display: saving ? 'none' : 'inherit' }}
                                    onClick={() =>
                                    {
                                        if (editName === "")
                                        {
                                            setEditName(props.ou.title)
                                        } else
                                        {
                                            if (props.ou.title === editName) return setEditName("");
                                            setSaving(true);
                                            changeOUTitle(props.ou.id, editName)
                                                .then((result) =>
                                                {
                                                    props.setCompany(result);
                                                    setEditName("");
                                                    setSaving(false);
                                                })
                                                .catch(() =>
                                                {
                                                    alert(langStrings.errorChangeName);
                                                    setTimeout(() =>
                                                    {
                                                        setSaving(false);
                                                    }, 3000);
                                                })
                                        }
                                    }}>
                                    {editName === "" ? <Edit /> : <Check />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leaderInfo">
                        <span>{langStrings.leader}:</span>
                        {
                            editLeader === -1 ? props.ou.leader.title :
                                <EmployeeSelect
                                    employees={props.employees}
                                    selectedEmployee={editLeader}
                                    setSelectedEmployee={function (val?: number): void
                                    {
                                        if (!val) return setEditLeader(0);
                                        setEditLeader(val);
                                    }}
                                />
                        }
                        <div className="editTitle">
                            <div className="editTitleIcon"
                                tabIndex={0}
                                style={{ display: saving ? 'none' : 'inherit' }}
                                onClick={() =>
                                {
                                    if (editLeader === -1)
                                    {
                                        setEditLeader(props.ou.leader.id > 0 ? props.ou.leader.id : 0)
                                    } else
                                    {
                                        if (props.ou.leader.id === editLeader) return setEditLeader(-1);
                                        if (editLeader === 0) return setEditLeader(-1);
                                        setSaving(true);
                                        changeOULeader(props.ou.id, editLeader)
                                            .then((result) =>
                                            {
                                                props.setCompany(result);
                                                setEditLeader(-1);
                                                setSaving(false);
                                            })
                                            .catch(() =>
                                            {
                                                alert(langStrings.errorChangeName);
                                                setTimeout(() =>
                                                {
                                                    setSaving(false);
                                                }, 3000);
                                            })
                                    }
                                }}>
                                {editLeader === -1 ? <Edit /> : <Check />}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="pad">
                        {langStrings.employees}:
                        <OUEmployeesList
                            id={props.ou.id}
                            OUs={props.OUs}
                            permissionGroups={props.permissionGroups}
                            setCompany={props.setCompany}
                            handleDragStart={props.handleDragStart}
                            handleDragEnd={props.handleDragEnd}
                            handleDragOver={props.handleDragOver}
                            employees={{ employees }}
                        >
                            <div className="addEmployee" onClick={() =>
                            {
                                setDialogOpen(true)
                            }}
                            ><Add /></div>
                        </OUEmployeesList>
                    </div>
                </div>
                {
                    props.OUs.filter(innerOU => innerOU.parentOU === props.ou.id).map(ou => <OU
                        handleDragOver={props.handleDragOver}
                        handleDragStart={props.handleDragStart}
                        handleDragEnd={props.handleDragEnd}
                        setCompany={props.setCompany}
                        employees={props.employees}
                        key={ou.id}

                        ou={ou}
                        layer={props.layer + 1}
                        OUs={props.OUs}
                        permissionGroups={props.permissionGroups}
                    />)
                }
                <div className="ouButtons">
                    <Button size="normal" text={langStrings.createOu} icon={<GroupAdd />} onClick={function (): void
                    {
                        openDialog(
                            <AddOUDialog
                                id="AddOUDialog"
                                parentOU={props.ou} employees={props.employees}
                                onSave={async function (val: OU[]): Promise<unknown>
                                {
                                    const result = await createOUs(val)
                                        .then((result) =>
                                        {
                                            props.setCompany(result);
                                        })
                                    return result;
                                }} />
                        );
                    }} disabled={false} />
                    <Button size="normal" text={langStrings.deleteOu} icon={<Delete />} onClick={function (): void
                    {
                        openDialog(
                            <RemoveOUDialog
                                id="RemoveOUDialog"
                                ou={props.ou}
                                employees={props.employees}
                                onSave={async function (val: boolean): Promise<unknown>
                                {
                                    if (!val) return;
                                    const result = await deleteOU(props.ou.id)
                                        .then((result) =>
                                        {
                                            props.setCompany(result);
                                        })
                                    return result;
                                }} />
                        )
                    }} disabled={false} />
                </div>
            </div>
            {
                dialogOpen &&
                <AddEmployeesDialog id="AddEmployeesDialog" ou={props.ou} resolve={() => { setDialogOpen(false); }}
                    onSave={async (emps) =>
                    {
                        const result = await addEmployees(props.ou.id, emps).then((result) =>
                        {
                            props.setCompany(result);
                            setDialogOpen(false);
                        });
                        return result;
                    }} permissionGroups={props.permissionGroups} />
            }
        </React.Fragment>
    }
}

interface OUEmployeesListProps
{
    id: number
    OUs: OU[]
    permissionGroups: IPermissionGroup[]
    employees: { employees: ICompanyUser[] }
    children?: React.ReactNode
    setCompany: (val: Company) => void
    handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void
    handleDragStart: (event: React.DragEvent<HTMLDivElement>, item: ICompanyUser) => void
    handleDragEnd: (event: React.DragEvent<HTMLDivElement>) => void
}
/**
 * OUEmployeesList component.
 * 
 * @param {OUEmployeesListProps} props - The props object for the OUEmployeesList component.
 * @returns {JSX.Element}
 */
function OUEmployeesList(props: OUEmployeesListProps)
{
    const { openDialog } = useContext(DialogManagerContext);

    return <div className="employees" id={'OU' + props.id}
        onDragOver={props.handleDragOver}
    >
        {props.employees.employees.map(e => <div
            key={e.id}
            className="employee"
            onClick={() =>
            {
                openDialog(
                    <EditEmployeeDialog
                        id="EditEmployeeDialog"
                        OUs={props.OUs}
                        employee={e as ICompanyUser}
                        onDelete={async (emp) =>
                        {
                            const result = await deleteEmployee(emp.id)
                                .then((result) =>
                                {
                                    props.setCompany(result);
                                });
                            return result;
                        }}
                        onSave={async (emp, ouId) =>
                        {
                            const result = await editEmployee(emp.id, emp.firstname, emp.lastname, ouId, emp.permissionGroup.id, emp.permissions.admin, emp.gender).then((result) =>
                            {
                                props.setCompany(result);
                            });
                            return result;
                        }}
                        permissionGroups={props.permissionGroups}
                    />
                )
            }}
            draggable
            onDragStart={(event) => props.handleDragStart(event, e)}
            onDragEnd={(event) =>
            {
                props.handleDragEnd(event);
            }}
        >
            {generateFullName(e.firstname, e.lastname)}
        </div>)}
        {props.children}
    </div>
}
export default EditOuList;