import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Menu, ListItemButton } from '@mui/material';
import { Entity } from '@sharedReact/Objects/Entity';

import { IView } from './EntitiesPage';
interface ViewMenuProps<T extends Entity>
{
    views: IView<T>[];
    selectedView: IView<T>;
    setSelectedView: (id: IView<T>) => void;
}
export function ViewMenu<T extends Entity>(props: ViewMenuProps<T>)
{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number
    ) =>
    {
        props.setSelectedView(props.views[index]);
        setAnchorEl(null);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };
    if (!props.views.length) return <></>;
    const selectedView = props.selectedView;
    return (
        <div >
            <ListItemButton
                dense
                disableGutters
                id="view-button"
                aria-haspopup="listbox"
                aria-controls="view-menu"
                aria-label={selectedView.title}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
            >
                <ListItemText
                    primary={selectedView.title} />
                <ListItemIcon>
                    <ExpandMore fontSize='small' />
                </ListItemIcon>
            </ListItemButton>
            <Menu
                id="view-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'view-button',
                    role: 'listbox',
                }}
            >
                {props.views.map((view, index) => (
                    <MenuItem
                        key={view.id}
                        dense
                        selected={view.id === selectedView.id}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {view.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
