import { useSelector } from 'react-redux';
import React from 'react';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

/**
 * NotFound component.
 *
 * @returns {JSX.Element} The JSX element.
 */
function NotFound()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).navigation;
    return (
        <InnerLayout title={langStrings.pageNotFound} >
            <span>{langStrings.pageNotFoundDescription}</span>
        </InnerLayout>
    );
}

export default NotFound;