import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import OpportunityOverlay from '@src/Components/Overlays/OpportunityOverlay/OpportunityOverlay';
import Item from '@src/App/NewLayout/Items/Item/Item';
import List from '@src/App/NewLayout/Lists/List/List';

interface OpportunitiesCardProps
{
    title?: string;
    opportunities: {
        id: number
        title: string,
        skillCoverage?: number
        level?: number
    }[];
    inOverlay?: boolean
}
/**
 * Renders a box displaying opportunities.
 *
 * @param {string} param.title - The title of the opportunities box.
 * @param {Array} param.opportunities - The list of opportunities to display.
 * @param {boolean} param.inOverlay - Determines whether the opportunities box is displayed in an overlay.
 *
 * @returns {JSX.Element} The rendered opportunities box.
 */
function OpportunitiesCard({ title, opportunities, inOverlay, }: OpportunitiesCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const newTitle = title ? title : langStrings.opportunities;

    const content = opportunities.map((opportunitie) =>
        <Item key={opportunitie.id} percent={0} onClick={entityTypeToLink(opportunitie.id, EEntityType.OPPORTUNITY)}>
            <OpportunityOverlay key={opportunitie.id} disabled={inOverlay} opportunityId={opportunitie.id}>
                {opportunitie.title}
            </OpportunityOverlay>
        </Item >
    );
    return (
        <CardBox title={newTitle}>
            <List>
                {content}
            </List>
        </CardBox>
    );
}

export default OpportunitiesCard;