
export interface IHoliday
{
    date: string;
    name: string;
    isFullDay: boolean;
}
export interface IHolidayYear
{
    year: number;
    holidays: IHoliday[];
}
export enum EHolidaySource
{
    clockodo = 'clockodo',
    openHolidaysAPI = 'openHolidaysAPI',
    manual = 'manual'
}
export interface IHolidaySettings
{
    source: EHolidaySource;
    holidays: IHolidayYear[];
}