import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../../graphQL";

const query = gql`
mutation hideSuggestedSkill($id:Int!) {
    hideSuggestedSkill(id: $id)
},
`;


/**
 * Hides the suggested skill with the given id.
 * 
 * @param {number} id - The id of the suggested skill to hide.
 * @returns {Promise<boolean>} A promise that resolves to true if successful, otherwise false.
 */
export default async function hideSuggestedSkill(id: number)
{
    const variables: OperationVariables = {
        id,
    };
    return GraphQL.mutate<boolean>(query, variables)
}