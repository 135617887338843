import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps
{
    children: ReactNode;
    fallback: (error: Error) => ReactNode;
}

interface ErrorBoundaryState
{
    hasError: boolean;
    error?: Error;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps)
    {
        super(props);
        this.state = { error: undefined, hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState
    {
        return { error, hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo)
    {
        this.setState({ error, hasError: true }); // Speichern Sie den Fehler
        logErrorToMyService(error, info.componentStack ?? '');
    }

    render()
    {
        if (this.state.hasError && this.state.error)
        {
            // Rufen Sie die fallback-Funktion mit dem Fehler auf
            return this.props.fallback(this.state.error);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

// Hilfsfunktion zum Protokollieren von Fehlern
/**
 * Logs an error to a service.
 * 
 * @param {Error} error - The error to be logged.
 * @param {string} componentStack - The stacktrace of the component.
 * @returns {void}
 */
function logErrorToMyService(error: Error, componentStack: string)
{
    // Implementieren Sie hier Ihre Fehlerprotokollierungslogik
    console.error('Caught an error:', error, componentStack);
}
