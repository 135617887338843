import IProduct, { IProductCompetenceReference, IProductEmployeeReference, IProductRoleReference } from "@sharedInterfaces/IProduct";
import { ISkillReferenceDTO } from "@sharedInterfaces/ISkill";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import { IReference } from "@sharedInterfaces/IReference";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export default class Product extends Entity implements IProduct
{
  type: EEntityType = EEntityType.PRODUCT;
  description: string;
  skillCoverage: number;
  skills: ISkillReferenceDTO[];
  certificates: { title: string; id: number; }[];
  employees: IProductEmployeeReference[];
  competences: IProductCompetenceReference[];
  roles: IProductRoleReference[];
  externalRessourceLink: string
  productOwner: IReference | null
  constructor(obj: IProduct)
  {
    super(obj)
    this.description = obj.description;
    this.externalRessourceLink = obj.externalRessourceLink;
    this.productOwner = obj.productOwner;
    this.skillCoverage = obj.skillCoverage;
    this.skills = obj.skills;
    this.certificates = obj.certificates;
    this.employees = obj.employees;
    this.competences = obj.competences;
    this.roles = obj.roles;
  }
  async delete()
  {
    const { default: deleteProduct } = await import("@src/APIs/graphQl/Product/deleteProduct");
    return deleteProduct(this.id);
  }
}

export const productGQL = `{
  id
  title
  description
  externalRessourceLink
  productOwner {
    id
    title
  }
  skillCoverage
  skills {
    id
    title
    level
  }
  certificates {
    id
    title
  }
  employees {
    id
    title
    match
    availability {
      workDayCount
      availability
    }
  }
  competences {
    id
    title
    level
  }
  roles {
      id
      title
  }
  ${entityFieldsGQL}

}`