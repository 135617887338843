import { ELinks } from "@sharedInterfaces/globalEnums"
import DocumentationPage from "@src/pages/Documentation/DocumentationPage"
import RestApiDocuPage from "@src/pages/Documentation/RestApiDocuPage/RestApiDocuPage"
import LoggedInPage from "@src/pages/LoggedInPage/LoggedInPage"
import LoginPage from "@src/pages/LoginPage"
import ProfileSettings, { EProfileSettingsTabs } from "@src/pages/MyProfilePage/ProfileSettings"
import NotAllowed from "@src/pages/NotAllowed"
import NotFound from "@src/pages/NotFound"
import PeopleOrgPage from "@src/pages/PeopleOrgPage/PeopleOrgPage"
import SalesPage from "@src/pages/SalesPage/SalesPage"
import SettingsPage, { ESettingsTabs } from "@src/pages/SettingsPage/SettingsPage"
import EmailUnsubscribePage from "@src/pages/email/EmailUnsubscribePage"
import React from "react"
import { Route, Routes } from "react-router-dom"
import EmailSettingsPage from "@src/pages/email/EmailSettingsPage"
import { AppState } from "@store/store"
import { useSelector } from "react-redux"
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings"

import BusinessPage from "./NewLayout/Pages/Businesses/BusinessPage/BusinessPage"
import BusinessesPage from "./NewLayout/Pages/Businesses/BusinessesPage/BusinessesPage"
import CertificatePage from "./NewLayout/Pages/Certificates/CertificatePage/CertificatePage"
import CertificatesPage from "./NewLayout/Pages/Certificates/CertificatesPage/CertificatesPage"
import CompetencePage from "./NewLayout/Pages/Competences/CompetencePage/CompetencePage"
import CompetencesPage from "./NewLayout/Pages/Competences/CompetencesPage/CompetencesPage"
import EmployeePage from "./NewLayout/Pages/Employees/EmployeePage/EmployeePage"
import EmployeesPage from "./NewLayout/Pages/Employees/EmployeesPage/EmployeesPage"
import OpportunitiesPage from "./NewLayout/Pages/Opportunities/OpportunitiesPage/OpportunitiesPage"
import OpportunityPage from "./NewLayout/Pages/Opportunities/OpportunityPage/OpportunityPage"
import OrgUnitPage from "./NewLayout/Pages/OrgUnits/OrgUnitPage/OrgUnitPage"
import OrgUnitsPage from "./NewLayout/Pages/OrgUnits/OrgUnitsPage/OrgUnitsPage"
import ProductPage from "./NewLayout/Pages/Products/ProductPage/ProductPage"
import ProductsPage from "./NewLayout/Pages/Products/ProductsPage/ProductsPage"
import ProjectPage from "./NewLayout/Pages/Projects/ProjectPage/ProjectPage"
import ProjectsPage from "./NewLayout/Pages/Projects/ProjectsPage/ProjectsPage"
import RolePage from "./NewLayout/Pages/Roles/RolePage/RolePage"
import RolesPage from "./NewLayout/Pages/Roles/RolesPage/RolesPage"
import SkillCategoriesPage from "./NewLayout/Pages/SkillCategories/SkillCategoriesPage/SkillCategoriesPage"
import SkillCategoryPage from "./NewLayout/Pages/SkillCategories/SkillCategoryPage/SkillCategoryPage"
import SkillPage from "./NewLayout/Pages/Skills/SkillPage/SkillPage"
import SkillsPage from "./NewLayout/Pages/Skills/SkillsPage/SkillsPage"

interface IRoutesComponentProps
{
    style: IStyleDTO;
    setStyle: (style: IStyleDTO) => void;
}
export default function RoutesComponent({ style, setStyle }: IRoutesComponentProps)
{

    const secret = useSelector((state: AppState) => state.client.secret);
    let secureRoutes = undefined
    if (secret)
    {
        secureRoutes = <React.Fragment>
            <Route index element={<ProfileSettings tabId={EProfileSettingsTabs.GENERAL} />} />
            <Route path={`${ELinks.LOGGEDIN}/*`} element={<LoggedInPage />} />

            <Route path={ELinks.EMAIL_SETTINGS} element={<EmailSettingsPage />} />

            <Route path={ELinks.PROFILE} element={<EmployeePage />} />
            <Route path={ELinks.PROFILE_SHOW} element={<EmployeePage />} />
            <Route path={ELinks.PROFILE_EDIT} element={<ProfileSettings tabId={EProfileSettingsTabs.GENERAL} />} />
            <Route path={ELinks.PROFILE_EDIT_ANALYSE} element={<ProfileSettings tabId={EProfileSettingsTabs.ANALYSE} />} />
            <Route path={ELinks.PROFILE_EDIT_FORECAST} element={<ProfileSettings tabId={EProfileSettingsTabs.FORECAST} />} />
            <Route path={ELinks.PROFILE_EDIT_ROLES} element={<ProfileSettings tabId={EProfileSettingsTabs.ROLES} />} />
            <Route path={ELinks.PROFILE_EDIT_SKILLS} element={<ProfileSettings tabId={EProfileSettingsTabs.SKILLS} />} />
            <Route path={ELinks.PROFILE_EDIT_SKILLS_SUGGESTIONS} element={<ProfileSettings tabId={EProfileSettingsTabs.SKILLS} extraInfo={'SUGGESTIONS'} />} />
            <Route path={ELinks.PROFILE_EDIT_CERTIFICATES} element={<ProfileSettings tabId={EProfileSettingsTabs.CERTIFICATES} />} />
            <Route path={ELinks.PROFILE_EDIT_DEVELOPMENT} element={<ProfileSettings tabId={EProfileSettingsTabs.DEVELOPMENT} />} />

            <Route path={ELinks.SETTINGS} element={<SettingsPage tabId={ESettingsTabs.GENERAL} />} />
            <Route path={ELinks.SETTINGS_STYLE} element={<SettingsPage style={style} tabId={ESettingsTabs.STYLE} setStyle={setStyle} />} />
            <Route path={ELinks.SETTINGS_LEVELS} element={<SettingsPage tabId={ESettingsTabs.LEVELS} />} />
            <Route path={ELinks.SETTINGS_REGISTRATION} element={<SettingsPage tabId={ESettingsTabs.REGISTRATION} />} />
            <Route path={ELinks.SETTINGS_ORGUNITS} element={<SettingsPage tabId={ESettingsTabs.ORGUNITS} />} />
            <Route path={ELinks.SETTINGS_PERMISSIONS} element={<SettingsPage tabId={ESettingsTabs.PERMISSIONS} />} />
            <Route path={ELinks.SETTINGS_CLOCKODO} element={<SettingsPage tabId={ESettingsTabs.CLOCKODO} />} />
            <Route path={ELinks.SETTINGS_HOLIDAYS} element={<SettingsPage tabId={ESettingsTabs.HOLIDAYS} />} />
            <Route path={ELinks.SETTINGS_CALCULATIONS} element={<SettingsPage tabId={ESettingsTabs.CALCULATIONS} />} />
            <Route path={ELinks.SETTINGS_EMAIL} element={<SettingsPage tabId={ESettingsTabs.EMAIL} />} />
            <Route path={ELinks.SETTINGS_RESTAPI} element={<SettingsPage tabId={ESettingsTabs.RESTAPI} />} />


            <Route path={ELinks.ROLES} element={<RolesPage />} />
            <Route path={`${ELinks.ROLES}/:id`} element={<RolePage />} />

            <Route path={ELinks.SKILLS} element={<SkillsPage />} />
            <Route path={`${ELinks.SKILLS}/:id`} element={<SkillPage />} />
            {/* <Route path={`${ELinks.SKILLS}/view/:tab`} element={<SkillsPage />} /> */}

            <Route path={`${ELinks.SKILLCATEGORIES}`} element={<SkillCategoriesPage />} />
            <Route path={`${ELinks.SKILLCATEGORIES}/:id`} element={<SkillCategoryPage />} />

            <Route path={ELinks.CERTIFICATES} element={<CertificatesPage />} />
            <Route path={`${ELinks.CERTIFICATES}/:id`} element={<CertificatePage />} />

            <Route path={ELinks.COMPETENCE} element={<CompetencesPage />} />
            <Route path={`${ELinks.COMPETENCE}/:id`} element={<CompetencePage />} />
            <Route path={`${ELinks.COMPETENCE}/:id/:tab`} element={<CompetencePage />} />

            <Route path={ELinks.SALES_PRODUCTS} element={<ProductsPage />} />
            <Route path={`${ELinks.SALES_PRODUCTS}/:id`} element={<ProductPage />} />
            <Route path={`${ELinks.SALES_PRODUCTS}/:id/:tab`} element={<ProductPage />} />

            <Route path={ELinks.PEOPLE} element={<PeopleOrgPage />} />
            <Route path={ELinks.PEOPLE_EMPLOYEES} element={<EmployeesPage />} />
            <Route path={`${ELinks.PEOPLE_EMPLOYEES}/:id`} element={<EmployeePage />} />
            <Route path={`${ELinks.PEOPLE_EMPLOYEES}/:id/:tab`} element={<EmployeePage />} />
            <Route path={ELinks.PEOPLE_ORGUNITS} element={<OrgUnitsPage />} />
            <Route path={`${ELinks.PEOPLE_ORGUNITS}/:id`} element={<OrgUnitPage />} />
            <Route path={`${ELinks.PEOPLE_ORGUNITS}/:id/:tab`} element={<OrgUnitPage />} />

            <Route path={ELinks.SALES} element={<SalesPage />} />
            {/* <Route path={`sales/opportunity/new`} element={<OpportunityPageNew />} /> */}
            <Route path={ELinks.SALES_OPPORTUNITIES} element={<OpportunitiesPage />} />
            <Route path={`${ELinks.SALES_OPPORTUNITIES}/:id`} element={<OpportunityPage />} />
            <Route path={ELinks.SALES_PROJECTS} element={<ProjectsPage />} />
            <Route path={`${ELinks.SALES_PROJECTS}/:id`} element={<ProjectPage />} />
            <Route path={ELinks.SALES_CUSTOMERS} element={<BusinessesPage />} />
            <Route path={`${ELinks.SALES_CUSTOMERS}/:id`} element={<BusinessPage />} />

            <Route path={ELinks.DOCUMENTATION} element={<DocumentationPage />} />
            <Route path={ELinks.DOCUMENTATION_REST} element={<RestApiDocuPage />} />

            <Route path={`*`} element={<NotFound />} />
        </React.Fragment>
    }

    return <Routes>
        <Route path={ELinks.LOGIN} element={<LoginPage />} />
        <Route path={`${ELinks.LOGGEDIN}/*`} element={<LoggedInPage />} />
        <Route path={ELinks.EMAIL_UNSUBSCRIBE} element={<EmailUnsubscribePage />} />
        {secureRoutes}
        <Route path={`*`} element={<NotAllowed />} />
    </Routes>
}