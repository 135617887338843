import { VpnKey } from '@mui/icons-material';
import { Button, InputAdornment, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { stageContext } from '@src/globals';

import styles from './Register.module.css'
    /**
    * Register
    * 
    * @returns {JSX.Element}
    */
    ;
export default function RegisterCompany({ resolve }: { resolve?: (companyId: string | null) => void })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registerCompany;
    const width = useSelector((state: AppState) => state.windowSize.width);

    const [step, setStep] = useState(0);
    const [userToken, setUserToken] = useState("");
    const [title, setTitle] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [inviteCode, setInviteCode] = useState("");

    useEffect(() =>
    {
        const hasToken = window.location.hash.indexOf('id_token=') > -1
        if (hasToken)
        {
            const token = window.location.hash.split('id_token=')[1].split('&')[0];
            setUserToken(token);
        }
    }, []);

    const showStepLabel = width > 900;
    return (
        <div className={styles.register}>
            <Stepper activeStep={step}>
                <Step>
                    <StepLabel>
                        {showStepLabel && langStrings.step1}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        {showStepLabel && langStrings.step3}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        {showStepLabel && langStrings.step4}
                    </StepLabel>
                </Step>
            </Stepper>
            <div className={styles.stepPage}>
                {step === 0 &&
                    <Step1 nextStep={(inviteCode) =>
                    {
                        setInviteCode(inviteCode);
                        setStep(step + 1)
                    }} />
                }
                {step === 1 &&
                    <Step3
                        userToken={userToken}
                        nextStep={(title, companyId) =>
                        {
                            setTitle(title);
                            setCompanyId(companyId);
                            setStep(step + 1)
                        }} />
                }
                {step === 2 &&
                    <Step4
                        userToken={userToken}
                        title={title}
                        companyId={companyId}
                        inviteCode={inviteCode}
                        resolve={() =>
                        {
                            resolve && resolve(companyId);
                        }} />
                }
            </div>
        </div>
    )
}


/**
 * Function Step4
 *
 * @param {Object} data - The data for the function.
 * @param {string} data.userToken - The user token.
 * @param {string} data.title - The title.
 * @param {string} data.companyId - The company ID.
 * 
 * @returns {JSX.Element} - The JSX element.
 */
function Step4({ userToken, title, companyId, inviteCode, resolve }: { userToken: string, title: string, companyId: string, inviteCode: string, resolve: () => void })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registerCompany;

    const [creating, setCreating] = useState(false);

    const innerCreateCompany = () =>
    {
        setCreating(true);
        createCompany(userToken, companyId, title, inviteCode)
            .then(() =>
            {
                //TODO: Register Client
                // window.location.href = `${ELinks.LOGIN_FIRST}/#id_token=${userToken}&access_token=undefined`
                setCreating(false);
                resolve();
            })
            .catch((error) =>
            {
                console.log(error);
                setCreating(false);
            })
    }
    return <>
        <Typography variant='h4'>
            {langStrings.confirmTitle}
        </Typography>
        <br />
        <Typography variant='body1'>
            {langStrings.confirmBody}
        </Typography>
        <br />
        <table>
            <tr>
                <td>{langStrings.companyDataCompanyName}:</td>
                <td>{title}</td>
            </tr>
            <tr>
                <td>{langStrings.companyDataCompanyId}:</td>
                <td>{companyId}</td>
            </tr>
        </table>
        <br />
        <Typography variant='body1'>
            {langStrings.confirmHelper1}
        </Typography>
        <br />
        <Typography variant='body1'>
            {langStrings.confirmHelper2}
        </Typography>
        <br />
        <Button
            className={styles.nextButton}
            variant='contained'
            onClick={innerCreateCompany}
            disabled={creating}
        >
            {langStrings.confirm}
        </Button>
    </>;
}


/**
 * Step3 function
 * @param {Object} nextStep - The nextStep object.
 * @param {nextStep} nextStep.nextStep - The nextStep function that takes a title (string) and companyId (string) as parameters and returns void.
 * @returns {JSX.Element} - The JSX element.
 */
function Step3({ userToken, nextStep }: { userToken: string, nextStep: (title: string, companyId: string) => void })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registerCompany;

    const [title, setTitle] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [companyIdChanged, setCompanyIdChanged] = useState(false);
    const [validating, setValidating] = useState(false);
    const [valid, setValid] = useState<null | boolean>(null);

    const innerValidateCompanyId = (companyId: string) =>
    {
        setValidating(true);
        validateCompanyId(userToken, companyId).then((valid) =>
        {
            setValid(valid);
            setValidating(false)
        }).catch((ex) =>
        {
            // setValid(true); //TODO: TEMP
            setValidating(false)
        })
    }

    useEffect(() =>
    {
        if (companyId === "")
        {
            setValid(null);
        }
        if (validating || companyId === "") return;
        setValid(null);
        const timer = setTimeout(() =>
        {
            if (companyId) innerValidateCompanyId(companyId);
        }, 1500);
        return () => clearTimeout(timer);
    }, [companyId]);

    return <>
        <Typography variant='h4'>
            {langStrings.companyDataTitle}
        </Typography>
        <br />
        <Typography variant='body1'>
            {langStrings.companyDataBody}
        </Typography>
        <br />
        <TextField
            label={langStrings.companyDataCompanyName}
            type='text'
            value={title}
            style={{ width: 'clamp(200px, 100%, 600px)' }}
            helperText={langStrings.companyDataCompanyNameHelper}
            focused
            onChange={(e) =>
            {
                setTitle(e.target.value);
                if (!companyIdChanged)
                {
                    setCompanyId(formatCompanyId(e.target.value));
                }
            }}
        />
        <br />
        <br />
        <TextField
            label={langStrings.companyDataCompanyId}
            type='text'
            value={companyId}
            style={{ width: 'clamp(200px, 100%, 600px)' }}
            // helperText={texts.companyDataCompanyIdHelper}
            helperText={valid === false ?
                langStrings.companyDataCompanyIdInvalid :
                (valid ?
                    langStrings.companyDataCompanyIdValid :
                    (validating ? langStrings.validating : langStrings.companyDataCompanyIdHelper)
                )}
            color={valid ? 'success' : 'primary'}
            error={valid === false}
            onChange={(e) =>
            {
                setCompanyId(e.target.value);
                setCompanyIdChanged(true)
            }}
        />
        <br />
        <Button
            className={styles.nextButton}
            variant='contained'
            disabled={companyId === "" || title === "" || !valid}
            onClick={() =>
            {
                nextStep(title, companyId);
            }}
        >
            {langStrings.next}
        </Button>
    </>;
}
/**
 * Formats the company ID.
 *
 * @param {string} input - The company ID to be formatted.
 * @returns {string} The formatted company ID.
 */
function formatCompanyId(input: string): string
{
    // Konvertiere den String zu Kleinbuchstaben
    let formatted = input.toLowerCase();

    // Entferne alle Zeichen, die nicht a-z oder 0-9 sind
    formatted = formatted.replace(/[^a-z0-9]/g, '');

    // Schneide den String nach 20 Zeichen ab
    formatted = formatted.substring(0, 20);

    return formatted;
}

/**
 * Step1 component
 *
 * @returns {JSX.Element}
 */
function Step1({ nextStep }: { nextStep: (inviteCode: string) => void })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).registerCompany;

    const [inviteCode, setInviteCode] = useState('');
    const [validated, setValidated] = useState(false);
    const [validating, setValidating] = useState(false);
    const [validationResult, setValidationResult] = useState<'success' | 'error' | null>(null);

    const validateInviteCode = (code: string) =>
    {
        setValidating(true)
        postInviteCode(code).then(valid =>
        {
            // valid = true; //TODO: TEMP
            setValidationResult(valid ? 'success' : 'error');
            setValidated(true)
            setValidating(false)
        }).catch((ex) =>
        {
        });
    };
    useEffect(() =>
    {
        if (validating) return;
        const timer = setTimeout(() =>
        {
            if (inviteCode) validateInviteCode(inviteCode);
        }, 1500);
        if (!inviteCode) 
        {
            setValidationResult(null)
            setValidated(true)
        } else
        {
            setValidated(false)
        }
        return () => clearTimeout(timer);
    }, [inviteCode]);

    const handleInviteCodeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setInviteCode(event.target.value.toUpperCase());
        setValidationResult(null)
        setValidated(false);
        setValidating(false)
    };
    return <>
        <Typography variant='h4'>
            {langStrings.welcomeText1}
        </Typography>
        <br />
        <Typography variant='body1'>
            {langStrings.welcomeText2}
        </Typography>
        <br />
        <Typography variant='body1'>
            {langStrings.validation1}
        </Typography>
        <br />
        <div className={styles.row}>
            <TextField
                label={langStrings.inviteCode}
                required
                type='search'
                fullWidth
                value={inviteCode}
                onChange={handleInviteCodeChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <VpnKey />
                        </InputAdornment>
                    ),
                }}
                focused
                color={validationResult === 'success' ? 'success' : 'primary'}
                error={validationResult === 'error'}
                helperText={validationResult === 'error' ?
                    langStrings.inviteCodeInvalid :
                    (validationResult === 'success') ?
                        langStrings.inviteCodeValid :
                        (validating ? langStrings.validating : langStrings.inviteCodeHelper)}
            />
            <Button
                onClick={validateInviteCode.bind(null, inviteCode)}
                disabled={validated || validating}
                variant='contained'
                style={{ height: 55 }}
            >{langStrings.check}</Button>
        </div>
        <Button
            className={styles.nextButton}
            variant='contained'
            onClick={() =>
            {
                nextStep(inviteCode)
            }}
            disabled={validationResult !== 'success'}
        >
            {langStrings.next}
        </Button>
    </>
}


const postInviteCode = async (inviteCode: string): Promise<boolean> =>
{
    const url = `${stageContext.apiGatewayRestDomain}/validateInviteCode`;
    const body = JSON.stringify({ inviteCode });

    try
    {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });

        if (!response.ok)
        {
            throw new Error(`HTTP-Fehler: Status ${response.status}`);
        }

        // Extrahieren Sie das "valid"-Attribut aus der JSON-Antwort
        const data = await response.json();
        return data.valid; // Gibt true oder false zurück basierend auf der Serverantwort
    } catch (error)
    {
        console.error('Fehler beim Senden des Invite Codes:', error);
        return false; // Bei einem Fehler, false zurückgeben
    }
};


// const requestUserInformations = async (token: string): Promise<boolean> =>
// {
//     const url = `${stageContext.apiGatewayRestDomain}/requestUserInformations`;

//     try
//     {
//         const response = await fetch(url, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': token,
//             },
//             body: null
//         });

//         if (!response.ok)
//         {
//             throw new Error(`HTTP-Fehler: Status ${response.status}`);
//         }

//         // Extrahieren Sie das "valid"-Attribut aus der JSON-Antwort
//         const data = await response.json();
//         return data.hasCompany; // Gibt true oder false zurück basierend auf der Serverantwort
//     } catch (error)
//     {
//         throw new Error(`Error Fetch`);
//     }
// };


const validateCompanyId = async (token: string, companyId: string): Promise<boolean> =>
{
    const url = `${stageContext.apiGatewayRestDomain}/validateCompanyId`;
    const body = JSON.stringify({ companyId });

    try
    {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: body
        });

        if (!response.ok)
        {
            throw new Error(`HTTP-Fehler: Status ${response.status}`);
        }

        // Extrahieren Sie das "valid"-Attribut aus der JSON-Antwort
        const data = await response.json();
        return data.valid; // Gibt true oder false zurück basierend auf der Serverantwort
    } catch (error)
    {
        throw new Error(`Error Fetch`);
    }
};


const createCompany = async (token: string, companyId: string, title: string, inviteCode: string): Promise<boolean> =>
{
    const url = `${stageContext.apiGatewayRestDomain}/createCompany`;
    const body = JSON.stringify({ companyId, title, inviteCode });

    try
    {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: body
        });

        if (!response.ok)
        {
            throw new Error(`HTTP-Fehler: Status ${response.status}`);
        }

        // Extrahieren Sie das "valid"-Attribut aus der JSON-Antwort
        const data = await response.json();
        return data.created; // Gibt true oder false zurück basierend auf der Serverantwort
    } catch (error)
    {
        throw new Error(`Error Fetch`);
    }
};