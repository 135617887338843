import { OperationVariables, gql } from "@apollo/client";
import ICertificateDetailDTO from "@sharedInterfaces/ICertificate";
import Certificate, { CertificateGQL } from "@src/Objects/Certificate";

import GraphQL from "../graphQL";


const query = gql`
mutation editCertificate(
        $id:Int!,
        $title: String!,
        $description: String!,
        $otherNames: [String!]!,
        $productName: String,
        $manufacturer: String,
        $link: String,
        $prerequisites: String!,
        $cost: Float
        $durationInDays: Int
    ) {
    editCertificate(
        id: $id,
        title: $title,
        description: $description,
        otherNames: $otherNames,
        productName: $productName,
        manufacturer: $manufacturer,
        link: $link,
        prerequisites: $prerequisites,
        cost: $cost,
        durationInDays: $durationInDays,
        ) 
    ${CertificateGQL}
},
`;

/**
 * Edit a certificate.
 * @param {number} id - The ID of the certificate.
 * @param {string} title - The title of the certificate.
 * @param {string} description - The description of the certificate.
 * @param {string[]} otherNames - Other names of the certificate.
 * @param {string} productName - The product name of the certificate.
 * @param {string} manufacturer - The manufacturer of the certificate.
 * @param {string} link - The link of the certificate.
 * @param {string} prerequisites - The prerequisites of the certificate.
 * @param {number} cost - The cost of the certificate.
 * @param {number} durationInDays - The duration of the certificate in days.
 * @returns {Promise<object>} - A promise that resolves with the edited certificate object.
 */
export default async function editCertificate(
    id: number,
    title: string,
    description: string,
    otherNames: string[],
    productName: string,
    manufacturer: string,
    link: string,
    prerequisites: string,
    cost: number,
    durationInDays: number,
)
{
    const variables: OperationVariables = {
        id,
        title,
        description,
        otherNames,
        productName,
        manufacturer,
        link,
        prerequisites: prerequisites ? prerequisites : "",
        cost,
        durationInDays,
    };
    return GraphQL.mutate<ICertificateDetailDTO>(query, variables)
        .then(s => new Certificate(s));
}