import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { Cancel, ChevronRight, DragIndicator, ExpandMore, Insights, Save } from '@mui/icons-material';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICategorizeSkill } from '@sharedInterfaces/ISkillCategory';
import { AppState } from '@store/store';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { SkillCategory } from '@src/Objects/SkillCategory';
import { setSuggestCategorizeSkills } from '@src/APIs/graphQl/SkillCategory/suggestCategorizeSkills';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { IISuggestCategorizationForSkillsWebsocketRequest, TISuggestCategorizationForSkillsWebsocketResponse } from '@sharedInterfaces/websocket/ISuggestCategorizationForSkillsWebsocketEvent';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';

import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import ErrorBox from '../../ErrorBox/ErrorBox';
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import LegendBox from '../../Legend/LegendBox';

interface CategorizeDialogProps
{
    id: string
    resolve?: () => void;
}
/**
 * Function to create a categorization dialog component.
 *
 * @returns {JSX.Element} The categorization dialog component.
 */
export function CategorizeDialog({ id, resolve: onClose }: CategorizeDialogProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);
    const skillCategories = useSelector((state: AppState) => state.company.skillCategories);
    const smallSkills = useSelector((state: AppState) => state.company.allSkills);

    const [skills, setSkills] = useState<ICategorizeSkill[]>([]);

    const [wishText, setWishText] = useState(langStrings.categorizeDialog.defaultFindCategoryForSkills as string);
    const [generating, setGenerating] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [draggedSkill, setDraggedSkill] = useState<ICategorizeSkill | null>(null);
    const [dragOverCategory, setDragOverCategory] = useState<number | null>(null);
    const [subKey, setSubKey] = React.useState<string>("");

    useEffect(() =>
    {
        setSkills(smallSkills);
    }, [smallSkills]);

    const onDragStart = (e: React.DragEvent, skill: ICategorizeSkill) =>
    {
        setDraggedSkill(skill);
    }

    const onDragDrop = (e: React.DragEvent, categoryId: number) =>
    {
        e.preventDefault();
        e.stopPropagation();
        if (!draggedSkill) return;
        const newSkills = cloneDeep(skills);
        const skillIndex = skills.findIndex(s => s.id === draggedSkill.id);
        const skill = newSkills[skillIndex];
        skill.categoryId = categoryId;
        setSkills(newSkills);
        setDraggedSkill(null);
        setDragOverCategory(null);
    }

    const onSave = async () =>
    {
        setSaving(true);
        await setSuggestCategorizeSkills(skills)
            .then(() =>
            {
                onClose && onClose();
                setSaving(false);
            })
            .catch(ex =>
            {
                showErrorDialog(ex);
                setTimeout(setSaving.bind(null, false), 2500)
            });
    }

    const topic = EWebsocketTopics.suggestCategorizationForSkillsWebsocket;
    function onGenerateCategories(): void
    {
        setGenerating(true);
        const request: IISuggestCategorizationForSkillsWebsocketRequest = {
            wishText,
            skills: skills.map((c) => ({ title: c.title, id: c.id, categoryId: c.categoryId })),
            key: subKey,
        };
        WebSocketClient.sendMessage(topic, request);
    }

    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<TISuggestCategorizationForSkillsWebsocketResponse>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if ('error' in data)
            {
                setErrorText(data.error)
                setTimeout(setGenerating.bind(null, false), 2500)
                return;
            }
            if (!data.skills)
            {
                setErrorText(langStrings.error.oftenUsed.error)
                setTimeout(setGenerating.bind(null, false), 2500)
                return;
            }
            setGenerating(false);

            const skills = data.skills;
            skills.sort((a, b) => a.title.localeCompare(b.title))
            setSkills(skills);
            setGenerating(false);
            setErrorText("");
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])

    const skillsInMain = skills.filter(s => s.categoryId === -1)
    const hasDragFocus = draggedSkill !== null && -1 === dragOverCategory

    const legendItems = [
        { title: langStrings.categorizeDialog.moved, colorVar: 'var(--var-primary-color)' },
        { title: langStrings.categorizeDialog.notMoved, colorVar: '#fff' },
    ];

    return <Dialog
        id={id}
        title={langStrings.categorizeDialog.suggestCategorisationTitle}
        footer={
            <div style={{
                marginTop: 10,
                display: 'flex',
                gap: 10,
                justifyContent: 'space-between'
            }}>
                <Button
                    size={'normal'}
                    text={langStrings.categorizeDialog.cancel}
                    icon={<Cancel />}
                    onClick={() =>
                    {
                        onClose && onClose()
                    }}
                />
                <Button
                    size={'normal'}
                    text={langStrings.categorizeDialog.useAndClose}
                    icon={<Save />}
                    onClick={onSave}
                    disabled={generating || saving}
                />
            </div>
        }
        onClose={() =>
        {
            onClose && onClose()
        }}
    >
        <div>
            <LegendBox items={legendItems} />
            <RowElement title={langStrings.categorizeDialog.existingCategories} style={{ width: '100%' }}>
                <TreeView
                    // key={skillCategories.length ? skillCategories[skillCategories.length - 1].id : 0}
                    style={{ width: '100%' }}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    defaultExpanded={["0", ...skillCategories.map(category => category.id.toString())]}
                >
                    <TreeItem
                        nodeId="0"
                        key={0}
                        onFocusCapture={(e) => { e.stopPropagation() }}
                        onDrop={(e) => { onDragDrop(e, -1) }}
                        onDragOver={(e: React.DragEvent) =>
                        {
                            setDragOverCategory(-1);
                            e.stopPropagation();
                            if (draggedSkill !== null && draggedSkill.categoryId !== -1)
                                e.preventDefault();
                        }}
                        label={
                            <div style={{ textDecoration: hasDragFocus ? 'underline' : 'auto' }}>
                                {langStrings.skillCategories.mainLevel}
                            </div>
                        }
                    >
                        <SkillList
                            skills={skillsInMain}
                            onDragStart={onDragStart}
                        />
                        <TreeRenderer
                            categories={skillCategories}
                            parentId={null}
                            skills={skills}
                            draggedSkill={draggedSkill}
                            dragOverCategory={dragOverCategory}
                            onDragStart={onDragStart}
                            setDragOverCategory={setDragOverCategory}
                            onDragDrop={onDragDrop}
                        />
                    </TreeItem>
                </TreeView>
            </RowElement>

            <Row>
                <RowElement title={langStrings.categorizeDialog.yourAiWish} alignTitle='left'>
                    <TextInput value={wishText} onChange={function (val: string): void
                    {
                        setWishText(val);
                    }} />
                </RowElement>
                <Button
                    icon={<Insights />}
                    text={langStrings.categorizeDialog.generate}
                    size={'normal'}
                    onClick={onGenerateCategories}
                    disabled={generating || saving}
                />
            </Row>
            {errorText && <ErrorBox close={() => { setErrorText("") }}>{errorText}</ErrorBox>}
            <Typography variant='body2'>
                {langStrings.categorizeDialog.explainWishTextSkills}
            </Typography>
        </div>
        {(generating || saving) && <LoadingDialog />}
    </Dialog>;
}




interface TreeRendererProps
{
    categories: SkillCategory[];
    parentId: number | null;
    skills: ICategorizeSkill[];
    draggedSkill: ICategorizeSkill | null;
    dragOverCategory: number | null;
    setDragOverCategory: (id: number | null) => void;
    onDragStart: (e: React.DragEvent, skill: ICategorizeSkill) => void;
    onDragDrop: (e: React.DragEvent, categoryId: number) => void;
}

/**
 * Renders a tree view of categories and skills with drag and drop functionality.
 *
 * @param {TreeRendererProps} categories - The categories to render
 * @param {TreeRendererProps} parentId - The parent ID of the categories
 * @param {TreeRendererProps} skills - The skills to render
 * @param {TreeRendererProps} onDragStart - The function to call when a drag operation starts
 *
 * @returns {JSX.Element} The rendered tree view
 */
function TreeRenderer({ categories, parentId, skills, draggedSkill, dragOverCategory, onDragDrop, setDragOverCategory, onDragStart }: TreeRendererProps)
{
    const categoriesToRender = categories
        .filter(category => category.parentCategory === parentId);

    return <>
        {
            categoriesToRender
                .map(category =>
                {
                    const hasDragFocus = draggedSkill !== null && category.id === dragOverCategory
                    const skillsInCat = skills.filter(skill => skill.categoryId === category.id);
                    return <TreeItem
                        key={categoriesToRender.length ? `${category.id}-${categoriesToRender[categoriesToRender.length - 1].id}-${hasDragFocus}` : `${category.id}-${hasDragFocus}`}
                        onFocusCapture={(e) => { e.stopPropagation() }}
                        onDrop={(e) => { onDragDrop(e, category.id) }}
                        onDragOver={(e: React.DragEvent) =>
                        {
                            setDragOverCategory(category.id)
                            e.stopPropagation();
                            if (draggedSkill !== null && draggedSkill.categoryId !== category.id)
                                e.preventDefault();
                        }}
                        nodeId={category.id.toString()}
                        label={
                            <div style={{ textDecoration: hasDragFocus ? 'underline' : 'auto' }}>
                                {
                                    category.title
                                }
                            </div>
                        }
                    >
                        <SkillList
                            skills={skillsInCat}
                            onDragStart={onDragStart}
                        />

                        <TreeRenderer
                            categories={categories}
                            parentId={category.id}
                            skills={skills}
                            draggedSkill={draggedSkill}
                            dragOverCategory={dragOverCategory}
                            setDragOverCategory={setDragOverCategory}
                            onDragStart={onDragStart}
                            onDragDrop={onDragDrop}
                        />
                    </TreeItem >
                })
        }</>
}

interface SkillListProps
{
    skills: ICategorizeSkill[];
    onDragStart: (e: React.DragEvent, skill: ICategorizeSkill) => void;
}

/**
 * Renders a list of skills.
 * 
 * @param { skills: ICategorizeSkill[]; } skills - An array of skill categories.
 * @returns {JSX.Element} - The rendered list of skills.
 */
function SkillList({ skills, onDragStart }: SkillListProps)
{
    const smallSkills = useSelector((state: AppState) => state.company.allSkills);
    //Compare skills category with smallSkills category
    const modifiedSkillIds = skills.filter(skill =>
    {
        const originalSkill = smallSkills.find(s => s.id === skill.id);
        if (originalSkill)
            return originalSkill.categoryId !== skill.categoryId;
        return false;
    }).map(skill => skill.id)

    return <div
        style={{
            display: 'flex',
            gap: '0px 5px',
            flexWrap: 'wrap',
            justifyContent: 'left',
            pointerEvents: 'auto',
        }}
    >
        {skills.map(skill => <DrabableSkillItem
            key={skill.id}
            skill={skill}
            modified={modifiedSkillIds.includes(skill.id)}
            onDragStart={onDragStart}
        />)}
    </div>
}

interface DragableSkillItemProps
{
    skill: ICategorizeSkill;
    modified: boolean;
    onDragStart: (e: React.DragEvent, skill: ICategorizeSkill) => void;
}

/**
 * Represents a draggable skill item component.
 * 
 * @param {Object} skill - The skill object to be rendered in the draggable item.
 * @returns {JSX.Element} - The JSX element representing the draggable skill item.
 */
function DrabableSkillItem({ skill, modified, onDragStart }: DragableSkillItemProps)
{
    return <div
        draggable
        onDragStart={(e) =>
        {
            onDragStart(e, skill)
        }}
        style={{
            display: 'flex',
            cursor: 'move',
            alignItems: 'center',
            pointerEvents: 'auto',
            // justifyContent: 'space-between',
            gap: '2px',
            margin: '3px 0',
            padding: '2px 3px',
            border: 'solid 1px #efefef',
            borderRadius: 10,
            boxShadow: '0 5px 3px 0 rgba(0, 0, 0, .2)',
            userSelect: 'none',
            flexGrow: 1,
            fontWeight: 600,
            transition: 'background-color 0.5s, box-shadow 0.5s, border 0.5s',
            color: modified ? 'var(--var-on-primary-color)' : '#000',
            backgroundColor: modified ? 'var(--var-primary-color)' : 'rgb(255, 255, 255)',
        }}
    >
        <IconButton size='small' style={{
            color: modified ? 'var(--var-on-primary-color)' : '#000',
        }}>
            <DragIndicator fontSize='small' />
        </IconButton>
        <Typography variant='body2'>
            {skill.title}
        </Typography>
    </div>
}
