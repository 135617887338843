import { OperationVariables, gql } from "@apollo/client";
import { ICategorizeSkill, ISetSuggestCategorizeSkillsRequest, } from "@sharedInterfaces/ISkillCategory";
import { ISmallSkill } from "@sharedInterfaces/IWhoIAm";
import store from "@store/store";
import { setCompanyAllSkills, } from "@store/reducer/companyReducer";

import GraphQL from "../graphQL";



const querySet = gql`
mutation setSuggestCategorizeSkills($skills:[SuggestCategorizeSkillInput!]!) {
    setSuggestCategorizeSkills(skills: $skills) 
    {
        title,
        id,
        categoryId,
        otherNames,
    }
},
`;

export async function setSuggestCategorizeSkills(skills: ICategorizeSkill[]):
    Promise<ISmallSkill[]>
{
    const variables: OperationVariables = {
        skills: skills.map((c) => ({ title: c.title, id: c.id, categoryId: c.categoryId }))
    } as ISetSuggestCategorizeSkillsRequest;

    const requestResult = await GraphQL.mutate<ISmallSkill[]>(querySet, variables)
    store.dispatch(setCompanyAllSkills(requestResult));
    return requestResult
}