import { Done, ForwardToInbox, Mail } from '@mui/icons-material';
import { Checkbox, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ICompanyEmailSettings } from '@sharedInterfaces/ICompany';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EMailCycle } from '@sharedInterfaces/globalEnums';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import Button from '@sharedReact/General/Button/Button';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { Container } from '@sharedReact/Layouts/LayoutElements/Container/Container';
import companyEmailSettingsSendMail from '@src/APIs/graphQl/Company/companyEmailSettingsSendMail';
import editCompanyEmailSettings from '@src/APIs/graphQl/Company/editCompanyEmailSettings';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import Company from '@src/Objects/Company';
import { AppState } from '@store/store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface EmailSettingsPageProps
{
    emailSettings: ICompanyEmailSettings,
    setCompany: (company: Company) => void;
}
export default function EmailSettingsPage({ emailSettings, setCompany }: EmailSettingsPageProps)
{
    const forecastMail = <ForecastSettings emailSettings={emailSettings} setCompany={setCompany} />;
    const certificateMail = <CertificateMailSettings emailSettings={emailSettings} setCompany={setCompany} />
    return <Container>
        {forecastMail}
        {certificateMail}
    </Container>
}

interface CertificateMailSettingsProps
{
    emailSettings: ICompanyEmailSettings,
    setCompany: (company: Company) => void;
}
function CertificateMailSettings({ emailSettings, setCompany }: CertificateMailSettingsProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [saving, setSaving] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(emailSettings.sendCertificateReminder ? true : false);

    useEffect(() =>
    {
        setChecked(emailSettings.sendCertificateReminder ? true : false);
    }, [emailSettings, emailSettings.sendCertificateReminder]);
    return <div>
        <Typography variant='h4'>
            {langStrings.certificateReminder}
        </Typography>
        <RowElement title={langStrings.activateCertificateReminder}>
            <Checkbox disabled={saving}
                checked={checked}
                onChange={(e) =>
                {
                    setSaving(true);
                    editCompanyEmailSettings(emailSettings.forecastCycle, emailSettings.forecastText, e.target.checked)
                        .then(setCompany)
                        .catch(err =>
                        {
                            showErrorDialog(err)
                            setChecked(emailSettings.sendCertificateReminder ? true : false)
                        })
                        .finally(() => setSaving(false));
                }}
            />
            {saving && <LoadingBox />}
        </RowElement>
    </div>
}


interface ForecastSettingsProps
{
    emailSettings: ICompanyEmailSettings,
    setCompany: (company: Company) => void;
}
function ForecastSettings({ emailSettings, setCompany }: ForecastSettingsProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    const [cycle, setCycle] = useState(emailSettings.forecastCycle);
    const [forecastText, setForecastText] = useState(emailSettings.forecastText);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);

    const sended = () =>
    {

    }
    const handleChange = (event: SelectChangeEvent<string>) =>
    {
        setCycle(event.target.value as EMailCycle);
        setChanged(true);
    };
    return <div>
        <Typography variant='h4'>
            {langStrings.forecastMail}
        </Typography>
        <RowElement title={langStrings.mailSendHowOften}>
            <Select
                labelId="sendHowOften"
                id="sendHowOften"
                value={cycle}
                label={langStrings.mailSendHowOften}
                onChange={handleChange}
            >
                <MenuItem value={EMailCycle.NEVER}>{langStrings.oftenUsed.never}</MenuItem>
                <MenuItem value={EMailCycle.MONTLY}>{langStrings.oftenUsed.monthly}</MenuItem>
            </Select>
        </RowElement>
        <RowElement title={langStrings.mailForecastText}>
            <FormatedTextInput value={forecastText} onChange={(text) => { setForecastText(text); setChanged(true); }} />
        </RowElement>
        {saveError && <ErrorBox close={setSaveError.bind(null, null)}>
            {saveError}
        </ErrorBox>}
        {sending && <Typography variant='body1'>{langStrings.oftenUsed.sending}</Typography>}
        {changed &&
            <Button
                style={{ maxWidth: 250, marginBottom: 10 }}
                disabled={saving}
                size={'normal'} text={langStrings.save} icon={<Done />} onClick={function (): void
                {
                    setSaving(true);
                    editCompanyEmailSettings(cycle, forecastText, emailSettings.sendCertificateReminder)
                        .then(setCompany).then(setChanged.bind(null, false))
                        .catch(err => setSaveError(err.toString()))
                        .finally(() => setSaving(false));
                }} />}

        <>
            <Button
                style={{ maxWidth: 250, marginBottom: 10 }}
                disabled={saving}
                size={'normal'} text={langStrings.sendToMe} icon={<Mail />} onClick={function (): void
                {
                    setSaving(true);
                    setSending(true);
                    companyEmailSettingsSendMail(true, forecastText)
                        .then(sended)
                        .catch(err => setSaveError(err.toString()))
                        .finally(() =>
                        {
                            setSaving(false);
                            setSending(false);
                        });
                }} />

            <Button
                style={{ maxWidth: 250 }}
                color='secondary'
                disabled={saving || changed}
                size={'normal'} text={langStrings.sendNow} icon={<ForwardToInbox />} onClick={function (): void
                {
                    const confirmed = confirm(langStrings.areYouSureForecastMail);
                    if (!confirmed) return;
                    setSaving(true);
                    setSending(true);
                    companyEmailSettingsSendMail(false)
                        .then(sended)
                        .catch(err => setSaveError(err.toString()))
                        .finally(() =>
                        {
                            setSaving(false);
                            setSending(false);
                        });
                }} />
            <Typography variant='subtitle2'>
                {langStrings.sendNowSubtitle}
            </Typography>
        </>
    </div>;
}