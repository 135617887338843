import store from '@store/store';
import { stageContext } from '@src/globals';
import { ApolloClient, DocumentNode, FetchPolicy, InMemoryCache, OperationVariables, defaultDataIdFromObject } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { setLoading, setOffline } from '@store/storeHelper';

const isoDateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertValue = (value: any): any =>
{
  if (typeof value === "string" && isoDateStringRegex.test(value))
  {
    const date = new Date(value);
    if (!isNaN(date.getTime()))
    {
      return date;
    }
  } else if (Array.isArray(value))
  {
    return value.map((item) => convertValue(item));
  } else if (typeof value === "object" && value !== null)
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertedObj: any = {};
    for (const key in value)
    {
      if (key !== '__typename')
      {
        convertedObj[key] = convertValue(value[key]);
      }
    }
    return convertedObj;
  }
  return value;
};

export interface CustomGraphQLError
{
  statusCode: number,
  message: string
}
const generateRandomString = () =>
{
  return Math.random().toString(36).substring(2, 15);
};

const cache = new InMemoryCache({
  dataIdFromObject: (object) =>
  {
    const randomString = generateRandomString();
    switch (object.__typename)
    {
      case 'EmployeeListDetailSkills':
        return `${object.__typename}:${object.id}:${object.level}:${randomString}`;
      case 'SuggestedSkill':
        return `${object.__typename}:${object.id}:${object.level}:${object.title}:${randomString}`;
      case 'SuggestedCertificate':
        return `${object.__typename}:${object.id}:${object.title}:${randomString}`;
      case 'EmployeeCoverageSkill':
        return `${object.__typename}:${object.id}:${object.level}:${object.wantedLevel}:${randomString}`;
      case 'CompetenceLevelSkill':
        return `${object.__typename}:${object.id}:${object.level}:${randomString}`;
      case 'AllCompetence':
        return `${object.__typename}:${object.id}:${object.title}:${randomString}`;
      case 'SmallRole':
        return `${object.__typename}:${object.id}:${object.title}:${randomString}`;
      case 'CompetenceLevel':
        return `${object.__typename}:${object.id}:${object.title}:${randomString}`;
      case 'OUAvailabilityDataEmployee':
        return `${object.__typename}:${object.id}:${object.title}:${randomString}`;
      case 'EmployeeCoverage':
        return `${object.__typename}:${object.id}:${object.score}:${randomString}`;
      case 'EmployeeSettingsCompetenceSettingsWantedCompetence':
        return `${object.__typename}:${object.id}:${object.level}:${randomString}`;
      default:
        return defaultDataIdFromObject(object); // Standardverhalten
    }
  }
});
export default class GraphQL
{
  static client: ApolloClient<unknown> | null = null;

  static initClient()
  {
    const secret = store.getState().client.secret;
    if (!secret)
    {
      throw new Error("No secret");
    }
    GraphQL.client = new ApolloClient({
      uri: `${stageContext.apiGatewayIdGraphQLDomain}/`,
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only' as FetchPolicy
        },
      },
      headers: {
        Authorization: secret,
      },
    });
    return GraphQL.client;
  }


  static query = async <T>(
    query: DocumentNode,
    variables: OperationVariables,
    callback?: (obj: T | null) => void,
  ) =>
  {
    const handleResult = (result: T | null) =>
    {
      if (result)
      {
        callback && callback(result);
      }
    };
    return new Promise<T>((resolve, reject: (result: CustomGraphQLError) => void) =>
    {
      setLoading(true)
      const client = GraphQL.initClient();
      client.query<T>({
        query,
        variables,
      }).then(data =>
      {
        setOffline(false)
        setLoading(false)
        const convertedData = convertValue(data.data);
        handleResult(convertedData);
        resolve(convertedData);
      }).catch(ex =>
      {
        setLoading(false)
        if (ex.networkError)
        {
          console.log(ex.networkError)
          if (ex.networkError.response?.status === 401)
          {
            return reject({ statusCode: 401, message: "Forbidden" });
          }
          setOffline(true)
          return console.warn("Unknown Networkerror, maybe offline");
        } else if (ex.graphQLErrors?.some((e: GraphQLError) => e.extensions.code === "FORBIDDEN"))
        {
          console.log("FORBIDDEN");
          return reject({ statusCode: 401, message: "Forbidden" });
        }
        const statusCode = ex.networkError?.statusCode;
        if (statusCode === 401)
        {
          // localStorage.clear();
          // window.location.reload();
        } else
          return reject({ statusCode: 400, message: ex.toString() });
      });
    })
  };

  static async mutate<T>(mutation: DocumentNode, variables: OperationVariables, errorCount = 0): Promise<T>
  {
    setLoading(true)
    return new Promise((resolve, reject) =>
    {
      const client = GraphQL.initClient();
      client.mutate({
        mutation,
        variables
      })
        .then(result =>
        {
          const data = convertValue(result.data);
          const key = Object.keys(data)[0];
          setLoading(false)
          setOffline(false)
          resolve(data[key] as T)
        })
        .catch(e =>
        {
          if (e.networkError?.statusCode)
          {
            const statusCode = e.networkError?.statusCode;
            if (statusCode === 503 && errorCount <= 3)
            {
              console.log("Service Unaviable, retry" + errorCount);
              setTimeout(() =>
              {
                this.mutate<T>(mutation, variables, errorCount + 1).then(d =>
                  resolve(d)
                ).catch(ex => reject(ex));
              }, errorCount * 1000);
              return
            }
          } else if (!e.graphQLErrors?.length)
          {
            setOffline(true)
          }

          setLoading(false)
          return reject(e)
        });
    });
  }
}
/**
 * Polls the given request function until it finishes, indicating completion by returning a non-null value.
 * Repeatedly invokes the request function at specified intervals until a truthy value is returned.
 *
 * @template T The type of the expected result.
 * @param {() => Promise<T | null>} request - A function returning a Promise that resolves to the expected result or null if not yet completed.
 * @returns {Promise<T>} A promise that resolves with the result of the request function once it returns a non-null value.
 */
export async function pollUntilFinished<T>(request: () => Promise<T | null>): Promise<T>
{
  const startTime = Date.now(); // Erfasse die Startzeit
  const timeoutDuration = 300000; // 5 Minuten in Millisekunden
  let response = await request().catch(() =>
  {
    throw new Error('Die Kategorieerzeugung ist fehlgeschlagen.');
  });
  while (!response)
  {
    if (Date.now() > startTime + timeoutDuration)
    {
      // Wenn die aktuelle Zeit die Startzeit plus die Timeout-Dauer überschreitet, wirf einen Fehler
      throw new Error('Der Prozess hat zu lange gedauert und wurde abgebrochen.');
    }

    await new Promise(resolve => setTimeout(resolve, 5000));
    response = await request().catch(() =>
    {
      throw new Error('Die Kategorieerzeugung ist fehlgeschlagen.');
    });
  }
  return response;
}