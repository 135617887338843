import { OperationVariables, gql } from "@apollo/client";
import IRole from "@sharedInterfaces/IRole";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteRole, updateRole } from "@store/reducer/rolesReducer";
import Role, { RoleGQL } from "@src/Objects/Role";

import GraphQL from "../graphQL";
import { NotFoundError } from '../NotFoundError';


const q = gql`
query GetRole($roleId: ID!) {
    role(id: $roleId) 
    ${RoleGQL}
},
`;


/**
 * Retrieves a role by roleId.
 *
 * @param {number} roleId - The ID of the role to retrieve.
 * @returns {Promise<Role>} A promise that resolves with the retrieved role.
 */
export default async function getRole(roleId: number)
{
    const variables: OperationVariables = { roleId };
    return GraphQL.query<{ role: IRole }>(q, variables).then((obj) =>
    {
        if (!obj?.role)
        {
            store.dispatch(deleteRole(roleId));
            throw new NotFoundError(roleId, EEntityType.ROLE)
        }
        const newRole = new Role(obj.role);
        store.dispatch(updateRole(newRole));
        return newRole;
    })
}
