import { OperationVariables, gql } from "@apollo/client";
import IOpportunity, { IOpportunityEdit } from "@sharedInterfaces/IOpportunity";
import Opportunity from "@src/Objects/Opportunity";

import GraphQL from "../graphQL";

import { opportunityGql } from "./getOpportunity";


const query = gql`
mutation editOpportunity($changedOpportunity:OpportunityEdit!) {
    editOpportunity(changedOpportunity: $changedOpportunity) 
    ${opportunityGql}
},
`;

export default async function editOpportunity(changedOpportunity: IOpportunityEdit): Promise<Opportunity>
{
    const variables: OperationVariables = {
        changedOpportunity: {
            ...changedOpportunity,
            type: undefined,
            createdBy: undefined,
            changedBy: undefined,
            createdAt: undefined,
            changedAt: undefined,
            roles: changedOpportunity.roles.map(role => ({ id: role.id, title: role.title }))
        }
    };
    return GraphQL.mutate<IOpportunity>(query, variables).then(result => new Opportunity(result))
}