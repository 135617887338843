import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation createNewApiKey($note: String!) {
    createNewApiKey(note: $note) 
    {
        companySettings ${companySettingsGql}
        apiKey
    }
},
`;


export default async function createNewApiKey(note: string)
{
    const variables: OperationVariables = {
        note
    };
    return GraphQL.mutate<{ companySettings: ICompanySettings, apiKey: string }>(query, variables)
        .then(c => ({
            company: new Company(c.companySettings),
            apiKey: c.apiKey,
        }));
}