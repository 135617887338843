import React, { ChangeEvent, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Dialog, IconButton, Typography } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';
import { setEmployeePictureId } from '@store/reducer/employeeReducer';
import getEmployeePictureId from '@src/APIs/graphQl/Employee/getEmployeePictureId';
import getUploadURLProfilePicture from '@src/APIs/graphQl/Employee/getUploadURLProfilePicture';
import { generateEmployeePictureUrl } from '@sharedInterfaces/globalEnums';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import { uploadFile } from '../Dialogs/AnalyseSkillsDialog/AnalyseCvDialog';

import styles from './EmployeePicture.module.css';
/**
 * Generates the JSX element for the employee picture.
 *
 * @param {Object} param - The parameter object.
 * @param {number} param.avatarSize - The size of the avatar. Default value is 38.
 * @param {import(".../Employee").default} param.employee - The employee object.
 *
 * @returns {JSX.Element} The JSX element for the employee picture.
 */
export function EmployeePicture({ avatarSize = 38, employee, allowUpload, openDialogOnClick }:
    {
        avatarSize: number;
        allowUpload?: true;
        openDialogOnClick?: boolean,
        employee: { firstName: string; lastName: string; pictureId: string | null; id: number; };
    })
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const width = useSelector((state: AppState) => state.windowSize.width);
    if (width < 500 && avatarSize > 50) avatarSize = 50;
    const companyId = useSelector((state: AppState) => state.company.companyId);
    const employeeId = useSelector((state: AppState) => state.employee.id);
    const actualPictureId = useSelector((state: AppState) => state.employee.pictureId);
    const [imageLoaded1, setImageLoaded1] = useState<boolean>(false);
    const [imageLoaded2, setImageLoaded2] = useState<boolean>(false);
    const [imageLoaded1000, setImageLoaded1000] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState(false);
    const [saving, setSaving] = useState(false);
    const offline = useSelector((state: AppState) => state.client.offline);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const nameShort = (employee.firstName ? employee.firstName[0] : '') + (employee.lastName ? employee.lastName[0] : '');

    const normalContent = <>
        {employee.pictureId && <div className={styles.normalContent}>
            {avatarSize > 38 &&
                <img
                    src={generateEmployeePictureUrl(companyId, employee, 100)}
                    width={avatarSize}
                    height={avatarSize}
                    onLoad={setImageLoaded1.bind(null, true)}
                    style={{
                        display: imageLoaded1 ? 'block' : 'none',
                    }} />
            }
            <img
                src={generateEmployeePictureUrl(companyId, employee, 38)}
                width={avatarSize}
                height={avatarSize}
                onLoad={setImageLoaded2.bind(null, true)}
                style={{
                    display: imageLoaded2 && !imageLoaded1 ? 'block' : 'none',
                }} />
            {(!imageLoaded1 && !imageLoaded2) &&
                nameShort
            }
        </div>}
        {!employee.pictureId &&
            <div className={styles.normalContent}>
                {nameShort}
            </div>
        }
    </>

    const checkForUpdate = () =>
    {
        getEmployeePictureId(employeeId, pictureId =>
        {
            if (pictureId !== actualPictureId)
            {
                dispatch(setEmployeePictureId(pictureId))
                setSaving(false);
            } else
            {
                setTimeout(checkForUpdate, 3000);
            }
        })
    }

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) =>
    {
        if (!e.target.files) return;
        setSaving(true);
        const file = e.target.files[0];
        await getUploadURLProfilePicture(async (post) =>
        {
            if (!post) return;
            await uploadFile(post, file)
                .catch(ex => showErrorDialog(ex));
            setTimeout(checkForUpdate, 3000);
        }).catch(ex =>
        {
            setSaving(false);
            alert(ex.toString())
        });
    };

    const onClick = () =>
    {
        if (openDialogOnClick) return setShowDialog(true);
        if ((allowUpload && !offline) && fileInputRef.current)
        {
            fileInputRef.current.click();
            return;
        }
    }

    const avatar = <Avatar
        onClick={onClick}
        alt={generateFullName(employee.firstName, employee.lastName)}
        title={generateFullName(employee.firstName, employee.lastName)}
        sx={{
            width: avatarSize,
            height: avatarSize,
            fontSize: avatarSize < 100 ? 16 : 32,
            lineHeight: `${avatarSize}px`,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'var(--var-secondary-color)',
            color: 'var(--var-on-secondary-color)',
            padding: '0px', // Adjust padding as needed
        }}
    >
        {normalContent}
        {
            (allowUpload && !offline) &&
            <div className={styles.uploadButton}>
                {
                    !saving &&
                    < IconButton >
                        <Upload fontSize='large' style={{
                            color: 'var(--var-on-secondary-color)',
                        }} />
                    </IconButton>
                }
            </div>
        }
    </Avatar >;

    return (
        <>
            <div className={`${styles.employeePicture} ${(allowUpload && !offline) ? styles.uploadAvatar : ''}`}>
                {
                    avatar
                }
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
            />
            <Dialog
                style={{ zIndex: 99999 }}
                open={showDialog}
                onClose={setShowDialog.bind(null, false)}>
                <img
                    src={generateEmployeePictureUrl(companyId, employee, 1000)}
                    alt={generateFullName(employee.firstName, employee.lastName)}
                    title={generateFullName(employee.firstName, employee.lastName)}
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: imageLoaded1000 ? 'block' : 'none',
                    }}
                    onLoad={setImageLoaded1000.bind(null, true)}
                />
                <img
                    src={generateEmployeePictureUrl(companyId, employee, 100)}
                    alt={generateFullName(employee.firstName, employee.lastName)}
                    title={generateFullName(employee.firstName, employee.lastName)}
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: !imageLoaded1000 ? 'block' : 'none',
                    }}
                />
                <Typography variant="h4" align="center">
                    {`${employee.firstName} ${employee.lastName}`}
                </Typography>
            </Dialog>
        </>
    );
}

