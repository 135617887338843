import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation changeCompanyName($name: String!) {
    changeCompanyName(name: $name) 
    ${companySettingsGql}
},
`;

export default async function changeCompanyName(name: string)
{
    const variables: OperationVariables = { name };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}