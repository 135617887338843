import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IProjectsTexts extends ITextBase
{
    startDate: string
    endDate: string
    internalProject: string
    missingPrice: string
    isHours: string
    planHours: string
    notFound: string
    search: string
    revenue: string
    editProject: string
    dayPrice: string
    tenantId: string
    hours: string
    no: string
    yes: string
    active: string
    empty: string
    edit: string
    delete: string
    employees: string
    create: string
    projectName: string
    description: string
    newProject: string
    projects: string
    newProjectError: string
    save: string
    customer: string


}
type ILangData = {
    [lang in ELanguage]: IProjectsTexts
}
export const projectsTexts: ILangData = {
            'th': {
                endDate: 'สิ้นสุดโครงการ',
        startDate: 'เริ่มต้นโครงการ',
        internalProject: 'โครงการภายใน',
                missingPrice: 'ไม่มีราคา',
                oftenUsed: oftenUsedTranslations['th'],
                projects: oftenUsedTranslations['th'].projects,
                newProject: 'โครงการใหม่',
                editProject: 'แก้ไขโครงการ: ',
                projectName: 'ชื่อโครงการ',
                description: oftenUsedTranslations['th'].description,
                create: oftenUsedTranslations['th'].create,
                newProjectError: 'กรุณาใส่ชื่อโครงการ',
                employees: oftenUsedTranslations['th'].employees,
                edit: oftenUsedTranslations['th'].edit,
                delete: oftenUsedTranslations['th'].delete,
                save: oftenUsedTranslations['th'].save,
                empty: oftenUsedTranslations['th'].empty,
                yes: oftenUsedTranslations['th'].yes,
                no: oftenUsedTranslations['th'].no,
                active: oftenUsedTranslations['th'].active,
                hours: oftenUsedTranslations['th'].hours,
                tenantId: 'ID Clockodo',
                dayPrice: 'อัตราต่อวัน',
                revenue: oftenUsedTranslations['th'].revenue,
                search: oftenUsedTranslations['th'].search,
                notFound: oftenUsedTranslations['th'].notFound,
                customer: 'ลูกค้า',
                isHours: 'ชั่วโมงจริง',
                planHours: 'ชั่วโมงที่วางแผน',
            },
            'en': {
                endDate: 'Project End',
        startDate: 'Project Start',
        internalProject: 'Internal project',
                missingPrice: 'No price',
                oftenUsed: oftenUsedTranslations['en'],
                projects: oftenUsedTranslations['en'].projects,
                newProject: 'New project',
                editProject: 'Edit project: ',
                projectName: 'Projectname',
                description: oftenUsedTranslations['en'].description,
                create: oftenUsedTranslations['en'].create,
                newProjectError: 'Please enter a project name.',
                employees: oftenUsedTranslations['en'].employees,
                edit: oftenUsedTranslations['en'].edit,
                delete: oftenUsedTranslations['en'].delete,
                save: oftenUsedTranslations['en'].save,
                empty: oftenUsedTranslations['en'].empty,
                yes: oftenUsedTranslations['en'].yes,
                no: oftenUsedTranslations['en'].no,
                active: oftenUsedTranslations['en'].active,
                hours: oftenUsedTranslations['en'].hours,
                tenantId: 'Clockodo ID',
                dayPrice: 'Daily rate',
                revenue: oftenUsedTranslations['en'].revenue,
                search: oftenUsedTranslations['en'].search,
                notFound: oftenUsedTranslations['en'].notFound,
                customer: 'Customer',
                isHours: 'Actual Hours',
                planHours: 'Planned Hours',
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                projects: oftenUsedTranslations['de'].projects,
                newProject: 'Neues Projekt',
                editProject: 'Projekt bearbeiten: ',
                projectName: 'Projektname',
                description: oftenUsedTranslations['de'].description,
                create: oftenUsedTranslations['de'].create,
                newProjectError: 'Bitte gebe einen Projektnamen ein.',
                employees: oftenUsedTranslations['de'].employees,
                edit: oftenUsedTranslations['de'].edit,
                delete: oftenUsedTranslations['de'].delete,
                save: oftenUsedTranslations['de'].save,
                empty: oftenUsedTranslations['de'].empty,
                yes: oftenUsedTranslations['de'].yes,
                no: oftenUsedTranslations['de'].no,
                active: oftenUsedTranslations['de'].active,
                hours: oftenUsedTranslations['de'].hours,
                tenantId: 'Clockodo ID',
                dayPrice: 'Tagessatz',
                revenue: oftenUsedTranslations['de'].revenue,
                search: oftenUsedTranslations['de'].search,
                notFound: oftenUsedTranslations['de'].notFound,
                customer: 'Kunde',
                isHours: 'Ist Stunden',
                planHours: 'Soll Stunden',
                missingPrice: 'Ohne Preis',
                internalProject: 'Internes Projekt',
                startDate: 'Projekt-Start',
                endDate: 'Projekt-Ende',
            },
        }