import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../../graphQL";


const query = gql`
mutation cofigureWantedCompetence($id:Int!, $level:Int!, $untilDate:DateTime!) {
    cofigureWantedCompetence(id: $id, level: $level, untilDate: $untilDate) 
},
`;

/**
 * Configures a wanted competence.
 *
 * @param {number} id - The ID of the competence.
 * @param {number} level - The level of the competence.
 * @param {Date} untilDate - The date until the competence is wanted.
 *
 * @returns {Promise<Certificate>} - A promise that resolves to the configured competence.
 */
export default async function cofigureWantedCompetence(id: number, level: number, untilDate: Date)
{
    const variables: OperationVariables = {
        id,
        level,
        untilDate,
    };
    return GraphQL.mutate<boolean>(query, variables)
}