import React, { } from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';

import styles from './List.module.css';
interface ListProps
{
    children?: React.ReactNode;
    isLoading?: boolean;
}

const List: React.FC<ListProps> = ({ children, isLoading }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;

    let content = children;
    if (isLoading || !children)
    {
        content = <LoadingBox />;
    }
    else if (!React.Children.count(children))
    {
        content = <p className={styles.emptyListText}>{langStrings.oftenUsed.empty}</p>;
    }

    return (
        <div className={styles.listContainer}>
            {content}
        </div>
    );
};

export default List;


/**
 * Generates a text based on the given title and topX value.
 *
 * @param {string} title - The title to be used in the generated text.
 * @param {number} topX - The value to determine the number of items in the text.
 * @returns {string} The generated text.
 */
export function generateTopXText(title: string, topX?: number): string
{
    if (!topX) return title;
    return `Top ${topX} - ${title}`;
}
