import { Add } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { ISmallSkill } from "@sharedInterfaces/IWhoIAm";
import Button from "@sharedReact/General/Button/Button";
import Item from "@src/App/NewLayout/Items/Item/Item";
import { INewSkill } from "@src/Components/EditSkillList/EditSkillList";

import SkillSearchBox from "../../searchBoxes/SkillSearchBox/SkillSearchBox";
import RateBar from "../RateBar/RateBar";

interface NewSkillRowProps
{
    maxLevel: number;
    filteredAllSkills: ISmallSkill[];
    newSkill: INewSkill;
    allowNewSkills?: true;
    minLevel?: number;
    onNewSkillChange: (newSkill: INewSkill) => void;
    onAddClick: () => void;
}
const NewSkillRow: React.FC<NewSkillRowProps> = ({ minLevel, maxLevel, filteredAllSkills, newSkill, allowNewSkills, onNewSkillChange, onAddClick }) =>
{
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            if (elementRef.current && newSkill.title !== '')
            {
                const element: HTMLInputElement | null | HTMLSelectElement = elementRef.current.querySelector('.rateBarInputElement');
                element && element.focus()
            }
        }, 50);
    }, [newSkill.title, elementRef])
    return (
        <div className="newSkillRow" ref={elementRef} style={{ "--percent": `${newSkill.level / maxLevel * 100}`, order: 999 } as React.CSSProperties}>
            <Item
                rightContent={
                    <Button
                        size="verysmall"
                        onClick={() =>
                        {
                            onAddClick();
                            setTimeout(() =>
                            {
                                if (elementRef.current)
                                {
                                    const element: HTMLInputElement | null | HTMLSelectElement = elementRef.current.querySelector('.entitySearchBoxInput input');
                                    element && element.focus()
                                }
                            }, 50);
                        }}
                        icon={<Add />}
                        disabled={newSkill.title === '' || newSkill.level === 0}
                        text="Add"
                    />
                }
            >
                <SkillSearchBox
                    filteredAllSkills={filteredAllSkills}
                    newSkill={newSkill}
                    size="small"
                    allowNew={allowNewSkills}
                    setNewSkill={(newSkill) => { onNewSkillChange({ ...newSkill, level: 0 }) }}
                />
                {newSkill.title !== "" &&
                    <RateBar
                        minLevel={minLevel}
                        level={newSkill.level}
                        name={newSkill.title}
                        setValue={(newLevel) => onNewSkillChange({ ...newSkill, level: newLevel })}
                    />}

            </Item>
        </div>
    );
};
export default NewSkillRow;
