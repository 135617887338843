import React from 'react';

import './InnerLayout.css';
import Content from '../LayoutElements/Content/Content';
import ContentHeader from '../LayoutElements/ContentHeader/ContentHeader';


interface InnerLayoutProps
{
  inDialog?: boolean
  title?: React.ReactNode;
  bigRightBox?: JSX.Element
  informations?: React.ReactNode;
  buttons?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * InnerLayout component
 * 
 * @param {InnerLayoutProps} props - Props containing the data for the InnerLayout component
 * @returns {JSX.Element} The JSX element representing the InnerLayout component
 */
function InnerLayout(props: InnerLayoutProps)
{
  const content = <Content contentHeader={
    <ContentHeader
      title={props.title}
      bigLeftBox={props.bigRightBox}
      buttons={props.buttons}
    >
      {props.informations}
    </ContentHeader >

  } >
    {props.children}
  </Content>;
  return content;

}

export default InnerLayout;
