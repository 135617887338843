import { IBusinessReferenceDTO } from "@sharedInterfaces/IBusiness";
import IProjectDTO, { IProjectEmployeeReferenceDTO } from "@sharedInterfaces/IProject";
import { EEntityType } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export default class Project extends Entity implements IProjectDTO
{
  type: EEntityType = EEntityType.PROJECT;
  description: string;
  employees: IProjectEmployeeReferenceDTO[];
  sync: boolean;
  tenantId: string;
  active: boolean;
  totalHours: number;
  isHours: number;
  dayPrice: number;
  business: IBusinessReferenceDTO;
  internalProject: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;

  constructor(obj: IProjectDTO)
  {
    super(obj);
    this.description = obj.description;
    this.employees = obj.employees;
    this.sync = obj.sync;
    this.tenantId = obj.tenantId;
    this.active = obj.active;
    this.totalHours = obj.totalHours;
    this.isHours = obj.isHours;
    this.dayPrice = obj.dayPrice;
    this.business = obj.business;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.internalProject = obj.internalProject;
  }
  public get percent(): number
  {
    const percent = Math.round(this.isHours / this.totalHours * 1000) / 10;
    if (percent === Infinity) return 0;
    if (isNaN(percent)) return 100;
    if (percent === undefined) return 0;
    return percent
  }

  public get revenue(): number
  {
    return Math.round(this.dayPrice * this.isHours / 8 * 100) / 100
  }
  async delete()
  {
    const { default: deleteProject } = await import("@src/APIs/graphQl/Project/deleteProject");
    return deleteProject(this.id);
  }
}

export const projectGQL = `{
  id
  title
  description
  tenantId
  active
  totalHours
  isHours
  sync
  dayPrice
  startDate
  endDate
  business{
    id
    title
  }
  employees {
    id
    title
    availability {
      workDayCount
      availability
    }
  }
  internalProject
  ${entityFieldsGQL}
}`