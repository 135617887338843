import IEntity from "@sharedInterfaces/IEntity";
import { EEntityType, entityTypeToLink } from "@sharedInterfaces/globalEnums";

export const entityFieldsGQL = `changedAt
changedBy
createdAt
createdBy`
export abstract class Entity implements IEntity
{
    id: number;
    title: string;
    version: number;
    createdBy?: number | undefined;
    changedBy?: number | undefined;
    createdAt?: Date | undefined;
    changedAt?: Date | undefined;
    abstract readonly type: EEntityType
    constructor(obj: IEntity)
    {
        switch (typeof obj.id)
        {
            case 'number':
                this.id = obj.id;
                break
            case 'string':
                this.id = Number.parseInt(obj.id);
                break
        }
        this.title = obj.title ? obj.title : '';
        this.version = obj.version
        this.createdBy = obj.createdBy
        this.changedBy = obj.changedBy
        this.createdAt = obj.createdAt
        this.changedAt = obj.changedAt
    }

    public get link(): string
    {
        return entityTypeToLink(this.id, this.type)
    }

    abstract delete?(): Promise<unknown>
    // abstract pageButtons(editing: boolean, langStrings: any): JSX.Element
}