import ICompetence, { ICompetenceLevel, ICompetenceEmployeeReference, ICompetenceProductReference, ICompetenceOpportunityReference, ICompetenceRole } from "@sharedInterfaces/ICompetence";
import { EEntityType, } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export default class Competence extends Entity implements ICompetence
{
  type: EEntityType = EEntityType.COMPETENCE;
  description: string;
  levels: ICompetenceLevel[];
  employees: ICompetenceEmployeeReference[];
  products: ICompetenceProductReference[];
  opportunities: ICompetenceOpportunityReference[];
  roles: ICompetenceRole[];

  constructor(obj: ICompetence)
  {
    super(obj)
    this.description = obj.description;
    this.levels = obj.levels;
    this.employees = obj.employees;
    this.products = obj.products;
    this.opportunities = obj.opportunities;
    this.roles = obj.roles;
  }

  async delete()
  {
    const { default: deleteCompetence } = await import("@src/APIs/graphQl/Competence/deleteCompetence");
    return deleteCompetence(this.id);
  }
}

export const competenceGQL = `{
  id
  title
  description
  levels{
    id
    title
    skills {
      id
      title
      level
    }
    certificates {
      id
      title
    }
  }
  employees {
    id
    title
    level
  }
  products {
    id
    title
  }
  opportunities {
    id
    title
  }
  roles {
    id
    title
  }
  ${entityFieldsGQL}
}`

