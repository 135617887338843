import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings, { ICompanyCalcualtions } from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editCalculations($workedMonthCount: Int!,$forecastMonthCount: Int!) {
    editCalculations(workedMonthCount: $workedMonthCount, forecastMonthCount:$forecastMonthCount) 
    ${companySettingsGql}
},
`;

/**
 * Edits the calculations of a company.
 *
 * @param {ICompanyCalcualtions} calculations - The calculations to be edited.
 * @returns {Promise<Company>} The updated company object.
 */
export default async function editCalculations(calculations: ICompanyCalcualtions)
{
    const variables: OperationVariables = {
        workedMonthCount: calculations.workedMonthCount,
        forecastMonthCount: calculations.forecastMonthCount
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}