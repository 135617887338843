import React, { ReactElement, useEffect, useRef } from 'react';
import './Overlay.css';
import { Popover, PopoverActions } from '@mui/material';

interface OverlayProps
{
    children: React.ReactNode
    disabled?: boolean
    triggerItem: React.ReactNode
    loaded: boolean
    onFirstOpen: () => void
}

/**
 * Overlay component.
 *
 * @param {OverlayProps} props - The props for the Overlay component.
 * @returns {JSX.Element} The rendered Overlay component.
 */
function Overlay({ children, disabled, triggerItem, loaded, onFirstOpen, }: OverlayProps)
{
    const popoverActionsRef = useRef<PopoverActions | null>(null);
    const [timeout, setTimeoutIntern] = React.useState<NodeJS.Timeout | null>(null);
    const [wasOpen, setWasOpen] = React.useState(false as boolean);
    const popoverRef = React.useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [position, setPosition] = React.useState({ x: 0, y: 0 });

    useEffect(() => //Update position
    {
        let timer: NodeJS.Timeout | null = null;
        if (open && popoverActionsRef.current)
        {
            timer = setTimeout(() =>
            {
                if (popoverActionsRef.current) popoverActionsRef.current.updatePosition();
            }, 100);
        }
        return () =>
        {
            if (timer) clearTimeout(timer);
        };
    }, [open, loaded, popoverActionsRef]);
    useEffect(() =>
    {
        const clearOnEvent = () =>
        {
            if (timeout)
            {
                clearTimeout(timeout);
                setTimeoutIntern(null);
            }
        };

        window.addEventListener("blur", clearOnEvent);
        document.querySelector('.contentOuter')?.addEventListener("scroll", clearOnEvent);

        return () =>
        {
            window.removeEventListener("blur", clearOnEvent);
            document.querySelector('.contentOuter')?.removeEventListener("scroll", clearOnEvent);
            if (timeout)
            {
                clearTimeout(timeout);
                setTimeoutIntern(null);
            }
        };
    }, [timeout]);

    useEffect(() =>
    {
        return () =>
        {
            if (timeout) clearTimeout(timeout);
        };
    }, []);

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => //TODO: Rein oder raus?
    {
        // if (e.clientX !== 0 && e.clientY !== 0)
        // { // Verhindert das Zurücksetzen bei Drag-End (wenn clientX und clientY 0 sind)
        //     setPosition({
        //         x: e.clientX,
        //         y: e.clientY,
        //     });
        // }
    };

    const handleMouseEnter = (e: React.MouseEvent) =>
    {
        if (!open && !timeout)
        {
            const newTimeout = setTimeout(() =>
            {
                setAnchorEl(e.target as HTMLElement);
                if (!wasOpen)
                {
                    onFirstOpen();
                    setWasOpen(true);
                }
                setTimeoutIntern(null);
            }, 1500);
            setTimeoutIntern(newTimeout);
        }
    };

    const handleMouseLeave = () =>
    {
        if (timeout)
        {
            clearTimeout(timeout);
            setTimeoutIntern(null);
        }
    };

    if (disabled) return <>{triggerItem}</>;

    const enhanceChildren = () =>
    {
        return React.Children.map(children, (child) =>
        {
            // Stellen Sie sicher, dass das Kind ein valides React-Element ist
            if (React.isValidElement(child))
            {
                // Fügen Sie dem Kind neue Props hinzu
                return React.cloneElement(child as ReactElement, { onDrag: handleDrag });
            }
            return child;
        });
    };

    return (
        <div
            className='overlay outerOverlay'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {triggerItem}
            {/* <Draggable> */}
            <Popover
                ref={popoverRef}
                action={popoverActionsRef}
                open={open}
                style={{
                    zIndex: 1302,
                    // transform: `translate(${position.x}px, ${position.y}px)`,
                }}
                container={() => document.getElementById('dialogContainer')}
                anchorEl={anchorEl}
                onClose={setAnchorEl.bind(null, null)}

                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                slotProps={{
                    paper: {
                        style: { maxWidth: '70%', maxHeight: '50%' },
                    }
                }}
            >
                <div className="overlay">
                    {enhanceChildren()}
                </div>
            </Popover>
            {/* </Draggable> */}
        </div>
    )
}

export default Overlay;