import React from 'react';
import { useSelector } from "react-redux";
import { Select, MenuItem } from '@mui/material';
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { IStartPagesTexts } from '@sharedInterfaces/Language/languageTexts/startPages';
import { AppState } from "@store/store";

interface StartPageSelectProps
{
    startPage: keyof IStartPagesTexts;
    disabled?: boolean
    setStartPage: (val: keyof IStartPagesTexts) => void;
}

/**
 * Renders a Material-UI select input for selecting a start page.
 *
 * @param {StartPageSelectProps} startPage - The currently selected start page.
 * @param {function} setStartPage - A function to set the selected start page.
 * @returns {JSX.Element} - The Material-UI select input for selecting a start page.
 */
function StartPageSelect({ startPage, disabled, setStartPage }: StartPageSelectProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).startPages;
    const startPages: [string, string][] = Object.entries(langStrings);
    startPages.sort((a, b) => a[1].localeCompare(b[1]));

    return (
        <Select
            labelId="startpage-select-label"
            value={startPage}
            disabled={disabled}
            onChange={(e) =>
            {
                if (!e) return;
                setStartPage((e.target as HTMLSelectElement).value as keyof IStartPagesTexts);
            }}
        >
            {(startPage as string === "" || !startPage) && <MenuItem value=""><em>-</em></MenuItem>}
            {startPages.map(([code, name]) => (
                <MenuItem key={code} value={code}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
}

export default StartPageSelect;
