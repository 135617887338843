import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import './CardBox.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { Typography } from '@mui/material';

import MenuButton from '../../sharedReact/General/MenuButton/MenuButton';
interface CardBoxProps
{
    title?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    selected?: boolean
    menu?: React.ReactNode
    rightButtons?: React.ReactNode
    leftButtons?: React.ReactNode
    style?: React.CSSProperties
    onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
    onSelectChange?: (state: boolean) => void
    onDragOver?: (evt: React.DragEvent<HTMLDivElement>) => void
    onDrop?: (evt: React.DragEvent<HTMLDivElement>) => void
}

/**
 * CardBox component.
 *
 * @param {CardBoxProps} props - The props for the CardBox component.
 * @returns {JSX.Element} The rendered CardBox component.
 */
function CardBox(props: CardBoxProps)
{
    const offline = useSelector((state: AppState) => state.client.offline);
    return (
        <div
            className={"CardBox" + (props.className ? (' ' + props.className) : '')}
            style={props.style}
            onDragOver={props.onDragOver}
            onDrop={props.onDrop}
        >
            {
                (props.onSelectChange || props.menu || props.title) &&
                <div className="boxHeader">
                    <div className='left'>
                        {
                            props.onSelectChange ? <div className='checkbox'>{props.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}</div> : undefined
                        }
                        {props.leftButtons && props.leftButtons}
                    </div>
                    <div className='center'
                        onClick={props.onClick}
                        style={{
                            cursor: props.onClick ? 'pointer' : 'initial'
                        }}
                    >
                        {
                            props.title ? <Typography variant='h6' style={{ wordBreak: 'break-word' }}>{props.title}</Typography> : undefined
                        }
                    </div>
                    <div className='right'>
                        {
                            props.menu === undefined || offline ? undefined : <MenuButton>{props.menu}</MenuButton>
                        }
                        {
                            props.rightButtons === undefined ? undefined : props.rightButtons
                        }
                    </div>
                </div>
            }
            {
                props.children
            }
        </div>
    );
}

export default CardBox;
