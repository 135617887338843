import React, { useEffect, useRef } from 'react';
import './FormatedTextInput.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import 'quill-html-edit-button';
// eslint-disable-next-line @typescript-eslint/no-var-requires
Quill.register('modules/htmlEditButton', require('quill-html-edit-button').default);


interface FormatedTextInputProps
{
    value: string
    placeholder?: string
    readonly?: boolean
    isAI?: true
    onChange?(val: string): void
}

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        // ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults
        // [{ 'font': [] }],
        // [{ 'align': [] }],
        ['link'],
        ['clean'],
    ],
    htmlEditButton: {
        closeOnClickOverlay: false,
        prependSelector: 'dialog.dialog',
    }
}

/**
 * Component for rendering a formatted text input
 * 
 * @param {FormatedTextInputProps} param0 - Input properties
 * - placeholder: The placeholder text for the input
 * - value: The current value of the input
 * - readonly: Flag indicating if the input is readonly
 * - onChange: Event handler for input change
 * 
 * @returns {JSX.Element} The rendered component
 */
function FormatedTextInput({ placeholder, value, readonly, isAI, onChange }: FormatedTextInputProps)
{
    interface ReactQuillExtension extends ReactQuill
    {
        editingArea: HTMLDivElement;
    }

    const quillRef = useRef<ReactQuillExtension>(null);

    const innerOnChange = (value: string) =>
    {
        if (onChange)
        {
            if (quillRef.current && quillRef.current.editingArea?.innerText.match(/^\W*$/g))
                return onChange('');
            return onChange(value);
        }
    }

    useEffect(() =>
    {
        if (quillRef.current && isAI)
        {
            const editor = quillRef.current.getEditor();
            if (editor && editor.root)
            {
                editor.root.style.backgroundColor = 'var(--var-ai-color)'
            }
        }
    }, []);

    return (
        <div className='FormatedTextInputContainer'>
            {readonly || !onChange ?
                <div
                    className='ql-editor show' dangerouslySetInnerHTML={{ __html: value }} />
                : <ReactQuill
                    ref={quillRef}
                    modules={modules}
                    placeholder={placeholder}
                    value={value}
                    style={{
                        // backgroundColor: isAI && 'var(--var-ai-color)'
                    }}
                    onChange={(value) => { innerOnChange(value) }}
                />
            }
        </div>
    );
}

export default FormatedTextInput;
