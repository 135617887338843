import './OpportunityPage.css';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import { formatDateLocalized } from '@sharedInterfaces/globalHelper';
import { updateOpportunity } from '@store/reducer/opportunityRecuder';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Opportunity from '@src/Objects/Opportunity';
import OpportunityDialog from '@src/Components/Dialogs/OpportunityDialog/OpportunityDialog';
import SuggestedEmployeesBox from '@src/Components/SuggestedEmployeesBox/SuggestedEmployeesBox';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import getOpportunity from '@src/APIs/graphQl/Opportunity/getOpportunity';
import getSuggestedEmployees from '@src/APIs/graphQl/Opportunity/getSuggestedEmployees';
import { combineSkillsAndCertificates } from "@src/helper/helper";
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import CompetencesCard from '@src/App/NewLayout/Cards/Competences/CompetencesCard/CompetencesCard';
import CertificatesCard from '@src/App/NewLayout/Cards/Certificates/CertificatesCard/CertificatesCard';
import RolesCard from '@src/App/NewLayout/Cards/Roles/RolesCard/RolesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';
interface OpportunityPageProps
{
    entity?: Opportunity;
    inDialog?: boolean;
}

/**
 * Represents the OpportunityPage component.
 * @param {OpportunityPageProps} entity - The entity object.
 * @param {boolean} inDialog - Flag indicating whether the component is rendered inside a dialog.
 * @returns {JSX.Element} The JSX element representing the opportunity page.
 */
function OpportunityPage({ entity, inDialog, }: OpportunityPageProps)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { openDialog } = useContext(DialogManagerContext);
    const { id } = useParams();
    const entityId = entity ? entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;
    const permissions = useSelector((state: AppState) => state.permissions);
    const readRoles = checkPermissions('Roles', 'Retrieve', permissions);
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const entityState = useSelector((state: AppState) => state.opportunity.find(a => a.id === entityId))

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    const [suggestedEmployees, setSuggestedEmployees] = useState<IEmployeeCoverage[] | null>(null);

    const { combinedSkills, combinedCertificates } = combineSkillsAndCertificates(entityState, allCompetences);

    const setEntityState = (op: Opportunity) => op && dispatch(updateOpportunity(op))


    useEffect(() => //Get Entity
    {
        if (inDialog) return;
        getOpportunity(entityId)
            .then(() =>
            {
                updateSelectedEmps(suggestedEmployees ? suggestedEmployees : []);

            })
            .catch((ex) =>
            {
                setLoadingError(ex);
            });
    }, [entityId, inDialog]);


    const updateSelectedEmps = (suggestedEmployees: IEmployeeCoverage[]) =>
    {
        if (!entityState || !suggestedEmployees) return;
        const coppiedEntity = new Opportunity(entityState);
        coppiedEntity.selectedEmployees = [];
        entityState.selectedEmployees.forEach(selEmp =>
        {
            const foundObj = suggestedEmployees.find(e => e.id === selEmp.id)
            let newEmp;
            if (foundObj)
            {
                newEmp = { ...selEmp, ...foundObj }
            } else
            {
                newEmp = {
                    ...selEmp,
                    score: 0,
                    skillCoverage: 0,
                    coveredSkills: [],
                    missingSkills: entityState.skills.map(s => ({ ...s, level: 0, wantedLevel: s.level })),
                    coveredCertificates: [],
                    missingCertificates: entityState.certificates.map(s => ({ ...s, })),
                }
            }
            coppiedEntity.selectedEmployees.push(newEmp);
        });
        setEntityState(coppiedEntity);
    }

    useEffect(() => //Get SuggestedEmployees
    {
        if (inDialog) return;
        if (!entityState || suggestedEmployees !== null) return
        getSuggestedEmployees(
            combinedSkills.map(s => ({ id: s.id, title: s.title, level: s.level })),
            combinedCertificates.map(c => ({ id: c.id, title: c.title, })),
        )
            .then((empData) =>
            {
                setSuggestedEmployees(empData);
                updateSelectedEmps(empData);
            })
            .catch((ex) =>
            {
                setLoadingError(ex);
            });
    }, [entityId, entityState, inDialog])

    const skillCoverage = !entityState ? 0 : Math.round(entityState.selectedEmployees
        .reduce((prev, cur) => cur.skillCoverage + prev, 0) / entityState.selectedEmployees.length * 100)
    const skillScore = !entityState ? 0 : Math.round(entityState.selectedEmployees
        .reduce((prev, cur) => cur.score + prev, 0) / entityState.selectedEmployees.length * 100)

    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <OpportunityDialog
                id="OpportunityDialog"
                opportunity={entityState}
            />);
    };

    return (
        <EntityPage
            inDialog={inDialog}
            entity={entityState}
            loadingError={loadingError}
            admin={permissions.admin}
            permissions={permissions.entities.Opportunities}
            onDelete={() =>
            {
                if (!inDialog)
                    navigate(`/${ELinks.SALES_OPPORTUNITIES}`);
            }}
            openEditDialog={handleOpenEditDialog}
            informations={
                !entityState ? [] :
                    [
                        entityState.customer ?
                            <EntityPageInformation
                                key={1}
                                title={langStrings.customer}
                            >
                                {entityState.customer}
                            </EntityPageInformation>
                            : undefined,
                        entityState.partner ?
                            <EntityPageInformation
                                key={2}
                                title={langStrings.partner}
                            >
                                {entityState.partner}
                            </EntityPageInformation>
                            : undefined,
                        <EntityPageInformation
                            key={3}
                            title={langStrings.utilization}
                        >
                            {`${Math.round(entityState.utilization * 10) / 10}%`}
                        </EntityPageInformation>,
                        <EntityPageInformation
                            key={4}
                            title={langStrings.remote}
                        >
                            {`${Math.round(entityState.remote * 10) / 10}%`}
                        </EntityPageInformation>,
                        ...(
                            entityState.dayPrice ? [
                                <EntityPageInformation
                                    key={5}
                                    title={langStrings.dayPrice}
                                >
                                    {`${entityState.dayPrice?.toLocaleString('de-DE')}€`}
                                </EntityPageInformation>
                            ] : []
                        ),
                        entityState.projectStart ?
                            <EntityPageInformation
                                key={6}
                                title={langStrings.startDate}
                            >
                                {formatDateLocalized(entityState.projectStart, lang)}
                            </EntityPageInformation>
                            : undefined,
                        entityState.projectEnd ?
                            <EntityPageInformation
                                key={7}
                                title={langStrings.endDate}
                            >
                                {formatDateLocalized(entityState.projectEnd, lang)}
                            </EntityPageInformation>
                            : undefined,
                        entityState.headCount ?
                            <EntityPageInformation
                                key={8}
                                title={langStrings.employees}
                            >
                                {`${entityState.headCount}`}
                            </EntityPageInformation>
                            : undefined,
                        <EntityPageInformation
                            key={9}
                            title={langStrings.active}
                        >
                            {`${entityState.active ? langStrings.yes : langStrings.no}`}
                        </EntityPageInformation>,
                        <EntityPageInformation
                            key={10}
                            title={langStrings.skillScore}
                        >
                            {`${!skillCoverage ? 0 : Math.round(skillScore * 10) / 10}%`}
                        </EntityPageInformation>,
                        entityState.requestText ?
                            <EntityPageInformation title={langStrings.opportunity} size={'full'}>
                                <ExpandableText>
                                    <FormatedTextInput value={entityState.requestText} readonly={true} />
                                </ExpandableText>
                            </EntityPageInformation> :
                            undefined,
                    ]
            }
        >
            {entityState ?
                <>
                    {
                        entityState.selectedEmployees &&
                        <SuggestedEmployeesBox
                            title={langStrings.selectedEmployee}
                            suggestedEmployees={entityState.selectedEmployees}
                        />
                    }
                    {suggestedEmployees && <SuggestedEmployeesBox
                        suggestedEmployees={suggestedEmployees}
                    />
                    }
                    {readRoles &&
                        <RolesCard roles={entityState.roles} />

                    }
                    <CompetencesCard competences={entityState.competences} />
                    <SkillsCard skills={combinedSkills} />
                    <CertificatesCard certificates={combinedCertificates} />
                </>
                :
                <LoadingBox />
            }
        </EntityPage>
    );
}

export default OpportunityPage;