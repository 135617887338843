import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import { IPermissionGroup } from "@sharedInterfaces/IPermissions";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";


import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editPermissionGroups( $groups: [EditPermissionGroup!]!) {
    editPermissionGroups(groups: $groups) 
    ${companySettingsGql}
},
`;

export default async function editPermissionGroups(groups: IPermissionGroup[])
{
    const variables: OperationVariables = {
        groups: groups.map(g => ({
            id: g.id,
            title: g.title,
            entities: g.entities,
        }))
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}