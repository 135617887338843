import { OperationVariables, gql } from "@apollo/client";
import { IStartPagesTexts } from "@sharedInterfaces/Language/languageTexts/startPages";

import GraphQL from "../graphQL";

const query = gql`
mutation updateStartPage($startPage:String!) {
    updateStartPage(startPage: $startPage)
},
`;

/**
 * Marks the first experience as read.
 * 
 * @returns {Promise<boolean>}
 */
export default async function updateStartPage(startPage: keyof IStartPagesTexts)
{
    const variables: OperationVariables = {
        startPage,
    };
    return GraphQL.mutate<boolean>(query, variables);
}