import React, { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import Layout from "@sharedReact/Layouts/Layout/Layout";
import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";
import { ELinks } from "@sharedInterfaces/globalEnums";
import { AppState } from "@store/store";
import { isLoggedIn } from "@store/reducer/clientReducer";
import { setWindowSize } from "@store/reducer/windowSizeReducer";
import getWhoIAm from "@src/APIs/graphQl/Employee/getWhoIAm";
import { CustomGraphQLError } from "@src/APIs/graphQl/graphQL";
import { WebSocketClient } from "@src/APIs/WebSockets/WebSocketClient";

import LoadingBox from "../sharedReact/General/LoadingBox/LoadingBox";
import { logout } from "../Components/UserButton/UserButton";
import ErrorPage from "../sharedReact/Error/ErrorPage";
import FatalErrorPage from "../sharedReact/Error/FatalErrorPage";
import CookieBanner from "../sharedReact/General/CookieBanner/CookieBanner";
import ErrorBoundary from "../sharedReact/Error/ErrorBoundary";
import { DialogManager, } from "../sharedReact/Dialog/DialogManager";

import RoutesComponent from "./Routes";


/**
 * App component.
 *
 * @returns {JSX.Element} The JSX element for the App component.
 */
function App()
{
  const dispatch = useDispatch();
  const secret = useSelector((state: AppState) => state.client.secret);
  const actualStyle = useSelector((state: AppState) => state.company.style);
  const startPage = useSelector((state: AppState) => state.employeeSettings.startPage);
  const [style, setStyle] = useState<IStyleDTO>(actualStyle);
  const dialogCount = useSelector((state: AppState) => state.client.dialogCount);



  useEffect(() =>
  {
    function handleResize()
    {
      dispatch(setWindowSize(window.innerWidth, window.innerHeight));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);



  useEffect(() =>
  {
    const themeColorDOM: HTMLMetaElement | null = window.document.querySelector('meta[name="theme-color"]')
    if (themeColorDOM)
    {
      themeColorDOM.content = style.primary;
    }
  }, [style.primary])

  const loadWhoIAm = () =>
  {
    if (isLoggedIn(secret))
    {
      getWhoIAm((me) =>
      {
        if (!me) return;

        setStyle(me.style);
      }).catch((error: CustomGraphQLError) =>
      {
        console.error(error);
        if (error.statusCode === 401)
        {
          logout();
        }
      });
    }
  }

  const openWebSocket = () =>
  {
    WebSocketClient.connect();
  }

  useEffect(() =>
  {
    if (!secret || secret == null || secret === "") return;
    // console.log("Secret", secret);
    openWebSocket();
    const intervalId = setInterval(() =>
    {
      loadWhoIAm();
    }, 2 * 60 * 60 * 1000);
    loadWhoIAm();

    return () =>
    {
      clearInterval(intervalId);
    };
  }, [dispatch, secret]);

  if (startPage && location.pathname === '/')
  {
    location.pathname = ELinks[startPage];
  }


  const MuiTheme = createTheme({
    palette: {
      primary: {
        main: style['primary'] ? style['primary'] : '#ff0000',
        contrastText: style['onPrimary'] ? style['onPrimary'] : '#ff0000',
      },
      secondary: {
        main: style['secondary'] ? style['secondary'] : '#00ffff',
        contrastText: style['onSecondary'] ? style['onSecondary'] : '#ff0000',

      },
    },
  });


  return (
    <ErrorBoundary fallback={(error) => <FatalErrorPage error={error} />}>
      <div
        id="themeContainer"
        style={
          {
            height: '100%',
            "--var-primary-color": style['primary'],
            "--var-secondary-color": style['secondary'],
            "--var-on-secondary-color": style['onSecondary'],
            "--var-on-primary-color": style['onPrimary'],
            "--var-dialogBar-height": dialogCount ? "45px" : "0px",
          } as CSSProperties
        }>
        <ThemeProvider theme={MuiTheme}>
          <BrowserRouter>
            <DialogManager>
              <CookieBanner />
              <Layout >
                <ErrorBoundary fallback={(error) => <ErrorPage error={error} />}>
                  <div id="dialogContainer"></div>
                  <React.Suspense fallback={<InnerLayout>
                    <LoadingBox />
                  </InnerLayout>}>
                    <RoutesComponent style={style} setStyle={setStyle} />
                  </React.Suspense>
                </ErrorBoundary>
              </Layout>
            </DialogManager>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;