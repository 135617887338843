import React from "react";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { color } from "@mui/system";

const outerBoxStyle: React.CSSProperties = {
    display: 'flex',
    gap: 20,
    alignItems: 'stretch',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 15,
    wordBreak: 'break-word',
};


interface BoxButtonProps
{
    title: string;
    icon?: JSX.Element;
    description?: string;
    color?: 'primary' | 'secondary';
    onClick?: (() => void) | string;
}

/**
 * React component representing a box button.
 *
 * @param {Object} props - The props object containing the properties for the box button.
 * @param {string} props.title - The title of the box button.
 * @param {string} props.description - The description of the box button.
 * @param {string} props.link - The link of the box button.
 *
 * @returns {JSX.Element} The rendered box button component.
 */

export function BoxButton({ title, icon, description, color: _color, onClick }: BoxButtonProps): JSX.Element
{
    const color = _color || 'primary'
    const navigate = useNavigate();
    const boxStyle: React.CSSProperties = {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '300px',
        justifyContent: 'stretch',
        cursor: 'pointer',
        gap: '7px',
        color: `${color}.contrastText`,
        backgroundColor: `${color}.main`,
        padding: '10px 20px',
        transition: 'background-color 0.5s, border-radius 1s',
        '&:hover': {
            backgroundColor: `${color}.dark`,
            color: `${color}.contrastText`,
            borderRadius: '7px',
            // opacity: [0.9, 0.8, 0.7],
        },
    } as React.CSSProperties;

    return <Box
        onClick={() =>
        {
            if (!onClick) return;
            if (typeof onClick === 'string')
                navigate(onClick)
            else
                onClick()
        }}
        sx={boxStyle}
    >

        {icon}
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
                flexGrow: 1,
                height: '100%',
            }}
        >
            <div
                style={{
                    // fontSize: 14, maxWidth: 300,
                    textAlign: 'center'
                }}
            >
                <Typography variant="h5">
                    {title}
                </Typography>
            </div>

            {description &&
                <div
                    style={{
                        // fontSize: 14, maxWidth: 300,
                        textAlign: 'center'
                    }}
                >

                    <Typography variant="subtitle2">
                        {description}
                    </Typography>
                </div>
            }
        </div>
    </Box>;
}

interface BoxButtonsProps
{
    children: React.ReactNode | React.ReactNode[];
}

/**
 * Function to handle the BoxButtons.
 * @returns {void}
 */
export function BoxButtons({ children }: BoxButtonsProps)
{
    return <div style={outerBoxStyle}>
        {children}
    </div>
}