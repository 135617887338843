import './AddRoleButton.css';
import React from 'react';
import { Add, AirplanemodeActive, Loop, Remove, Tune } from '@mui/icons-material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import changeMapRole from '@src/APIs/graphQl/Role/changeMapRole';
import { useSelector } from 'react-redux';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import RoundIconButton from '../RoundIconButton/RoundIconButton';

interface AddRoleButtonProps
{
    id: number
    size: 'small' | 'medium' | 'verysmall'
    onChange?: (val: boolean) => void
}

/**
 * AddRoleButton component.
 *
 * @param {AddRoleButtonProps} id - The id of the button.
 * @param {AddRoleButtonProps} size - The size of the button.
 * @param {AddRoleButtonProps} onChange - The callback function for when the button is clicked.
 *
 * @returns {JSX.Element} The JSX element for the Add Role button.
 */
function AddRoleButton({ id, size, onChange, }: AddRoleButtonProps)
{
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);
    const smallRole = useSelector((state: AppState) => state.company.smallRoles.find(c => c.id === id));

    const [saving, setSaving] = React.useState<boolean>(false);

    const add = () =>
    {
        if (saving || !employee || !smallRole) return;
        setSaving(true);
        changeMapRole(id, true)
            .then(() =>
            {
                setSaving(false);
            })
            .catch(async (ex) =>
            {
                console.log(ex)
                await showErrorDialog(ex, langStrings.errorAdd)
                setSaving(false);
            })
    }
    const remove = () =>
    {
        if (saving || !employee) return;
        setSaving(true);
        changeMapRole(id, false)
            .then(() =>
            {
                setSaving(false);
                if (onChange) onChange(false);
                // const newRoles = cloneDeep(roles);
                // const fRule = newRoles.find(r => r.id === id)
                // if (fRule)
                // {
                //     fRule.employees = fRule.employees.filter(e => e.id !== employee.id);
                //     dispatch(setRoles(newRoles))
                // }
            })
            .catch(async (ex) =>
            {
                console.warn(ex);
                await showErrorDialog(ex, langStrings.errorRemove)
                setSaving(false);
            })
    }

    if (!employee || id === -1) return null
    const own = employee.roles.find(oc => oc.id === id);
    if (saving)
    {
        return <Loop fontSize={size === 'verysmall' ? 'small' : 'medium'} />;
    }

    return (
        <>
            {
                offline ?
                    <AirplanemodeActive />
                    :
                    (
                        own ?
                            <div className={`addRoleButton removeButton ${size}`}>
                                <RoundIconButton
                                    helperText={langStrings.removeButtonTitle}
                                    onClick={() =>
                                    {
                                        remove();
                                    }}
                                    icon={<Remove fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                    size={size}
                                />
                                <RoundIconButton
                                    size={size}
                                    helperText={langStrings.editButtonTitle}
                                    onClick={async () =>
                                    {
                                        add()
                                        if (onChange) onChange(true);
                                    }}
                                    icon={<Tune fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                />
                            </div> :
                            <RoundIconButton
                                helperText={langStrings.addButtonTitle}
                                onClick={async () =>
                                {
                                    add()
                                    if (onChange) onChange(true);
                                    // add();
                                }}
                                icon={<Add fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                size={size}
                            />
                    )
            }
        </>
    )
}

export default AddRoleButton;
