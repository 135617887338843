import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import VersionDialogButton from '@sharedReact/Dialog/Dialogs/VersionDialog/VersionDialog';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';

export function Footer()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = React.useMemo(() => getLanguageTexts(lang).layout, [lang]);
    const width = useSelector((state: AppState) => state.windowSize.width);
    // const dialogCount = useSelector((state: AppState) => state.client.dialogCount);

    return <div className='footer'>
        <NavLink className='imprint' to={ELinks.FOOTER_IMPRINT}>
            {langStrings.imprint}
        </NavLink>
        <NavLink className='contact' to={ELinks.FOOTER_CONTACT}>
            {langStrings.contact}
        </NavLink>
        <span className='copyRight'>
            {width < 450 ?
                langStrings.copyrightShort :
                langStrings.copyright}
            Mirco Frye
        </span>
        <VersionDialogButton />
        {/* {dialogCount} */}
    </div>;
}
