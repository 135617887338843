import React, { } from 'react';
// import './SkillSearchBox.css';
import { AppState } from '@store/store';
import { ISmallSkill } from '@sharedInterfaces/IWhoIAm';

import EntitySearchBox from '../EntitySearchBox/EntitySearchBox';
interface SkillSearchBoxProps
{
    filteredAllSkills: ISmallSkill[]
    newSkill: IListElement
    allowNew?: true
    size?: 'medium' | 'small' | 'verysmall'
    clearOnSelect?: true
    setNewSkill: (newSkill: IListElement) => void;
}
interface IListElement
{
    title: string,
    id?: number
    // otherNames: string[]
}

/**
 * SkillSearchBox component.
 * 
 * @param {SkillSearchBoxProps} props - The props for the SkillSearchBox component.
 * @returns {JSX.Element} - The rendered SkillSearchBox component.
 */
const SkillSearchBox = (props: SkillSearchBoxProps) =>
{
    return <EntitySearchBox
        filteredItems={props.filteredAllSkills}
        newItem={props.newSkill}
        allowNew={props.allowNew}
        size={props.size}
        clearOnSelect={props.clearOnSelect}
        setNewItem={props.setNewSkill}
        allItemsSelector={(state: AppState) => state.company.allSkills}
    />
};

export default SkillSearchBox;
