import React from 'react';

import styles from './Row.module.css';

interface RowProps
{
    align?: 'top';
    justifyContent?: 'space-evenly' | 'start' | 'space-between'
    margin?: string;
    children: React.ReactNode
}
/**
 * Renders a row component.
 *
 * @param {RowProps} align - The alignment of the row.
 * @param {RowProps} children - The children of the row.
 * 
 * @returns {JSX.Element} - The rendered row component.
 */
export function Row({ align, justifyContent, children, margin }: RowProps)
{
    return (
        <div
            className={
                styles.row +
                ` ${align === 'top' ? styles.alignTop : ''}`
            }
            style={{
                justifyContent: justifyContent,
                margin: margin,
            }}
        >{children}</div>
    );
}

export default Row;
