import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ICertificatesTexts extends ITextBase
{
    pleaseEnterTitle: string;
    generateCertificateData: string;
    suggestedCertificateName: string;
    editCertificateTitle: string;
    addCertificateTitle: string;
    addCertDialogHint: string;
    startDateHint: string;
    startDate: string;
    endDate: string;
    productInfo: string;
    durationInDays: string;
    productName: string;
    openInNewTab: string;
    manufacturer: string;
    prerequisites: string;
    errorDuplicateOtherName: string;
    errorDuplicateTitle: string;
    otherNames: string;
    notAllowed: string;
    products: string;
    notFound: string;
    delete: string;
    edit: string;
    certificateName: string;
    save: string;
    create: string;
    newCertificateError: string;
    empty: string
    newCertificate: string;
    mergeCertificates: string;
    employees: string;
    employeesShort: string;
    certificate: string;
    employeesTitle: string;
    description: string;
    merge: string;
    search: string;
    certificates: string;
    employeesWithCertificate: string;
    errorAdd: string,
    errorRemove: string
    certificatesSelectedForMerge: string
    effectEmployeesWarning: string
    error: string
    pleaseEnterAName: string
    removeButtonHelper: string
    addButtonHelper: string
    editButtonHelper: string
    addButtonTitle: string
    removeButtonTitle: string
    editButtonTitle: string
}
type ILangData = {
    [lang in ELanguage]: ICertificatesTexts
}
export const certificatesTexts: ILangData = {
            'th': {
                removeButtonTitle: 'ลบใบรับรองออกจากโปรไฟล์ของฉัน',
        addButtonTitle: 'เพิ่มใบรับรองลงในโปรไฟล์ของฉัน',
        editButtonTitle: 'เปลี่ยนวันหมดอายุของฉันเอง',
                editButtonHelper: 'คุณต้องการปรับวันหมดอายุของใบรับรองนี้ในโปรไฟล์ของคุณหรือไม่?',
                addButtonHelper: 'คุณมีใบรับรองนี้ด้วยหรือ? เพิ่มเข้าไปในโปรไฟล์ของคุณ',
                removeButtonHelper: 'ลบใบรับรองนี้ออกจากโปรไฟล์ของคุณ',
                pleaseEnterTitle: 'กรุณาใส่ชื่อ',
                generateCertificateData: 'สร้างข้อมูลใบรับรองด้วย AI',
                suggestedCertificateName: 'ชื่อใบรับรองที่แนะนำ',
                errorDuplicateTitle: 'ชื่อ "[TITLE]" ไม่ถูกต้อง มีใบรับรองที่มีชื่อ "[TITLE]" อยู่แล้ว',
                editCertificateTitle: 'ปรับแต่งใบรับรองในโปรไฟล์ของฉัน',
                addCertificateTitle: 'เพิ่มใบรับรองในโปรไฟล์ของฉัน',
                startDateHint: 'วันที่ออกใบรับรองแสดงถึงวันที่คุณได้รับใบรับรองนี้และสำคัญสำหรับการจัดการคุณสมบัติของคุณ',
                addCertDialogHint: 'กรุณากรอกรายละเอียดใบรับรองของคุณ วันที่ออกใบรับรองเป็นฟิลด์ที่จำเป็นและต้องกรอก',
                endDate: 'วันที่สิ้นสุด',
                startDate: 'วันที่เริ่มต้น',
                productInfo: 'ข้อมูลผลิตภัณฑ์',
                durationInDays: 'ระยะเวลาในหน่วยวัน',
                prerequisites: 'ข้อกำหนดที่จำเป็น',
                manufacturer: 'ผู้ผลิต',
                openInNewTab: 'เปิดในแท็บใหม่',
                productName: 'ชื่อผลิตภัณฑ์',
                notFound: oftenUsedTranslations['th'].notFound,
                edit: oftenUsedTranslations['th'].edit,
                delete: oftenUsedTranslations['th'].delete,
                errorAdd: 'ไม่สามารถเพิ่มได้!',
                errorRemove: 'ไม่สามารถลบได้!',
                employeesWithCertificate: "พนักงานที่มีใบรับรองนี้",
                certificate: "ใบรับรอง",
                description: oftenUsedTranslations['th'].description,
                certificates: "ใบรับรอง",
                employeesTitle: 'จำนวนพนักงาน (Head count)',
                employees: oftenUsedTranslations['th'].employees,
                employeesShort: 'HC',
                search: oftenUsedTranslations['th'].search,
                merge: 'ผสาน',
                mergeCertificates: 'ผสานใบรับรอง',
                certificatesSelectedForMerge: 'เลือกใบรับรองที่จะผสาน',
                effectEmployeesWarning: 'หากคุณผสานใบรับรองนี้ จำนวนพนักงานที่ได้รับใบรับรองนี้จะเปลี่ยนแปลง ',
                newCertificate: 'ใบรับรองใหม่',
                error: 'ข้อผิดพลาด',
                pleaseEnterAName: 'โปรดป้อนชื่อ',
                empty: oftenUsedTranslations['th'].empty,
                create: oftenUsedTranslations['th'].create,
                save: oftenUsedTranslations['th'].save,
                certificateName: 'ชื่อใบรับรอง',
                newCertificateError: 'ข้อผิดพลาดในการบันทึก',
                products: oftenUsedTranslations['th'].products,
                notAllowed: oftenUsedTranslations['th'].notAllowed,
                otherNames: oftenUsedTranslations['th'].otherNames,
                errorDuplicateOtherName: `ชื่อ "[VALUE]" ไม่ถูกต้องเนื่องจากมีอยู่แล้วในนามของซินอนิมสำหรับใบรับรอง "[TITLE]"`,
                oftenUsed: oftenUsedTranslations['th'],

            },
            'en': {
                removeButtonTitle: 'Remove certificate from my profile',
        addButtonTitle: 'Add certificate to my profile',
        editButtonTitle: 'Change my own expiration date',
                editButtonHelper: 'Would you like to adjust the expiration date of this certificate in your profile?',
                addButtonHelper: 'Do you also have this certificate? Add it to your profile.',
                removeButtonHelper: 'Remove this certificate from your profile.',
                pleaseEnterTitle: 'Please enter a name.',
                generateCertificateData: 'Generate Certificate Data via AI',
                suggestedCertificateName: 'Suggested Certificate Name',
                errorDuplicateTitle: 'The name "[TITLE]" is invalid, a certificate with the name "[TITLE]" already exists.',
                editCertificateTitle: 'Adjust certificate in my profile',
                addCertificateTitle: 'Add a certificate to my profile',
                startDateHint: 'The issue date indicates when you received this certificate and is essential for managing your qualifications.',
                addCertDialogHint: 'Please enter the details of your certificate. The issue date is a mandatory field and must be filled out.',
                endDate: 'End date',
                startDate: 'Start date',
                productInfo: 'Product Information',
                durationInDays: 'Duration in days',
                prerequisites: 'Prerequisites',
                manufacturer: 'Manufacturer',
                openInNewTab: 'Open in New Tab',
                productName: 'Product Name',
                notFound: oftenUsedTranslations['en'].notFound,
                edit: oftenUsedTranslations['en'].edit,
                delete: oftenUsedTranslations['en'].delete,
                errorAdd: 'Failed to add!',
                errorRemove: 'Failed to remove!',
                employeesWithCertificate: "Employee's with this certificate",
                certificate: "Certificate",
                description: oftenUsedTranslations['en'].description,
                certificates: "Certificates",
                employeesTitle: 'Employee count (Head count)',
                employees: oftenUsedTranslations['en'].employees,
                employeesShort: 'HC',
                search: oftenUsedTranslations['en'].search,
                merge: 'Merge',
                mergeCertificates: 'Merge certificates',
                certificatesSelectedForMerge: 'Certificates selected for merge',
                effectEmployeesWarning: 'If you merge this certificates this will effect ',
                newCertificate: 'New certificate',
                error: 'error',
                pleaseEnterAName: 'Please enter a name.',
                empty: oftenUsedTranslations['en'].empty,
                create: oftenUsedTranslations['de'].create,
                save: oftenUsedTranslations['en'].save,
                certificateName: 'Certificate name',
                newCertificateError: 'Error saving',
                products: oftenUsedTranslations['en'].products,
                notAllowed: oftenUsedTranslations['en'].notAllowed,
                otherNames: oftenUsedTranslations['en'].otherNames,
                errorDuplicateOtherName: `The name "[VALUE]" is invalid as it's already registered as a synonym for the certificate "[TITLE]".`,
                oftenUsed: oftenUsedTranslations['en'],

            },
            'de': {
                notFound: oftenUsedTranslations['de'].notFound,
                edit: oftenUsedTranslations['de'].edit,
                delete: oftenUsedTranslations['de'].delete,
                errorAdd: 'Fehler beim Hinzufügen!',
                errorRemove: 'Fehler beim Entfernen!',
                employeesWithCertificate: 'Mitarbeiter mit diesem Zertifikat',
                certificate: "Zertifikat",
                description: oftenUsedTranslations['de'].description,
                certificates: "Zertifikate",
                employeesTitle: 'Mitarbeiter-Anzahl',
                employees: oftenUsedTranslations['de'].employees,
                employeesShort: 'HC',
                search: oftenUsedTranslations['de'].search,
                merge: 'Zusammenfügen',
                certificatesSelectedForMerge: 'Folgende Zertifikate wurden zum Zusammenfügen ausgewählt',
                effectEmployeesWarning: 'Das Zusammenfügen beeinflusst ',
                mergeCertificates: 'Zertifikate zusammenfügen',
                newCertificate: 'Neues Zertifikat',
                error: oftenUsedTranslations['de'].error,
                pleaseEnterAName: 'Bitte gebe einen Namen ein.',
                empty: oftenUsedTranslations['de'].empty,
                certificateName: 'Zertifikatsname',
                newCertificateError: 'Fehler beim Speichern',
                create: oftenUsedTranslations['de'].create,
                save: oftenUsedTranslations['de'].save,
                products: oftenUsedTranslations['de'].products,
                notAllowed: oftenUsedTranslations['de'].notAllowed,
                otherNames: oftenUsedTranslations['de'].otherNames,
                errorDuplicateTitle: `Der Name "[TITLE]" ist ungültig, ein Zertifikat mit dem Namen "[TITLE]" existiert bereits.`,
                errorDuplicateOtherName: `Der Name "[VALUE]" ist ungültig da dieser bereits als Synonym für das Zertifikat "[TITLE]" hinterlegt wurde.`,
                oftenUsed: oftenUsedTranslations['de'],
                productName: 'Produkt',
                openInNewTab: 'In neuer Registerkarte öffnen',
                manufacturer: 'Hersteller',
                prerequisites: 'Voraussetzungen',
                durationInDays: 'Laufzeit in Tagen',
                productInfo: 'Produktinformationen',
                startDate: 'Ausstellungsdatum',
                endDate: 'Ablaufdatum',
                addCertDialogHint: 'Bitte gib die Details deines Zertifikats ein. Das Ausstellungsdatum ist ein Pflichtfeld und muss ausgefüllt werden.',
                startDateHint: 'Das Ausstellungsdatum gibt an, wann du dieses Zertifikat erhalten hast und ist wichtig für die Verwaltung deiner Qualifikationen.',
                addCertificateTitle: 'Zertifikat meinem Profil hinzufügen',
                editCertificateTitle: 'Zertifikat in meinem Profil anpassen',
                suggestedCertificateName: 'Vorgeschlagener Zertifikatsname',
                generateCertificateData: 'Zertifikatsinformationen per AI generieren',
                pleaseEnterTitle: 'Bitte gib einen Namen ein.',

                removeButtonHelper: 'Entferne dieses Zertifikat aus deinem Profil.',
                addButtonHelper: 'Besitzt du diese Zertifikat auch? Füge es deinem Profil hinzu.',
                editButtonHelper: 'Möchtest du das Ablaufdatum dieses Zertifikats in deinem Profil anpassen?',
                addButtonTitle: 'Zertifikat zu meinem Profil hinzufügen',
                removeButtonTitle: 'Zertifikat aus meinem Profil entfernen',
                editButtonTitle: 'Eigenes Ablaufdatum ändern',
            },
        }