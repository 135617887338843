import React from 'react';
import { Typography } from '@mui/material';

import styles from './PercentInput.module.css';

interface PercentInputProps
{
    value: number
    disabled?: boolean
    helperText?: string
    onChange(val: number): void
}
export default function PercentInput(props: PercentInputProps)
{
    return (
        <div>
            <input
                type="number"
                className={styles.percentInput}
                min="0"
                max="100"
                value={props.value?.toString()}
                disabled={props.disabled}
                onChange={e =>
                {
                    let nr = 0;
                    if (e.target.value !== "")
                        nr = parseFloat(e.target.value);
                    if (nr > 100) nr = 100;
                    if (nr < 0) nr = 0;
                    props.onChange(nr)
                }} /> <div className="hint">%</div>
            <br />
            {props.helperText && <Typography variant="caption" color="textSecondary">{props.helperText}</Typography>}
        </div>
    );
}