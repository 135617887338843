import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import EntityPageInformation from '@sharedReact/Layouts/LayoutElements/EntityPageInformation/EntityPageInformation';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import Role from '@src/Objects/Role';
import AddRoleButton from '@src/Components/Buttons/AddRoleButton/AddRoleButton';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import { RoleDialog } from '@src/Components/Dialogs/RoleDialog/RoleDialog';
import getRole from '@src/APIs/graphQl/Role/getRole';
import { CustomGraphQLError } from '@src/APIs/graphQl/graphQL';
import { combineSkillsAndCertificates } from "@src/helper/helper";
import EmployeesCard from '@src/App/NewLayout/Cards/Employees/EmployeesCard/EmployeesCard';
import ProductsCard from '@src/App/NewLayout/Cards/Products/ProductsCard/ProductsCard';
import CompetencesCard from '@src/App/NewLayout/Cards/Competences/CompetencesCard/CompetencesCard';
import SkillsCard from '@src/App/NewLayout/Cards/Skills/SkillsCard/SkillsCard';
import CertificatesCard from '@src/App/NewLayout/Cards/Certificates/CertificatesCard/CertificatesCard';

import EntityPage from '../../../../../sharedReact/Pages/EntityPage/EntityPage';


interface RolePageProps
{
    entity?: Role;
    inDialog?: boolean;
}

const RolePage: React.FC<RolePageProps> = (props) =>
{
    const { openDialog } = useContext(DialogManagerContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const entityId = props.entity ? props.entity.id : id ? Number.parseInt(id) : -1;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const entityState = useSelector((state: AppState) => state.roles.find(a => a.id === entityId))

    const emp = useSelector((state: AppState) => state.employee);
    const permissions = useSelector((state: AppState) => state.permissions);

    const [loadingError, setLoadingError] = useState<CustomGraphQLError | null>(null);
    const { combinedSkills, combinedCertificates } = combineSkillsAndCertificates(entityState, allCompetences);

    useEffect(() =>
    {
        if (props.inDialog) return;
        getRole(entityId).catch((ex) =>
        {
            setLoadingError(ex);
        });
    }, [props.inDialog, entityId]);


    if (!emp) return null;
    const handleOpenEditDialog = async () =>
    {
        return openDialog(
            <RoleDialog
                id="RoleDialog"
                role={entityState}
            />);
    };
    return (
        <EntityPage
            inDialog={props.inDialog}
            loadingError={loadingError}
            entity={entityState}
            admin={permissions.admin}
            permissions={permissions.entities.Roles}
            onDelete={() =>
            {
                if (!props.inDialog)
                    navigate(`/${ELinks.ROLES}`);
            }}
            buttons={
                entityState ?
                    <AddRoleButton
                        size="small"
                        id={entityState.id}
                    />
                    : undefined
            }
            openEditDialog={handleOpenEditDialog}
            informations={
                entityState ?
                    [
                        entityState.description ? <EntityPageInformation title={langStrings.description} size={'full'}>
                            <ExpandableText>
                                <FormatedTextInput value={entityState.description} readonly={true} />
                            </ExpandableText>
                        </EntityPageInformation> : undefined,
                    ]
                    : []
            }
        >
            {entityState &&
                <>
                    <EmployeesCard title={langStrings.employeesWithRole} employees={entityState.employees} />
                    <CompetencesCard competences={entityState.competences} />
                    <SkillsCard
                        title={langStrings.oftenUsed.skills}
                        skills={combinedSkills} />
                    <CertificatesCard
                        certificates={[...combinedCertificates]}
                        title={langStrings.oftenUsed.certificates}
                    />

                    <ProductsCard products={entityState.products} />
                </>
            }
        </EntityPage>
    );
};

export default RolePage;
