import { Download } from "@mui/icons-material";
import React from 'react';
import { useSelector } from "react-redux";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import { AppState } from "@store/store";

import Button from "../../sharedReact/General/Button/Button";


type RowType = Array<string | number>;
interface CSVExporterProps
{
    filename: string
    headers: string[]
    rows: RowType[] | (() => RowType[])
}
/**
 * CSVExporter function
 * 
 * @param {CSVExporterProps} props - The props object for the CSVExporter component
 * @returns {JSX.Element} - The exported JSX element
 */
export default function CSVExporter(props: CSVExporterProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const onClick = () =>
    {
        let rows: RowType[];
        if (typeof props.rows === 'function')
        {
            rows = props.rows();
        }
        else
        {
            rows = props.rows;
        }

        const csvData = `${props.headers.join(';')}
${rows.map(
            row => row.map(f => f.toLocaleString()).join(';')
        ).join('\n')}
`;
        downloadCSV(csvData, props.filename);
    }
    return (<Button
        size={"normal"}
        text={langStrings.downloadAsCSV}
        icon={<Download />}
        onClick={onClick} />)
}

/**
 * Downloads a CSV file.
 * 
 * @param {string} data - The CSV data to download.
 * @param {string} filename - The name of the file.
 * 
 * @returns {void}
 */
function downloadCSV(data: string, filename = 'download.csv')
{
    const BOM = "\uFEFF"; // Byte Order Mark für UTF-8
    const blob = new Blob([BOM + data], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}