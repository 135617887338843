import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ISettingsTexts extends ITextBase
{
    apiKey: string;
    pleaseEnterApiKeyNote: string;
    noApiKeysYet: string;
    addApiKey: string;
    note: string;
    restApiKeys: string;
    restAPI: string;
    activateCertificateReminder: string;
    forecastMail: string;
    certificateReminder: string;
    syncNow: string;
    lastUpdateProjects: string;
    lastUpdateEmployeeAbsence: string;
    lastUpdateEmployeeTimesThisYear: string;
    lastUpdateEmployeeTimesLastYear: string;
    lastUpdateCustomer: string;
    status: string;
    sendNowSubtitle: string;
    areYouSureForecastMail: string;
    sendNow: string;
    sendToMe: string;
    mailForecastText: string;
    mailSendHowOften: string;
    emailSettings: string;
    offline: string;
    memberOfGroupEmpty: string
    memberOfGroup: string;
    showMember: string;
    openHolidaysAPI: string;
    editMode: string;
    showMode: string;
    holidayManagement: string;
    sumOfDays: string;
    elementCount: string;
    holidaySource: string;
    holidayManuel: string;
    holidayTabError: string;
    holidayName: string;
    completeDay: string;
    holidays: string;
    clockodo: string;
    dataTabTitle: string;
    userAndOUs: string;
    industry: string;
    language: string;
    country: string;
    general: string;
    pastForecastMonth: string,
    futureForecastMonths: string,
    calculationsText: string;
    calculations: string;
    clockodoUserApiKey: string;
    clockodoUserEmail: string;
    clockodoSync: string;
    synchronisation: string;
    registration: string;
    entity: string;
    id: string;
    newPermissionGroup: string;
    permissionGroups: string;
    permissions: string;
    employeeWithoutOU: string;
    errorChangeName: string
    loading: string
    styleTitle: string
    primaryColor: string
    onPrimaryColor: string
    secondaryColor: string
    onSecondaryColor: string
    levelDefinitions: string
    title: string
    level: string
    description: string
    explanation: string
    newLevelDefinition: string
    orgUnits: string
    leader: string
    employees: string
    createOu: string
    deleteOu: string
    createNewOrgUnder: string
    createOrgDescription: string
    orgName: string
    create: string
    save: string
    pleaseEnterOUname: string
    pleaseEnterLeaderName: string
}
type ILangData = {
    [lang in ELanguage]: ISettingsTexts
}
export const settingsTexts: ILangData = {
            'th': {
                apiKey: 'คีย์ API',
        pleaseEnterApiKeyNote: 'โปรดใส่หมายเหตุสำหรับคีย์ API ใหม่',
                noApiKeysYet: 'ยังไม่มีการสร้าง API-Keys ใดๆ',
                addApiKey: 'เพิ่มคีย์ API',
                note: 'บันทึกย่อ',
                restApiKeys: 'คีย์สำหรับ REST API',
                restAPI: 'REST API',
                lastUpdateEmployeeTimesThisYear: 'การอัปเดตเวลาของพนักงานครั้งล่าสุด (สำหรับปีนี้)',
                lastUpdateEmployeeTimesLastYear: 'การอัปเดตเวลาของพนักงานครั้งล่าสุด (สำหรับปีที่แล้ว)',
                activateCertificateReminder: 'เปิดใช้งานอีเมลแจ้งเตือนใบรับรองอัตโนมัติหรือไม่?',
                forecastMail: 'อีเมลพยากรณ์อากาศ',
                certificateReminder: 'การแจ้งเตือนใบรับรอง',
                syncNow: 'ซิงค์ข้อมูลเดี๋ยวนี้',
                status: 'สถานะ',
                lastUpdateCustomer: 'อัปเดตข้อมูลลูกค้าครั้งล่าสุด',
                lastUpdateEmployeeAbsence: 'อัปเดตการขาดงานของพนักงานครั้งล่าสุด',
                lastUpdateProjects: 'อัปเดตโครงการครั้งล่าสุด',
                sendNowSubtitle: 'โปรดทราบ, การกระทำนี้จะส่งอีเมลพยากรณ์ไปยังพนักงานทุกคนทันที',
                areYouSureForecastMail: 'คุณแน่ใจหรือว่าต้องการส่งอีเมลถึงพนักงานทุกคน?',
                sendNow: 'ส่งตอนนี้',
                sendToMe: 'ส่งถึงฉัน (ทดสอบ)',
                mailForecastText: 'คุณต้องการเพิ่มเติมข้อความในอีเมล์หรือไม่?',
                mailSendHowOften: 'คุณต้องการให้ส่งอีเมล์นี้บ่อยแค่ไหน?',
                emailSettings: 'ตั้งค่าอีเมล',
                offline: 'ไม่สามารถใช้งานการตั้งค่าในโหมดออฟไลน์ได้',
                memberOfGroupEmpty: 'กลุ่มนี้ไม่มีสมาชิก',
                memberOfGroup: 'สมาชิกของกลุ่มสิทธิ์ [GROUPNAME]',
                showMember: 'แสดงสมาชิก',
                completeDay: 'ตลอดทั้งวัน?',
                openHolidaysAPI: 'OpenHolidays API',
                editMode: 'โหมดการแก้ไข',
                showMode: 'โหมดการแสดงผล',
                holidayManagement: 'การจัดการวันหยุด',
                sumOfDays: 'ผลรวมของวัน',
                elementCount: 'จำนวนครั้ง',
                holidaySource: 'แหล่งข้อมูลวันหยุด',
                holidayManuel: 'ดูแลด้วยมือ',
                holidayTabError: 'กรุณากรอกข้อมูลในทุกช่อง',
                holidayName: 'ชื่อวันหยุด',
                oftenUsed: oftenUsedTranslations['th'],
                holidays: 'วันหยุด',
                dataTabTitle: 'ข้อมูลและการประมวลผล',
                userAndOUs: 'ผู้ใช้ & OUs (IAM)',
                industry: 'อุตสาหกรรม',
                language: 'ภาษา',
                country: 'ประเทศ',
                general: 'ทั่วไป',
                calculationsText: 'กำหนดจำนวนเดือนที่ผ่านมาและเดือนที่จะมาของการคำนวณของคุณ\nเดือนปัจจุบันถือว่าเป็นเดือนในอนาคต',
                futureForecastMonths: 'เดือนในอนาคต',
                pastForecastMonth: 'เดือนที่ผ่านมา',
                calculations: 'การคำนวณ',
                styleTitle: 'ช่วงสี',
                errorChangeName: 'เปลี่ยนชื่อไม่สำเร็จ!',
                loading: oftenUsedTranslations['th'].loading,
                primaryColor: 'สีหลัก',
                onPrimaryColor: 'สีบนพื้นหลังหลัก',
                secondaryColor: 'สีรอง',
                onSecondaryColor: 'สีบนพื้นหลังรอง',
                levelDefinitions: 'การกำหนดระดับ',
                title: 'ชื่อเรื่อง',
                level: 'ระดับ',
                description: oftenUsedTranslations['th'].description,
                explanation: oftenUsedTranslations['th'].description,
                newLevelDefinition: 'การกำหนดระดับใหม่',
                orgUnits: oftenUsedTranslations['th'].orgUnits,
                leader: 'ผู้นำ',
                employees: oftenUsedTranslations['th'].employees,
                createOu: 'สร้างหน่วยงาน',
                deleteOu: 'ลบหน่วยงาน',
                createNewOrgUnder: 'สร้างหน่วยงานใหม่ภายใต้',
                createOrgDescription: 'โปรดป้อนหน่วยงานทั้งหมดที่คุณต้องการสร้างภายใต้',
                orgName: 'ชื่อหน่วยงาน',
                create: oftenUsedTranslations['th'].create,
                save: oftenUsedTranslations['th'].save,
                employeeWithoutOU: 'พนักงานที่ไม่มีหน่วยงาน',
                pleaseEnterOUname: 'โปรดป้อนชื่อหน่วยงาน',
                pleaseEnterLeaderName: 'โปรดเลือกผู้นำ',
                permissions: oftenUsedTranslations['th'].permissions,
                permissionGroups: 'กลุ่มสิทธิ์การเข้าถึง',
                newPermissionGroup: 'กลุ่มสิทธิ์การเข้าถึงใหม่',
                id: "ID",
                entity: 'องค์กร',
                registration: 'การลงทะเบียน',
                synchronisation: 'การประสาน',
                clockodoSync: 'การประสานกับ Clockodo',
                clockodoUserEmail: 'อีเมลผู้ใช้สำหรับการประสานกับ Clockodo',
                clockodoUserApiKey: 'คีย์ API ผู้ใช้สำหรับการประสานกับ Clockodo',
                clockodo: 'Clockodo',
            },
            'en': {
                apiKey: 'API Key',
        pleaseEnterApiKeyNote: 'Please enter a note for the new API Key.',
                noApiKeysYet: 'No API keys have been created yet.',
                addApiKey: 'Add API Key',
                note: 'Note',
                restApiKeys: 'REST API Keys',
                restAPI: 'REST API',
                lastUpdateEmployeeTimesThisYear: 'Last Update of Employee Times (For This Year)',
                lastUpdateEmployeeTimesLastYear: 'Last Update of Employee Times (For Last Year)',
                activateCertificateReminder: 'Activate automatic certificate reminder emails?',
                forecastMail: 'Forecast Email',
                certificateReminder: 'Certificate Reminders',
                syncNow: 'Sync now',
                status: 'Status',
                lastUpdateCustomer: 'Last Company Update',
                lastUpdateEmployeeAbsence: 'Last Employee Absence Update',
                lastUpdateProjects: 'Last Project Update',
                sendNowSubtitle: 'Please note, this will send the forecast email immediately to all employees.',
                areYouSureForecastMail: 'Are you sure you want to send the email to all employees?',
                sendNow: 'Send now',
                sendToMe: 'Send to me (Test)',
                mailForecastText: 'Would you like to supplement the email text?',
                mailSendHowOften: 'How often should the email be sent?',
                emailSettings: 'Email settings',
                offline: 'Settings are not available in offline mode.',
                memberOfGroupEmpty: 'The group has no members',
                memberOfGroup: 'Members of the permission group [GROUPNAME]',
                showMember: 'Show members',
                completeDay: 'All day?',
                openHolidaysAPI: 'OpenHolidays API',
                editMode: 'Edit mode',
                showMode: 'Display mode',
                holidayManagement: 'Holiday Management',
                sumOfDays: 'Sum of days',
                elementCount: 'Number of entries',
                holidaySource: 'Holiday Source',
                holidayManuel: 'maintain manually',
                holidayTabError: 'Please fill out all fields.',
                holidayName: 'Holiday name',
                oftenUsed: oftenUsedTranslations['en'],
                holidays: 'Holidays',
                dataTabTitle: 'Data & Processing',
                userAndOUs: 'Users & OUs (IAM)',
                industry: 'Industry',
                language: 'Language',
                country: 'Country',
                general: 'General',
                calculationsText: 'Define the number of past and future months for your calculations.\nThe current month counts as future months.',
                futureForecastMonths: 'Future months',
                pastForecastMonth: 'Past months',
                calculations: 'calculations',
                styleTitle: 'Color palette',
                errorChangeName: "Failed to change the name!",
                loading: oftenUsedTranslations['en'].loading,
                primaryColor: 'Primary color',
                onPrimaryColor: 'On primary color',
                secondaryColor: 'Secondary color',
                onSecondaryColor: 'On secondary color',
                levelDefinitions: 'Level-Definitions',
                title: 'Title',
                level: 'Level',
                description: oftenUsedTranslations['en'].description,
                explanation: 'Explanation',
                newLevelDefinition: 'New level-definition',
                orgUnits: 'Organisational units',
                leader: 'Leader',
                employees: oftenUsedTranslations['en'].employees,
                createOu: 'Create OU',
                deleteOu: 'Delete OU',
                createNewOrgUnder: 'Create new OU under',
                createOrgDescription: 'Please enter all the Organisational units you want to create under',
                orgName: 'Organisational unit',
                create: 'Create',
                save: oftenUsedTranslations['en'].save,
                employeeWithoutOU: 'Employees without organizational unit',
                pleaseEnterOUname: 'Please enter a OU-name.',
                pleaseEnterLeaderName: 'Please select a leader.',
                permissions: oftenUsedTranslations['en'].permissions,
                permissionGroups: 'Permission Groups',
                newPermissionGroup: 'New Permission Group',
                id: "ID",
                entity: 'Entity',
                registration: 'Registration',
                synchronisation: 'Synchronization',
                clockodoSync: 'Clockodo Synchronization',
                clockodoUserEmail: 'Clockodo Synchronization User Email Address',
                clockodoUserApiKey: 'Clockodo Synchronization User API Key',
                clockodo: 'Clockodo',
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                styleTitle: 'Farbgebung',
                errorChangeName: 'Fehler beim ändern des Namens!',
                loading: oftenUsedTranslations['de'].loading,
                primaryColor: 'Primärfarbe',
                onPrimaryColor: 'Schrift auf Primärfarbe',
                secondaryColor: 'Sekundärfarbe',
                onSecondaryColor: 'Schrift auf Sekundärfarbe',
                levelDefinitions: 'Level-Definitionen',
                title: 'Titel',
                level: 'Level',
                description: oftenUsedTranslations['de'].description,
                explanation: 'Erklärung',
                newLevelDefinition: 'Neue Level-Definition',
                orgUnits: oftenUsedTranslations['de'].orgUnits,
                leader: 'Leitung',
                employees: oftenUsedTranslations['de'].employees,
                createOu: 'Organisationseinheit erstellen',
                deleteOu: 'Organisationseinheit löschen',
                createNewOrgUnder: 'Neue Organisationseinheit unter',
                createOrgDescription: 'Bitte gebe alle organisatorischen Einheiten ein, die du erstellen möchtest.',
                orgName: oftenUsedTranslations['de'].orgUnit,
                create: oftenUsedTranslations['de'].create,
                save: oftenUsedTranslations['de'].save,
                employeeWithoutOU: 'Mitarbeiter ohne Organisationseinheit',
                pleaseEnterOUname: 'Bitte trage einen OU Namen ein.',
                pleaseEnterLeaderName: 'Bitte trage eine Führungskraft ein.',
                permissions: oftenUsedTranslations['de'].permissions,
                permissionGroups: 'Berechtigungsgruppen',
                newPermissionGroup: 'Neue Berechtigungsgruppe',
                id: "ID",
                entity: "Entität",
                registration: "Registration",
                synchronisation: "Synchronisation",
                clockodoSync: 'Clockodo Synchronisation',
                clockodoUserEmail: 'Clockodo Synchronisations-Nutzer-E-Mail-Adresse',
                clockodoUserApiKey: 'Clockodo Synchronisations-Nutzer API-Key',
                calculations: 'Kalkulationen',
                calculationsText: 'Legen die Anzahl der vergangenen und zukünftigen Monate für die Kalkulationen fest.\nDer aktuelle Monat zählt zu den zukünftigen Monaten.',
                pastForecastMonth: 'Vergangene Monate',
                futureForecastMonths: 'Zukünftige Monate',
                general: 'Allgemein',
                country: 'Land',
                language: 'Sprache',
                industry: 'Branche',
                userAndOUs: 'Nutzer & OUs (IAM)',
                dataTabTitle: 'Daten & Verarbeitung',
                clockodo: 'Clockodo',
                holidays: 'Feiertage',
                holidayName: 'Feiertagsname',
                completeDay: 'Ganztägig?',
                holidayTabError: 'Bitte fülle alle Felder aus.',
                holidayManuel: 'Manuell pflegen',
                holidaySource: 'Feiertagsquelle',
                elementCount: 'Anzahl der Eintäge',
                sumOfDays: 'Summe der Tage',
                holidayManagement: 'Feiertagsverwaltung',
                showMode: 'Anzeigemodus',
                editMode: 'Bearbeitungsmodus',
                openHolidaysAPI: 'OpenHolidays API',
                showMember: 'Mitglieder anzeigen',
                memberOfGroup: 'Mitglieder der Berechtigungsgruppe [GROUPNAME]',
                memberOfGroupEmpty: 'Die Gruppe hat keine Mitglieder',
                offline: 'Die Einstellungen stehen Offline leider nicht zur Verfügung.',
                emailSettings: 'E-Mail',
                mailSendHowOften: 'Wie oft soll die E-Mail versendet werden?',
                mailForecastText: 'Möchten Sie den E-Mail Text ergänzen?',
                sendToMe: 'An mich senden (Test)',
                sendNow: 'Jetzt versenden',
                areYouSureForecastMail: 'Bist du sicher das du die E-Mail an alle Mitarbeiter versenden möchtest?',
                sendNowSubtitle: 'Achtung, dies versendet die Forecast-E-Mail jetzt sofort an alle Mitarbeiter.',
                lastUpdateProjects: 'Letztes Projekt Update',

                lastUpdateEmployeeAbsence: 'Letztes Abwesenheiten Update',
                lastUpdateEmployeeTimesLastYear: 'Letztes Mitarbeiter Zeiten Update (Für letztes Jahr)',
                lastUpdateEmployeeTimesThisYear: 'Letztes Mitarbeiter Zeiten Update (Für dieses Jahr)',
                lastUpdateCustomer: 'Letztes Firmen Update',
                status: 'Status',
                syncNow: 'Jetzt synchronisieren',
                certificateReminder: 'Zertifikats-Erinnerungen',
                forecastMail: 'Forecast-Mail',
                activateCertificateReminder: 'Automatische Zertifikats-Emails aktivieren?',

                restAPI: 'REST API',
                restApiKeys: 'REST API Keys',
                note: 'Notiz',
                addApiKey: 'API Key hinzufügen',
                noApiKeysYet: 'Es wurden noch keine API-Keys angelegt.',
                pleaseEnterApiKeyNote: 'Bitte gib eine Notiz für den neuen API Key ein.',
                apiKey: 'API-Key'
            },
        }