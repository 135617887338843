import { OperationVariables, gql } from "@apollo/client";
import IProjectDTO, { IEditProjectDTO } from "@sharedInterfaces/IProject";
import { projectGQL } from "@src/Objects/Project";

import GraphQL from "../graphQL";

const query = gql`
mutation editProject($id:Int!, $obj: EditProject!) {
    editProject(id: $id ,obj: $obj) 
    ${projectGQL}
},
`;

/**
 * Edit the project.
 *
 * @param {IEditProjectDTO} project - The project to be edited.
 * @returns {Promise<IProject>} - A promise that resolves with the edited project.
 */
export default async function editProject(project: IEditProjectDTO)
{
    const variables: OperationVariables = {
        id: project.id,
        obj: {
            ...project,
            employees: project.employees.map(s => ({
                id: s.id,
                title: s.title,
            })),
            business: {
                id: project.business.id,
                title: project.business.title
            },
            startDate: project.startDate ? project.startDate : null,
            endDate: project.endDate ? project.endDate : null,

        },
    };
    return GraphQL.mutate<IProjectDTO>(query, variables)
}

//, (obj: any) => callback(new Project(companyId, obj.editProject as IProject))