import { OperationVariables, gql } from "@apollo/client";
import store from "@store/store";
import { setEmployeeCertificates } from "@store/reducer/employeeReducer";

import GraphQL from "../graphQL";

const query = gql`
mutation changeMapCertificate($id: Int!, $add: Boolean!, $startDate: DateTime, $endDate: DateTime) {
    changeMapCertificate(id: $id, add: $add, startDate: $startDate, endDate: $endDate) 
},
`;

/**
 * Change the map certificate.
 * 
 * @param {number} id - The ID of the certificate.
 * @param {boolean} add - Specifies whether to add or remove the certificate.
 * @param {Date} startDate - The start date of the certificate.
 * @param {Date} endDate - The end date of the certificate.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the certificate was successfully changed.
 */
export default async function changeMapCertificate(id: number, add: boolean, startDate: Date | undefined, endDate: Date | undefined)
{
    const variables: OperationVariables = {
        id,
        add,
        startDate,
        endDate,
    };
    return GraphQL.mutate<boolean>(query, variables).then(() =>
    {
        const state = store.getState();
        const employee = state.employee;
        store.dispatch(setEmployeeCertificates(employee.certificates.filter(c => c.id !== id)))
    });
}
