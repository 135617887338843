import { gql } from "@apollo/client";
import { SplitSkillRequestDTO, SplitSkillResponseDTO } from "@sharedInterfaces/ISkill";
import { skillDetailGQL } from "@src/Objects/SkillDetail";

import GraphQL from "../graphQL";

const query = gql`
mutation splitSkill($skillId: Int!, $newName1: String!,$newName2: String!,$newDescription1: String!,$newDescription2: String!) {
    splitSkill(skillId: $skillId,newName1: $newName1,newName2: $newName2,newDescription1: $newDescription1,newDescription2: $newDescription2) 
    {
    skill1 ${skillDetailGQL}
    skill2 ${skillDetailGQL}
  }
},
`;

export default async function mergeSkills(skillId: number, newName1: string, newName2: string,
    newDescription1: string, newDescription2: string)
{
    const variables: SplitSkillRequestDTO = {
        skillId,
        newName1,
        newName2,
        newDescription1,
        newDescription2,
    };
    const response = await GraphQL.mutate<SplitSkillResponseDTO>(query, variables);
    response.skill1.id = parseInt(response.skill1.id.toString())
    response.skill2.id = parseInt(response.skill2.id.toString())
    return response;
}