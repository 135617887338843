import { OperationVariables, gql } from "@apollo/client";
import { ISkillCategoryDetailDTO } from "@sharedInterfaces/ISkillCategory";
import { SkillCategory } from "@src/Objects/SkillCategory";
import { entityFieldsGQL } from "@sharedReact/Objects/Entity";

import GraphQL from "../graphQL";
export const skillCategoryGQL = `
{
    id
    title
    description
    parentCategory
    employees {
        id
        title
        skillCount
        averageSkillLevel
        levelCount
    }
    ${entityFieldsGQL}
}`;

const query = gql`
mutation editSkillCategory($id:Int!, $title: String!, $description: String!, $parentCategory: Int, $skills: [Int!]!) {
    editSkillCategory(id: $id ,title: $title, description: $description, parentCategory: $parentCategory, skills: $skills) 
    ${skillCategoryGQL}
},
`;

/**
 * Edit a skill category using GraphQL.
 * 
 * @param {number} id - The id of the skill category.
 * @param {string} title - The title of the skill category.
 * @param {string} description - The description of the skill category.
 * @param {number[]} skills - An array of skill ids of the skill category.
 * 
 * @returns {Promise<SkillCategory>} - A Promise that resolves to the edited skill category.
 */
export default async function editSkillCategoryGraphQL(
    id: number,
    title: string,
    description: string,
    parentCategory: number | null,
    skills: number[]
)
{
    let numberId = id;
    if (typeof id !== 'number')
    {
        numberId = parseInt(id);
    }
    const variables: OperationVariables = {
        id: numberId,
        title,
        description,
        parentCategory,
        skills,
    };
    return GraphQL.mutate<ISkillCategoryDetailDTO>(query, variables).then(sc => new SkillCategory(sc));
}