import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EAvailabilityInputAs, EAvailabilitySelectionType, IAvailabilityWeekDTO } from '@sharedInterfaces/IAvailabilityMonth';
import { AppState } from '@store/store';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';

import CardBox from '../CardBox/CardBox';
import AvailabilityMonthDialog from '../Dialogs/AvailabilityMonthDialog/AvailabilityMonthDialog';

interface MonthBoxProps
{
    month: AvailabilityMonth;
    setAvailability: (id: string, weekArray: IAvailabilityWeekDTO[], selectionType: EAvailabilitySelectionType, inputAs: EAvailabilityInputAs) => void;
    setMonths: (objs: AvailabilityMonth[]) => void;
}
/**
 * Renders a MonthBox component.
 * 
 * @param {MonthBoxProps} props - The MonthBox props.
 * @returns {JSX.Element} - The rendered MonthBox component.
 */
export function MonthBox(props: MonthBoxProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const month = props.month;
    const lang = useSelector((state: AppState) => state.employee.language);
    const offline = useSelector((state: AppState) => state.client.offline);
    const langStrings = getLanguageTexts(lang).availability;

    // const percent = Math.round(month.planings * 100) / 100;
    // const days = Math.round(month.availability / 100 * month.workDayCount * 100) / 100;
    return (
        <CardBox
            title={month.LocalTitle}
            rightButtons={<IconButton
                disabled={offline}
                size='small'
                onClick={() =>
                {
                    openDialog(<AvailabilityMonthDialog
                        id={'AvailabilityMonthDialog'}
                        availabilityMonth={month}
                        onEdit={(weekArray, type, inputType) =>
                        {
                            props.setAvailability(month.id, weekArray, type, inputType);
                            AvailabilityMonth.getAvailabilityMonths((newMonths) =>
                            {
                                props.setMonths(newMonths);
                            });
                        }}
                        resolve={function (): void
                        {

                        }} />)
                }}><Edit /></IconButton>}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            {langStrings.workdays}
                        </td>
                        <td>
                            {month.workDayCount}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {langStrings.plannedDays}
                        </td>
                        <td>
                            {Math.round(month.plannedDayCount * 10) / 10}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {langStrings.absenceDays}
                        </td>
                        <td>
                            {month.absenceDayCount}
                        </td>
                    </tr>
                    <tr title={langStrings.onlyOnWorkday}>
                        <td>
                            {langStrings.holidays}*
                        </td>
                        <td>
                            {month.holidayCount}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {langStrings.oftenUsed.availability}
                        </td>
                        <td>
                            {100 - Math.round((month.plannedDayCount + month.absenceDayCount + month.holidayCount) / month.workDayCount * 100)}
                            %
                        </td>
                    </tr>
                </tbody>
            </table>
        </CardBox>
    );
}
