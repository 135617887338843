import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import getEmployee from '@src/APIs/graphQl/Employee/getEmployee';
import EmployeePage from '@src/App/NewLayout/Pages/Employees/EmployeePage/EmployeePage';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import Overlay from '../Overlay/Overlay';

import './EmployeeOverlay.css';

interface EmployeeOverlayProps
{
    employeeId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * EmployeeOverlay function component.
 * @param {EmployeeOverlayProps} props - The props object for the EmployeeOverlay component.
 * @returns {JSX.Element} The rendered EmployeeOverlay component.
 */
function EmployeeOverlay(props: EmployeeOverlayProps)
{
    const showErrorDialog = useErrorDialog();
    const employee = useSelector((state: AppState) => state.employees.find(e => e.id === props.employeeId));
    return (
        <Overlay
            loaded={employee !== undefined}
            disabled={props.disabled}
            triggerItem={props.children}
            onFirstOpen={() =>
            {
                getEmployee(props.employeeId).catch(showErrorDialog)
            }}>
            {employee && <EmployeePage entity={employee} inDialog={true} />}
        </Overlay>
    );
}

export default EmployeeOverlay;
