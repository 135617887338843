import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"
export interface IProfileTexts extends ITextBase
{
    description: string;
    availability: string;
    empty: string
    loading: string
    similarUsers: string;
    skills: string;
    certificates: string;
    editMyProfile: string;
    orgUnit: string,
}
type ILangData = {
    [lang in ELanguage]: IProfileTexts
}
export const profileTexts: ILangData = {
    'th': {
        oftenUsed: oftenUsedTranslations['th'],
        loading: oftenUsedTranslations['th'].loading,
        orgUnit: 'หน่วยงาน',
        editMyProfile: 'แก้ไขโปรไฟล์ของฉัน',
        skills: oftenUsedTranslations['th'].skills,
        certificates: oftenUsedTranslations['th'].certificates,
        similarUsers: "พนักงานที่คล้ายกัน",
        empty: oftenUsedTranslations['th'].empty,
        availability: oftenUsedTranslations['th'].availability,
        description: oftenUsedTranslations['th'].description,
    },
    'en': {
        oftenUsed: oftenUsedTranslations['en'],
        loading: oftenUsedTranslations['en'].loading,
        orgUnit: 'Organisational unit',
        editMyProfile: 'Edit my profile',
        skills: oftenUsedTranslations['en'].skills,
        certificates: oftenUsedTranslations['en'].certificates,
        similarUsers: "Similar employees",
        empty: oftenUsedTranslations['en'].empty,
        availability: oftenUsedTranslations['en'].availability,
        description: oftenUsedTranslations['en'].description,

    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        loading: oftenUsedTranslations['de'].loading,
        orgUnit: oftenUsedTranslations['de'].orgUnit,
        editMyProfile: 'Mein Profil bearbeiten',
        skills: oftenUsedTranslations['de'].skills,
        certificates: oftenUsedTranslations['de'].certificates,
        similarUsers: 'Ähnliche Mitarbeiter',
        empty: oftenUsedTranslations['de'].empty,
        availability: oftenUsedTranslations['de'].availability,
        description: oftenUsedTranslations['de'].description,
    },
}