import { OperationVariables, gql } from "@apollo/client";


import GraphQL from "../../graphQL";

const query = gql`
mutation updateGoalState($id:Int!) {
    updateGoalState(id: $id)
},
`;

/**
 * Marks the first experience as read.
 * 
 * @returns {Promise<boolean>}
 */
export default async function updateGoalState(id: number)
{
    const variables: OperationVariables = {
        id,
    };
    return GraphQL.mutate<boolean>(query, variables);
}