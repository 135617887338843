import { stageContext } from "@src/globals";
import store from "@store/store";

export class WebSocketClient
{
    static socket: WebSocket | null = null;
    static lastSubscriptionId = 0;
    static subscriptions: { [topic: string]: { callback: CallableFunction, subKey: string }[] } = {};
    // Verbindung herstellen
    static async connect(): Promise<void>
    {
        if (WebSocketClient.socket)
        {
            WebSocketClient.disconnect();
        }
        const state = store.getState();
        const secret = state.client.secret;
        if (!secret)
        {
            return
        }
        const url = `${stageContext.apiWebsocketDomain}?auth=${encodeURIComponent(secret)}`;
        WebSocketClient.socket = new WebSocket(url);
        await new Promise<void>((resolve, reject) =>
        {
            const timeout = setTimeout(() =>
            {
                reject(new Error('Verbindungsaufbau zu WebSocket timeout.'));
            }, 5000); // Setzen Sie den Timeout nach Bedarf

            if (!WebSocketClient.socket)
            {
                clearTimeout(timeout);
                reject(new Error('WebSocket-Instanz konnte nicht erstellt werden.'));
                return;
            }

            WebSocketClient.socket.onopen = () =>
            {
                clearTimeout(timeout);
                // console.log('WebSocket Verbindung geöffnet');
                resolve();
            };

            WebSocketClient.socket.onerror = (error) =>
            {
                clearTimeout(timeout);
                console.error('WebSocket Fehler:', error);
                reject(error);
            };

            WebSocketClient.socket.onmessage = (event) =>
            {
                // console.log('Nachricht erhalten:', event.data);
                const message = JSON.parse(event.data);
                const topic = message.topic;
                const data = message.data;
                // console.log(topic, data)
                const subscriptions = WebSocketClient.subscriptions[topic];
                if (subscriptions)
                {
                    const jsonData = JSON.parse(data);
                    subscriptions.forEach(sub => sub.callback(jsonData));
                }
                else
                    console.log(`Keine Subscriptions für ${topic}`)
                // Behandeln des Empfangs einer Nachricht
            };
        });
    }

    static async sendMessage(topic: string, data: unknown): Promise<void>
    {
        try
        {
            if (!this.socket || this.socket.readyState !== WebSocket.OPEN)
            {
                console.log('WebSocket ist nicht verbunden. Verbindung wird hergestellt...');
                await this.connect();
            }

            if (this.socket && this.socket.readyState === WebSocket.OPEN)
            {
                this.socket.send(JSON.stringify({ topic, data }));
                console.log("Message send");
            } else
            {
                throw new Error('WebSocket-Verbindung konnte nicht hergestellt werden.');
            }
        } catch (error)
        {
            console.error('Fehler beim Senden der Nachricht:', error);
        }
    }

    // Verbindung schließen
    static disconnect(): void
    {
        if (WebSocketClient.socket)
        {
            WebSocketClient.socket.close();
        }
    }
    static unsubscripe(subKey: string)
    {
        const [topic] = subKey.split('-');
        WebSocketClient.subscriptions[topic] = WebSocketClient.subscriptions[topic].filter(sub => sub.subKey !== subKey);
    }

    static subscripe<T>(topic: string, callback: (data: T) => void): string
    {
        const subKey = `${topic}-${WebSocketClient.lastSubscriptionId + 1}`
        WebSocketClient.lastSubscriptionId = WebSocketClient.lastSubscriptionId + 1
        const subscriptions = WebSocketClient.subscriptions || {};
        if (!subscriptions[topic])
        {
            subscriptions[topic] = [{
                subKey,
                callback,
            }];
        } else
        {
            subscriptions[topic].push({
                subKey,
                callback,
            });
        }
        return subKey;
    }
}
