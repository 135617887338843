import React from 'react';
import { Typography } from '@mui/material';

import styles from './CurrencyInput.module.css';

interface CurrencyInputProps
{
    value: number
    disabled?: boolean
    helperText?: string
    onChange(val: number): void
}
export default function CurrencyInput(props: CurrencyInputProps)
{
    return (
        <div>
            <input
                type="number"
                className={styles.currencyInput}
                disabled={props.disabled}
                min="0"
                style={{ maxWidth: 100 }} value={props.value?.toString()}
                onChange={e => props.onChange(parseFloat(e.target.value))}
            />
            <div className="hint">€</div>
            <br />
            {props.helperText && <Typography variant="caption" color="textSecondary">{props.helperText}</Typography>}
        </div>
    );
}