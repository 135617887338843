import { ELanguage, findMatchingLanguage } from "@sharedInterfaces/Language/languageHelper";
import { EGender, IAvailabilityDTO, IEmployeeDTO, IEmployeeRoleReference } from "@sharedInterfaces/IEmployee";
import { ICertificateEmployeeDTO } from "@sharedInterfaces/ICertificate";
import { ISkillReferenceDTO } from "@sharedInterfaces/ISkill";
export interface EmployeeState extends IEmployeeDTO
{
    language: ELanguage;
    unknownSkillCount: number;
}

export const initialEmployeeState: EmployeeState = {
    id: -1,
    title: '',
    description: '',
    firstName: "",
    lastName: "",
    gender: EGender.OTHER,
    email: "",
    language: findMatchingLanguage(window.navigator.languages),
    certificates: [],
    skills: [],
    similarUsers: [],
    availability: { availability: 0, workDayCount: 0 },
    products: [],
    projects: [],
    opportunities: [],
    competences: [],
    roles: [],
    salesIdea: '',
    pictureId: null,
    unknownSkillCount: 0,
    version: 0,
};
export enum EmployeeActionTypes
{
    SET_EMPLOYEE = 'SET_EMPLOYEE',
    SET_EMPLOYEE_AVAILABILITY = 'SET_EMPLOYEE_AVAILABILITY',
    SET_EMPLOYEE_LANG = 'SET_EMPLOYEE_LANG',
    SET_EMPLOYEE_DESCRIPTION = 'SET_EMPLOYEE_DESCRIPTION',
    SET_EMPLOYEE_CERTIFICATES = 'SET_EMPLOYEE_CERTIFICATES',
    SET_EMPLOYEE_SKILLS = 'SET_EMPLOYEE_SKILLS',
    SET_EMPLOYEE_PICTURE_ID = 'SET_EMPLOYEE_PICTURE_ID',
    SET_EMPLOYEE_UNKNOWN_SKILL_COUNT = 'SET_EMPLOYEE_UNKNOWN_SKILL_COUNT',
    SET_EMPLOYEE_ROLES = 'SET_EMPLOYEE_ROLES',
    SET_EMPLOYEE_NAMES = 'SET_EMPLOYEE_NAMES',
}

// Spezifische Aktionstypen
interface ISetEmployeeAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE;
    payload: EmployeeState;
}

interface ISetEmployeeAvailabilityAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_AVAILABILITY;
    payload: IAvailabilityDTO;
}

interface ISetEmployeeLangAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_LANG;
    payload: ELanguage;
}

interface ISetEmployeeDescriptionAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_DESCRIPTION;
    payload: string;
}
interface ISetEmployeePictureIdAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID;
    payload: string | null;
}

interface ISetEmployeeCertificatesAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_CERTIFICATES;
    payload: ICertificateEmployeeDTO[];
}

interface ISetEmployeeSkillsAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_SKILLS;
    payload: ISkillReferenceDTO[];
}
interface ISetEmployeeUnknownSkillCount
{
    type: EmployeeActionTypes.SET_EMPLOYEE_UNKNOWN_SKILL_COUNT;
    payload: number;
}

interface ISetEmployeeRolesAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_ROLES;
    payload: IEmployeeRoleReference[];
}

interface ISetEmployeeNamesAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_NAMES;
    payload: { firstname: string, lastname: string };
}


// Kombinierter Aktionstyp
type EmployeeAction =
    | ISetEmployeeAction
    | ISetEmployeeAvailabilityAction
    | ISetEmployeeLangAction
    | ISetEmployeeDescriptionAction
    | ISetEmployeeCertificatesAction
    | ISetEmployeeSkillsAction
    | ISetEmployeePictureIdAction
    | ISetEmployeeUnknownSkillCount
    | ISetEmployeeRolesAction
    | ISetEmployeeNamesAction
    ;

export const employeeReducer = (state = initialEmployeeState, action: EmployeeAction): EmployeeState =>
{
    switch (action.type)
    {
        case EmployeeActionTypes.SET_EMPLOYEE:
            return {
                ...state,
                ...action.payload,
            };
        case EmployeeActionTypes.SET_EMPLOYEE_AVAILABILITY:
            return {
                ...state,
                availability: action.payload,
            };
        case EmployeeActionTypes.SET_EMPLOYEE_LANG:
            return {
                ...state,
                language: action.payload
            };
        case EmployeeActionTypes.SET_EMPLOYEE_DESCRIPTION:
            return {
                ...state,
                description: action.payload
            };
        case EmployeeActionTypes.SET_EMPLOYEE_CERTIFICATES:
            return {
                ...state,
                certificates: action.payload
            };
        case EmployeeActionTypes.SET_EMPLOYEE_SKILLS:
            return {
                ...state,
                skills: action.payload
            };
        case EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID:
            return {
                ...state,
                pictureId: action.payload
            }
        case EmployeeActionTypes.SET_EMPLOYEE_UNKNOWN_SKILL_COUNT:
            return {
                ...state,
                unknownSkillCount: action.payload
            }
        case EmployeeActionTypes.SET_EMPLOYEE_ROLES:
            return {
                ...state,
                roles: action.payload,
            }
        case EmployeeActionTypes.SET_EMPLOYEE_NAMES:
            return {
                ...state,
                firstName: action.payload.firstname,
                lastName: action.payload.lastname,
            }
        default:
            return state;
    }
};


// SET_EMPLOYEE
export const setEmployeeStore = (state: EmployeeState): ISetEmployeeAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE,
    payload: state,
});

// Action Creator for setting employee
export const setEmployee = (employeeState: EmployeeState): ISetEmployeeAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE,
    payload: employeeState,
});

// Action Creator for setting employee availability
export const setEmployeeAvailability = (availability: IAvailabilityDTO): ISetEmployeeAvailabilityAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_AVAILABILITY,
    payload: availability,
});

// Action Creator for setting employee language
export const setEmployeeLang = (language: ELanguage): ISetEmployeeLangAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_LANG,
    payload: language,
});

// Action Creator for setting employee description
export const setEmployeeDescription = (description: string): ISetEmployeeDescriptionAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_DESCRIPTION,
    payload: description,
});

// Action Creator for setting employee picture ID
export const setEmployeePictureId = (pictureId: string | null): ISetEmployeePictureIdAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID,
    payload: pictureId,
});

// Action Creator for setting employee certificates
export const setEmployeeCertificates = (certificates: ICertificateEmployeeDTO[]): ISetEmployeeCertificatesAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_CERTIFICATES,
    payload: certificates,
});

// Action Creator for setting employee skills
export const setEmployeeSkills = (skills: ISkillReferenceDTO[]): ISetEmployeeSkillsAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_SKILLS,
    payload: skills,
});

// Action Creator for setting unknown skill count
export const setEmployeeUnknownSkillCount = (count: number): ISetEmployeeUnknownSkillCount => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_UNKNOWN_SKILL_COUNT,
    payload: count,
});


// Action Creator for setting employee roles
export const setEmployeeRoles = (roles: IEmployeeRoleReference[]): ISetEmployeeRolesAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_ROLES,
    payload: roles,
});

export const setEmployeeNames = ({ firstname, lastname }: { firstname: string, lastname: string }): ISetEmployeeNamesAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_NAMES,
    payload: { firstname, lastname },
});