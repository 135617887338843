import { Action } from "redux";
import Product from "@src/Objects/Product";

const initialState: Product[] = [];

export enum EProductAction
{
    SET_PRODUCTS = "SET_PRODUCTS",
    DELETE_PRODUCT = "DELETE_PRODUCT",
    UPDATE_PRODUCT = "UPDATE_PRODUCT",
}

interface SetProductsAction extends Action
{
    type: EProductAction.SET_PRODUCTS;
    payload: Product[];
}

interface DeleteProductAction extends Action
{
    type: EProductAction.DELETE_PRODUCT;
    payload: number; // Annahme, dass ID eine Zahl ist
}

interface UpdateProductAction extends Action
{
    type: EProductAction.UPDATE_PRODUCT;
    payload: Product;
}

type ProductAction = SetProductsAction | DeleteProductAction | UpdateProductAction;

export const productReducer = (state = initialState, action: ProductAction): Product[] =>
{
    switch (action.type)
    {
        case EProductAction.SET_PRODUCTS:
            return action.payload;
        case EProductAction.DELETE_PRODUCT:
            return state.filter(product => product.id !== action.payload);
        case EProductAction.UPDATE_PRODUCT:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(product => product.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setProducts = (products: Product[]): SetProductsAction => ({
    type: EProductAction.SET_PRODUCTS,
    payload: products,
});

export const deleteProduct = (id: number): DeleteProductAction => ({
    type: EProductAction.DELETE_PRODUCT,
    payload: id,
});

export const updateProduct = (product: Product): UpdateProductAction => ({
    type: EProductAction.UPDATE_PRODUCT,
    payload: product,
});
