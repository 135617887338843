import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType } from '@sharedInterfaces/globalEnums';
import store from '@store/store';

import { CustomGraphQLError } from './graphQL';




export class NotFoundError extends Error implements CustomGraphQLError
{
    id: number;
    entityType?: EEntityType;
    statusCode: number;
    constructor(id: number, entityType?: EEntityType)
    {
        const lang = store.getState().employee.language;
        const message = getLanguageTexts(lang).entityPage.notFound;

        // const message = `Entity of type ${entityType} with ID ${id} not found.`;
        super(message);
        this.name = "NotFoundError"; // Setzt den Namen des Fehlers
        this.id = id;
        this.entityType = entityType;
        this.statusCode = 404;
        if (Error.captureStackTrace)
        {
            Error.captureStackTrace(this, NotFoundError);
        }
    }

}
