import { ELanguage } from "../languageHelper";

export interface ICountriesTexts
{
    de: string
    gb: string
    usa: string
    th: string
    au: string
    ca: string
    ie: string
    nz: string
    sg: string
    za: string
    at: string
    ch: string
    li: string
    lu: string
    be: string
}
type ILangData = {
    [lang in ELanguage]: ICountriesTexts
}
export const subNationLabels: ILangData = {
    'th': {
        "de": "รัฐ",
        "gb": "เขต",
        "usa": "รัฐ",
        "th": "จังหวัด",
        "au": "รัฐ",
        "ca": "จังหวัด",
        "ie": "เขต",
        "nz": "ภูมิภาค",
        "sg": "ภูมิภาค",
        "za": "จังหวัด",
        "at": "รัฐ",
        "ch": "แคนตอน",
        "li": "เทศบาล",
        "lu": "อำเภอ",
        "be": "ภูมิภาค"
    },
    'en': {
        "de": "State",
        "gb": "County",
        "usa": "State",
        "th": "Province",
        "au": "State",
        "ca": "Province",
        "ie": "County",
        "nz": "Region",
        "sg": "Region",
        "za": "Province",
        "at": "State",
        "ch": "Canton",
        "li": "Municipality",
        "lu": "District",
        "be": "Region"
    },
    'de': {
        "de": "Bundesland",
        "gb": "Grafschaft",
        "usa": "Bundesstaat",
        "th": "Provinz",
        "au": "Bundesstaat",
        "ca": "Provinz",
        "ie": "Grafschaft",
        "nz": "Region",
        "sg": "Region",
        "za": "Provinz",
        "at": "Bundesland",
        "ch": "Kanton",
        "li": "Gemeinde",
        "lu": "Distrikt",
        "be": "Region"
    },
}