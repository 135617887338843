import { Add, Delete, Done } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import { OU } from '@src/Objects/Company';

import EmployeeSelect from '../../formsControls/inputs/EmployeeSelect/EmployeeSelect';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';


interface AddOUDialogProps
{
    id: string
    employees: ICompanyUser[];
    parentOU: OU;
    resolve?: () => void;
    onSave: (val: OU[]) => Promise<unknown>;
}
/**
 * AddOUDialog component.
 *
 * @param {AddOUDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export function AddOUDialog(props: AddOUDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    const defaultOU = new OU({ id: -1, title: '', parentOU: props.parentOU.id, employees: [], leader: props.parentOU.leader.id }, props.employees);

    const [ous, setOUs] = React.useState([] as OU[]);
    const [saving, setSaving] = React.useState(false as boolean);
    const [newOU, setNewOU] = React.useState(defaultOU as OU);
    const [error, setError] = React.useState("");

    return <Dialog
        id={props.id}
        title={langStrings.createNewOrgUnder + ': ' + props.parentOU.title}
        footer={<div style={{ float: 'right' }}>
            <Button
                icon={<Done />}
                disabled={saving || !ous.length}
                text={langStrings.create}
                size={'normal'}
                onClick={async function (): Promise<void>
                {
                    setSaving(true);
                    await props.onSave(ous)
                        .then((ex) =>
                        {
                            return props.resolve && props.resolve();
                        })
                        .catch(ex =>
                        {
                            setError(ex.toString());
                            setTimeout(() =>
                            {
                                setSaving(false);
                            }, 3000);

                        });
                }} />
        </div>}
        onClose={function (): void
        {
            props.resolve && props.resolve();
        }}>
        <div className="addOUDialog">
            <h3>{langStrings.createOrgDescription}: {props.parentOU.title}</h3>

            <div className="unsavedOUs">
                {ous.map(ou =>
                {

                    return <div className="unsavedOU" key={ou.title}>
                        <div className="headLine">
                            <div className="titleNewOU">
                                <RowElement title={langStrings.orgName} alignTitle="left">
                                    <div className="ouName">{ou.title}</div>
                                </RowElement>
                            </div>
                            <div className="removeOU" onClick={() =>
                            {
                                setOUs(
                                    ous.filter(eo => eo.title !== ou.title)
                                );
                            }}><Delete /></div>
                        </div>
                        <RowElement title={langStrings.leader} alignTitle="left">
                            {ou.leader.title}
                        </RowElement>
                    </div>;
                })}
            </div>

            <div className="newOURow">
                <RowElement title={langStrings.orgName} alignTitle="left">
                    <input type="text" className="input ouName" name="ouName" onChange={(val) =>
                    {
                        const newOUTemp = newOU.generateDTO();
                        newOUTemp.title = val.currentTarget.value;
                        setNewOU(new OU(newOUTemp, props.employees));
                    }}
                        value={newOU.title} />
                </RowElement>
                <RowElement title={langStrings.leader} alignTitle="left">

                    <EmployeeSelect employees={props.employees}
                        selectedEmployee={newOU.leader.id}
                        setSelectedEmployee={(id) =>
                        {
                            if (!id) return;
                            const newOUTemp = newOU.generateDTO();
                            newOUTemp.leader = id;
                            setNewOU(new OU(newOUTemp, props.employees));
                        }} />

                </RowElement>
                <Button
                    size="verysmall"
                    disabled={false}
                    onClick={() =>
                    {
                        if (newOU.title === '')
                        {
                            setError(langStrings.pleaseEnterOUname);
                            return;
                        } else if (newOU.leader.id === -1)
                        {
                            setError(langStrings.pleaseEnterLeaderName);
                            return;
                        }

                        else
                        {
                            const newOUs = ous.slice();
                            newOUs.push(newOU);
                            setOUs(newOUs);
                            setNewOU(defaultOU);
                            if (error) setError("");
                        }
                    }}
                    icon={<Add />}
                    text={langStrings.create} />
            </div>
            {error}
        </div>
    </Dialog>;
}
