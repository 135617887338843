import React from 'react';

import styles from './Container.module.css';
/**
 * Container component.
 *
 * @param {object} children - The children props.
 * @param {React.ReactNode} children.children - React node children.
 * @returns {JSX.Element} The container element.
 */
export function Container({ style, children }: {
    style?: React.CSSProperties;
    children: React.ReactNode;
})
{
    return <div
        style={style}
        className={styles.container}
    >
        {children}
    </div>;
}
