import { OperationVariables, gql } from "@apollo/client";
import { IOrgUnitDetailDTO } from "@sharedInterfaces/IOrgUnit";
import { ELinks } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { setOrgUnits } from "@store/reducer/orgUnitReducer";

import GraphQL from "../graphQL";

import { ORG_UNIT_FRAGMENT } from "./getOrgUnit";

const GET_ORG_UNITS_QUERY = gql`
query {
    ous {
        ...OrgUnitFields
    }
}
${ORG_UNIT_FRAGMENT}
`;

/**
 * Retrieves the organization units.
 * 
 * @param {Function} callback - The callback function to be called with the organization units.
 * @param {Promise} - A promise that resolves to an object containing the organization units.
 */
export default async function getOrgUnits()
{
    const variables: OperationVariables = {};
    return GraphQL.query<{ ous: IOrgUnitDetailDTO[] }>(GET_ORG_UNITS_QUERY, variables, (obj) =>
    {
        if (!obj) return;
        obj.ous.forEach(ou => ou.link = `${ELinks.PEOPLE_ORGUNITS}/${ou.id}`);

        store.dispatch(setOrgUnits(obj.ous))

        return obj.ous;
    });
}
