import React, { useRef } from 'react';
import './LinkInput.css';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

interface LinkInputProps
{
    value: string
    placeholder?: string
    disabled?: boolean
    size?: 'medium' | 'small' | 'verysmall'
    onChange?: (val: string) => void
}

/**
 * Component for rendering a link input field.
 *
 * @param {LinkInputProps} value - The value of the input field.
 * @param {LinkInputProps} password - Indicate if the input field is for password.
 * @param {LinkInputProps} placeholder - The placeholder text for the input field.
 * @param {LinkInputProps} disabled - Indicate if the input field is disabled.
 * @param {LinkInputProps} size - The size of the input field.
 * @param {LinkInputProps} onChange - The callback function for handling input changes.
 * 
 * @returns {JSX.Element} The rendered link input field.
 */
function LinkInput({ value, placeholder, disabled, size, onChange, }: LinkInputProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;

    const innerSize = size ? size : 'medium';
    const inputSize = innerSize === 'medium' || innerSize === 'small' ? innerSize : 'small';
    const inputRef = useRef<HTMLTextAreaElement>(null);

    React.useEffect(() =>
    {
        if (!inputRef.current) return
        inputRef.current.style.height = inputRef.current.scrollHeight + "px";

    }, [inputRef]);

    const inValid = !new RegExp("((https?://.+)|(^$))", "i").test(value);
    return (
        <div className='linkInputContainer'>
            {onChange ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'stretch',
                    width: '100%'
                }}>
                    <TextField variant='outlined'
                        disabled={disabled}
                        size={inputSize}
                        margin='none'
                        color='primary'
                        value={value}
                        placeholder={placeholder}
                        multiline={true}
                        // style={{
                        //     margin: 0
                        // }}
                        fullWidth
                        inputProps={{
                            pattern: "((https?://.+)|(^$))"
                        }}
                        error={inValid}
                        helperText={inValid ? langStrings.invaildURL : ""}
                        InputProps={
                            {
                                style: { margin: 0, padding: innerSize === 'verysmall' ? '0px 5px' : undefined }
                            }
                        }
                        onChange={(event) =>
                        {
                            onChange && onChange(event.target.value)
                        }}
                    />
                    <div style={{
                        marginLeft: 5,
                        fontSize: 15,
                    }}>
                        {(!inValid && value) &&
                            <a href={value} target='_blank' rel="noreferrer">
                                {langStrings.checkLink}
                            </a>
                        }
                    </div>
                </div> :
                <div>
                    {value}
                </div>
            }
        </div>
    );
}

export default LinkInput;
