import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IEmployeeCoverage } from '@sharedInterfaces/IOpportunity';
import { AppState } from '@store/store';

import CardBox from '../CardBox/CardBox';
import './SuggestedEmployeesBox.css';
import { EmployeeSuggestions } from '../Opportunities/EmployeeSuggestions/EmployeeSuggestions';
import List from '../../App/NewLayout/Lists/List/List';

interface SuggestedEmployeesBoxProps
{
    title?: string;
    suggestedEmployees: IEmployeeCoverage[];
}


/**
 * Renders a component that displays suggested employees in a box.
 * 
 * @param {SuggestedEmployeesBoxProps} props - The properties for the SuggestedEmployeesBox component.
 * @param {string} props.title - The title for the suggested employees box.
 * @param {Array<Employee>} props.suggestedEmployees - The array of suggested employees.
 * 
 * @returns {JSX.Element} The rendered SuggestedEmployeesBox component.
 */
function SuggestedEmployeesBox({ title, suggestedEmployees, }: SuggestedEmployeesBoxProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).opportunities;

    const newTitle = title ? title : langStrings.suggestedEmployee;

    const innerSuggestedEmployees = suggestedEmployees.slice();
    innerSuggestedEmployees.sort((a, b) =>
    {
        const diff = b.score - a.score;
        if (diff !== 0) return diff;
        return a.title.localeCompare(b.title);
    })

    return (
        <CardBox title={newTitle}>
            <List>
                <EmployeeSuggestions
                    id="selected"
                    flexDirection='column'
                    suggestedEmployees={innerSuggestedEmployees}
                />
            </List>
        </CardBox>
    );
}
export default SuggestedEmployeesBox;