import { AppState } from '@store/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Box, List } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { ELinks } from '@sharedInterfaces/globalEnums';
import './Navigation.css';
import { useLocation } from 'react-router-dom';

import { NavigationItem } from './NavigationItem';


interface NavigationProps
{
  // children: React.ReactNode;
  loggedIn: boolean;
  isOpen: boolean;
  fixedNav: boolean;
  setNavIsOpen: (open: boolean) => void
}

/**
 * Navigation component.
 *
 * @param {NavigationProps} props - The props for the navigation component.
 * @returns {JSX.Element} - The rendered navigation component.
 */
function Navigation(props: NavigationProps)
{
  const lang = useSelector((state: AppState) => state.employee.language);
  const langStrings = getLanguageTexts(lang).navigation;
  const permissions = useSelector((state: AppState) => state.permissions);

  const skillPermissions = checkPermissions('Skills', 'Retrieve', permissions) || checkPermissions('Skills', 'RetrieveOwn', permissions)
  const skillCategoryPermissions = checkPermissions('SkillCategories', 'Retrieve', permissions) || checkPermissions('SkillCategories', 'RetrieveOwn', permissions)
  const certificatesPermissions = checkPermissions('Certificates', 'Retrieve', permissions) || checkPermissions('Certificates', 'RetrieveOwn', permissions)
  const competencePermissions = checkPermissions('Competencies', 'Retrieve', permissions) || checkPermissions('Competencies', 'RetrieveOwn', permissions)
  const productPermissions = checkPermissions('Products', 'Retrieve', permissions) || checkPermissions('Products', 'RetrieveOwn', permissions)
  const opportunityPermissions = checkPermissions('Opportunities', 'Retrieve', permissions) || checkPermissions('Opportunities', 'RetrieveOwn', permissions)
  const companyPermissions = checkPermissions('Companies', 'Retrieve', permissions) || checkPermissions('Companies', 'RetrieveOwn', permissions)
  const projectPermissions = checkPermissions('Projects', 'Retrieve', permissions) || checkPermissions('Projects', 'RetrieveOwn', permissions)
  const rolesPermissions = checkPermissions('Roles', 'Retrieve', permissions) || checkPermissions('Roles', 'RetrieveOwn', permissions)

  // useEffect(() =>
  // {
  //   document.querySelector('.contentOuter')?.scroll(0, 0) //TODO: Überall anders einbauen?
  // }, [location]);

  const onClick = async () =>
  {
    if (props.fixedNav)
      props.setNavIsOpen(false);
  }
  if (!props.loggedIn) return (<></>);
  return (
    <div className={'navigation' + (props.isOpen ? ' navIsOpen' : ' navIsClosed')}>
      <Drawer
        variant={props.fixedNav ? 'temporary' : 'persistent'}
        open={props.isOpen}
        container={document.querySelector('.navigation')}
        transitionDuration={{
          appear: 1000,
          enter: 500,
          exit: 0
        }}
        // hideBackdrop={true}
        onClose={() => props.setNavIsOpen(false)}
        sx={{
          width: 'var(--nav-size)',
          top: props.fixedNav ? 'var(--var-header-height)' : 0,
          flexShrink: 0,
          height: '100%',
          [`& .MuiDrawer-paper`]: {
            position: 'relative',
            width: 'var(--nav-size)',

          },
        }}
      // onClose={() => props.setNavIsOpen(false)}
      // onOpen={() => props.setNavIsOpen(true)}
      >
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <NavigationItem onClick={onClick} path={`/${ELinks.PROFILE_SHOW}`} shortPath={`/${ELinks.PROFILE}`} title={langStrings.myProfile}
              fixedNav={props.fixedNav}
            >
              <NavigationItem onClick={onClick} path={`/${ELinks.PROFILE_SHOW}`} title={langStrings.show} >
              </NavigationItem>
              <NavigationItem onClick={onClick} path={`/${ELinks.PROFILE_EDIT}`} title={langStrings.edit} >
              </NavigationItem>
            </NavigationItem>
            {rolesPermissions &&
              <NavigationItem onClick={onClick} path={`/${ELinks.ROLES}`} title={langStrings.roles}>
              </NavigationItem>
            }
            {skillPermissions &&
              <NavigationItem onClick={onClick} path={`/${ELinks.SKILLS}`} title={langStrings.skills}>
              </NavigationItem>
            }
            {skillCategoryPermissions &&
              <NavigationItem onClick={onClick} path={`/${ELinks.SKILLCATEGORIES}`} title={langStrings.skillCategories}>
              </NavigationItem>
            }
            {certificatesPermissions &&
              <NavigationItem onClick={onClick} path={`/${ELinks.CERTIFICATES}`} title={langStrings.certificates}>
              </NavigationItem>
            }
            {competencePermissions &&
              <NavigationItem onClick={onClick} path={`/${ELinks.COMPETENCE}`} title={langStrings.competences}>
              </NavigationItem>
            }

            {/* <NavgationItem onClick={onClick} path="/solutions" title={langStrings.solutions}>
     </NavgationItem> */}
            <NavigationItem onClick={onClick} path={`/${ELinks.PEOPLE}`} title={langStrings.employeeAndOEs} fixedNav={props.fixedNav}>
              <NavigationItem onClick={onClick} path={`/${ELinks.PEOPLE_EMPLOYEES}`} title={langStrings.employees}>
              </NavigationItem>
              <NavigationItem onClick={onClick} path={`/${ELinks.PEOPLE_ORGUNITS}`} title={langStrings.orgUnits}>
              </NavigationItem>
            </NavigationItem>
            {(productPermissions || opportunityPermissions || companyPermissions || projectPermissions) &&
              <NavigationItem onClick={onClick} path={`/${ELinks.SALES}`} title={langStrings.sales} fixedNav={props.fixedNav}>
                {productPermissions &&
                  <NavigationItem onClick={onClick} path={`/${ELinks.SALES_PRODUCTS}`} title={langStrings.products}>
                  </NavigationItem>
                }
                {opportunityPermissions &&
                  <NavigationItem onClick={onClick} path={`/${ELinks.SALES_OPPORTUNITIES}`} title={langStrings.opportunities} >
                  </NavigationItem>
                }
                {companyPermissions &&
                  <NavigationItem onClick={onClick} path={`/${ELinks.SALES_CUSTOMERS}`} title={langStrings.businesses} >
                  </NavigationItem>
                }
                {projectPermissions &&
                  <NavigationItem onClick={onClick} path={`/${ELinks.SALES_PROJECTS}`} title={langStrings.projects} >
                  </NavigationItem>
                }
              </NavigationItem>
            }
            {/* <NavgationItem onClick={onClick} path="/settings" title={langStrings.settings}>
     </NavgationItem> */}
          </List>
        </Box>
      </Drawer>
    </div >
  )
}
export default Navigation;
