import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getOrgUnit from '@src/APIs/graphQl/OrgUnit/getOrgUnit';
import OrgUnitPage from '@src/App/NewLayout/Pages/OrgUnits/OrgUnitPage/OrgUnitPage';

import Overlay from '../Overlay/Overlay';
import './OrgUnitOverlay.css';

interface OrgUnitOverlayProps
{
    id: number;
    children: React.ReactNode;
    disabled?: boolean;
}

function OrgUnitOverlay(props: OrgUnitOverlayProps)
{
    const orgUnit = useSelector((state: AppState) => state.orgUnits.find(ou => ou.id === props.id));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('OrgUnits', 'Retrieve', permissions) || checkPermissions('OrgUnits', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={orgUnit !== null}
            onFirstOpen={() =>
            {
                getOrgUnit(props.id);
            }}>
            {orgUnit && <OrgUnitPage entity={orgUnit} inDialog={true} />}
        </Overlay>
    );
}

export default OrgUnitOverlay;
