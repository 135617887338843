import { OperationVariables, gql } from "@apollo/client";
import IBusinessDTO from "@sharedInterfaces/IBusiness";
import { EEntityType } from "@sharedInterfaces/globalEnums";
import store from "@store/store";
import { deleteBusiness, updateBusiness } from "@store/reducer/businessReducer";
import Business, { businessGQL } from "@src/Objects/Business";

import GraphQL from "../graphQL";
import { NotFoundError } from "../NotFoundError";

const q = gql`
query GetBusiness($businessId: ID!) {
    business(id: $businessId) 
    ${businessGQL}
},
`;

/**
 * Retrieves a business by its ID and updates the Redux store.
 *
 * @param {number} businessId - The ID of the business to retrieve.
 * @returns {Promise<Business>} A promise that resolves with the retrieved business.
 */
export default async function getBusiness(businessId: number)
{
    const variables: OperationVariables = { businessId };
    return GraphQL.query<{ business: IBusinessDTO }>(q, variables).then((obj) =>
    {
        if (!obj?.business)
        {
            store.dispatch(deleteBusiness(businessId));
            throw new NotFoundError(businessId, EEntityType.BUSINESS);
        }
        const business = new Business(obj.business);
        store.dispatch(updateBusiness(business));
        return business;
    });
}
