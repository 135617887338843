import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IEmailTexts extends ITextBase
{
    receiveEmails: string;
    emailSettingsInstructions: string;
    emailSettingsDescription: string;
    skillSuggestion: string;
    updates: string;
    newsletter: string;
    emailSettingsTitle: string;
    retry: string;
    error: string;
    noEmail: string;
    youHaveBeenUnsubscribed: string;
    resubscribeButton: string;
    unsubscribeButton: string;
    emailDisplay: string;
    title: string;
    youAreBeingUnsubscribed: string;
    youWillBeResubscribed: string;
    youHaveBeenResubscribed: string;
    beingCanceled: string;
    beingResubscribed: string;
    newClient: string
    forecast: string
}
type ILangData = {
    [lang in ELanguage]: IEmailTexts
}
export const emailTexts: ILangData = {
            'th': {
                forecast: 'การแจ้งเตือนพยากรณ์อากาศเป็นประจำ',
        newClient: 'ลงชื่อเข้าใช้ใหม่',
                emailSettingsInstructions: 'ตั้งค่าที่นี่ว่าคุณต้องการรับอีเมลโดยทั่วไปหรือไม่ และเลือกประเภทอีเมลที่คุณสนใจ',
                emailSettingsDescription: 'การตั้งค่าการแจ้งเตือนทางอีเมล',
                receiveEmails: 'รับอีเมล',
                skillSuggestion: 'ข้อเสนอแนะทักษะ',
                updates: 'คุณสมบัติใหม่และการอัพเดท',
                newsletter: 'จดหมายข่าว',
                emailSettingsTitle: 'ตั้งค่าอีเมล',
                emailDisplay: 'ที่อยู่อีเมล:',
                error: 'เกิดข้อผิดพลาด',
                retry: 'ลองใหม่อีกครั้ง...',
                noEmail: 'ไม่มีการระบุที่อยู่อีเมล',
                beingResubscribed: 'กำลังดำเนินการสมัครใหม่...',
                beingCanceled: 'กำลังยกเลิก...',
                unsubscribeButton: 'ยกเลิกการสมัครสมาชิก',
                youHaveBeenResubscribed: 'คุณได้รับการสมัครรับอีเมลแจ้งเตือนอีกครั้งแล้ว.',
                youWillBeResubscribed: 'คุณจะถูกสมัครรับอีเมลแจ้งเตือนอีกครั้ง...',
                youAreBeingUnsubscribed: 'คุณกำลังถูกยกเลิกการสมัครรับอีเมลแจ้งเตือนที่ไม่สำคัญ...',
                resubscribeButton: 'กู้คืนการสมัครรับข้อมูล',
                youHaveBeenUnsubscribed: 'คุณได้ยกเลิกการรับอีเมลแจ้งเตือนที่ไม่สำคัญเรียบร้อยแล้ว',
                title: 'ยกเลิกการลงทะเบียน',
                oftenUsed: oftenUsedTranslations['th'],
            },
            'en': {
                forecast: 'Regular Forecast Reminder',
        newClient: 'Login on a new device',
                emailSettingsInstructions: 'Set your preferences here if you generally want to receive emails and select which types of emails are of interest to you.',
                emailSettingsDescription: 'Email notification settings',
                receiveEmails: 'Receive emails',
                skillSuggestion: 'Skill Suggestions',
                updates: 'New Features and Updates',
                newsletter: 'Newsletter',
                emailSettingsTitle: 'Email Settings',
                error: 'An error has occurred.',
                retry: 'Try again...',
                noEmail: 'No email address was provided.',
                beingResubscribed: 'Being resubscribed...',
                beingCanceled: 'Being canceled...',
                unsubscribeButton: 'Unsubscribe',
                youHaveBeenResubscribed: 'You have been resubscribed to the email notifications.',
                youWillBeResubscribed: 'You will be resubscribed to the email notifications...',
                youAreBeingUnsubscribed: 'You are being unsubscribed from all non-critical email notifications...',
                resubscribeButton: 'Restore Subscription',
                emailDisplay: 'Email Address:',
                youHaveBeenUnsubscribed: 'You have successfully unsubscribed from all non-critical email notifications.',
                title: 'Unsubscribe',
                oftenUsed: oftenUsedTranslations['en'],
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                title: "Abmeldung",
                emailDisplay: "E-Mail-Adresse: ",
                resubscribeButton: "Abonnement wiederherstellen",
                unsubscribeButton: "Abonnement kündigen",
                youAreBeingUnsubscribed: "Du wirst von allen nicht kritischen E-Mail-Benachrichtigungen abgemeldet...",
                youWillBeResubscribed: "Du wirst wieder für die E-Mail-Benachrichtigungen angemeldet...",
                youHaveBeenResubscribed: "Du wurdest wieder für die E-Mail-Benachrichtigungen angemeldet.",
                youHaveBeenUnsubscribed: "Du hast dich erfolgreich von allen nicht kritischen E-Mail-Benachrichtigungen abgemeldet.",
                beingCanceled: "Wird gekündigt...",
                beingResubscribed: "Wird wiederhergestellt...",
                noEmail: "Es wurde keine E-Mail-Adresse übergeben.",
                retry: 'Erneut versuchen...',
                error: 'Es ist ein Fehler aufgetreten.',
                emailSettingsTitle: "E-Mail Einstellungen",
                newsletter: "Newsletter",
                updates: "Neue Features und Updates",
                skillSuggestion: "Fähigkeitsvorschläge",
                receiveEmails: "E-Mails erhalten",
                emailSettingsDescription: "E-Mail Benachrichtigungseinstellungen",
                emailSettingsInstructions: "Stell hier ein, ob du generell E-Mails erhalten möchtest und wähle aus, welche Arten von E-Mails für dich interessant sind.",
                newClient: 'Anmeldung an einem neuen Gerät',
                forecast: 'Regelmäßige Forecast Erinnerung',
            },
        }