import { IReference } from "@sharedInterfaces/IReference";
import ISkillDetailDTO, { IEmployeeListDetailDTO, IProductSkillReferenceDTO, ISkillRole } from "@sharedInterfaces/ISkill";
import { EEntityType, ESkillType, } from "@sharedInterfaces/globalEnums";

import { Entity, entityFieldsGQL } from "../sharedReact/Objects/Entity";

export const skillDetailGQL = `{
            id
            title
            description
            version
            categoryId
            employees {
              id
              title
              level
            }
            products {
                id
                title
                level
            }
            roles{
                id
                title
            }
            otherNames
            skillType
            productInfo {
                productName
                manufacturer
                link
            }
            acquisition
            acquisitionLink
            ${entityFieldsGQL}
          }`
export default class SkillDetail extends Entity implements ISkillDetailDTO
{
    type: EEntityType = EEntityType.SKILL;
    description: string;
    categoryId: number
    employees: IEmployeeListDetailDTO[];
    products: IProductSkillReferenceDTO[];
    otherNames: string[];
    opportunities: IReference[];
    roles: ISkillRole[];
    skillType?: ESkillType | undefined;
    productInfo?: { productName: string; manufacturer: string; link: string; } | undefined;
    acquisition: string;
    acquisitionLink?: string | undefined;
    constructor(obj: ISkillDetailDTO)
    {
        super(obj);
        this.description = obj.description;
        this.categoryId = obj.categoryId;
        this.employees = obj.employees;
        this.products = obj.products;
        this.otherNames = obj.otherNames;
        this.opportunities = obj.opportunities;
        this.skillType = obj.skillType;
        this.productInfo = obj.productInfo;
        this.acquisition = obj.acquisition;
        this.acquisitionLink = obj.acquisitionLink;
        this.roles = obj.roles;
    }

    get minLevel(): number
    {
        if (this.employees.length === 0) return 0;
        return Math.min(...this.employees.map((employee) => employee.level));
    }

    get maxLevel(): number
    {
        if (this.employees.length === 0) return 0;
        return Math.max(...this.employees.map((employee) => employee.level));
    }

    get averageLevel(): number
    {
        if (this.employees.length === 0) return 0;
        const sum = this.employees.reduce((acc, employee) => acc + employee.level, 0);
        return (sum / this.employees.length);
    }
    async delete()
    {
        const { default: deleteSkill } = await import("@src/APIs/graphQl/Skill/deleteSkill");
        return deleteSkill(this.id);
    }
}