import { Done } from '@mui/icons-material';
import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EAvailabilityInputAs, EAvailabilitySelectionType, IAvailabilityWeekDTO, IDayDTO } from '@sharedInterfaces/IAvailabilityMonth';
import { IEmployeeProjectReferenceDTO } from '@sharedInterfaces/IEmployee';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { setHiddenProjects, setLastForecastDate } from '@store/reducer/employeeSettingsReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';
import { getMonthLocalName } from '@src/helper/helper';

import ErrorBox from '../../ErrorBox/ErrorBox';

import './AvailabilityMonthDialog.css';
import { WeeksContainer } from './WeeksContainer';
import { clearPlaning } from './clearPlaning';
import { onChangeInputType } from './onChangeInputType';
import { WeekContainer } from './WeekContainer';
import { HideShowProjectsDialog } from './HideShowProjectsDialog';
import { MonthContainer } from './MonthContainer';

export const FACTOR_HIGH = 1.1;
export const FACTOR_VERYHIGH = 1.25;
export const FACTOR_MUCHTOHIGH = 1.5;

export interface IState
{
    inputAs: EAvailabilityInputAs;
    selectionType: EAvailabilitySelectionType,
    months: {
        id: string,
        title: string,
        workDayCount: number,
        inputAs: EAvailabilityInputAs,
        weekArray: IAvailabilityWeekDTO[]
    }[]
}
interface AvailabilityMonthDialogProps
{
    id: string
    availabilityMonth: AvailabilityMonth
    onCreate?: (availabilitymonth: AvailabilityMonth) => void
    onEdit?: (weekArray: IAvailabilityWeekDTO[],
        selectionType: EAvailabilitySelectionType,
        inputAs: EAvailabilityInputAs) => void
    resolve?: () => void
}

/**
 * AvailabilityMonthDialog component.
 *
 * @param props - The properties for the AvailabilityMonthDialog component.
 * @returns The rendered AvailabilityMonthDialog component.
 */
function AvailabilityMonthDialog(props: AvailabilityMonthDialogProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const dispatch = useDispatch();
    const availabilityMonth = props.availabilityMonth;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const hiddenProjects = useSelector((state: AppState) => state.employeeSettings.hiddenProjects);
    const offline = useSelector((state: AppState) => state.client.offline);
    const empProjects = useSelector((state: AppState) => state.employee.projects)
        .filter(p => p.active);

    // const dispatch = useDispatch();
    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);
    const [state, setState] = React.useState({
        selectionType: availabilityMonth.selectionType,
        inputAs: availabilityMonth.inputAs,
        months: [
            {
                id: availabilityMonth.id,
                title: availabilityMonth.LocalTitle,
                workDayCount: availabilityMonth.workDayCount,
                inputAs: availabilityMonth.inputAs,
                weekArray: [],
            }
        ]
    } as IState);
    const [loaded, setLoaded] = React.useState(false as boolean);
    const [loadedExtension, setLoadedExtension] = React.useState(false as boolean);
    const [loadMoreMonth, setLoadMoreMonth] = React.useState(false as boolean);


    const clearPlaningAndClone = useCallback((data: IAvailabilityWeekDTO[]) => data.map(w => ({
        ...w,
        days: w.days.map(d => ({
            ...d,
            planings: clearPlaning(d.planings, hiddenProjects, empProjects),
        })),
    })), [hiddenProjects]);

    React.useEffect(() => //Load Normal Week Array
    {
        if (!loaded && !state.months[0].weekArray.length)
        {
            setLoaded(true)
            availabilityMonth.getWeekArray().then(
                (weekArray) =>
                {
                    const newState = {
                        ...state,
                    };
                    newState.months[0] = {
                        ...newState.months[0],
                        weekArray: clearPlaningAndClone(weekArray)
                    }
                    setState(newState);
                });
        }
    }, [availabilityMonth, clearPlaningAndClone, hiddenProjects, loaded, state]);

    React.useEffect(() => // LOad Extension Months
    {
        if (loadMoreMonth && !loadedExtension && state.months.length === 1 && state.selectionType === EAvailabilitySelectionType.month)
        {
            setLoadedExtension(true);
            availabilityMonth.getMoreMonths().then(
                (moreMonths) =>
                {
                    const newState = {
                        ...state,
                    };
                    moreMonths.forEach(month =>
                    {
                        const factor = state.inputAs === month.inputAs ? 1 :
                            (state.inputAs === EAvailabilityInputAs.days) ? 1 / 8 : 8
                        if (factor !== 1)
                            month.weekArray.forEach(week => week.days.forEach(day =>
                                day.planings.forEach(p => p.value *= factor)
                            ))
                        const firstDay = month.weekArray.length && month.weekArray[0].days.length ?
                            month.weekArray[0].days[0].date :
                            new Date();
                        newState.months.push({
                            id: month.id,
                            title: getMonthLocalName(firstDay),
                            workDayCount: month.workDayCount,
                            inputAs: month.inputAs,
                            weekArray: clearPlaningAndClone(month.weekArray),
                        })
                    });
                    newState.months.sort((a, b) => a.id.localeCompare(b.id))
                    setState(newState);
                });
        }
    }, [availabilityMonth, clearPlaningAndClone, hiddenProjects, loadMoreMonth, loadedExtension, state]);

    React.useEffect(() => //Remove hidden Projects
    {
        state.months.forEach((month, index) =>
        {
            const clearedWeekArray = clearPlaningAndClone(month.weekArray);
            if (
                JSON.stringify(clearedWeekArray) !== JSON.stringify(month.weekArray))
            {
                const newState = { ...state };
                newState.months[index] = {
                    ...newState.months[index],
                    weekArray: clearedWeekArray,
                }
                setState(newState);
            }
        })


        // Überprüfen, ob eine Änderung erforderlich ist

    }, [clearPlaningAndClone, hiddenProjects, state]); // Entferne `state` aus den Abhängigkeiten


    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = () =>
    {
        dispatch(setLastForecastDate(new Date()))

        if (props.onEdit !== undefined)
        {
            const month = state.months.find(m => m.id === props.availabilityMonth.id);

            if (month) props.onEdit(month.weekArray, state.selectionType, state.inputAs);
            dispatch(setHiddenProjects(hiddenProjects));
        }
        props.resolve && props.resolve()
        setSaving(false);
    }

    const onSave = async () =>
    {
        const month = state.months.find(m => m.id === props.availabilityMonth.id);
        if (!state.months.length || !month) return;
        setSaving(true);
        if (loadMoreMonth)
        {
            await availabilityMonth.editMultiple({
                inputAs: state.inputAs,
                type: state.selectionType,
                months: state.months,
            })
                .then(onFetch).catch(onFetchError)
        }
        else
        {
            await availabilityMonth.edit({
                inputAs: state.inputAs,
                id: availabilityMonth.id,
                type: state.selectionType,
                weekArray: month.weekArray,
            })
                .then(onFetch).catch(onFetchError)
        }
    };

    const onClickSettings = async () =>
    {
        openDialog(
            <HideShowProjectsDialog
                id="HideShowProjectsDialog"
            />
        );
    }


    //key={Math.random() * 1000}
    return (
        <Dialog
            id={props.id}
            title={langStrings.dialogName + (!loadMoreMonth ? availabilityMonth?.LocalTitle : state.months.length > 1 ? `${state.months[0].title} - ${state.months[state.months.length - 1].title}` : '')} onClose={function (): void
            {
                props.resolve && props.resolve()
            }}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving || offline}
                        text={!availabilityMonth ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            {
                offline ?
                    <div className='offline'>
                        {langStrings.oftenUsed.offline}
                    </div>
                    :
                    <div className="availabilityMonthDialog">
                        {state.months[0].weekArray.length === 0 ?
                            <LoadingBox /> :
                            <div>
                                <div className='helpers'>
                                    <div>

                                        {langStrings.inputAsHelper}
                                        <RadioGroup value={state.inputAs} onChange={(event) =>
                                        {
                                            const type = event.target.value as EAvailabilityInputAs;
                                            setState(onChangeInputType(type, state))
                                        }}
                                        >
                                            <FormControlLabel value="days" control={<Radio size='small' />} label={langStrings.days} />
                                            <FormControlLabel value="hours" control={<Radio size='small' />} label={langStrings.hours} />
                                        </RadioGroup>
                                    </div>
                                    <div>
                                        {langStrings.typeHelper}
                                        <RadioGroup value={state.selectionType} onChange={(event) =>
                                        {
                                            const type = event.target.value as EAvailabilitySelectionType;
                                            if (type !== EAvailabilitySelectionType.month && loadMoreMonth)
                                            {
                                                setLoadMoreMonth(false);
                                                setLoadedExtension(false);
                                            }
                                            setState({
                                                ...state, selectionType: type,
                                                months: loadMoreMonth ? state.months : [state.months[0]]
                                            })
                                        }}
                                        >
                                            <FormControlLabel value="month" control={<Radio size='small' />} label={langStrings.month} />
                                            <FormControlLabel value="week" control={<Radio size='small' />} label={langStrings.week} />
                                            <FormControlLabel value="day" control={<Radio size='small' />} label={langStrings.day} />
                                        </RadioGroup>
                                    </div>
                                </div>
                                {
                                    empProjects.length > 0 &&
                                    <>
                                        {state.months[0].weekArray.length &&
                                            <div>
                                                {state.selectionType === EAvailabilitySelectionType.month &&
                                                    <MonthContainer state={state}
                                                        setState={setState}
                                                        availabilityMonth={availabilityMonth}
                                                        setLoadMoreMonth={setLoadMoreMonth}
                                                        loadMoreMonth={loadMoreMonth}
                                                        renderProjectTD={renderProjectTD}
                                                        onClickSettings={onClickSettings} />
                                                }
                                                {state.selectionType === EAvailabilitySelectionType.week &&
                                                    <WeeksContainer state={state} setState={setState}
                                                        availabilityMonth={availabilityMonth} onClickSettings={onClickSettings}
                                                        renderProjectTD={renderProjectTD}
                                                    />
                                                }
                                                {state.selectionType === EAvailabilitySelectionType.day &&
                                                    <WeekContainer state={state} setState={setState} availabilityMonth={availabilityMonth}
                                                        onClickSettings={onClickSettings}
                                                        renderProjectTD={renderProjectTD}
                                                    />
                                                }
                                            </div>
                                        }

                                    </>
                                }
                                {empProjects.length === 0 && <ErrorBox close={() => { }}>
                                    {langStrings.noProjects}
                                </ErrorBox>}
                                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
                            </div>
                        }
                    </div>
            }
        </Dialog>
    );
}

/**
 * Renders a TD element for a project.
 *
 * @param {IEmployeeProjectReferenceDTO} project - The project to be rendered.
 * @returns {JSX.Element} - The rendered TD element.
 */
function renderProjectTD(project: IEmployeeProjectReferenceDTO)
{
    return (
        <td>
            <div className='projectColumn'>
                {project.business.title !== "" &&
                    < div className='business'>
                        {project.business.title}
                    </div>
                }
                <div> {project.title}</div>
            </div>
        </td>
    )
}

interface AvailabilityTextFieldDaysProps
{
    disabled?: boolean
    max: number
    value: number
    setValue: (value: number) => void
}
/**
 * AvailabilityTextFieldDays function component.
 *
 * @param {AvailabilityTextFieldDaysProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export function AvailabilityTextFieldDays(props: AvailabilityTextFieldDaysProps)
{
    const [text, setText] = React.useState(props.value.toString() as string);
    React.useEffect(() =>
    {
        setText(props.value.toString());
    }, [props.value]);
    return (
        <div className='numberBox'>
            <TextField variant='standard'
                size='small' margin='none'
                disabled={props.disabled}
                type='number'
                value={text}
                onChange={(event) =>
                {
                    setText(event.target.value);
                    try
                    {
                        const parsed = Number.parseFloat(event.target.value);
                        if (parsed >= 0 && parsed < props.max)
                        {
                            props.setValue(parsed);
                            setText(parsed.toString());
                        }
                    } catch (error)
                    { /* empty */ }
                }}
                onBlur={(event) =>
                {
                    let parsed = Number.parseFloat(event.target.value);
                    if (isNaN(parsed)) parsed = 0;
                    if (parsed < 0) parsed = 0;
                    if (parsed > props.max) parsed = props.max;
                    props.setValue(parsed);
                    setText(parsed.toString());
                }}
                InputProps={{
                    inputProps: {
                        max: props.max, min: 0
                    }
                }} />
        </div>
    )
}

export default AvailabilityMonthDialog;

/**
 * Distributes work on different days in a week.
 * 
 * @param {IAvailabilityWeekDTO} newWeek - The new week with availability data.
 * @param {number} workPerDay - The amount of work to be distributed per day.
 * @param {IEmployeeProjectReferenceDTO} project - The project information for the employee.
 * 
 * @returns {void}
 */
export function distributeWorkOnDays(newWeek: IAvailabilityWeekDTO, workPerDay: number, project: IEmployeeProjectReferenceDTO)
{
    newWeek.days.forEach(d =>
    {
        let workOnThisDay = workPerDay;
        const fullAbsenceTime = (d.absence ? d.absence : 0) + (d.holiday ? d.holiday : 0);

        if (!d.isWorkday)
        {
            workOnThisDay = 0;
        }
        else if (fullAbsenceTime >= 1)
        {
            workOnThisDay = 0;
        }

        else
        {
            workOnThisDay = workPerDay * (1 - fullAbsenceTime);
        }
        const plan = d.planings.find(p => parseInt(p.activityId) === project.id);
        if (plan)
        {
            plan.value = workOnThisDay;
        }
        else
        {
            d.planings.push({ activityId: project.id.toString(), value: workOnThisDay });
        }
    });
}

/**
 * Returns the number of work days for the given day.
 * 
 * @param {IDayDTO} day - The day for which to get the work day count.
 * @returns {number} The number of work days for the given day.
 */
export function getWorkDayCountForDay(day: IDayDTO)
{
    if (!day.isWorkday) return 0
    if (!day.absence && !day.holiday) return 1;
    return 1 - day.absence - day.holiday;
}