import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company, { OU } from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation createOUs($newOUs: [NewOU!]!) {
    createOUs(newOUs: $newOUs) 
    ${companySettingsGql}
},
`;

export default async function createOUs(newOUs: OU[])
{
    const variables: OperationVariables = {
        newOUs: newOUs.map(o => o.generateDTO()),
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}