import { Action } from "redux";
import Role from "@src/Objects/Role";

const initialState: Role[] = [];

export enum ERoleAction
{
    SET_ROLES = "SET_ROLES",
    DELETE_ROLE = "DELETE_ROLE",
    UPDATE_ROLE = "UPDATE_ROLE",
}

interface SetRolesAction extends Action
{
    type: ERoleAction.SET_ROLES;
    payload: Role[];
}

interface DeleteRoleAction extends Action
{
    type: ERoleAction.DELETE_ROLE;
    payload: number; // Annahme, dass ID eine Zahl ist
}

interface UpdateRoleAction extends Action
{
    type: ERoleAction.UPDATE_ROLE;
    payload: Role;
}

type RoleAction = SetRolesAction | DeleteRoleAction | UpdateRoleAction;

export const roleReducer = (state = initialState, action: RoleAction): Role[] =>
{
    switch (action.type)
    {
        case ERoleAction.SET_ROLES:
            return action.payload;
        case ERoleAction.DELETE_ROLE:
            return state.filter(role => role.id !== action.payload);
        case ERoleAction.UPDATE_ROLE:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(role => role.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setRoles = (roles: Role[]): SetRolesAction => ({
    type: ERoleAction.SET_ROLES,
    payload: roles,
});

export const deleteRole = (id: number): DeleteRoleAction => ({
    type: ERoleAction.DELETE_ROLE,
    payload: id,
});

export const updateRole = (role: Role): UpdateRoleAction => ({
    type: ERoleAction.UPDATE_ROLE,
    payload: role,
});
