import React from 'react';
import './RoundIconButton.css';
import IconButton from '@mui/material/IconButton';

interface RoundIconButtonProps
{
    icon: React.ReactNode
    helperText: string
    size: 'small' | 'medium' | 'verysmall'
    color?: 'secondary-color' | 'primary-color' | 'on-secondary-color' | 'on-primary-color'
    onClick: (e: React.MouseEvent<HTMLElement>) => void
}

/**
 * RoundIconButton component.
 *
 * @param icon - The icon.
 * @param helperText - The helper text.
 * @param size - The size of the button.
 * @param color - The color of the button.
 * @param onClick - The click event handler.
 * @returns A JSX.Element representing the RoundIconButton component.
 */
function RoundIconButton({ icon, helperText, size, color, onClick }: RoundIconButtonProps)
{
    const iconButtonSize = size === 'verysmall' ? 'small' : size;
    return (
        <div className='roundIconButton'>
            <IconButton
                size={iconButtonSize}
                // color={color}
                title={helperText}
                style={{
                    color: color ? `var(--var-${color})` : undefined,
                    padding: size === 'verysmall' ? 0 : 'inital',
                }}
                onClick={(ev) => { ev.preventDefault(); onClick(ev) }}
            >

                {/* <div className={'roundIconButton ' + size} title={helperText}
                onClick={() => { onClick() }}

            > */}
                {icon}
                {/* </div> */}
            </IconButton>
        </div>
    );
}

export default RoundIconButton;
