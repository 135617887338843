import React, { useRef } from 'react';
import './LoadingDialog.css';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';


interface LoadingDialogProps
{
    text?: string
}

function LoadingDialog(props: LoadingDialogProps)
{
    const dialogRef = useRef<HTMLDialogElement>(null);
    React.useEffect(() =>
    {
        if (dialogRef.current)
        {
            dialogRef.current.showModal();
        }
    }, []);

    return (
        <dialog ref={dialogRef} className="loadingDialog">
            <LoadingBox text={props.text} />
        </dialog>
    );
}

export default LoadingDialog;