import React, { useState } from 'react';
import { Done } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ICompanyCalcualtions } from '@sharedInterfaces/ICompanySettings';
import { AppState } from '@store/store';
import Company from '@src/Objects/Company';
import editCalculations from '@src/APIs/graphQl/Company/editCalculations';
import { Typography } from '@mui/material';

import RowElement from '../../sharedReact/General/Forms/RowElement/RowElement';
import ErrorBox from '../../Components/ErrorBox/ErrorBox';
import Button from '../../sharedReact/General/Button/Button';
import NumberInput from '../../Components/formsControls/inputs/NumberInput/NumberInput';

interface CalculationsTabProps
{
    calculations: ICompanyCalcualtions;
    setCompany: (company: Company) => void;
}
/**
 * Represents a CalculationsTab component.
 *
 * @param {CalculationsTabProps} params - The parameters for the CalculationsTab component.
 * @param {Object} params.calculations - The calculations data.
 * @param {Function} params.setCompany - The function to set the company.
 *
 * @returns {JSX.Element} The JSX element representing the CalculationsTab component.
 */
export function CalculationsTab({ calculations, setCompany }: CalculationsTabProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [calculationsState, setCalculationsState] = useState<ICompanyCalcualtions>(calculations);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [changed, setChanged] = useState<boolean>(false);

    return (
        <div className='calculationsTab'>
            <Typography variant='h4'>{langStrings.calculations}</Typography>
            <Typography variant='body1'>{langStrings.calculationsText}</Typography>
            <RowElement title={langStrings.pastForecastMonth}>
                <NumberInput min={0} max={12} value={calculationsState.workedMonthCount} onChange={(value) =>
                {
                    setChanged(true);
                    setCalculationsState({ ...calculationsState, workedMonthCount: value })
                }} />
            </RowElement>
            <RowElement title={langStrings.futureForecastMonths}>
                <NumberInput min={0} max={12} value={calculationsState.forecastMonthCount} onChange={(value) =>
                {
                    setChanged(true);
                    setCalculationsState({ ...calculationsState, forecastMonthCount: value })
                }} />
            </RowElement>
            {saveError && <ErrorBox close={setSaveError.bind(null, null)}>
                {saveError}
            </ErrorBox>}
            {changed &&
                <Button
                    disabled={saving}
                    size={'normal'} text={langStrings.save} icon={<Done />} onClick={function (): void
                    {
                        setSaving(true);
                        editCalculations(calculationsState)
                            .then(setCompany).then(setChanged.bind(null, false))
                            .catch(err => setSaveError(err.toString()))
                            .finally(() => setSaving(false));
                    }} />
            }
        </div>
    );
}
