import { OperationVariables, gql } from "@apollo/client";
import { EGender } from "@sharedInterfaces/IEmployee";

import GraphQL from "../graphQL";

const query = gql`
mutation updateMyGenderAndName($firstName: String!,$lastName: String!,$gender: String!) {
    updateMyGenderAndName(firstName: $firstName, lastName: $lastName, gender: $gender) 
},
`;

export default async function updateMyGenderAndName(firstName: string, lastName: string, gender: EGender)
{
    const variables: OperationVariables = {
        firstName,
        lastName,
        gender,
    };
    return GraphQL.mutate<boolean>(query, variables);
}