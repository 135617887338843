import { OperationVariables, gql } from "@apollo/client";
import ICompetence, { IEditCompetence } from "@sharedInterfaces/ICompetence";
import { competenceGQL } from "@src/Objects/Competence";

import GraphQL from "../graphQL";

const query = gql`
mutation editCompetence($id:Int!, $obj: EditCompetence!) {
    editCompetence(id: $id ,obj: $obj) 
    ${competenceGQL}
},
`;

export default async function editCompetence(competence: IEditCompetence)
{
    const variables: OperationVariables = {
        id: competence.id,
        obj: {
            ...competence,
            levels: competence.levels.map((c) => ({
                id: c.id,
                title: c.title,
                skills: c.skills,
                certificates: c.certificates,
            })),
            // employees: competence.employees.map((c) => ({
            //     id: c.id,
            //     title: c.title,
            //     level: c.level,
            // })),
        },
    };

    return GraphQL.mutate<ICompetence>(query, variables)
}

//, (obj: any) => callback(new Competence(companyId, obj.editCompetence as ICompetence))