import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';

import './LoadingBox.css';

interface LoadingBoxProps
{
    text?: string

}

/**
 * LoadingBox component.
 *
 * @param {LoadingBoxProps} props - The props for the LoadingBox component.
 * @returns {JSX.Element} The LoadingBox component.
 */
function LoadingBox(props: LoadingBoxProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const loadingText = getLanguageTexts(lang).loading;
    const text = props.text ? props.text : loadingText

    return (
        <div className='loadingBox'>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <span className='loadingText'>{text}</span>
        </div>
    );
}

export default LoadingBox;
