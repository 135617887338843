import { Action } from "redux";

// Initial State
export const initialCookieSettingsState: ICookieSettingsState = {
    userConfirmed: false, // Standardmäßig nicht bestätigt
    functional: true, // Standardmäßig aktiviert
    marketing: false, // Standardmäßig deaktiviert
    analyses: false,  // Standardmäßig deaktiviert
};

// Interface for State
export interface ICookieSettingsState
{
    userConfirmed: boolean;
    functional: boolean;
    marketing: boolean;
    analyses: boolean;
}

// Enum for Action Types
export enum ECookieSettingsAction
{
    SET_USER_CONFIRMED = "SET_USER_CONFIRMED",
    SET_FUNCTIONAL = "SET_FUNCTIONAL",
    SET_MARKETING = "SET_MARKETING",
    SET_ANALYSES = "SET_ANALYSES",
}

// Action Interfaces
interface SetUserConfirmedAction extends Action
{
    type: ECookieSettingsAction.SET_USER_CONFIRMED;
    payload: boolean;
}
interface SetFunctionalAction extends Action
{
    type: ECookieSettingsAction.SET_FUNCTIONAL;
    payload: boolean;
}
interface SetMarketingAction extends Action
{
    type: ECookieSettingsAction.SET_MARKETING;
    payload: boolean;
}
interface SetAnalysesAction extends Action
{
    type: ECookieSettingsAction.SET_ANALYSES;
    payload: boolean;
}

// Union Type for Actions
type CookieSettingsActions = SetFunctionalAction | SetMarketingAction | SetAnalysesAction | SetUserConfirmedAction;

// Reducer
export const cookieSettingsReducer = (state = initialCookieSettingsState, action: CookieSettingsActions): ICookieSettingsState =>
{
    switch (action.type)
    {
        case ECookieSettingsAction.SET_FUNCTIONAL:
            return {
                ...state,
                functional: action.payload,
            };
        case ECookieSettingsAction.SET_MARKETING:
            return {
                ...state,
                marketing: action.payload,
            };
        case ECookieSettingsAction.SET_ANALYSES:
            return {
                ...state,
                analyses: action.payload,
            };
        case ECookieSettingsAction.SET_USER_CONFIRMED:
            return {
                ...state,
                userConfirmed: action.payload,
            };
        default:
            return state;
    }
};

// Action Creators
export const setFunctional = (functional: boolean): SetFunctionalAction => ({
    type: ECookieSettingsAction.SET_FUNCTIONAL,
    payload: functional,
});

export const setMarketing = (marketing: boolean): SetMarketingAction => ({
    type: ECookieSettingsAction.SET_MARKETING,
    payload: marketing,
});

export const setAnalyses = (analyses: boolean): SetAnalysesAction => ({
    type: ECookieSettingsAction.SET_ANALYSES,
    payload: analyses,
});

export const setUserConfirmed = (userConfirmed: boolean): SetUserConfirmedAction => ({
    type: ECookieSettingsAction.SET_USER_CONFIRMED,
    payload: userConfirmed,
});