import { OperationVariables, gql } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { setCompanyAllSkills } from "@store/reducer/companyReducer";
import { setEmployeeSkills } from "@store/reducer/employeeReducer";
import { setLastSkillUpdate } from "@store/reducer/employeeSettingsReducer";
import store from "@store/store";

import GraphQL from "../graphQL";

const query = gql`
mutation createAndMapSkill($title: String!, $level: Int!) {
    createAndMapSkill(title: $title, level: $level) 
},
`;
/**
 * Creates a new skill with the provided title and level and maps it.
 *
 * @param {string} title - The title of the skill.
 * @param {number} level - The level of the skill.
 * @returns {Promise<number>} A promise that resolves with the newly created skill's ID.
 */
export default async function createAndMapSkill(title: string, level: number)
{
    const variables: OperationVariables = { title, level };
    return GraphQL.mutate<number>(query, variables)
        .then(result =>
        {
            const state = store.getState();
            const newSkills = cloneDeep(state.employee.skills);
            newSkills.push({ id: result, title: title, level: level });
            store.dispatch(setEmployeeSkills(newSkills));
            const newAllSkills = [...state.company.allSkills];
            newAllSkills.push({
                title: title, id: result, otherNames: [], categoryId: -1,
                version: 0,
            });
            store.dispatch(setCompanyAllSkills(newAllSkills))
            store.dispatch(setLastSkillUpdate(new Date()));
            return result;
        });



}
