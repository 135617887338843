import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const q = gql`
query getUploadedFileContent($path: String!) {
    getUploadedFileContent(path:  $path)
},
`;

export default async function getUploadedFileContent(path: string, callback: (content: string) => void)
{
    const variables: OperationVariables = { path };
    return GraphQL.query<{ getUploadedFileContent: string }>(q, variables, (obj) => obj && callback(obj.getUploadedFileContent));
}