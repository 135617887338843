import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EEntityType, entityTypeToLink } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import './CompetencesCard.css';
import CompetenceItem from '@src/App/NewLayout/Items/CompetenceItem/CompetenceItem';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';
interface CompetencesCardProps
{
    title?: string;
    competences: { id: number, title: string, level?: number, }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * Represents a CompetencesCard component.
 * 
 * @param {CompetencesCardProps} title - The title of the card.
 * @param {CompetencesCardProps} competences - The competences of the card.
 * @param {CompetencesCardProps} inOverlay - Indicates whether the card is displayed in an overlay.
 * 
 * @return {JSX.Element} The rendered CompetencesCard component.
 */
function CompetencesCard({ limit, title, competences, inOverlay }: CompetencesCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).competence;
    const allCompetences = useSelector((state: AppState) => state.company.allCompetences);
    const newTitle = generateTopXText(title ? title : langStrings.competences, limit);

    competences = [...competences].sort((a, b) =>
    {
        const allCompetenceA = allCompetences.find(c => c.id === a.id);
        const allCompetenceB = allCompetences.find(c => c.id === b.id);
        let percentA = 0;
        let percentB = 0;
        if (a.level !== undefined && allCompetenceA)
        {
            percentA = a.level / allCompetenceA.levels.length * 100
        }
        if (b.level !== undefined && allCompetenceB)
        {
            percentB = b.level / allCompetenceB.levels.length * 100
        }
        const diff = percentB - percentA;
        if (diff !== 0) return diff;
        return a.title.localeCompare(b.title);
    })
    const limitedCompetences = limit ? competences
        .slice(0, limit > competences.length ? competences.length : limit) : competences;
    const DOM = limitedCompetences.map((competence, i) =>
    {
        return <CompetenceItem
            key={i}
            id={competence.id}
            title={competence.title}
            level={competence.level}
            disableOverlay={inOverlay}
            onClick={entityTypeToLink(competence.id, EEntityType.COMPETENCE)}
        />
    });
    return (
        <CardBox title={newTitle}>
            <List>
                {DOM}
            </List>
        </CardBox>
    );
}
export default CompetencesCard;