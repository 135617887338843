import IUser from "../../interfaces/IUser";

export const initialUserState: IUser = {
    email: '',
    confirmed: false,
    companyId: '',
    employeeId: -1,
};

export enum UserActionTypes
{
    SET_USER = 'SET_USER'
}

interface SetUserAction
{
    type: UserActionTypes.SET_USER;
    payload: IUser;
}

type UserAction = SetUserAction;

export const userReducer = (state = initialUserState, action: UserAction): IUser =>
{
    switch (action.type)
    {
        case UserActionTypes.SET_USER:
            return action.payload
        default:
            return state;
    }
};
export const setUser = (user: IUser): SetUserAction => ({
    type: UserActionTypes.SET_USER,
    payload: user,
});
