import { OperationVariables, gql } from "@apollo/client";
import IProduct, { IEditProduct } from "@sharedInterfaces/IProduct";
import { productGQL } from "@src/Objects/Product";

import GraphQL from "../graphQL";

const query = gql`
mutation editProduct($id:Int!, $obj: EditProduct!) {
    editProduct(id: $id ,obj: $obj) 
    ${productGQL}
},
`;

/**
 * Function to edit a product.
 * 
 * @param {object[]} product - The product to be edited.
 * @param {string} product.name - The name of the product.
 * @param {string} product.type - The type of the product.
 * @returns {Promise<object>} - Returns a promise that resolves with the edited product.
 */
export default async function editProduct(product: IEditProduct)
{
    const variables: OperationVariables = {
        id: product.id,
        obj: {
            ...product,
            skills: product.skills.map(s => ({
                id: s.id,
                title: s.title,
                level: s.level
            })),
            certificates: product.certificates.map(s => ({
                id: s.id,
                title: s.title,
            })),
            employees: product.employees.map(s => ({
                id: s.id,
                title: s.title,
            })),
            competences: product.competences.map((c) => ({
                id: c.id,
                title: c.title,
                level: c.level,
            })),
            roles: product.roles.map((c) => ({
                id: c.id,
                title: c.title,
            })),
        },
    };

    return GraphQL.mutate<IProduct>(query, variables)
}

//, (obj: any) => callback(new Product(companyId, obj.editProduct as IProduct))