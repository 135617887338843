import React from 'react';

import './Content.css';
import { AutoBreadcrumb } from '../../../../Components/AutoBreadcrumb/AutoBreadcrumb';
import { HoverFab } from '../../../../Components/HoverFab/HoverFab';

interface ContentProps
{
  inDialog?: boolean
  contentHeader: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Represents a content component.
 * 
 * @param {ContentProps} inDialog - The in dialog property.
 * @param {ContentProps} contentHeader - The content header property.
 * @param {ContentProps} children - The children property.
 * 
 * @returns {JSX.Element} The JSX element representing the content component.
 */
export default function Content({ inDialog, contentHeader, children }: ContentProps)
{
  return (
    <>
      {!inDialog && <AutoBreadcrumb />}
      {contentHeader}
      {children && <div
        className={'content'}
      >
        {
          children
        }
        <div
          className='hoverSpacer'
          style={{ marginTop: 56 }}
        />
      </div>}
      {!inDialog &&
        <HoverFab />
      }
    </>
  );
}