import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

import Button from '../General/Button/Button';

/**
 * Represents the FatalErrorPage component.
 * 
 * @returns {JSX.Element} The rendered FatalErrorPage component.
 */
function FatalErrorPage({ error }: { error?: Error })
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).error;

    const reload = async function (): Promise<void>
    {
        const client = localStorage.getItem("client");
        const cookies = localStorage.getItem("cookies");
        localStorage.clear();
        if (client)
        {
            localStorage.setItem("client", client);
        }
        cookies && localStorage.setItem("cookies", cookies);

        if ('caches' in window)
        {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
        }
        location.reload();

    };

    return (
        <>
            <Typography variant='body1'>
                {langStrings.unknownErrorText}
                <br />
                {error && (
                    <div>
                        <Typography variant='body2'>{langStrings.errorName}: {error.name}</Typography>
                        <Typography variant='body2'>{langStrings.errorMessage}: {error.message}</Typography>
                        {error.stack && (
                            <Typography variant='body2'>{langStrings.stackTrace}:</Typography>
                        )}
                        {error.stack && (
                            <Typography variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                                {error.stack}
                            </Typography>
                        )}
                    </div>
                )}
            </Typography>
            <Button style={{ marginTop: 10 }} text={'Cache leeren & Seite Neuladen'} onClick={reload} />
        </>
    );
}

export default FatalErrorPage;
