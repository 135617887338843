import { Action } from "redux";
import Employee from "@src/Objects/Employee";

const initialState: Employee[] = [];

export enum EEmployeesAction
{
    SET_EMPLOYEES = "SET_EMPLOYEES",
    DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
    UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
}

interface SetEmployeesAction extends Action
{
    type: EEmployeesAction.SET_EMPLOYEES;
    payload: Employee[];
}

interface DeleteEmployeeAction extends Action
{
    type: EEmployeesAction.DELETE_EMPLOYEE;
    payload: number; // Assuming ID is a number
}

interface UpdateEmployeeAction extends Action
{
    type: EEmployeesAction.UPDATE_EMPLOYEE;
    payload: Employee;
}

type EmployeesAction = SetEmployeesAction | DeleteEmployeeAction | UpdateEmployeeAction;

export const employeesReducer = (state = initialState, action: EmployeesAction): Employee[] =>
{
    switch (action.type)
    {
        case EEmployeesAction.SET_EMPLOYEES:
            return action.payload;
        case EEmployeesAction.DELETE_EMPLOYEE:
            return state.filter(employee => employee.id !== action.payload);
        case EEmployeesAction.UPDATE_EMPLOYEE:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(employee => employee.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creator
export const setEmployees = (employees: Employee[]): SetEmployeesAction => ({
    type: EEmployeesAction.SET_EMPLOYEES,
    payload: employees,
});

export const deleteEmployee = (id: number): DeleteEmployeeAction => ({
    type: EEmployeesAction.DELETE_EMPLOYEE,
    payload: id,
});

export const updateEmployee = (employee: Employee): UpdateEmployeeAction => ({
    type: EEmployeesAction.UPDATE_EMPLOYEE,
    payload: employee,
});
