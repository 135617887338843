import { ELanguage } from "../languageHelper"

export const oftenUsedTranslations: ILangDataGeneral = {
    'th': {
        openInNewTab: 'เปิดในแท็บใหม่',
        productOwner: 'เจ้าของผลิตภัณฑ์',
        externalRessource: 'แหล่งข้อมูลภายนอก',
        documentation: 'เอกสารคู่มือ',
        generating: 'กำลังสร้างข้อมูล...',
        createdBy: 'สร้างโดย',
        changedBy: 'เปลี่ยนแปลงโดย',
        changedAt: 'เปลี่ยนแปลงเมื่อ',
        version: 'เวอร์ชัน',
        sending: 'กำลังส่ง...',
        monthly: 'ทุกเดือน',
        never: 'ไม่เคย',
        language: 'ภาษา',
        company: 'บริษัท',
        companies: 'บริษัท',
        send: 'ส่ง',
        link: 'ลิงก์',
        text: 'ข้อความ',
        subject: 'หัวข้อ',
        confirm: 'ยืนยัน',
        treeStructure: 'โครงสร้างแบบต้นไม้',
        roles: 'บทบาท',
        role: 'บทบาท',
        okay: 'ตกลง',
        inactive: 'ไม่ใช้งาน',
        today: 'วันนี้',
        cost: 'ราคา',
        deleteHint: 'คุณแน่ใจหรือว่าต้องการลบ "[TITLE]" จริงๆ หรือไม่?',
        deleteTitle: 'จะลบ "[TITLE]" จริงหรือไม่?',
        createdAt: 'สร้างเมื่อ',
        map: 'การจับคู่',
        shareWithText: 'แบ่งปันกับ',
        msTeams: 'ไมโครซอฟท์ ทีมส์',
        mail: 'อีเมล',
        copy: 'คัดลอก',
        share: 'แบ่งปัน',
        fullDay: 'ทั้งวัน',
        halfDay: 'ครึ่งวัน',
        errorPleaseRetry: 'เกิดข้อผิดพลาด กรุณาลองอีกครั้ง',
        saving: 'กำลังบันทึก...',
        year: 'ปี',
        actions: 'การกระทำ',
        date: 'วันที่',
        add: 'เพิ่ม',
        unknown: 'ไม่ทราบ',
        availabilityNext28Days: 'ความพร้อมใช้งานใน 28 วันถัดไป',
        similarity: 'ความคล้ายคลึง',
        level: 'ระดับ',
        revenue: 'รายได้',
        firstName: 'ชื่อ',
        lastName: 'นามสกุล',
        opportunity: 'คำขอ',
        opportunities: 'คำขอ',
        'loading': 'กำลังโหลด กรุณารอสักครู่...',
        description: 'คำอธิบาย',
        orgUnits: 'หน่วยงาน',
        error: 'ข้อผิดพลาด',
        previous: 'กลับ',
        next: 'ต่อไป',
        close: 'ปิด',
        overview: 'ภาพรวม',
        delete: 'ลบ',
        edit: 'แก้ไข',
        employees: 'พนักงาน',    // Can be used for both singular and plural, but contextually often plural
        employee: 'พนักงาน',    // Singular
        orgUnit: 'หน่วยงาน',
        create: 'สร้าง',
        save: 'บันทึก',
        skill: "ทักษะ",
        skills: "ทักษะ",
        certificates: 'ใบรับรอง',
        empty: 'ว่างเปล่า',
        skillCategories: 'หมวดความสามารถ',
        skillCategory: 'หมวดหมู่ทักษะ',
        'leader': 'ผู้นำ',
        count: 'จำนวน',
        cancel: 'ยกเลิก',
        products: 'ผลิตภัณฑ์',
        solutions: 'สิ่งที่ช่วยแก้ปัญหา',
        projects: 'โครงการ',
        availability: 'ความพร้อม',
        yes: 'ใช่',
        no: 'ไม่',
        active: 'ใช้งานอยู่',
        hours: 'ชั่วโมง',
        month: 'เดือน',
        week: 'สัปดาห์',
        day: 'วัน',
        days: 'วัน',
        search: 'ค้นหา',
        notFound: 'ไม่พบ',
        business: 'บริษัท',
        login: 'เข้าสู่ระบบได้เลย',
        businesses: 'บริษัท ๆ',
        permissions: 'สิทธิ์การเข้าถึง',
        permissionGroup: 'กลุ่มสิทธิ์การเข้าถึง',
        notAllowed: 'คุณไม่มีสิทธิ์ที่จำเป็นในการดูหน้านี้',
        open: 'เปิด',
        hoursShort: 'ชม.',
        project: 'โครงการ',
        dashboard: 'Dashboard',
        downloadAsCSV: 'ดาวน์โหลดเป็นไฟล์ CSV',
        offline: 'คุณออฟไลน์อยู่ขณะนี้ และข้อมูลที่คุณต้องการดูยังไม่ได้ถูกแคชไว้',
        otherNames: 'คำเหมือน',
        markAsRead: 'ทำเครื่องหมายว่าอ่านแล้ว',
        gender: 'เพศ',
        certificate: 'ใบรับรอง',
        product: 'ผลิตภัณฑ์',
        sum: 'ผลรวม',
        type: 'ประเภท',
        "back": "ย้อนกลับ", //หน้าเดียวกลับ
        competences: 'ความสามารถ',
        competence: 'ความสามารถ',
        allEntries: 'รายการทั้งหมด',
        myEntries: 'รายการของฉัน',
        table: 'ตาราง',
        cards: 'การ์ด',
        locked: 'ล็อก',
        lockedBy: 'ถูกล็อคโดย',
    },
    'en': {
        openInNewTab: 'Open in new tab',
        productOwner: 'Product Owner',
        externalRessource: 'External Resource',
        documentation: 'Documentation',
        generating: 'Generating...',
        createdBy: 'Created by',
        changedBy: 'Changed by',
        changedAt: 'Changed at',
        version: 'Version',
        sending: 'Sending...',
        monthly: 'Monthly',
        never: 'Never',
        language: 'Language',
        company: 'Company',
        companies: 'Companies',
        send: 'Send',
        link: 'Link',
        text: 'Text',
        subject: 'Subject',
        confirm: 'Confirm',
        treeStructure: 'Tree structure',
        roles: 'Roles',
        role: 'Role',
        okay: 'Okay',
        inactive: 'Inactive',
        today: 'Today',
        cost: 'Price',
        deleteHint: 'Are you sure you want to really delete "[TITLE]"?',
        deleteTitle: 'Really delete "[TITLE]"?',
        createdAt: 'Created at',
        map: 'Map',
        shareWithText: 'Share with',
        msTeams: 'Microsoft Teams',
        mail: 'Email',
        copy: 'Copy',
        share: 'Share',
        fullDay: 'Full day',
        halfDay: 'Half day',
        errorPleaseRetry: 'An error has occurred. Please try again.',
        saving: 'Saving...',
        year: 'year',
        actions: 'Actions',
        date: 'Date',
        add: 'Add',
        unknown: 'Unknown',
        availabilityNext28Days: 'Availability in the next 28 days',
        similarity: 'similarity',
        level: 'level',
        revenue: 'Revenue',
        firstName: 'Firstname',
        lastName: 'Lastname',
        opportunity: 'Request',
        opportunities: 'Requests',
        loading: 'Loading, please wait a moment...',
        description: 'Description',
        orgUnits: 'Organizational Units',
        error: 'Error',
        previous: 'Previous',
        next: 'Next',
        close: 'Close',
        overview: 'Overview',
        delete: 'Delete',
        edit: 'Edit',
        employees: 'Employees',  // Plural
        employee: 'Employee',    // Singular
        orgUnit: 'Organizational Unit',
        create: 'Create',
        save: 'Save',
        skill: "Skill",
        skills: "Skills",
        certificates: 'Certificates',
        empty: 'Empty...',
        skillCategories: 'Skill-categories',
        skillCategory: 'Skill-category',
        leader: 'Leader',
        count: 'Quantity',
        cancel: 'Cancel',
        products: 'Products',
        projects: 'Projects',
        solutions: 'Solutions',
        availability: 'Availability',
        yes: 'Yes',
        no: 'No',
        active: 'Active',
        hours: 'Hours',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        days: 'Days',
        search: 'Search',
        notFound: 'Not found',
        business: 'Company',
        login: 'login...',
        businesses: 'Companies',
        permissions: 'Permissions',
        permissionGroup: 'Permission Group',
        notAllowed: 'You do not have the necessary permissions to view this page.',
        open: 'Open',
        hoursShort: 'hrs.',
        project: 'Project',
        dashboard: 'Dashboard',
        downloadAsCSV: 'Download as CSV file',
        offline: 'You are currently offline, and the data you want to view has not been cached yet.',
        otherNames: 'Synonyms',
        markAsRead: 'Mark as Read',
        gender: 'Gender',
        certificate: 'Certificate',
        product: 'Product',
        sum: 'Sum',
        type: 'Type',
        "back": "Back", //One page back
        competences: 'Competences',
        competence: 'Competence',
        allEntries: 'All Entries',
        myEntries: 'My Entries',
        table: 'Table',
        cards: 'Cards',
        locked: 'Locked',
        lockedBy: 'Locked by',
    },
    'de': {
        revenue: 'Umsatz',
        openInNewTab: 'In neuer Registerkarte öffnen',
        firstName: 'Vorname',
        lastName: 'Nachname',
        opportunity: 'Anfrage',
        opportunities: 'Anfragen',
        loading: 'Wird geladen, einen Moment bitte...',
        description: 'Beschreibung',
        orgUnits: 'Organisationseinheiten',
        error: 'Fehler',
        previous: 'Zurück',
        next: 'Weiter',
        close: 'Schließen',
        overview: 'Übersicht',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        employees: 'Mitarbeiter', //Mehrzahl
        employee: 'Mitarbeiter', //Einzahl
        orgUnit: 'Organisationseinheit',
        create: 'Erstellen',
        save: 'Speichern',
        skill: "Fähigkeit",
        skills: "Fähigkeiten",
        certificates: 'Zertifikate',
        empty: 'Leer...',
        skillCategories: 'Fähigkeitskategorien',
        skillCategory: 'Fähigkeitskategorie',
        leader: 'Leitung',
        count: 'Anzahl',
        cancel: 'Abbrechen',
        products: 'Produkte',
        projects: 'Projekte',
        solutions: 'Lösungen',
        availability: 'Verfügbarkeit',
        yes: 'Ja',
        no: 'Nein',
        active: 'Aktiv',
        hours: 'Stunden',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
        days: 'Tage',
        search: 'Suchen',
        notFound: 'Nicht gefunden',
        business: 'Firma',
        login: 'einloggen...',
        businesses: 'Firmen',
        permissions: 'Berechtigungen',
        permissionGroup: 'Berechtigungsgruppe',
        notAllowed: 'Du hast nicht die nötigen Berechtigungen, um diese Seite zu anzuzeigen.',
        open: 'Öffnen',
        hoursShort: 'h',
        project: 'Projekt',
        dashboard: 'Dashboard',
        downloadAsCSV: 'Als CSV-Datei herunterladen',
        offline: 'Du bist gerade offline, und die Daten, die du anzeigen möchtest, wurden noch nicht zwischengespeichert.',
        otherNames: 'Synonyme',
        markAsRead: 'Als gelesen markieren',
        gender: 'Geschlecht',
        certificate: "Zertifikat",
        product: "Produkt",
        sum: 'Summe',
        type: "Typ",
        back: 'Zurück', //Eine Seite zurück
        competences: 'Kompetenzen',
        competence: 'Komepetenz',
        allEntries: 'Alle Einträge',
        myEntries: 'Meine Einträge',
        table: 'Tabelle',
        cards: 'Karten',
        locked: 'Gesperrt',
        lockedBy: 'Gesperrt durch',
        level: 'Stufe',
        similarity: 'Ähnlichkeit',
        availabilityNext28Days: "Verfügbarkeit in den nächsten 28 Tagen",
        unknown: 'Unbekannt',
        add: 'Hinzufügen',
        date: 'Datum',
        actions: 'Aktionen',
        year: 'Jahr',
        saving: 'Wird gespeichert...',
        errorPleaseRetry: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        halfDay: 'Halber Tag',
        fullDay: 'Ganzer Tag',
        share: 'Teilen',
        copy: 'Kopieren',
        mail: 'E-Mail',
        msTeams: 'Microsoft Teams',
        shareWithText: 'Teilen mit',
        map: 'Zuordnen',
        version: 'Version',
        changedAt: 'Geändert am',
        changedBy: 'Geändert von',
        createdAt: 'Erstellt am',
        createdBy: 'Erstellt von',
        deleteTitle: '"[TITLE]" wirklich löschen?',
        deleteHint: 'Bist du dir sicher das du "[TITLE]" wirklich löschen möchtest?',
        cost: 'Preis',
        today: 'Heute',
        inactive: 'Inaktiv',
        okay: 'Okay',
        role: 'Rolle',
        roles: 'Rollen',
        treeStructure: 'Baumstruktur',
        confirm: 'Bestätigen',
        subject: 'Betreff',
        text: 'Text',
        link: 'Link',
        send: 'Senden',
        companies: 'Firmen',
        company: 'Firma',
        language: 'Sprache',
        never: 'Nie',
        monthly: 'Monatlich',
        sending: 'Wird gesendet...',
        generating: 'Wird generiert...',
        documentation: 'Dokumentation',
        externalRessource: 'Externe Ressource',
        productOwner: 'Produktowner',
    },
}

export interface ITextBase
{
    oftenUsed: ILanguageGeneral;
}

export interface ILanguageGeneral
{
    openInNewTab: string
    externalRessource: string;
    productOwner: string;
    documentation: string;
    generating: string;
    version: string;
    changedAt: string;
    changedBy: string;
    createdBy: string;
    sending: string;
    monthly: string;
    never: string;
    language: string;
    company: string;
    companies: string;
    send: string;
    link: string;
    subject: string;
    confirm: string;
    treeStructure: string;
    roles: string;
    role: string;
    okay: string;
    inactive: string;
    today: string;
    cost: string;
    deleteHint: string;
    deleteTitle: string;
    createdAt: string;
    map: string;
    shareWithText: string;
    msTeams: string
    mail: string;
    copy: string;
    share: string;
    halfDay: string;
    fullDay: string;
    errorPleaseRetry: string;
    saving: string;
    year: string;
    date: string;
    actions: string;
    add: string;
    unknown: string;
    availabilityNext28Days: string;
    similarity: string;
    level: string;
    lockedBy: string;
    locked: string;
    competence: string
    competences: string
    allEntries: string
    myEntries: string
    table: string
    cards: string
    back: string
    type: string
    sum: string
    certificate: string
    product: string
    gender: string
    markAsRead: string
    previous: string
    otherNames: string
    offline: string
    revenue: string
    downloadAsCSV: string
    dashboard: string
    project: string
    hoursShort: string
    employee: string
    open: string
    notAllowed: string
    permissionGroup: string
    permissions: string
    firstName: string
    lastName: string
    login: string
    notFound: string
    days: string
    day: string
    week: string
    month: string
    hours: string
    active: string
    no: string
    yes: string
    availability: string
    loading: string
    opportunity: string
    opportunities: string
    description: string
    orgUnits: string
    error: string
    next: string
    close: string
    overview: string
    delete: string
    edit: string
    employees: string
    orgUnit: string
    create: string
    save: string
    skill: string
    skills: string
    certificates: string
    empty: string
    skillCategories: string
    skillCategory: string
    leader: string
    count: string
    cancel: string
    products: string
    projects: string
    solutions: string
    search: string
    business: string
    businesses: string
    text: string
}
type ILangDataGeneral = {
    [lang in ELanguage]: ILanguageGeneral
}

