import { Add, Error, Settings } from '@mui/icons-material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EAvailabilityInputAs, IAvailabilityWeekDTO } from '@sharedInterfaces/IAvailabilityMonth';
import { IEmployeeProjectReferenceDTO } from '@sharedInterfaces/IEmployee';
import { AppState } from '@store/store';
import AvailabilityMonth from '@src/Objects/AvailabilityMonth';

import { IState, AvailabilityTextFieldDays, FACTOR_MUCHTOHIGH, FACTOR_VERYHIGH, FACTOR_HIGH, distributeWorkOnDays, getWorkDayCountForDay } from './AvailabilityMonthDialog';

interface MonthContainerProps
{
    state: IState;
    setState: (state: IState) => void;
    availabilityMonth: AvailabilityMonth;
    loadMoreMonth: boolean;
    onClickSettings: () => void;
    setLoadMoreMonth: (state: boolean) => void;
    renderProjectTD: (project: IEmployeeProjectReferenceDTO) => JSX.Element;
}
/**
 * Function that returns a JSX element representing the MonthContainer.
 * 
 * @param {MonthContainerProps} props - The props for the MonthContainer.
 * @returns {JSX.Element} - The rendered MonthContainer component.
 */
export function MonthContainer(props: MonthContainerProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).availability;
    const projects = useSelector((state: AppState) => state.employee.projects)
        .filter(p => p.active);
    const hiddenProjects = useSelector((state: AppState) => state.employeeSettings.hiddenProjects);

    const state = props.state;
    const maxUnits = state.inputAs === EAvailabilityInputAs.days ? state.months.map(m => m.workDayCount) : state.months.map(m => m.workDayCount * 8);
    const units = state.months.map(
        month => Math.round(month.weekArray.flatMap(week => week.days.flatMap(day => day.planings))
            .filter((obj) => !hiddenProjects.includes(Number(obj.activityId)))
            .reduce((sum, { value }) => sum + value, 0)
            * 100) / 100
    );
    const absenceSums = state.months.map(month => month.weekArray.map(week =>
    {
        return week.days.reduce((sum, current) =>
        {
            return sum + (current.absence ? current.absence : 0) + (current.holiday ? current.holiday : 0);
        }, 0);
    }).reduce((sum, current) => sum + current) * (state.inputAs === EAvailabilityInputAs.days ? 1 : 8));
    const factors = absenceSums.map((sum, index) => (units[index] + absenceSums[index]) / maxUnits[index]);

    const visibleProjects = projects.filter(p => !hiddenProjects.includes(p.id));

    return <div id="monthContainer">
        <table className='activityTable'>
            <tbody>
                <tr className='thead'>
                    <td>
                        <div className='activityHeader'>
                            {langStrings.activity}
                            <IconButton size='small' onClick={props.onClickSettings}><Settings fontSize='small' /></IconButton>
                        </div>
                    </td>
                    {state.months.map((month) =>
                    {
                        return <td key={month.id}>{month.title}</td>;
                    })}
                    {!props.loadMoreMonth && <td rowSpan={3 + visibleProjects.length} id="addMonthButton" onClick={() =>
                    {
                        const result = window.confirm("Sie sind dabei, den Modus zur Bearbeitung mehrerer Monate zu aktivieren. Dadurch werden beim Speichern alle bisherigen Planungen, die auf Wochen oder Tagen für die Folgemonate basieren, überschrieben. Möchten Sie fortfahren?");
                        if (result) props.setLoadMoreMonth(true);
                    }}>
                        <IconButton><Add /></IconButton>
                    </td>}
                </tr>
                {visibleProjects.map(project =>
                {
                    return <tr key={project.id}>
                        {props.renderProjectTD(project)}
                        {/* <td>
                            {project.business.title}
                            {project.title}
                        </td> */}
                        {state.months.map((month, index) =>
                        {
                            const units = month.weekArray.flatMap(week => week.days.flatMap(day => day.planings))
                                .filter((obj) => parseInt(obj.activityId) === project.id)
                                .reduce((sum, obj) => sum + obj.value, 0)
                            const projectWorkdays = units;

                            return <td key={month.id}>
                                <AvailabilityTextFieldDays disabled={false} max={maxUnits[index] * 2}
                                    value={Math.round(projectWorkdays * 100) / 100}
                                    setValue={(value) =>
                                    {
                                        const newState = {
                                            ...state,
                                        };
                                        const newMonth = newState.months.find(m => m.id === month.id) as {
                                            id: string;
                                            title: string;
                                            workDayCount: number;
                                            weekArray: IAvailabilityWeekDTO[];
                                        };

                                        // const actualMonth = state.months.find(m => m.id === newMonth.id);

                                        const workdays = month.weekArray.reduce((sum, current) => sum + current.days.reduce((sum, day) =>
                                        {
                                            return sum + getWorkDayCountForDay(day);
                                        }, 0), 0);
                                        const workPerDay = value / workdays;
                                        newMonth.weekArray.forEach(week => distributeWorkOnDays(week, workPerDay, project))
                                        props.setState(newState);
                                    }} />
                            </td>;
                        })}
                    </tr>;
                }
                )}
                <tr>
                    <td>{langStrings.absence}</td>
                    {state.months.map((month, index) =>
                    {
                        return <td key={month.id}>
                            {absenceSums[index]}
                        </td>;
                    })}

                </tr>

                <tr className='tfoot'>
                    <td className='sumBox'>
                        {langStrings.sum}
                    </td>
                    {state.months.map((month, index) =>
                    {
                        return <td key={month.id}>
                            <div className={'sumBox ' +
                                (
                                    factors[index] > FACTOR_MUCHTOHIGH ?
                                        'muchToHigh' :
                                        factors[index] > FACTOR_VERYHIGH ?
                                            'veryhigh' :
                                            (
                                                factors[index] > FACTOR_HIGH ?
                                                    'high' :
                                                    ''
                                            )
                                )}>
                                {`${units[index] + absenceSums[index]} / ${maxUnits[index]}`}{factors[index] > FACTOR_MUCHTOHIGH ? <Error className='warning' /> : ''}
                            </div>
                        </td>;
                    })}
                </tr>
            </tbody>
        </table>
    </div>;
}
