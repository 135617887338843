import { generateFullName } from '@sharedInterfaces/globalHelper';
import store from '@store/store';
import { Entity } from '@sharedReact/Objects/Entity';
import Employee from '@src/Objects/Employee';
import SkillDetail from '@src/Objects/SkillDetail';
import Certificate from '@src/Objects/Certificate';
import { SkillCategory } from '@src/Objects/SkillCategory';
import Competence from '@src/Objects/Competence';
import Product from '@src/Objects/Product';
import Opportunity from '@src/Objects/Opportunity';
import Business from '@src/Objects/Business';
import Project from '@src/Objects/Project';
import getSuggestedEmployees from '@src/APIs/graphQl/Opportunity/getSuggestedEmployees';
import { combineSkillsAndCertificates } from "@src/helper/helper";

import { generateDescription, isOrgUnit } from '../Components/Buttons/ShareButton/ShareButton';

/**
 * Generates text for the given entity.
 *
 * @param {Entity} entity - The entity object to generate text for.
 * @returns {string} The generated text.
 */
export async function generateTextForEntity(entity: Entity, link: string): Promise<{ subject: string; text: string; }>
{

    if (entity instanceof Employee)
    {
        return {
            subject: `Competence Manager - Profil: ${entity.fullName}`,
            text: `Sie dir bitte das Profil von ${entity.fullName} an.

${generateDescription(entity.description, 'Zusammenfassung')}
Link: ${link}`,
        };
    } else if (entity instanceof SkillDetail)
    {
        return {
            subject: `Competence Manager - Fähigkeit: ${entity.title}`,
            text: `Sie dir bitte die Fähigkeit "${entity.title}" an.

${generateDescription(entity.description)}
Link: ${link}`,
        };
    } else if (entity instanceof Certificate)
    {
        return {
            subject: `Competence Manager - Zertifikat: ${entity.title}`,
            text: `Sie dir bitte das Zertifikat "${entity.title}" an.

${generateDescription(entity.description)}
Link: ${link}`,
        };
    } else if (entity instanceof SkillCategory)
    {
        return {
            subject: `Competence Manager - Fähigkeitskategorie: ${entity.title}`,
            text: `Sie dir bitte die Fähigkeitskategorie "${entity.title}" an.

${generateDescription(entity.description)}
Link: ${link}`,
        };
    } else if (entity instanceof Competence)
    {
        return {
            subject: `Competence Manager - Kompetenz: ${entity.title}`,
            text: `Sie dir bitte die Kompetenz "${entity.title}" an.

${generateDescription(entity.description)}
Level:
${entity.levels.map(l => `${l.id}. ${l.title}`).join(`\n`)}

Mitarbeiter:
${entity.employees.map(e => `${e.level}\t -\t ${e.title}`).join(`\n`)}

Link: ${link}`,
        };
    } else if (entity instanceof Product)
    {
        return {
            subject: `Competence Manager - Produkt: ${entity.title}`,
            text: `Sie dir bitte das Produkt "${entity.title}" an.

${generateDescription(entity.description)}
Mitarbeiter:
${entity.employees.filter(e => e.match > 0).map(e => `${e.match.toString().padStart(2, '0')}%\t -\t ${e.title}`).join(`\n`)}

Kompetenzen:
${entity.competences.map(l => `${l.level}\t -\t ${l.title}`).join(`\n`)}

Fähigkeiten:
${entity.skills.map(l => `${l.level}\t -\t ${l.title}`).join(`\n`)}

Zertifikate:
${entity.certificates.map(l => l.title).join(`\n`)}

Link: ${link}`,
        };
    } else if (entity instanceof Opportunity)
    {
        const { combinedSkills, combinedCertificates } = combineSkillsAndCertificates(entity, store.getState().company.allCompetences);

        const suggestedEmployees = await getSuggestedEmployees(
            combinedSkills.map(s => ({ id: s.id, title: s.title, level: s.level })),
            combinedCertificates.map(c => ({ id: c.id, title: c.title }))
        );
        return {
            subject: `Competence Manager - Anfrage: ${entity.title}`,
            text: `Sie dir bitte die Anfrage "${entity.title}" an.

${generateDescription(entity.requestText, "Anfragetext")}
Ausgewählte Mitarbeiter:
${entity.selectedEmployees.filter(e => e.score > 0).map(e => `${Math.round(e.score * 100).toString().padStart(2, '0')}%\t -\t ${e.title}`).join(`\n`)}

Vorgeschlagene Mitarbeiter:
${suggestedEmployees.filter(e => e.score > 0).map(e => `${Math.round(e.score * 100).toString().padStart(2, '0')}%\t -\t ${e.title}`).join(`\n`)}

Kompetenzen:
${entity.competences.map(l => `${l.level}\t -\t ${l.title}`).join(`\n`)}

Fähigkeiten:
${entity.skills.map(l => `${l.level}\t -\t ${l.title}`).join(`\n`)}

Link: ${link}`,
        };
    } else if (isOrgUnit(entity))
    {
        return {
            subject: `Competence Manager - Organisationseinheit: ${entity.title}`,
            text: `Sie dir bitte die Organisationseinheit "${entity.title}" an.

Mitarbeiter:
${entity.employees.map(e => generateFullName(e.firstname, e.lastname)).join(`\n`)}

Fähigkeitskategorien:
${entity.skillCategories.map(l => `${l.count}\t -\t ${l.title}`).join(`\n`)}

Fähigkeiten:
${entity.skills.map(l => `${l.count}\t -\t ${l.title}`).join(`\n`)}

Link: ${link}`,
        };
    } else if (entity instanceof Business)
    {
        return {
            subject: `Competence Manager - Firma: ${entity.title}`,
            text: `Sie dir bitte die Firma "${entity.title}" an.

Projekte:
${entity.projects.map(l => l.title).join(`\n`)}

Link: ${link}`,
        };
    } else if (entity instanceof Project)
    {
        return {
            subject: `Competence Manager - Projekt: ${entity.title}`,
            text: `Sie dir bitte das Projekt "${entity.title}" an.

Kunde: ${entity.business.title}
Aktiv: ${entity.active ? 'Ja' : 'Nein'}
Stunden: ${entity.isHours} / ${entity.totalHours} - ${Math.round(entity.isHours / entity.totalHours * 100)}%
Tagespreis: ${entity.dayPrice}
Bisheriger Umsatz: ${entity.revenue}€

Mitarbeiter:
${entity.employees.map(l => l.title).join(`\n`)}

Link: ${link}`,
        };
    }
    return {
        subject: 'Competence Manager',
        text: `${entity.title} - ${link}`,
    };
}
