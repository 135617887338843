import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Edit, FilterList, Info, Save, Settings } from '@mui/icons-material';
import { Box, Checkbox, Chip, FormControlLabel, Grid, IconButton, MenuItem, Select, Slider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { IOrgUnitSalesDataDTO, IOrgUnitSalesView, IOUSalesDataEmployee, IOUSalesDataEmployeeMonth, IOUSalesDataEmployeeMonthProject } from '@sharedInterfaces/IOrgUnit';
import { IOrgUnitTexts } from '@sharedInterfaces/Language/languageTexts/orgUnit';
import { AppState } from '@store/store';
import LoadingBox from '@sharedReact/General/LoadingBox/LoadingBox';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import RoundIconButton from '@src/Components/Buttons/RoundIconButton/RoundIconButton';
import ProjectDialog from '@src/Components/Dialogs/ProjectDialog/ProjectDialog';
import CSVExporter from '@src/Components/CSVExporter/CSVExporter';
import TextInput from '@src/Components/formsControls/inputs/TextInput/TextInput';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';
import Row from '@sharedReact/General/Forms/Row/Row';
import EmployeeOverlay from '@src/Components/Overlays/EmployeeOverlay/EmployeeOverlay';
import ErrorBox from '@src/Components/ErrorBox/ErrorBox';
import DataTable, { IDataTableField } from '@src/App/NewLayout/Components/DataTable/DataTable';
import getProject from '@src/APIs/graphQl/Project/getProject';
import { hashToJson } from '@src/helper/helper';
import createOUSalesView from '@src/APIs/graphQl/OrgUnit/createOUSalesView';
import { AskForTextDialog } from '@src/Components/Dialogs/AskForTextDialog/AskForTextDialog';

import { RevenueGraph } from './RevenueGraph';
import { InnerDataTable } from './InnerDataTable';
import { SalesContentMonthSourceInfoDialog } from './SalesContentMonthSourceInfoDialog';
import { SalesContentViewSettings, } from './SalesContentViewSettings/SalesContentViewSettings';
import { SalesField } from './SalesField';



interface SalesContentProps
{
    salesData: IOrgUnitSalesDataDTO | null;
    refresh: () => void;
}
/**
 * SalesContent.
 *
 * @param {SalesContentProps} props - The props for SalesContent.
 * @returns {JSX.Element} - The rendered SalesContent.
 */
export function SalesContent(props: SalesContentProps)
{
    const navigate = useNavigate();
    const { openDialog } = useContext(DialogManagerContext);

    const salesData = props.salesData;
    let availableMonthIds: string[] = [];
    if (salesData)
    {
        if (salesData.employees.length)
        {
            availableMonthIds = salesData.employees[0].months.map(m => m.monthId);
        }
    }

    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const width = useSelector((state: AppState) => state.windowSize.width);
    const projects = useSelector((state: AppState) => state.projects)
    const [searchText, setSearchText] = React.useState<string>("");
    const [showProjectsWithoutRevenue, setShowProjectsWithoutRevenue] = React.useState<boolean>(true);
    const [showProjectsWithRevenue, setShowProjectsWithRevenue] = React.useState<boolean>(true);
    const [showInternalProjects, setShowInternalProjects] = React.useState<boolean>(true);
    const [showExternalProjects, setShowExternalProjects] = React.useState<boolean>(true);
    const [showAbsence, setShowAbsence] = React.useState<boolean>(true);
    const [showUnbilableProjectTime, setShowUnbilableProjectTime] = React.useState<boolean>(true);
    const [actualMonthSource, setActualMonthSource] = React.useState<string>("both");
    const [monthIds, setMonthIds] = React.useState<string[]>([]);
    const [hideProjectWarning, setHideProjectWarning] = React.useState<boolean>(false);
    const [filterChanged, setFilterChanged] = React.useState<boolean>(false);
    const [selectedView, setSelectedView] = React.useState<number | null>(null);
    const [showFilter, setShowFilter] = React.useState<boolean>(false);

    const [trendSensitivity, setTrendSensitivity] = React.useState<number>(15);

    const [views, setViews] = React.useState<IOrgUnitSalesView[]>([]);

    const isDense = (width: number) => true;
    // const isDense = (width: number) => width <= 1350;

    const baseUrl = location.pathname.replaceAll('//', '/');
    const selectView = (viewId: number | null) =>
    {
        if (!viewId)
        {
            setSelectedView(viewId);
            // setFilterChanged(false);
            return;
        }
        const view = views.find(v => v.id === viewId);
        setSelectedView(viewId);
        if (view)
        {
            setShowProjectsWithoutRevenue(view.showProjectsWithoutRevenue);
            setShowProjectsWithRevenue(view.showProjectsWithRevenue);
            setShowInternalProjects(view.showInternalProjects);
            setShowExternalProjects(view.showExternalProjects);
            setShowAbsence(view.showAbsence);
            setShowUnbilableProjectTime(view.showUnbilableProjectTime);
            setFilterChanged(false);
            setShowFilter(false);
        }
    }

    const updateParameterUrl = () =>
    {
        if (selectedView)
        {
            navigate(`${baseUrl}#viewId=${selectedView}`)
        } else
        {
            navigate(
                `${baseUrl}#showProjectsWithoutRevenue=${showProjectsWithoutRevenue
                }&showProjectsWithRevenue=${showProjectsWithRevenue
                }&showInternalProjects=${showInternalProjects
                }&showExternalProjects=${showExternalProjects
                }&showAbsence=${showAbsence
                }&actualMonthSource=${actualMonthSource
                }&showUnbilableProjectTime=${showUnbilableProjectTime
                }`)
        }
    };

    const now = new Date();
    const actualMonth = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;

    useEffect(() =>
    {
        let viewId: number;
        const parameter = hashToJson(window.location.hash);
        const parameters = Object.entries(parameter);
        if (Object.keys(parameter).length === 0 && views.length)
        {
            selectView(views[0].id)
            return;
        } else if (Object.keys(parameter).length === 0)
        {
            if (salesData?.views.length)
            {
                selectView(salesData.views[0].id)
                return;
            }
            return;
        }
        parameters.forEach(([key, value]) =>
        {
            switch (key)
            {
                case 'viewId':
                    viewId = parseInt(value);
                    selectView(viewId);
                    return;
                case 'showProjectsWithoutRevenue':
                    setShowProjectsWithoutRevenue(value === 'true');
                    break;
                case 'showProjectsWithRevenue':
                    setShowProjectsWithRevenue(value === 'true');
                    break;
                case 'showInternalProjects':
                    setShowInternalProjects(value === 'true');
                    break;
                case 'showExternalProjects':
                    setShowExternalProjects(value === 'true');
                    break;
                case 'showAbsence':
                    setShowAbsence(value === 'true');
                    break;
                case 'showUnbilableProjectTime':
                    setShowUnbilableProjectTime(value === 'true');
                    break
                case 'actualMonthSource':
                    setActualMonthSource(value);
                    break;
                default:
                    break;
            }
        })
    }, [])

    useEffect(() =>
    {
        if (!salesData) return;
        updateParameterUrl();
    }, [
        showProjectsWithoutRevenue,
        showProjectsWithRevenue,
        showInternalProjects,
        showExternalProjects,
        showAbsence,
        showUnbilableProjectTime,
        actualMonthSource,
        selectedView,
        //TODO: FIX BEI TAB WECHSEL
    ]);

    useEffect(() =>
    {
        if (salesData)
        {
            setViews(salesData.views ? salesData.views : []);
            if (selectedView)
            {
                selectView(selectedView);
            } else
            {
                const parameter = hashToJson(window.location.hash);
                if (Object.keys(parameter).length === 0 && views.length)
                {
                    selectView(salesData.views[0].id)
                    return;
                }
            }
        }
        setMonthIds(availableMonthIds);
    },
        [salesData]
    );
    if (!salesData) return <LoadingBox />;

    // Create a Set to track unique projectIds
    const uniqueProjectIds = new Set<number>();

    // Search for external projects without day price and with booked hours
    const externalProjectWithoutRevenue = salesData.employees.flatMap(e =>
        e.months.flatMap(m => m.projects)
    ).filter(p =>
    {
        const isExternalProject = (
            ((p.bookedHours > p.bookedNotBillableHours) && !p.bookedRevenue) || (p.plannedHours && !p.plannedRevenue)
        ) && !p.internalProject && p.title && p.title !== "";

        // Check if projectId is already encountered
        if (isExternalProject && !uniqueProjectIds.has(p.projectId))
        {
            uniqueProjectIds.add(p.projectId);
            return true;
        }
        return false;
    });

    //Filter EMployees
    let filteredSalesDataEmployees = salesData.employees;
    if (!showProjectsWithoutRevenue) // Filter Projects without revenue
    {
        filteredSalesDataEmployees =
            filteredSalesDataEmployees.map(
                salesEmp => ({
                    ...salesEmp,
                    months: salesEmp.months.map(
                        month => ({
                            ...month,
                            projects: month.projects.filter(p => p.bookedRevenue + p.plannedRevenue > 0)
                        })
                    )
                })
            )
    }
    if (!showProjectsWithRevenue) // Filter Projects with revenue
    {
        filteredSalesDataEmployees =
            filteredSalesDataEmployees.map(
                salesEmp => ({
                    ...salesEmp,
                    months: salesEmp.months.map(
                        month => ({
                            ...month,
                            projects: month.projects.filter(p => p.bookedRevenue + p.plannedRevenue === 0)
                        })
                    )
                })
            )
    }
    if (!showInternalProjects) // Filter internal Projects
    {
        filteredSalesDataEmployees =
            filteredSalesDataEmployees.map(
                salesEmp => ({
                    ...salesEmp,
                    months: salesEmp.months.map(
                        month => ({
                            ...month,
                            projects: month.projects.filter(p => !p.internalProject)
                        })
                    )
                })
            )
    }
    if (!showExternalProjects) // Filter external Projects
    {
        filteredSalesDataEmployees =
            filteredSalesDataEmployees.map(
                salesEmp => ({
                    ...salesEmp,
                    months: salesEmp.months.map(
                        month => ({
                            ...month,
                            projects: month.projects.filter(p => p.internalProject)
                        })
                    )
                })
            )
    }
    // if (!showUnbilableProjectTime) // Filter unbilable projects
    // {
    //     filteredSalesDataEmployees =
    //         filteredSalesDataEmployees.map(
    //             salesEmp => ({
    //                 ...salesEmp,
    //                 months: salesEmp.months.map(
    //                     month => ({
    //                         ...month,
    //                         projects: month.projects.filter(p => p.unbilable)
    //                     })
    //                 )
    //             })
    //         )
    // }
    // }
    if (searchText !== "") // Filter for search Text
    {
        filteredSalesDataEmployees = filteredSalesDataEmployees
            .filter(emp =>
            {
                if (emp.months.some(m => m.projects.some(p => p.title.toLowerCase().includes(searchText))))
                    return true;
                return emp.title.toLowerCase().includes(searchText);
            });
    }
    const employeeRows = filteredSalesDataEmployees.map(empdata =>
    {
        return generateEmpRow(monthIds, showAbsence, showUnbilableProjectTime, langStrings, searchText, props, empdata, actualMonth, actualMonthSource,
            trendSensitivity)
    });

    // region Generate Sum for each Month
    const sumMonthFields: { [key: string]: { value: JSX.Element; orderKey: number; }; } = {};
    monthIds.forEach(id =>
    {
        const employeesMonth = filterByMonthId(filteredSalesDataEmployees, id);
        const monthSource = employeesMonth.length ? employeesMonth[0].source : undefined;

        const shouldCalculateBooked = (actualMonth !== id || actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        const shouldCalculateForecast = (actualMonth !== id) || (actualMonthSource === 'both' || actualMonthSource === 'forecast');

        const sumUnbilableProjectTime = calculateSum(employeesMonth, proj => proj.bookedNotBillableHours);
        const sumBookedHours = shouldCalculateBooked ? calculateSum(employeesMonth, proj => proj.bookedHours) : 0;
        const sumBookedRevenue = shouldCalculateBooked ? calculateSum(employeesMonth, proj => proj.bookedRevenue) : 0;

        // const bookedMonth = employeesMonth.length > 0 ? employeesMonth[0].source === 'booking' : false;
        const sumPlannedHours = shouldCalculateForecast ? calculateSum(employeesMonth, proj => monthSource !== 'mixed' && proj.totalPlannedHours != null ? proj.totalPlannedHours : proj.plannedHours) : 0;
        const sumPlannedRevenue = shouldCalculateForecast ? calculateSum(employeesMonth, proj => monthSource !== 'mixed' && proj.totalPlannedRevenue != null ? proj.totalPlannedRevenue : proj.plannedRevenue) : 0;

        const sumAbsenceHours = showAbsence ? employeesMonth.reduce((sum, month) => sum + month.holidayHours, 0) : 0;

        const boxTitle = generateBoxTitleEmp(sumPlannedHours, langStrings, sumBookedHours, sumAbsenceHours, sumPlannedRevenue, sumBookedRevenue, sumUnbilableProjectTime);

        sumMonthFields['monthId' + id] = {
            orderKey: sumPlannedRevenue + sumBookedRevenue + 1,
            value: <SalesField
                title={boxTitle}
                source={monthSource}
                bookedHours={sumBookedHours}
                plannedHours={sumPlannedHours}
                bookedRevenue={sumBookedRevenue}
                plannedRevenue={sumPlannedRevenue}
                absenceHours={sumAbsenceHours}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={sumUnbilableProjectTime}
                showTrend
                trendSensitivity={trendSensitivity}
            />
        }
    });


    // Filtere Monate basierend auf den gegebenen monthIds
    const filteredMonths = filterByMonthId(filteredSalesDataEmployees, monthIds);

    // Berechnung der Summen unter Berücksichtigung von actualMonthSource und actualMonth
    const { sumPlannedHours, sumBookedHours, sumAbsenceHours, sumPlannedRevenue, sumBookedRevenue, sumUnbilableBookedTime } = calculateTotalSumsWithFilters();

    // Generieren des Box-Titels
    const boxTitle = generateBoxTitleEmp(sumPlannedHours, langStrings, sumBookedHours, sumAbsenceHours, sumPlannedRevenue, sumBookedRevenue, sumUnbilableBookedTime);

    // region Füge Summen Zeile hinzu
    employeeRows.push({
        //Totale Summe aller Emps und Monate
        sum: {
            orderKey: (sumBookedRevenue + sumPlannedRevenue) + 1,
            value: <SalesField
                title={boxTitle}
                bookedHours={sumBookedHours}
                plannedHours={sumPlannedHours}
                bookedRevenue={sumBookedRevenue}
                plannedRevenue={sumPlannedRevenue}
                absenceHours={sumAbsenceHours}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={sumUnbilableBookedTime}
                showTrend
                trendSensitivity={trendSensitivity}
            />
            // value: ((sumBookedHours !== 0 || sumPlannedHours !== 0 || sumBookedRevenue !== 0 || sumPlannedRevenue !== 0 || sumAbsenceHours !== 0)) ?
            //     <div
            //         title={boxTitle}>
            //         <div> {`${Math.round(sumBookedRevenue + sumPlannedRevenue).toLocaleString()}€`}</div>
            //         <div> {`${(Math.round((sumBookedHours - (showUnbilableProjectTime ? 0 : sumUnbilableBookedTime) + sumPlannedHours + sumAbsenceHours) * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
            //     </div> : <>
            //         -
            //     </>
        },
        expandable: {
            orderKey: 99999,
            value: <></>
        },
        ...sumMonthFields,
        id: -999,
        employee: {
            orderKey: 'TOP',
            value: <div className='nameColumn'>{langStrings.sum}</div>
        }
    });
    const renderMonthValue = (selected: string[]): string =>
    {
        if (selected.length > 3)
        {
            const ranges = [];
            let last: string | null = null;
            let rangeStart: string | null = null;

            selected.sort().forEach(month =>
            {
                if (!rangeStart)
                {
                    rangeStart = month;
                } else if (last && month !== getNextMonth(last))
                {
                    ranges.push(formatRange(rangeStart, last));
                    rangeStart = month;
                }
                last = month;
            });

            if (rangeStart && last)
            {
                ranges.push(formatRange(rangeStart, last));
            }

            return ranges.join(', ');
        }
        return selected.join(', ');
    };
    const createNewView = async () =>
    {
        openDialog(<AskForTextDialog
            id={'AskForTextDialog'}
            title={langStrings.enterViewTitle}
            message={langStrings.enterViewTitleHelper}
            defaultValue={langStrings.newView}
            resolve={async (title) =>
            {
                if (!title) return;
                const newView = await createOUSalesView({
                    id: findNextFreeId(views),
                    title,
                    showProjectsWithoutRevenue,
                    showProjectsWithRevenue,
                    showInternalProjects,
                    showExternalProjects,
                    showAbsence,
                    showUnbilableProjectTime,
                });
                setViews([...views, newView]);
                selectView(newView.id);
                setShowFilter(false);
            }}
        />)
    };
    function onCheckboxChanged()
    {
        setFilterChanged(true);
        selectView(null)
    }

    return <div className='salesContent'>
        {/* Here I want to insert MUI Chips for managing views */}
        <RowElement title={langStrings.views}>
            <ViewContainer
                views={views}
                selectedView={selectedView}
                modified={filterChanged}
                // setViews={setViews}
                showFilter={showFilter}
                setViews={setViews}
                setShowFilter={setShowFilter}
                createNewView={createNewView}
                setSelectedView={selectView}
            />
        </RowElement>
        <Row align='top'>
            {showFilter &&

                <RowElement title={langStrings.oftenUsed.projects}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showUnbilableProjectTime}
                                onChange={(e) =>
                                {
                                    setShowUnbilableProjectTime(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showUnbilableProjectTime}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showProjectsWithRevenue}
                                onChange={(e) =>
                                {
                                    setShowProjectsWithRevenue(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showProjectsWithRevenue}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showProjectsWithoutRevenue}
                                onChange={(e) =>
                                {
                                    setShowProjectsWithoutRevenue(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showProjectsWithoutRevenue}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showExternalProjects}
                                onChange={(e) =>
                                {
                                    setShowExternalProjects(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showExternalProjects}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showInternalProjects}
                                onChange={(e) =>
                                {
                                    setShowInternalProjects(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showInternalProjects}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ padding: '0px 9px' }}
                                checked={showAbsence}
                                onChange={(e) =>
                                {
                                    setShowAbsence(e.target.checked);
                                    onCheckboxChanged()
                                }}
                                color="primary"
                            />
                        }
                        label={langStrings.showAbsence}
                    />
                </RowElement>
            }
            <RowElement title={langStrings.actualMonthDataSource}>
                <div>
                    <Select
                        value={actualMonthSource}
                        onChange={(e) => setActualMonthSource(e.target.value as string)}
                        label={langStrings.actualMonthDataSource}
                    >
                        <MenuItem value="both">{langStrings.actualMonthCombined}</MenuItem>
                        <MenuItem value="timeRecording">{langStrings.actualMonthTimeRecording}</MenuItem>
                        <MenuItem value="forecast">{langStrings.actualMonthForecast}</MenuItem>
                    </Select>
                    <IconButton title={langStrings.actualMonthDialogTitle} onClick={() =>
                    {
                        return openDialog(<SalesContentMonthSourceInfoDialog id='SalesContentMonthSourceInfoDialog' />);
                    }}>
                        <Info />
                    </IconButton>
                </div>
            </RowElement>

            <RowElement title={langStrings.selectedMonths}>
                <Select
                    multiple
                    value={monthIds}
                    onChange={(e) => setMonthIds((e.target.value as string[]).sort((a, b) => a.localeCompare(b)))}
                    renderValue={renderMonthValue}
                >
                    {availableMonthIds.map((monthId) => (
                        <MenuItem key={monthId} value={monthId}>
                            {new Date(monthId).toLocaleString(undefined, monthOptions)}
                        </MenuItem>
                    ))}
                </Select>
            </RowElement>
            <RowElement title={langStrings.sensitivityBorder}>
                <div style={{
                    display: 'flex',
                    width: 200,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 15,
                }}>
                    <Slider
                        value={typeof trendSensitivity === 'number' ? trendSensitivity : 0}
                        onChange={(e, v) => { setTrendSensitivity(v as number) }}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        step={5}
                        marks
                        min={5}
                        max={35}
                        style={{
                            width: 180,
                        }}
                    />
                    <Typography variant='body2'>{trendSensitivity}%</Typography>
                </div>
            </RowElement>
        </Row>


        <RowElement title={langStrings.search} alignTitle={'left'}>
            <TextInput
                value={searchText}
                onChange={(value) => setSearchText(value.toLowerCase())}
                placeholder={langStrings.searchPlaceholder}
                size="small" />
        </RowElement>
        {
            (externalProjectWithoutRevenue.length > 0 && !hideProjectWarning) &&
            <ErrorBox
                warning={true}
                close={function (): void
                {
                    setHideProjectWarning(true);
                }}
            >
                <Typography variant='body1'>

                    {langStrings.projectWarningTitle.replace('[COUNT]', externalProjectWithoutRevenue.length.toString())}
                </Typography>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {
                        externalProjectWithoutRevenue.map(project =>
                        {
                            return <div
                                key={project.projectId}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <RoundIconButton
                                    icon={<Edit />}
                                    helperText={langStrings.edit} size={'small'}
                                    onClick={() =>
                                    {
                                        const oldProject = projects.find(p => p.id === project.projectId);
                                        getProject(project.projectId)
                                            .then((newVersion) =>
                                            {
                                                const project = newVersion ? newVersion : oldProject;
                                                return openDialog(
                                                    <ProjectDialog
                                                        id="ProjectDialog"
                                                        project={project}
                                                        resolve={(entity) =>
                                                        {
                                                            if (entity)
                                                            {
                                                                props.refresh()
                                                            }
                                                        }} />);
                                            });
                                    }}
                                />
                                <Typography variant='body2'>
                                    {project.title}
                                </Typography>
                            </div>
                        })
                    }
                </div>
            </ErrorBox>
        }
        <RevenueGraph
            monthIds={monthIds}
            monthOptions={monthOptions}
            salesData={salesData}
            monthSource={actualMonthSource}
        />
        <DataTable
            id={'sales'}
            noCheckbox={true}
            expandable={true}
            hideFooter={true}
            dense={true}
            rows={employeeRows}
            order='desc'
            orderBy='sum'
            fields={[
                {
                    id: 'employee',
                    label: langStrings.employee,
                    disablePadding: isDense(width),
                    width: 220,
                    align: 'left',
                    type: 'JSX.Element',
                },
                ...monthIds.map(id => ({
                    id: `monthId${id}`,
                    label: (new Date(id)).toLocaleString(undefined, monthOptions),
                    disablePadding: true,
                    align: 'center',
                    type: 'JSX.Element',
                }) as IDataTableField<'JSX.Element'>
                ),
                {
                    id: 'sum',
                    label: langStrings.sum,
                    disablePadding: isDense(width),
                    align: 'center',
                    type: 'JSX.Element',
                },
            ]}
            selected={[]}
            setSelected={() => { }} />
        <div style={{ marginTop: 10 }}>
            <SalesContentCsvExporter monthIds={monthIds} monthOptions={monthOptions} salesData={salesData} />
        </div>
    </div >;

    function calculateTotalSumsWithFilters()
    {
        const sumBookedHours = filteredMonths.reduce((total, month) =>
        {
            const isCurrentMonth = month.monthId === actualMonth;
            const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
            return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedHours) : 0);
        }, 0);

        const sumUnbilableBookedTime = filteredMonths.reduce((total, month) =>
        {
            const isCurrentMonth = month.monthId === actualMonth;
            const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
            return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedNotBillableHours) : 0);
        }, 0);

        const sumPlannedHours = filteredMonths.reduce((total, month) =>
        {
            const isCurrentMonth = month.monthId === actualMonth;
            const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
            return total + (calculatePlanned ? sumProjectValues(month.projects, proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedHours != null ? proj.totalPlannedHours : proj.plannedHours) : 0);
        }, 0);

        const sumBookedRevenue = filteredMonths.reduce((total, month) =>
        {
            const isCurrentMonth = month.monthId === actualMonth;
            const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
            return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedRevenue) : 0);
        }, 0);

        const sumPlannedRevenue = filteredMonths.reduce((total, month) =>
        {
            const isCurrentMonth = month.monthId === actualMonth;
            const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
            return total + (calculatePlanned ? sumProjectValues(month.projects, proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedRevenue != null ? proj.totalPlannedRevenue : proj.plannedRevenue) : 0);
        }, 0);

        // Berechnung der Abwesenheitsstunden
        const sumAbsenceHours = !showAbsence ? 0 : filteredMonths.reduce((prev, cur) => prev + cur.holidayHours, 0);
        return { sumPlannedHours, sumBookedHours, sumAbsenceHours, sumPlannedRevenue, sumBookedRevenue, sumUnbilableBookedTime };
    }
}





interface ISalesContentCsvExporterProps
{
    monthIds: string[];
    monthOptions: Intl.DateTimeFormatOptions;
    salesData: IOrgUnitSalesDataDTO;
}
const SalesContentCsvExporter: React.FC<ISalesContentCsvExporterProps> = ({ monthIds, monthOptions, salesData }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;
    const csvExportRows = () =>
    {
        return salesData.employees.flatMap(salesEmp => //TODO: ADD HOLIDAY + SUM + Reisekosten... + intern extern
        {
            const allEmpProjects: { [key: number]: string; } = {};
            salesEmp.months.forEach(m => m.projects.forEach(p => allEmpProjects[p.projectId] = p.title));
            return [
                [
                    salesEmp.id,
                    salesEmp.title,
                    langStrings.employee,
                    ...monthIds.flatMap(id =>
                    {
                        const month = salesEmp.months.find(m => m.monthId === id);
                        const hoursSum = month ? month.projects.map(p => p.bookedHours + p.plannedHours).reduce((current, sum) => sum + current, 0) : 0;
                        const revenueSum = month ? month.projects.map(p => p.bookedRevenue + p.plannedRevenue).reduce((current, sum) => sum + current, 0) : 0;
                        return [hoursSum, `${revenueSum.toLocaleString()}€`];
                    })
                ],
                ...Object.entries(allEmpProjects).map(([projectId, projectTitle]) =>
                {
                    if (!projectTitle) projectTitle = langStrings.unknown;
                    return [
                        salesEmp.id,
                        projectTitle,
                        langStrings.project,
                        ...monthIds.flatMap(id =>
                        {
                            const month = salesEmp.months.find(m => m.monthId === id);
                            const project = month?.projects.find(p => p.projectId === parseInt(projectId));
                            const hoursSum = project ? (project.bookedHours + project.plannedHours) : 0;
                            const revenueSum = project ? (project.bookedRevenue + project.plannedRevenue) : 0;
                            return [hoursSum, `${revenueSum.toLocaleString()}€`];
                        })
                    ];
                })
            ];
        });
    }

    return <CSVExporter
        filename={`SalesData-${(new Date()).toISOString()}.csv`}
        headers={[
            `${langStrings.employee}-ID`,
            `${langStrings.employee}/${langStrings.project}`,
            langStrings.type,
            ...monthIds.flatMap(id => [
                `${(new Date(id)).toLocaleString(undefined, monthOptions)} (${langStrings.hours})`,
                `${(new Date(id)).toLocaleString(undefined, monthOptions)} (${langStrings.revenue})`
            ])
        ]}
        rows={csvExportRows} />;
}


interface IViewContainerProps
{
    views: IOrgUnitSalesView[];
    selectedView: number | null;
    modified: boolean;
    // setViews: (views: string[]) => void;
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
    createNewView: () => void;
    setSelectedView: (view: number | null) => void;
    setViews: (views: IOrgUnitSalesView[]) => void;
}

function ViewContainer({ views, selectedView, modified, showFilter, setViews, setShowFilter, setSelectedView, createNewView }: IViewContainerProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).orgUnit;

    const permissions = useSelector((state: AppState) => state.permissions);

    return <div style={{
        display: 'flex',
        gap: 7,
        alignItems: 'center',
        flexWrap: 'wrap',
    }}>
        {views.map(view => <Chip
            key={view.id}
            label={view.title}
            color={view.id === selectedView ? 'primary' : 'secondary'}
            variant={view.id === selectedView ? 'filled' : 'outlined'}
            onClick={() =>
            {
                setSelectedView(view.id);
            }}
        />)}
        {!(showFilter && modified) &&
            <Chip
                label={langStrings.filter}
                color='default'
                variant={showFilter ? 'filled' : 'outlined'}
                icon={<FilterList fontSize='small' />}
                onClick={() =>
                {
                    setShowFilter(true);
                    // setSelectedView(null);
                }}
            />
        }
        {(permissions.admin && showFilter && modified) &&
            <Chip
                label={langStrings.saveView}
                color='primary'
                variant={'filled'}
                icon={<Save fontSize='small' />}
                onClick={async () =>
                {
                    createNewView();
                }}
            />
        }
        {permissions.admin &&
            <IconButton onClick={async () =>
            {
                openDialog(
                    <SalesContentViewSettings views={views} id={''} resolve={newViews => setViews(newViews)} />)
                // const newViews = await openSalesContentViewSettings(views);

            }}>
                <Settings />
            </IconButton>
        }
    </div>
}

/**
 * Generates the next month string based on the given month.
 * 
 * @param {string} month - The month to generate the next month for.
 * @returns {string} - The string representing the next month.
 */
function getNextMonth(month: string)
{
    let [year, mon] = month.split('-').map(Number);
    mon++;
    if (mon > 12)
    {
        mon = 1;
        year++;
    }
    return `${year}-${mon.toString().padStart(2, '0')}`;
}

/**
 * Formats a range from the start to the end.
 * 
 * @param {string} start - The start of the range.
 * @param {string} end - The end of the range.
 * @returns {string} The formatted range.
 */
function formatRange(start: string, end: string)
{
    if (start === end)
    {
        return start;
    }
    return `${start} - ${end}`;
}





/**
 * Generates a row for an employee in the sales data table.
 * 
 * @param monthIds - An array of month IDs.
 * @param showAbsence - A boolean indicating whether to show absence data.
 * @param langStrings - The language texts for organization units.
 * @param searchText - The search text.
 * @param props - The sales content props.
 * @param salesEmp - The sales data for the employee.
 * 
 * @returns An object representing a row in the sales data table.
 *          - sum: The total sales data for the employee.
 *            - orderKey: The order key for sorting.
 *            - value: The JSX element representing the value.
 *          - expandable: The expandable data for the employee.
 *            - orderKey: The order key for sorting.
 *            - value: The JSX element representing the value.
 *          - id: The ID of the employee.
 *          - employee: The data for the employee.
 *            - orderKey: The order key for sorting.
 *            - value: The JSX element representing the value.
 */
function generateEmpRow(monthIds: string[], showAbsence: boolean, showUnbilableProjectTime: boolean, langStrings: IOrgUnitTexts, searchText: string, props: SalesContentProps, salesEmp: IOUSalesDataEmployee, actualMonth: string, actualMonthSource: string, trendSensitivity: number)
{
    const monthFields: { [key: string]: { value: JSX.Element; orderKey: number; }; } = {};
    const filteredMonths = salesEmp.months.filter(m => monthIds.includes(m.monthId));
    monthIds.forEach(id =>
    {
        const month = filteredMonths.find(m => m.monthId === id);

        // Bedingungen basierend auf actualMonthSource
        const shouldCalculateBooked = (actualMonth !== id || actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        const shouldCalculatePlanned = (actualMonth !== id || actualMonthSource === 'both' || actualMonthSource === 'forecast');

        // Berechnungen unter Berücksichtigung von actualMonthSource
        const bookedHours = shouldCalculateBooked && month ? sumProjectValues(month.projects, p => p.bookedHours) : 0;
        const bookedUnbillableTimes = shouldCalculateBooked && month ? sumProjectValues(month.projects, p => p.bookedNotBillableHours) : 0;

        const bookedRevenue = shouldCalculateBooked && month ? sumProjectValues(month.projects, p => p.bookedRevenue) : 0;

        const plannedHours = shouldCalculatePlanned && month ? sumProjectValues(month.projects, p => month?.source !== 'mixed' && p.totalPlannedHours != null ? p.totalPlannedHours : p.plannedHours) : 0;
        const plannedRevenue = shouldCalculatePlanned && month ? sumProjectValues(month.projects, p => month?.source !== 'mixed' && p.totalPlannedRevenue != null ? p.totalPlannedRevenue : p.plannedRevenue) : 0;

        // Berechnung der Abwesenheitsstunden
        const holidayHoursSum = showAbsence && month ? month.holidayHours : 0;

        // const visiblePlannedHours = month?.source === 'booking' ? 0 : plannedHours;
        const visiblePlannedRevenue = month?.source === 'booking' ? 0 : plannedRevenue;
        const revenueSum = visiblePlannedRevenue + bookedRevenue;

        const boxTitle = generateBoxTitleEmp(plannedHours, langStrings, bookedHours, holidayHoursSum, plannedRevenue, bookedRevenue, bookedUnbillableTimes);

        monthFields['monthId' + id] = {
            orderKey: revenueSum,
            value: <SalesField
                title={boxTitle}
                source={month?.source}
                bookedHours={bookedHours}
                plannedHours={plannedHours}
                bookedRevenue={bookedRevenue}
                plannedRevenue={plannedRevenue}
                absenceHours={holidayHoursSum}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={bookedUnbillableTimes}
                showTrend
                trendSensitivity={trendSensitivity}
            />
            // value: (month && (hoursSum !== 0 || revenueSum !== 0)) ?
            //     <div className={`orgUnitPageMonthBox ${month.source} ${revenueSum > 0 ? '' : 'noRevenue'}`}
            //         title={boxTitle}>
            //         <div> {`${Math.round(revenueSum).toLocaleString()}€`}</div>
            //         <div> {`${(Math.round(hoursSum * 100) / 100).toLocaleString()}${langStrings.hoursShort}`}</div>
            //     </div> : <div className={`orgUnitPageMonthBox `}
            //         title={boxTitle}>-</div>
        };
    });
    const allEmpProjects: { [key: number]: string; } = {};
    filteredMonths.forEach(m => m.projects.forEach(p => allEmpProjects[p.projectId] = p.title));

    const empBookedHours = filteredMonths.reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedHours) : 0);
    }, 0);

    const empBookedUnbillableTimes = filteredMonths.reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedNotBillableHours) : 0);
    }, 0);

    const empPlannedHours = filteredMonths.reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
        return total + (calculatePlanned ? sumProjectValues(month.projects, proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedHours != null ? proj.totalPlannedHours : proj.plannedHours) : 0);
    }, 0);

    const empBookedRevenue = filteredMonths.reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculateBooked = !isCurrentMonth || (actualMonthSource === 'both' || actualMonthSource === 'timeRecording');
        return total + (calculateBooked ? sumProjectValues(month.projects, proj => proj.bookedRevenue) : 0);
    }, 0);

    const empPlannedRevenue = filteredMonths.reduce((total, month) =>
    {
        const isCurrentMonth = month.monthId === actualMonth;
        const calculatePlanned = (!isCurrentMonth && month.source !== 'booking') || (actualMonthSource === 'both' || actualMonthSource === 'forecast');
        return total + (calculatePlanned ? sumProjectValues(month.projects, proj => isCurrentMonth && actualMonthSource === 'forecast' && proj.totalPlannedRevenue != null ? proj.totalPlannedRevenue : proj.plannedRevenue) : 0);
    }, 0);

    // Berechnung der Abwesenheitsstunden
    const sumAbsenceHours = !showAbsence ? 0 : filteredMonths.reduce((total, month) => total + month.holidayHours, 0);

    // Generieren des Box-Titels
    const boxTitle = generateBoxTitleEmp(empPlannedHours, langStrings, empBookedHours, sumAbsenceHours, empPlannedRevenue, empBookedRevenue, empBookedUnbillableTimes);



    return {
        id: salesEmp.id,
        employee: {
            orderKey: salesEmp.title,
            value: <div className='nameColumn'>
                <EmployeeOverlay employeeId={salesEmp.id}>
                    {salesEmp.title}
                </EmployeeOverlay>
            </div>
        },
        ...monthFields,
        sum: {
            //Summe des Emps über alle Monate
            orderKey: empBookedRevenue + empPlannedRevenue,
            value: <SalesField
                title={boxTitle}
                bookedHours={empBookedHours}
                plannedHours={empPlannedHours}
                bookedRevenue={empBookedRevenue}
                plannedRevenue={empPlannedRevenue}
                absenceHours={sumAbsenceHours}
                showUnbilableProjectTime={showUnbilableProjectTime}
                unbilableProjectTime={empBookedUnbillableTimes}
                trendSensitivity={trendSensitivity}
            />
        },
        expandable: {
            orderKey: -1,
            value: <InnerDataTable
                salesEmp={salesEmp}
                allEmpProjects={allEmpProjects}
                monthIds={monthIds}
                searchText={searchText}
                showAbsence={showAbsence}
                showUnbilableProjectTime={showUnbilableProjectTime}
                actualMonth={actualMonth}
                actualMonthSource={actualMonthSource}
                refresh={props.refresh}
                trendSensitivity={trendSensitivity}
            />,
        },
    };
}

/**
 * Generates a title line.
 *
 * @param value - The value of the number.
 * @param labelKey - The key of the label.
 * @param suffix - The suffix string.
 * @param langStrings - The language texts for organization unit.
 * @returns The generated title line as a string.
 */
function generateTitleLine(value: number, labelKey: keyof IOrgUnitTexts, suffix: string, langStrings: IOrgUnitTexts)
{
    return value ? <>
        <tr>
            <td>
                <Typography noWrap variant='caption' lineHeight={0}>
                    {
                        `${langStrings[labelKey]}:`
                    }
                </Typography>
            </td>
            <td>
                <Typography noWrap variant='caption' lineHeight={0}>
                    {`${(Math.round(value * 100) / 100).toLocaleString()}${suffix}`}
                </Typography>
            </td>
        </tr>
    </>
        : undefined;
}
/**
 * Generates the title for the box representing an employee.
 * 
 * @param {number} empPlannedHours - The planned hours for the employee.
 * @param {IOrgUnitTexts} langStrings - The language texts for the organizational unit.
 * @param {number} empBookedHours - The booked hours for the employee.
 * @param {number} sumAbsenceHours - The total absence hours for the employee.
 * @param {number} empPlannedRevenue - The planned revenue for the employee.
 * @param {number} empBookedRevenue - The booked revenue for the employee.
 * 
 * @returns {string} The generated title for the employee box.
 */
function generateBoxTitleEmp(
    empPlannedHours: number,
    langStrings: IOrgUnitTexts,
    empBookedHours: number,
    sumAbsenceHours: number,
    empPlannedRevenue: number,
    empBookedRevenue: number,
    sumUnbilableProjectTime: number,
)
{
    return <table>
        <tbody>
            {generateTitleLine(empPlannedHours, 'plannedHours', langStrings.hoursShort, langStrings)}
            {generateTitleLine(empBookedHours, 'bookedHours', langStrings.hoursShort, langStrings)}
            {generateTitleLine(sumUnbilableProjectTime, 'unbilableProjectTime', langStrings.hoursShort, langStrings)}
            {generateTitleLine(sumAbsenceHours, 'absenceHours', langStrings.hoursShort, langStrings)}
            {generateTitleLine(empPlannedRevenue, 'plannedRevenue', '€', langStrings)}
            {generateTitleLine(empBookedRevenue, 'bookedRevenue', '€', langStrings)}
        </tbody>
    </table>
}
/**
 * Generates the title for a project box.
 * 
 * @param {number} projectPlannedHours - The planned hours for the project.
 * @param {IOrgUnitTexts} langStrings - The language strings for the organizational unit.
 * @param {number} projectBookedHours - The booked hours for the project.
 * @param {number} projectPlannedRevenue - The planned revenue for the project.
 * @param {number} projectBookedRevenue - The booked revenue for the project.
 * @return {string} The generated box title.
 */
export function generateBoxTitleProject(
    projectPlannedHours: number,
    langStrings: IOrgUnitTexts,
    projectBookedHours: number,
    projectPlannedRevenue: number,
    projectBookedRevenue: number,
    projectUnbilableTime: number,
)
{
    return <table>
        <tbody>
            {generateTitleLine(projectPlannedHours, 'plannedHours', langStrings.hoursShort, langStrings)}
            {generateTitleLine(projectBookedHours, 'bookedHours', langStrings.hoursShort, langStrings)}
            {generateTitleLine(projectUnbilableTime, 'unbilableProjectTime', langStrings.hoursShort, langStrings)}
            {generateTitleLine(projectPlannedRevenue, 'plannedRevenue', '€', langStrings)}
            {generateTitleLine(projectBookedRevenue, 'bookedRevenue', '€', langStrings)}
        </tbody>
    </table>
}
/**
 * Filters employees by month id.
 *
 * @param employees - List of employees of type IOUSalesDataEmployee[].
 * @param monthId - The month id of type string.
 * @returns - List of filtered employees of type IOUSalesDataEmployeeMonth[].
 */
export function filterByMonthId(employees: IOUSalesDataEmployee[], monthId: string | string[]): IOUSalesDataEmployeeMonth[]
{
    if (typeof monthId === 'string')
    {
        return employees.flatMap(emp => emp.months.filter(month => month.monthId === monthId));
    } else
    {
        return employees.flatMap(emp => emp.months.filter(month => monthId.includes(month.monthId)));
    }
}
/**
 * Calculates the sum of values selected from the given array of months.
 *
 * @param filteredMonths - Array of months to calculate the sum from.
 * @param valueSelector - Function to select the value to be summed from a project object.
 * @returns The sum of the selected values.
 */
export function calculateSum(filteredMonths: IOUSalesDataEmployeeMonth[], valueSelector: (project: IOUSalesDataEmployeeMonthProject) => number): number
{
    return filteredMonths.reduce((sum, month) =>
    {
        return sum + month.projects.reduce((projSum, project) => projSum + valueSelector(project), 0);
    }, 0);
}
/**
 * Calculates the sum of project values.
 * 
 * @param {IOUSalesDataEmployeeMonthProject[]} projects - The array of projects.
 * @param {function} valueExtractor - The function that extracts the value from a project.
 * @returns {number} The sum of project values.
 */
export function sumProjectValues(projects: IOUSalesDataEmployeeMonthProject[], valueExtractor: (project: IOUSalesDataEmployeeMonthProject) => number): number
{
    return projects.reduce((sum, project) => sum + valueExtractor(project), 0);
}



function findNextFreeId(views: IOrgUnitSalesView[]): number
{
    if (views.length === 0)
    {
        return 1; // Startet mit 1, wenn das Array leer ist
    }

    const sortedIds = views.map(view => view.id).sort((a, b) => a - b);
    let nextFreeId = sortedIds[0];

    for (let i = 1; i < sortedIds.length; i++)
    {
        // Wenn eine Lücke gefunden wird, return die nächste ID
        if (sortedIds[i] - sortedIds[i - 1] > 1)
        {
            nextFreeId = sortedIds[i - 1] + 1;
            return nextFreeId;
        }
    }

    // Keine Lücke gefunden, gib die nächsthöhere ID zurück
    return sortedIds[sortedIds.length - 1] + 1;
}