import { Action } from "redux";
import Business from "@src/Objects/Business";

const initialState: Business[] = [];

export enum EBusinessAction
{
    SET_BUSINESSS = "SET_BUSINESSS",
    DELETE_BUSINESS = "DELETE_BUSINESS",
    UPDATE_BUSINESS = "UPDATE_BUSINESS",
}

interface SetBusinessesAction extends Action
{
    type: EBusinessAction.SET_BUSINESSS;
    payload: Business[];
}

interface DeleteBusinessAction extends Action
{
    type: EBusinessAction.DELETE_BUSINESS;
    payload: number; // Assuming ID is a number
}

interface UpdateBusinessAction extends Action
{
    type: EBusinessAction.UPDATE_BUSINESS;
    payload: Business;
}

type BusinessAction = SetBusinessesAction | DeleteBusinessAction | UpdateBusinessAction;

export const businessReducer = (state = initialState, action: BusinessAction): Business[] =>
{
    switch (action.type)
    {
        case EBusinessAction.SET_BUSINESSS:
            return action.payload;
        case EBusinessAction.DELETE_BUSINESS:
            return state.filter(business => business.id !== action.payload);
        case EBusinessAction.UPDATE_BUSINESS:
            // eslint-disable-next-line no-case-declarations
            const index = state.findIndex(business => business.id === action.payload.id);
            if (index !== -1)
            {
                const newState = [...state];
                newState[index] = action.payload;
                return newState;
            } else
            {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

// Action Creators
export const setBusinessses = (businesss: Business[]): SetBusinessesAction => ({
    type: EBusinessAction.SET_BUSINESSS,
    payload: businesss,
});

export const deleteBusiness = (id: number): DeleteBusinessAction => ({
    type: EBusinessAction.DELETE_BUSINESS,
    payload: id,
});

export const updateBusiness = (business: Business): UpdateBusinessAction => ({
    type: EBusinessAction.UPDATE_BUSINESS,
    payload: business,
});
