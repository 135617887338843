import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermissions } from '@sharedInterfaces/IPermissions';
import { AppState } from '@store/store';
import getBusiness from '@src/APIs/graphQl/Business/getBusiness';
import BusinessPage from '@src/App/NewLayout/Pages/Businesses/BusinessPage/BusinessPage';

import Overlay from '../Overlay/Overlay';
import './BusinessOverlay.css';


interface BusinessOverlayProps
{
    businessId: number;
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * BusinessOverlay component.
 * 
 * @param {BusinessOverlayProps} props - The props for the BusinessOverlay component.
 * @returns {JSX.Element}
 */
function BusinessOverlay(props: BusinessOverlayProps)
{
    const business = useSelector((state: AppState) => state.businesses.find(b => b.id === props.businessId));
    const permissions = useSelector((state: AppState) => state.permissions);
    const isAllowed = checkPermissions('Companies', 'Retrieve', permissions) || checkPermissions('Companies', 'RetrieveOwn', permissions);
    const disabled = !isAllowed || props.disabled;

    return (
        <Overlay
            disabled={disabled}
            triggerItem={props.children}
            loaded={business !== null}
            onFirstOpen={() =>
            {
                getBusiness(props.businessId);
            }}>
            {business &&
                <BusinessPage
                    entity={business}
                    inDialog={true}
                />
            }
        </Overlay>
    );
}

export default BusinessOverlay;
