import React from 'react';
import { useSelector } from 'react-redux';
import { Add, Lock, Loop, Remove, Tune } from "@mui/icons-material";
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import CertificateOverlay from '@src/Components/Overlays/CertificateOverlay/CertificateOverlay';
import { useMapCertificateDialog } from '@src/Components/Dialogs/MapCertificateDialog/MapCertificateDialog';
import changeMapCertificate from '@src/APIs/graphQl/Certificate/changeMapCertificate';

import Item from '../Item/Item';
import ItemBadge from '../../Components/ItemBadge/ItemBadge';
import { IItemAction } from '../Item/ItemAction';

import styles from './CertificateItem.module.css';
interface CertificateItemProps
{
    certId: number;
    title: string;
    startDate?: Date
    endDate?: Date
    disableOverlay?: boolean
    lockedBy?: string;
    negative?: boolean
    onClick?: string | (() => void)
}

/**
 * Represents a certificate item.
 *
 * @param {CertificateItemProps} param - The parameters for the certificate item.
 * @param {string} param.title - The title of the certificate item.
 * @param {string} param.id - The ID of the certificate item.
 * @param {boolean} param.disableOverlay - Indicates if the overlay is disabled for the certificate item.
 * @param {string} param.lockedBy - The user who has locked the certificate item.
 * @param {Function} param.onClick - The function to be called when the certificate item is clicked.
 * 
 * @returns {JSX.Element} The certificate item as a JSX element.
 */
function CertificateItem({ title, certId: certId, disableOverlay, lockedBy, endDate, negative, onClick }: CertificateItemProps)
{
    const mapCertificateDialog = useMapCertificateDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const [updating, setUpdating] = React.useState("");
    const employee = useSelector((state: AppState) => state.employee);
    const own = employee.certificates.find(oc => oc.id === certId);

    let percent = 75;
    let active = true;
    if (endDate && endDate.getTime)
    {
        const now = new Date();
        const daysDifference = (endDate.getTime() - now.getTime()) / (24 * 60 * 60 * 1000); // Unterschied in Tagen
        if (daysDifference <= 0)
        {
            active = false;
            percent = 0;
        } else if (daysDifference >= 365)
        {
            percent = 75;
        } else
        {
            percent = 20 + 55 * (daysDifference / 365);
        }
    }

    if (negative)
    {
        percent = percent * -1;
    }

    const actions: IItemAction[] = []
    const laodingIcon = <Loop fontSize='small' />;

    if (certId)
    {
        if (own) //Nicht Edit Mode und ist im System bekannt Und Verknüpft mit Emp
        {
            actions.push({
                id: 'remove',
                icon: updating === 'remove' ? laodingIcon : <Remove fontSize='small' />,
                helper: langStrings.removeButtonHelper,
                text: langStrings.removeButtonTitle,
                onClick: () =>
                {
                    setUpdating('remove');
                    changeMapCertificate(certId, false, undefined, undefined)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
            actions.push({
                id: 'edit',
                icon: updating === 'edit' ? laodingIcon : <Tune fontSize='small' />,
                text: langStrings.editButtonTitle,
                helper: langStrings.editButtonHelper,
                onClick: () =>
                {
                    setUpdating('edit');
                    mapCertificateDialog(certId)
                        .finally(() =>
                        {
                            setUpdating('');
                        })
                }

            });
        } else
        {
            actions.push({
                id: 'add',
                icon: updating === 'add' ? laodingIcon : <Add fontSize='small' />,
                text: langStrings.addButtonTitle,
                helper: langStrings.addButtonHelper,
                onClick: () =>
                {
                    setUpdating('add');
                    mapCertificateDialog(certId)
                        .finally(() =>
                        {
                            setUpdating('');
                        });
                }
            });
        }
    }

    return <Item
        percent={percent}
        onClick={onClick}
        actions={actions}
        leftContent={
            <ItemBadge value={
                active ?
                    langStrings.oftenUsed.active :
                    langStrings.oftenUsed.inactive
            } title={langStrings.oftenUsed.active + (endDate && endDate.toLocaleDateString ? `, ${langStrings.endDate}: ${endDate.toLocaleDateString()}` : '')} />
        }
        rightContent={<>
            {(lockedBy) &&
                <div className={styles.itemLocked} title={`${langStrings.oftenUsed.lockedBy}: "${lockedBy}"`}>
                    <Lock fontSize="small" />
                </div>
            }
        </>}
    >
        <CertificateOverlay key={certId} disabled={disableOverlay} certificateId={certId}>
            {title}
        </CertificateOverlay>
    </Item>
}
export default CertificateItem;