import { Done } from '@mui/icons-material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { IStyleDTO } from '@sharedInterfaces/ICompanySettings';
import { setCompanyStyle } from '@store/reducer/companyReducer';
import Button from '@sharedReact/General/Button/Button';
import setStyle from '@src/APIs/graphQl/Company/saveStyle';

import CardBox from '../../CardBox/CardBox';

import './StyleSettings.css';

interface StyleSettingsProps
{
    style: IStyleDTO
    setStyle: (val: IStyleDTO) => void
}

/**
 * StyleSettings function.
 *
 * @param {StyleSettingsProps} props - The props for the StyleSettings.
 * @returns {JSX.Element} The JSX element representing the StyleSettings.
 */
function StyleSettings(props: StyleSettingsProps)
{
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;
    const [changed, setChanged] = React.useState(false as boolean);
    const [colorPrimary, setColorPrimary] = React.useState(props.style['primary'] as string);
    const [colorOnPrimary, setColorOnPrimary] = React.useState(props.style['onPrimary'] as string);
    const [colorSecondary, setColorSecondary] = React.useState(props.style['secondary'] as string);
    const [colorOnSecondary, setColorOnSecondary] = React.useState(props.style['onSecondary'] as string);


    React.useEffect(() =>
    {
        setColorPrimary(props.style['primary'])
        setColorOnPrimary(props.style['onPrimary'])
        setColorSecondary(props.style['secondary'])
        setColorOnSecondary(props.style['onSecondary'])
    }, [props.style]);
    React.useEffect(() =>
    {
        const wrapper = window.document.querySelector('.wrapper') as HTMLDivElement
        const header = window.document.querySelector('.header') as HTMLDivElement
        wrapper.style.setProperty('--var-primary-color', colorPrimary);
        header.style.setProperty('--var-primary-color', colorPrimary);
        wrapper.style.setProperty('--var-on-primary-color', colorOnPrimary);
        header.style.setProperty('--var-on-primary-color', colorOnPrimary);

        wrapper.style.setProperty('--var-secondary-color', colorSecondary);
        header.style.setProperty('--var-secondary-color', colorSecondary);
        wrapper.style.setProperty('--var-on-secondary-color', colorOnSecondary);
        header.style.setProperty('--var-on-secondary-color', colorOnSecondary);

    }, [colorOnPrimary, colorOnSecondary, colorPrimary, colorSecondary]);

    return (
        <CardBox title={langStrings.styleTitle}>
            <div className='styleSettings'>
                <table cellSpacing={0} cellPadding={0}>
                    <tbody>
                        <tr>
                            <td>
                                <span>{langStrings.primaryColor}: </span>
                            </td>
                            <td className='styleLine'>
                                <input type="color" id="primary" name="primary"
                                    value={colorPrimary}
                                    onChange={(e) => { setColorPrimary(e.currentTarget.value); setChanged(true) }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>{langStrings.onPrimaryColor}: </span>
                            </td>
                            <td className='styleLine'>
                                <input type="color" id="onprimary" name="onprimary"
                                    value={colorOnPrimary}
                                    onChange={(e) => { setColorOnPrimary(e.currentTarget.value); setChanged(true) }}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span>{langStrings.secondaryColor}: </span>
                            </td>
                            <td className='styleLine'>
                                <input type="color" id="secondary" name="secondary"
                                    value={colorSecondary}
                                    onChange={(e) => { setColorSecondary(e.currentTarget.value); setChanged(true) }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>{langStrings.onSecondaryColor}: </span>
                            </td>
                            <td className='styleLine'>
                                <input type="color" id="onsecondary" name="onsecondary"
                                    value={colorOnSecondary}
                                    onChange={(e) => { setColorOnSecondary(e.currentTarget.value); setChanged(true) }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {changed ?
                                    <Button size={'normal'}
                                        text={langStrings.save}
                                        icon={<Done />}
                                        onClick={function (): void
                                        {
                                            const newStyle: IStyleDTO = {
                                                primary: colorPrimary,
                                                onPrimary: colorOnPrimary,
                                                secondary: colorSecondary,
                                                onSecondary: colorOnSecondary
                                            };
                                            setStyle(newStyle).then(newStyle =>
                                            {
                                                props.setStyle(newStyle);
                                                dispatch(setCompanyStyle(newStyle))
                                                // window.localStorage.setItem('style', JSON.stringify(newStyle));
                                                setChanged(false);
                                            });
                                        }}
                                        disabled={!changed} />
                                    :
                                    ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </CardBox>
    )
}

export default StyleSettings;
