import { OperationVariables, gql } from "@apollo/client";

import GraphQL from "../graphQL";

const query = gql`
mutation companyEmailSettingsSendMail($test: Boolean!, $forecastText: String) {
    companyEmailSettingsSendMail(test: $test, forecastText: $forecastText) 
},
`;


export default async function companyEmailSettingsSendMail(test: boolean, forecastText?: string)
{
    const variables: OperationVariables = {
        test,
        forecastText,
    };
    return GraphQL.mutate<boolean>(query, variables)
}