import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

import styles from './ItemBadge.module.css';
interface ItemBadgeProps
{
    value: string | number | JSX.Element
    title: string
    style?: React.CSSProperties
    size?: 'small' | 'medium'
}

/**
 * Represents an item badge.
 *
 * @param {ItemBadgeProps} value - The value of the item badge.
 * @param {ItemBadgeProps} title - The title of the item badge.
 * @param {ItemBadgeProps} size - The size of the item badge.
 * 
 * @returns {JSX.Element} The rendered item badge component.
 */
export default function ItemBadge({ value, title, size, style }: ItemBadgeProps)
{
    const classes = [styles.itemBadge,];
    if (size === 'small') classes.push(styles.small)

    return (
        <div
            className={classes.join(' ')}
            style={style}
            title={(title)}
        >
            {value}
        </div>
    )
}


export function NewBadge()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout;
    return <ItemBadge value={langStrings.newBadgeTitle} title={langStrings.newBadgeHelper} style={{ marginBottom: 10 }} />
}