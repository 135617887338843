import React from 'react';
import './SkillsLevelCompare.css'
import { ISkillLevelCompare } from '@sharedInterfaces/IOpportunity';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';

import SkillItem from '../../App/NewLayout/Items/SkillItem/SkillItem';
interface SkillsLevelCompareProps
{
    skills: ISkillLevelCompare[]
    inDialog?: true
    employeeId?: number;
    size: 'small' | 'medium'
}
/**
 * Compares the skill levels of two players.
 * 
 * @param {SkillsLevelCompareProps} props - The props for SkillsLevelCompare
 * 
 * @returns {JSX.Element} The JSX element representing the comparison result.
 */
function SkillsLevelCompare(props: SkillsLevelCompareProps)
{
    const skills = props.skills.map(skill =>
    {
        const withoutLink = props.inDialog || skill.id === undefined;

        return <SkillItem
            key={skill.title + skill.id}
            disableOverlay={withoutLink}
            title={skill.title}
            id={skill.id} level={skill.level}
            wantedLevel={skill.wantedLevel}
            employeeId={props.employeeId}
            onClick={(!props.inDialog && skill.id) ?
                entityTypeToLink(skill.id, EEntityType.SKILL)
                : undefined
            }
        />;
    }
    );

    return (
        <div className={'SkillsLevelCompare ' + (props.size)} style={{ border: props.inDialog ? 'none' : '' }} >
            {
                skills
            }
        </div>
    );
}

export default SkillsLevelCompare;