import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import CardBox from '@src/Components/CardBox/CardBox';
import './CertificateCard.css';
import Certificate from '@src/Objects/Certificate';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { CertificateDialog } from '@src/Components/Dialogs/CertificateDialog/CertificateDialog';

interface CertificateCardProps
{
    certificate: Certificate
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * CertificateCard component.
 *
 * @param {CertificateCardProps} props - The props for the CertificateCard component.
 *
 * @returns {JSX.Element} The rendered CertificateCard component.
 */
function CertificateCard(props: CertificateCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).certificates;
    const navigate = useNavigate();
    const certificate = props.certificate;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={() =>
                        {
                            return openDialog(
                                <CertificateDialog
                                    id="CertificateDialog"
                                    certificate={certificate}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            certificate.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={certificate.title}
            className={'certificateCard'}
            onClick={() =>
            {
                navigate(`${certificate.link}`)
            }}
        >
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={certificate.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default CertificateCard;
