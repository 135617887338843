import React from 'react';
// import './CompetenceSearchBox.css';
import { ISmallCompetence } from '@sharedInterfaces/IWhoIAm';
import { AppState } from '@store/store';

import EntitySearchBox from '../EntitySearchBox/EntitySearchBox';
interface CompetenceSearchBoxProps
{
    filteredAllCompetences: ISmallCompetence[]
    newCompetence: IListElement
    allowNew?: true
    size?: 'medium' | 'small' | 'verysmall'
    clearOnSelect?: true
    setNewCompetence: (newCompetence: IListElement) => void;
}
interface IListElement
{
    title: string,
    id: number
    // otherNames: string[]
}



/**
 * CompetenceSearchBox component.
 * 
 * @param {CompetenceSearchBoxProps} props - The props for the CompetenceSearchBox component.
 * @returns {JSX.Element} The rendered CompetenceSearchBox component.
 */
const CompetenceSearchBox = (props: CompetenceSearchBoxProps) =>
{
    return <EntitySearchBox
        filteredItems={props.filteredAllCompetences}
        newItem={props.newCompetence}
        allowNew={props.allowNew}
        size={props.size}
        clearOnSelect={props.clearOnSelect}
        setNewItem={props.setNewCompetence}
        allItemsSelector={(state: AppState) => state.company.allCompetences}
    />
};

export default CompetenceSearchBox;
