import { AutoFixHigh, Clear, Done } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, CircularProgress } from '@mui/material';
import { cloneDeep } from '@apollo/client/utilities';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import IRoleDTO, { IRoleCertificateMaybe, IRoleCompetence, IRoleSkill, IRoleSkillMaybe, TRoleRequestingState } from '@sharedInterfaces/IRole';
import { updateRole } from '@store/reducer/rolesReducer';
import { AppState } from '@store/store';
import { setEmployeeRoles } from '@store/reducer/employeeReducer';
import { setCompanySmallRoles } from '@store/reducer/companyReducer';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import Button from '@sharedReact/General/Button/Button';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';
import Role from '@src/Objects/Role';
import { EWebsocketTopics } from '@sharedInterfaces/globalEnums';
import { WebSocketClient } from '@src/APIs/WebSockets/WebSocketClient';
import { IRoleMetadataResponse } from '@sharedInterfaces/websocket/IRoleMetadata';
import createRole from '@src/APIs/graphQl/Role/createRole';
import editRole from '@src/APIs/graphQl/Role/editRole';

import ErrorBox from '../../ErrorBox/ErrorBox';
import './RoleDialog.css';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import MultiTextChipSelect from '../../formsControls/inputs/MultiTextChipSelect/MultiTextChipSelect';
import Row from '../../../sharedReact/General/Forms/Row/Row';
import FormatedTextInput from '../../formsControls/inputs/FormatedTextInput/FormatedTextInput';
import CompetencesInput from '../../formsControls/inputs/CompetencesInput/CompetencesInput';
import SkillsInput from '../../formsControls/inputs/SkillsInput/SkillsInput';
import CertificatesInput from '../../formsControls/inputs/CertificatesInput/CertificatesInput';

interface RoleDialogProps
{
    id: string
    role?: Role
    resolve?: (val: Role | null) => void;
}

/**
 * React component representing a role dialog.
 * 
 * @param props - The props for the role dialog.
 * @returns JSX element representing the role dialog.
 */
export function RoleDialog({ id, role, resolve }: RoleDialogProps)
{
    const showErrorDialog = useErrorDialog();
    const dispatch = useDispatch();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).roles;

    const allRoles = useSelector((state: AppState) => state.company.smallRoles);
    const emp = useSelector((state: AppState) => state.employee);
    const allSkills = useSelector((state: AppState) => state.company.allSkills);
    const allCertificates = useSelector((state: AppState) => state.company.allCertificates);

    const [errorText, setErrorText] = React.useState("" as string);
    const [saving, setSaving] = React.useState(false as boolean);

    const [roleName, setRoleName] = React.useState(role ? role.title : '' as string);
    const [roleNameSuggestion, setRoleNameSuggestion] = React.useState<string>("");
    const [roleNameError, setRoleNameError] = React.useState<string | undefined>(undefined);

    const [description, setDescription] = React.useState(role ? role.description : '' as string);
    const [otherNames, setOtherNames] = React.useState<string[]>(role ? role.otherNames : []);

    const [competences, setCompetences] = React.useState<IRoleCompetence[]>((role ? role.competences : []));
    const [skills, setSkills] = React.useState<IRoleSkillMaybe[]>((role ? role.skills : []));
    const [certificates, setCertificates] = React.useState<IRoleCertificateMaybe[]>((role ? role.certificates : []));

    const [generatingMetadata, setGeneratingMetadata] = React.useState<boolean>(false);

    // const [products, setProducts] = React.useState((role ? role.products : [] as { title: string, id?: number }[]));
    // const [employees, setEmployees] = React.useState((role ? role.employees : [] as IAllEmployee[]));

    const onFetchError = (ex: { toString(): string }) =>
    {
        const error = ex.toString();
        setErrorText(error);
        setTimeout(() =>
        {
            setSaving(false);
        }, 3000);
    }
    const onFetch = (newRole: IRoleDTO) =>
    {
        if (!emp) return;
        const newEntity = new Role(newRole);
        const newAllRoles = [...allRoles];
        const newArray = [...emp.roles];
        if (!role) // CREATE
        {
            newAllRoles.push({
                id: newRole.id, title: newRole.title, otherNames,
                version: 0,
            });
            // newArray.push(newRole);
        }
        else //MODIFY
        {
            let index = newAllRoles.findIndex(e => newEntity.id === e.id);
            newAllRoles[index] = { ...newAllRoles[index], title: newEntity.title };
            index = newArray.findIndex(e => newEntity.id === e.id);
            if (index > -1)
                newArray[index] = newEntity;
        }
        dispatch(setEmployeeRoles(newArray))
        dispatch(setCompanySmallRoles(newAllRoles))
        dispatch(updateRole(newEntity))
        resolve && resolve(newEntity);
        setSaving(false);
    }

    const onSave = async () =>
    {
        setSaving(true);
        if (roleName === "")
        {
            setErrorText(langStrings.newRoleError);
            setTimeout(() =>
            {
                setSaving(false);
            }, 1500);
            return;
        }
        if (!role)
        {
            await createRole(
                {
                    title: roleName,
                    description,
                    otherNames,
                    competences,
                    skills,
                    certificates
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        } else
        {
            await editRole(
                role.id,
                {
                    title: roleName,
                    description,
                    otherNames,
                    competences,
                    skills,
                    certificates
                }
            )
                .then(onFetch)
                .catch(onFetchError)
        }

    };
    const onClose = () =>
    {
        resolve && resolve(null);
    }

    const existingRoles = allRoles.filter(a => !role || role.id !== a.id).map(s => s.title.toLowerCase());

    const [subKey, setSubKey] = React.useState<string>("");
    const topic = EWebsocketTopics.generateRoleMetadata;
    const startAnalyse = () =>
    {
        const request: TRoleRequestingState = {
            all: true,
        };
        WebSocketClient.sendMessage(topic, {
            roleName,
            key: subKey,
            request
        });
        setGeneratingMetadata(true)
    }
    React.useEffect(() =>
    {
        const subKey = WebSocketClient.subscripe<IRoleMetadataResponse>(topic, (data) =>
        {
            if (subKey !== data.key) return;
            if (data.error)
            {
                showErrorDialog(new Error(data.error))
                setGeneratingMetadata(false);
                return;
            }
            const metaData = data.metadata;

            if (!metaData)
            {
                showErrorDialog(new Error(langStrings.error))
                setGeneratingMetadata(false);
                return;
            }
            setGeneratingMetadata(false)
            if (metaData.description && (!description || description === ""))
                setDescription(metaData.description);
            if (metaData.synonyms && (!otherNames || otherNames.length === 0))
                setOtherNames(metaData.synonyms.map(n => n.toLowerCase()));
            if (metaData.roleName && (roleName !== metaData.roleName))
            {
                setRoleNameSuggestion(metaData.roleName);
            }
            if (metaData.competences.length && !competences.length)
            {
                setCompetences(metaData.competences);
            }
            if (metaData.skills.length && !skills.length)
            {
                setSkills(metaData.skills);
            }
            if (metaData.certificates.length && !certificates.length)
            {
                setCertificates(metaData.certificates);
            }
        });
        setSubKey(subKey);
        return () =>
        {
            return WebSocketClient.unsubscripe(subKey)
        }
    }, [])

    const validate = () =>
    {
        if (roleName === "")
        {
            setRoleNameError(
                langStrings.pleaseEnterTitle
            )
        } else if (existingRoles.includes(roleName.toLowerCase()))
        {
            setRoleNameError(
                langStrings.errorDuplicateTitle
                    .replace("[TITLE]", roleName)
                    .replace("[TITLE]", roleName)
            )
        }
        else
        {
            setRoleNameError(undefined);
        }
    }

    useEffect(validate, [roleName])

    return (
        <Dialog
            id={id}
            title={role ? role.title : langStrings.newRole}
            onClose={onClose}
            footer={
                <div style={{ float: 'right' }}>
                    <Button
                        icon={<Done />}
                        disabled={saving}
                        text={!role ? langStrings.create : langStrings.save}
                        size={'normal'}
                        onClick={onSave}
                    />
                </div>
            }
        >
            <div className="roleDialog">
                <div className="newRole">
                    <Row>
                        <RowElement title={langStrings.roleName} alignTitle="left">
                            <TextInput
                                value={roleName}
                                onChange={setRoleName}
                                helperText={roleNameError}
                                isAI
                            />
                        </RowElement>
                        <IconButton
                            title={langStrings.generateRoleData}
                            disabled={!!roleNameError || generatingMetadata}
                            onClick={startAnalyse}
                        >
                            {generatingMetadata ? <CircularProgress size={24} color='secondary' /> : <AutoFixHigh />}
                        </IconButton>
                    </Row>
                    {
                        roleNameSuggestion !== "" &&
                        <div>
                            {langStrings.suggestedRoleName}: {roleNameSuggestion}
                            <IconButton
                                onClick={() =>
                                {
                                    setRoleName(roleNameSuggestion);
                                    setRoleNameSuggestion("");
                                }}
                            >
                                <Done />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                {
                                    setRoleNameSuggestion("");
                                }}
                            >
                                <Clear />
                            </IconButton>
                        </div>
                    }
                    <RowElement title={langStrings.description} alignTitle="left">
                        <FormatedTextInput value={description} onChange={setDescription} />
                    </RowElement>
                    <RowElement title={langStrings.oftenUsed.competences} alignTitle="left">
                        <CompetencesInput showMode={false} size='medium' competences={cloneDeep(competences)}
                            onChange={competences => setCompetences(competences)} />
                    </RowElement>
                    <RowElement title={langStrings.oftenUsed.skills} alignTitle="left">
                        <SkillsInput showMode={false} allowNewSkills={true} size='medium' skills={skills} allSkills={allSkills}
                            competences={competences}
                            onChange={skills => setSkills(skills as IRoleSkill[])}
                        />
                    </RowElement>
                    <RowElement title={langStrings.oftenUsed.certificates} alignTitle="left">
                        <CertificatesInput allCertificates={allCertificates} selectedCertificates={certificates}
                            showMode={false} size='medium'
                            competences={competences}
                            onChange={setCertificates}
                        />
                    </RowElement>
                    <RowElement title={langStrings.otherNames} alignTitle="left">
                        <MultiTextChipSelect value={otherNames}
                            onChange={(names) =>
                            {
                                setErrorText("");
                                const newOtherNames = Array.from(new Set(names.map(n => n.toLowerCase())))
                                    .filter(newON =>
                                    {
                                        const existingSkill = allRoles.find(a => a.title.toLocaleLowerCase() === newON && (!role || a.id !== role.id)) ||
                                            allRoles.find(a => a.otherNames.find(o => o === newON) !== undefined && (!role || a.id !== role.id));
                                        if (existingSkill)
                                        {
                                            const errorTitle = existingSkill.title.toLocaleLowerCase() === newON;
                                            const errorString = (errorTitle ? langStrings.errorDuplicateTitle : langStrings.errorDuplicateOtherName)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[TITLE]", existingSkill.title)
                                                .replace("[VALUE]", newON);
                                            setErrorText(errorString);
                                        }
                                        return !existingSkill;
                                    });
                                setOtherNames(newOtherNames)
                            }}
                        />
                    </RowElement>
                </div>
                {errorText && <ErrorBox close={() => setErrorText("")}> {errorText}</ErrorBox>}
            </div>
        </Dialog>
    );
}