import { useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import SkillDetail from '@src/Objects/SkillDetail';
import CardBox from '@src/Components/CardBox/CardBox';
import './SkillCard.css';
import ExpandableText from '@src/Components/ExpandableText/ExpandableText';
import FormatedTextInput from '@src/Components/formsControls/inputs/FormatedTextInput/FormatedTextInput';
import { SkillDialog } from '@src/Components/Dialogs/SkillDialog/SkillDialog';

interface SkillCardProps
{
    skill: SkillDetail
    selected?: boolean
    onSelectChange?: (state: boolean) => void
}

/**
 * SkillCard component.
 *
 * @param {SkillCardProps} props - The props for the SkillCard.
 * @returns {JSX.Element} The SkillCard JSX element.
 */
function SkillCard(props: SkillCardProps)
{
    const { openDialog } = useContext(DialogManagerContext);
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const navigate = useNavigate();
    const skill = props.skill;
    return (
        <CardBox
            menu={
                <div>
                    <MenuItem
                        onClick={async () =>
                        {
                            return openDialog(
                                <SkillDialog
                                    id='skillDialog'
                                    skill={skill}
                                />);
                        }}
                    >
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{langStrings.edit}</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                        {
                            skill.delete()
                        }}
                    >
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{langStrings.delete}</ListItemText>
                    </MenuItem>
                </div>
            }
            onSelectChange={props.onSelectChange}
            selected={props.selected}
            title={skill.title}
            className={'skillCard'}
            onClick={() =>
            {
                navigate(skill.link)
            }}
        >
            <ExpandableText>
                <>
                    {langStrings.description + ": "}
                    <FormatedTextInput value={skill.description} readonly={true} />
                </>
            </ExpandableText>
        </CardBox>
    );
}

export default SkillCard;