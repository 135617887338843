import React from 'react';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { entityTypeToLink, EEntityType } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import CardBox from '@src/Components/CardBox/CardBox';
import ProjectOverlay from '@src/Components/Overlays/ProjectOverlay/ProjectOverlay';
import Item from '@src/App/NewLayout/Items/Item/Item';
import List, { generateTopXText } from '@src/App/NewLayout/Lists/List/List';

interface ProjectsCardProps
{
    title?: string;
    projects: {
        id: number
        title: string,
        level?: number
    }[];
    inOverlay?: boolean
    limit?: number
}

/**
 * Render a project card with given title, projects, and overlay indicator.
 * 
 * @param {ProjectsCardProps} title - The title of the projects card.
 * @param {ProjectsCardProps} projects - The projects to be displayed in the card.
 * @param {ProjectsCardProps} inOverlay - The indicator if the card is in overlay mode.
 * 
 * @returns {JSX.Element} The rendered project card.
 */
function ProjectsCard({ limit, title, projects, inOverlay, }: ProjectsCardProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).projects;
    const newTitle = generateTopXText(title ? title : langStrings.projects, limit);
    const limitedElements = limit ? projects
        .slice(0, limit > projects.length ? projects.length : limit) : projects;
    const content = limitedElements.map((project) =>
        <Item key={project.id} percent={0} onClick={entityTypeToLink(project.id, EEntityType.PROJECT)}>
            <ProjectOverlay key={project.id} disabled={inOverlay} projectId={project.id}>
                {project.title}
            </ProjectOverlay>
        </Item>
    );
    return (
        <CardBox title={newTitle}>
            <List>
                {content}
            </List>
        </CardBox>
    );
}

export default ProjectsCard;