import './AddSkillButton.css';
import React from 'react';
import { Add, AirplanemodeActive, Loop, Remove, Tune } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import changeMapSkill from '@src/APIs/graphQl/Skill/changeMapSkill';
import { useErrorDialog } from '@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog';

import RoundIconButton from '../RoundIconButton/RoundIconButton';
import { useMapSkillDialog } from '../../Dialogs/MapSkillDialog/MapSkillDialog';

interface AddSkillButtonProps
{
    id: number
    title: string
    size: 'small' | 'medium' | 'verysmall'
}

/**
 * AddSkillButton component.
 *
 * @param {AddSkillButtonProps} - The props object for the AddSkillButton component.
 *   - {number} id - The id of the skill button.
 *   - {string} title - The title of the skill button.
 *   - {string} size - The size of the skill button.
 *
 * @returns {JSX.Element} - The JSX element representing the AddSkillButton component.
 */
function AddSkillButton({ id, title, size }: AddSkillButtonProps)
{
    const mapSkillDialog = useMapSkillDialog();
    const showErrorDialog = useErrorDialog();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).skills;
    const employee = useSelector((state: AppState) => state.employee);
    const offline = useSelector((state: AppState) => state.client.offline);

    const [saving, setSaving] = React.useState(false as boolean);

    const remove = () =>
    {
        if (saving || !employee) return;
        setSaving(true);
        changeMapSkill(id, false)
            .then(() =>
            {
                setSaving(false);
            })
            .catch(async (ex) =>
            {
                console.log(ex)
                await showErrorDialog(ex, langStrings.errorRemove)
                setSaving(false);
            })
    }

    if (!employee || !title || id === -1)
        return null;

    const own = employee.skills.find(oc => oc.id === id);
    if (saving)
    {
        return <Loop fontSize={size === 'verysmall' ? 'small' : 'medium'} />;
    }

    return (
        <>
            {
                offline ?
                    <AirplanemodeActive />
                    :
                    (
                        own ?
                            <div className={`addSkillButton removeButton ${size}`}>
                                <RoundIconButton
                                    size={size}
                                    helperText={langStrings.removeButtonHelper}
                                    onClick={() =>
                                    {
                                        remove();

                                    }}
                                    icon={<Remove fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                />
                                <RoundIconButton
                                    size={size}
                                    helperText={langStrings.editButtonHelper}
                                    onClick={mapSkillDialog.bind(null, id)}
                                    icon={<Tune fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                />
                            </div>
                            :
                            <>
                                <RoundIconButton
                                    size={size}
                                    helperText={langStrings.addButtonHelper}
                                    onClick={() =>
                                    {
                                        mapSkillDialog(id);
                                        //add();
                                    }}
                                    icon={<Add fontSize={size === 'verysmall' ? 'small' : 'medium'} />}
                                />
                            </>
                    )
            }
        </>
    )
}

export default AddSkillButton;