import { IRestDocuTexts } from "@sharedInterfaces/Language/languageTexts/restDocu";

import { ApiEndpoint, ApiObject } from "./RestApiDocuPage";
import { endpointRequestCreate } from "./endpoints/RequestCreate";
import { endpointRequestGet } from "./endpoints/RequestGet";

export function generateTexts(langStrings: IRestDocuTexts)
{


    const apiEndpoints: ApiEndpoint[] = [
        endpointRequestCreate(langStrings),
        endpointRequestGet(langStrings),
    ];
    const apiObjects: ApiObject[] = [
        {
            title: 'AnalyseObject',
            description: langStrings.endpointCreateRequestAnalyseObject,
            parameter: [
                {
                    name: 'active',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseObjectActive
                },
                {
                    name: 'title',
                    required: langStrings.endpointCreateRequestAnalyseObjectTitleRequired,
                    type: 'string',
                    description: langStrings.endpointCreateRequestAnalyseObjectTitle,
                },
                {
                    name: 'location',
                    required: false,
                    type: 'string',
                    description: langStrings.endpointCreateRequestAnalyseObjectLoc
                },
                {
                    name: 'customer',
                    required: false,
                    type: 'string',
                    description: langStrings.endpointCreateRequestAnalyseObjectCustomer
                },
                {
                    name: 'partner',
                    required: false,
                    type: 'string',
                    description: langStrings.endpointCreateRequestAnalyseObjectPartner
                },
                {
                    name: 'requestText',
                    required: langStrings.endpointCreateRequestAnalyseObjectTitleRequired,
                    type: 'string',
                    description: langStrings.endpointCreateRequestAnalyseObjectRequestText,
                },
                {
                    name: 'dayPrice',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectDayPrice
                },
                {
                    name: 'utilization',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectUtilization
                },
                {
                    name: 'remote',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectRemote
                },
                {
                    name: 'headCount',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectHeadCount
                },
                {
                    name: 'projectStart',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectProjectStart
                },
                {
                    name: 'projectEnd',
                    required: false,
                    type: 'number',
                    description: langStrings.endpointCreateRequestAnalyseObjectProjectEnd
                },
            ]
        },
        {
            title: 'Options',
            description: langStrings.endpointCreateRequestOptions,
            parameter: [
                {
                    name: 'analyse',
                    required: false,
                    type: 'object',
                    object: 'AnalyseParameter',
                    description: langStrings.endpointCreateRequestOptionsAnalyse,
                },
                {
                    name: 'analyseAll',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestOptionsAnalyseAll,
                },
            ]
        },
        {
            title: 'AnalyseParameter',
            description: langStrings.endpointCreateRequestAnalyseParameter,
            parameter: [
                {
                    name: 'title',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterTitle
                },
                {
                    name: 'projectDates',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterProjectDates
                },
                {
                    name: 'projectNumbers',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterProjectNumbers
                },
                {
                    name: 'customerPartner',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterCustomerPartner
                },
                {
                    name: 'location',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterLocation
                },
                {
                    name: 'roles',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterRoles
                },
                {
                    name: 'competences',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterCompetences
                },
                {
                    name: 'skills',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterSkills
                },
                {
                    name: 'certificates',
                    required: false,
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestAnalyseParameterCertificates
                },
            ]
        },
        {
            title: 'RequestResponse',
            description: langStrings.endpointCreateRequestResponse,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseId
                },
                {
                    name: 'active',
                    type: 'boolean',
                    description: langStrings.endpointCreateRequestResponseActive
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseTitle
                },
                {
                    name: 'location',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseLocation
                },
                {
                    name: 'customer',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseCustomer
                },
                {
                    name: 'partner',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponsePartner
                },
                {
                    name: 'requestText',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseRequestText
                },
                {
                    name: 'dayPrice',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseDayPrice
                },
                {
                    name: 'utilization',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseUtilization
                },
                {
                    name: 'remote',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseRemote
                },
                {
                    name: 'headCount',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseHeadCount
                },
                {
                    name: 'projectStart',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseProjectStart
                },
                {
                    name: 'projectEnd',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseProjectEnd
                },
                {
                    name: 'loc',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseLoc
                },
                {
                    name: 'selectedEmployees',
                    type: 'array',
                    object: 'EmployeeCoverage',
                    description: langStrings.endpointCreateRequestResponseSelectedEmployees
                },
                {
                    name: 'suggestedEmployees',
                    type: 'array',
                    object: 'EmployeeCoverage',
                    description: langStrings.endpointCreateRequestResponseSuggestedEmployees
                },
                {
                    name: 'roles',
                    type: 'array',
                    object: 'RequestRole',
                    description: langStrings.endpointCreateRequestResponseRoles
                },
                {
                    name: 'competences',
                    type: 'array',
                    object: 'RequestCompetence',
                    description: langStrings.endpointCreateRequestResponseCompetences
                },
                {
                    name: 'skills',
                    type: 'array',
                    object: 'RequestSkill',
                    description: langStrings.endpointCreateRequestResponseSkills
                },
                {
                    name: 'certificates',
                    type: 'array',
                    object: 'RequestCertificate',
                    description: langStrings.endpointCreateRequestResponseCertificates
                },
                {
                    name: 'version',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseVersion
                },
                {
                    name: 'changedAt',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseChangedAt
                },
                {
                    name: 'changedBy',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseChangedBy
                },
                {
                    name: 'createdAt',
                    type: 'string',
                    description: langStrings.endpointCreateRequestResponseCreatedAt
                },
                {
                    name: 'createdBy',
                    type: 'number',
                    description: langStrings.endpointCreateRequestResponseCreatedBy
                },
            ]
        },
        {
            title: 'RequestRole',
            description: langStrings.endpointCreateRequestRole,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'number',
                    description: langStrings.endpointCreateRequestRoleId
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestRoleTitle
                },
            ]
        },
        {
            title: 'RequestCompetence',
            description: langStrings.endpointCreateRequestCompetence,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'number',
                    description: langStrings.endpointCreateRequestCompetenceId
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestCompetenceTitle
                },
                {
                    name: 'level',
                    type: 'number',
                    description: langStrings.endpointCreateRequestCompetenceLevel,
                },
            ]
        },
        {
            title: 'RequestSkill',
            description: langStrings.endpointCreateRequestSkill,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'number',
                    description: langStrings.endpointCreateRequestSkillId
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestSkillTitle
                },
            ]
        },
        {
            title: 'RequestCertificate',
            description: langStrings.endpointCreateRequestCertificate,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'number',
                    description: langStrings.endpointCreateRequestCertificateId,
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestCertificateTitle,
                },
            ]
        },
        {
            title: 'EmployeeCoverage',
            description: langStrings.endpointCreateRequestEmployeeCoverage,
            response: true,
            parameter: [
                {
                    name: 'id',
                    type: 'number',
                    description: langStrings.endpointCreateRequestEmployeeCoverageId,
                },
                {
                    name: 'title',
                    type: 'string',
                    description: langStrings.endpointCreateRequestEmployeeCoverageTitle,
                },
                {
                    name: 'skillCoverage',
                    type: 'number',
                    description: langStrings.endpointCreateRequestEmployeeCoverageSkillCoverage,
                },
                {
                    name: 'Score',
                    type: 'number',
                    description: langStrings.endpointCreateRequestEmployeeCoverageScore,
                },
            ],
        },
    ];
    return { apiEndpoints, apiObjects };
}
