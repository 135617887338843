import { Add, Delete, Edit, Done, People, Close } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { DialogManagerContext } from '@sharedReact/Dialog/DialogManager';
import { IPermissionGroup, IEntitiesPermissions, IPermissionActions, defaultEntitiesPermissions, disabledEntitiesPermissions, hiddenEntitiesPermissions } from '@sharedInterfaces/IPermissions';
import { ICompanyUser } from '@sharedInterfaces/ICompanySettings';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';
import Dialog from '@sharedReact/Dialog/Dialogs/Dialog/Dialog';
import editPermissionGroups from '@src/APIs/graphQl/Company/editPermissionGroups';
import Company from '@src/Objects/Company';

import CardBox from '../../CardBox/CardBox';
import RowElement from '../../../sharedReact/General/Forms/RowElement/RowElement';
import TextInput from '../../formsControls/inputs/TextInput/TextInput';
import './EditPermissionGroupsList.css';
import RoundIconButton from '../../Buttons/RoundIconButton/RoundIconButton';

interface EditPermissionGroupsListProps
{
    permissionGroups: IPermissionGroup[]
    employees: ICompanyUser[]
    setCompany: (val: Company) => void
}

/**
 * EditPermissionGroupsList function.
 *
 * @param {EditPermissionGroupsListProps} props - The props object.
 * @returns {JSX.Element} - The JSX element.
 */
function EditPermissionGroupsList(props: EditPermissionGroupsListProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    // const [edit, setEdit] = React.useState(false as boolean);
    const [saving, setSaving] = React.useState(false as boolean);
    const [changed, setChanged] = React.useState(false as boolean);
    const [editGroups, setEditGroups] = React.useState(null as null | IPermissionGroup[]);

    return (
        <CardBox title={
            <div className='editPermissionGroupsListTitle'>

                {langStrings.permissionGroups}
                {!saving ?
                    < div className='edit' onClick={async () =>
                    {
                        if (saving) return;
                        if (editGroups)
                        {
                            if (!changed)
                            {
                                setEditGroups(null);
                                setSaving(false);
                                setChanged(false);
                                return;
                            }
                            setSaving(true);
                            const result = await editPermissionGroups(editGroups)
                                .then((result) =>
                                {
                                    props.setCompany(result);
                                    setEditGroups(null);
                                    setSaving(false);
                                    setChanged(false);
                                }).catch(ex =>
                                {
                                    setTimeout(() =>
                                    {
                                        setSaving(false);
                                        setChanged(false);
                                    }, 3000);
                                    alert(ex);

                                });
                            return result;
                        } else
                        {
                            setEditGroups(props.permissionGroups.slice());
                            setChanged(false);
                        }
                    }}>
                        <RoundIconButton icon={!editGroups ? <Edit /> : <Done />}
                            helperText={''}
                            size={'small'}
                            onClick={() => { }} />
                        {/* <div className='icon'>{!editLevel ? <Edit /> : <Done />}</div> */}
                    </div> :
                    null
                }
            </div>
        }>

            <div className='editPermissionGroupsList'>

                <div className='permissionGroups'>
                    {!editGroups &&
                        props.permissionGroups.map(group =>
                            <div key={group.id} className='permissionGroup'>
                                <div className='headLine'>
                                    <RowElement title={langStrings.title} alignTitle={'left'}>
                                        <div className='title'>{group.title}</div>
                                    </RowElement>
                                    <div className='id'>{langStrings.id} {group.id}</div>

                                </div>
                            </div>)
                    }
                    {editGroups &&
                        editGroups.map((group, index) => <PermissionGroup key={index}
                            group={group}
                            index={index}
                            editGroups={editGroups}
                            employees={props.employees.filter(e => e.permissionGroup.id === group.id)}
                            onEdit={(groups) =>
                            {
                                setEditGroups(groups);
                                setChanged(true);
                            }}
                        />
                        )
                    }
                    {editGroups &&
                        <Button size={'normal'} text={langStrings.newPermissionGroup} icon={<Add />} onClick={function (): void
                        {
                            const newEditGroups = editGroups.slice();
                            const maxId = editGroups.map(g => g.id).reduce((last, current) =>
                            {
                                return last >= current ? last : current;
                            }, 0)

                            const entitiesPermissions: IEntitiesPermissions = defaultEntitiesPermissions;

                            const newGroup: IPermissionGroup = {
                                id: maxId + 1,
                                title: langStrings.newPermissionGroup,
                                entities: entitiesPermissions,
                            };

                            newEditGroups.push(newGroup);
                            setEditGroups(newEditGroups);
                            setChanged(true)
                        }} disabled={saving}
                        />
                    }
                </div>
            </div>
        </CardBox>
    );
}

export default EditPermissionGroupsList;

interface PermissionGroupProps
{
    group: IPermissionGroup;
    editGroups: IPermissionGroup[];
    index: number;
    employees: ICompanyUser[];
    onEdit: (groups: IPermissionGroup[]) => void
}

/**
 * Represents a permission group.
 *
 * @param {PermissionGroupProps} param0 - The parameters for the permission group.
 * @param {group} param0.group - The group.
 * @param {editGroups} param0.editGroups - The edit groups.
 * @param {index} param0.index - The index.
 * @param {onEdit} param0.onEdit - The onEdit function.
 * 
 * @returns {JSX.Element} The permission group element.
 */
function PermissionGroup({ group, editGroups, index, employees, onEdit }: PermissionGroupProps)
{
    const { openDialog } = useContext(DialogManagerContext)
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;


    const actionKeys = Object.keys(group.entities.Skills) as (keyof IPermissionActions)[];
    return (
        <div key={group.id} className='permissionGroup editMode'>
            <div className='headLine'>
                <RowElement title={langStrings.title} alignTitle={'left'}>
                    <TextInput value={group.title} onChange={function (val: string): void
                    {
                        const newEditLevel = editGroups.slice().map(l => Object.assign({}, l));
                        newEditLevel[index].title = val;
                        onEdit(newEditLevel);
                    }} />
                </RowElement>
                <div className='id'>{langStrings.id} {group.id}</div>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <td>{langStrings.entity}</td>
                            {
                                actionKeys.map((key) =>
                                    <td key={key}>
                                        {key}
                                    </td>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.entries(group.entities).map(([key, value]) =>
                            {
                                const entityKey = key as keyof IEntitiesPermissions;
                                return <tr key={entityKey}>
                                    <td>{entityKey}</td>
                                    {
                                        actionKeys.map(
                                            (actionKey) =>
                                            {
                                                const checked: boolean = value[actionKey];
                                                return <td key={actionKey} className={actionKey}>
                                                    <Checkbox
                                                        style={{ opacity: hiddenEntitiesPermissions[entityKey][actionKey] ? 0 : 1 }}
                                                        disabled={
                                                            disabledEntitiesPermissions[entityKey][actionKey]}
                                                        checked={checked ? true : false}
                                                        onChange={(event) =>
                                                        {
                                                            const newGroups = editGroups.slice();
                                                            const editGroup = newGroups.find(e => e.id === group.id) as IPermissionGroup;
                                                            editGroup.entities[entityKey][actionKey] = event.target.checked;
                                                            onEdit(newGroups);
                                                        }}
                                                    />
                                                </td>
                                            }
                                        )
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='btnRow'>
                <IconButton
                    onClick={() =>
                    {
                        const newEditLevel = editGroups.slice();

                        newEditLevel.splice(index, 1);
                        onEdit(newEditLevel);
                    }}
                >
                    <Delete />
                </IconButton>
                <IconButton
                    title={langStrings.showMember}
                    onClick={() =>
                    {
                        openDialog(
                            <ShowMemberDialog
                                id="ShowMemberDialog"
                                group={group}
                                employees={employees}
                            />
                        )
                    }}
                >
                    <People />
                </IconButton>
            </div>
        </div>
    )
}

const tableStyle: React.CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '20px 0'
};

const rowStyle: React.CSSProperties = {
    borderBottom: '1px solid #ccc'
};

const cellStyle: React.CSSProperties = {
    padding: '10px',
    textAlign: 'left'
};

const evenRowStyle: React.CSSProperties = {
    ...rowStyle,
    backgroundColor: '#f2f2f2'
};
interface ShowMemberDialogProps
{
    id: string
    group: IPermissionGroup;
    employees: ICompanyUser[];
    resolve?: () => void
}
/**
 * Displays the member dialog.
 *
 * @param {ShowMemberDialogProps} group - The group.
 * @param {function} onClose - The function to be called when the dialog is closed.
 * @returns {JSX.Element} The JSX element representing the member dialog.
 */
function ShowMemberDialog({ id, group, employees, resolve: onClose }: ShowMemberDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).settings;

    return (
        <Dialog
            id={id}
            footer={
                <>
                    <Button text={langStrings.oftenUsed.close} icon={<Close />} onClick={() =>
                    {
                        onClose && onClose();
                    }}
                    />
                </>
            }
            title={langStrings.memberOfGroup.replace('[GROUPNAME]', `"${group.title}"`)}
            onClose={
                function (): void
                {
                    onClose && onClose();
                }
            } >

            {employees.length > 0 &&
                <table style={tableStyle}>
                    <tbody>
                        {
                            employees.sort((a, b) => a.firstname.localeCompare(b.firstname) || a.lastname.localeCompare(b.lastname)).map((e, index) =>
                                <tr key={e.id} style={index % 2 === 0 ? rowStyle : evenRowStyle}>
                                    <td style={cellStyle}>
                                        <Typography variant='body1'>
                                            {e.firstname}
                                        </Typography>
                                    </td>
                                    <td style={cellStyle}>
                                        <Typography variant='body1'>
                                            {e.lastname}
                                        </Typography>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            }
            {employees.length === 0 &&
                <Typography variant='subtitle2'>
                    {langStrings.memberOfGroupEmpty}
                </Typography>
            }
        </Dialog >
    )
}