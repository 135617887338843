import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editDefaultPermissionGroup( $permissionGroup: Int!) {
    editDefaultPermissionGroup(permissionGroup: $permissionGroup) 
    ${companySettingsGql}
},
`;

export default async function editDefaultPermissionGroup(permissionGroup: number)
{
    const variables: OperationVariables = {
        permissionGroup
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}