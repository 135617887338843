import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";
import { companySettingsGql } from "../Company/getCompanySettings";

const query = gql`
mutation changeOULeader($id:Int!, $leader: Int!) {
    changeOULeader(id: $id,leader: $leader) 
    ${companySettingsGql}
},
`;

export default async function changeOULeader(id: number, leader: number)
{
    const variables: OperationVariables = { id, leader };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c));
}