import { OperationVariables, gql } from "@apollo/client";
import ICompanySettings from "@sharedInterfaces/ICompanySettings";
import Company from "@src/Objects/Company";

import GraphQL from "../graphQL";

import { companySettingsGql } from "./getCompanySettings";

const query = gql`
mutation editMailEndings( $allowedMailEndings: [String!]!) {
    editMailEndings(allowedMailEndings: $allowedMailEndings) 
    ${companySettingsGql}
},
`;

export default async function editMailEndings(allowedMailEndings: string[])
{
    const variables: OperationVariables = {
        allowedMailEndings
    };
    return GraphQL.mutate<ICompanySettings>(query, variables)
        .then(c => new Company(c))
}